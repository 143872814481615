import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { getsuggestions } from '../actions/results';
import { get_all_services } from '../actions/form';

import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserHomepageNavbar';

// sections
import BannerTitle from '../components/BannerTitle';
import BannerSearch from '../components/BannerSearch';
import BannerServices from '../components/BannerServices';
import BannerBenefits from '../components/BannerBenefits';
import SnackBar from "./SnackBar";
import SnackBarInfo from "./SnackBarInfo";
import { isChrome } from "react-device-detect";

import Footer from '../components/Footer';
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;

class HomePage extends React.Component {
  static propTypes = {
    autocompletes: PropTypes.any.isRequired,
    getsuggestions: PropTypes.func.isRequired,
    get_all_services: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getsuggestions();
    this.props.get_all_services();

    localStorage.removeItem('miles');
    localStorage.removeItem('zipcode');
    //console.log(this.props.errors)
    // scroll.scrollToTop();

    const get_scroll_case = localStorage.getItem('scroll_home');
    if (get_scroll_case) {
      scroll.scrollMore(500)
      setTimeout(() => {
        localStorage.removeItem('scroll_home');
      }, 2000);
    } else {
      scroll.scrollToTop();
    }
  }

  // scroll.scrollTo(100);
  // },
  // scrollMore: function() {
  //   scroll.scrollMore(100)

  render() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12}>
          <Fragment>
            <div>{this.props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
            <div>{!this.props.auth.token && <GuestNavBar />}</div>
            <BannerTitle />
            <BannerSearch auto_complete={this.props.autocompletes} />
            <BannerServices />
            <BannerBenefits />
            {!isChrome && (localStorage.getItem("user_acknowlege") === null || localStorage.getItem("user_acknowlege") === undefined) ? <SnackBar /> : null}
            <br />
            {localStorage.getItem("user_info_acknowlege") === null || localStorage.getItem("user_info_acknowlege") === undefined ? <SnackBarInfo /> : null}

            <Footer />
          </Fragment>
        </Grid>
        {/* <QuickSuggestionsArea /> */}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  autocompletes: state.results.autocompletes,
  errors: state.errors
});

export default connect(mapStateToProps, { get_all_services, getsuggestions })(HomePage);
