/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import GuestNavBar from "../components/GuestNavBar";
import PrimarySearchAppBar from "../components/AuthorizedUserHomepageNavbar";
import {
  Box,
  Typography,
} from "@material-ui/core";
import clsx from 'clsx';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { sendUserData } from "../actions/form.js";
import { loadUser } from "../actions/auth.js";

import axios from "axios";


import {
  call_save,
  reset_go_to_next,
  get_payment_method,
  clear_error,
  make_booking_first_signal
} from "../actions/form.js";
import DashboardWizardStepsBooking from "../components/DashboardWizardStepsBooking";
import Loader from "../components/Spinner";
import Loading from "./Loading";

import { createMessage } from "../actions/messages";
import { useDispatch } from "react-redux";
import * as Scroll from "react-scroll";
import { isSafari } from 'react-device-detect';

var scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  stepper: {
    width: "84%",
    margin: theme.spacing(8, "auto", 4),
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "0",
    marginBottom: "0",
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  wizardContainer: {
    margin: theme.spacing(2, 'auto', 4),
    [theme.breakpoints.down("550")]: {
      padding: '0 !important',
    },
  },
  form: {
    // margin: 'auto',
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    fontFamily: "Dosis"
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  imageSelector: {
    fontSize: "8rem",
    paddingLeft: theme.spacing(0),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#FF3D00!important",
    marginRight: "auto",
    minHeight: '30px',
    minWidth: '180px'
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginRight: theme.spacing(4),
    // marginBottom: theme.spacing(2)
  },
  sized: {
    width: '220px',
    height: '30px'
  }
}));

function getSteps() {
  return ["Booking", "Payment method", "Charges & Services"];
}

function WizardPetOwner(props) {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading_card, setLoading] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    //return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const dispatch = useDispatch();

  const handleNext = async () => {
    // console.log(activeStep)
    if (props.form.user_editing === true) {
      console.log("Saving...");
      props.call_save();
      props.clear_error();
    } else {
      console.log("Check status...");

      const no_primary_card = localStorage.getItem(
        `no_primary_card_${props.user.id}`
      );
      const payment_methods = localStorage.getItem(
        `payment_methods_${props.user.id}`
      );
      const current_stage = localStorage.getItem(
          `booking_status_${props.user.id}`
        );

      if (payment_methods === "false" && current_stage === '1') {
        dispatch(
          createMessage("Please add a payment method to proceed to booking.")
        );
        scroll.scrollTo();
      } else if (no_primary_card === "true" && current_stage === '1') {
        dispatch(
          createMessage(
            "Please make sure you have a primary card on your list of cards to complete your booking."
          )
        );
        scroll.scrollTo();
        //scroll.scrollToTop();
      } else {
        // user didn't edit any data, so just move to next step
        const current_stage_pet = localStorage.getItem(
          `booking_status_${props.user.id}`
        );
        console.log(current_stage_pet)
        if (current_stage_pet === '1' || current_stage_pet === '0') {
          const check_booking = localStorage.getItem("booking_object");
          if (check_booking === null) {
            // console.log("Dont go...")
            // console.log(check_booking)
            props.make_booking_first_signal()
          } else {
            
            const created = localStorage.getItem("booking_created");
            if(created === "true"){
              const booking_api_object = localStorage.getItem("booking_api_object");
              const parsedObject = JSON.parse(booking_api_object);
              // console.log(JSON.stringify(parsedObject, null, 2));
             
              // console.log("booking_api_object?.id")
              // console.log(parsedObject?.id)
              const booking_id = parsedObject?.id

              const payment_methods_id = localStorage.getItem("owner_primary_card");
              const payment_method = payment_methods_id

              const update_id = async () => {
                setLoading(true)
                const token = props.auth.token
                if(token){
                  const Auth_Config = {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `JWT ${token}`
                    },
                  };
                  const body = JSON.stringify({ payment_method });
                  const res = await axios.patch(`${process.env.REACT_APP_API_URL}/bookings/${booking_id}/`, body, Auth_Config)
                  console.log("res.data: Updating Payment ID")
                  // console.log(res.data)
                  return res.data;
                }
              }

              if(booking_id && payment_method){
                await update_id()
                setLoading(false)

                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                  newSkipped = new Set(newSkipped.values());
                  newSkipped.delete(activeStep);
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);

                // code for saving recent stage....


                if (current_stage_pet) {
                  var next_stage = parseInt(current_stage_pet) + 1;
                  localStorage.setItem(`booking_status_${props.user.id}`, next_stage);
                }
              
              }

            }

          }
        } 
      }
    }
  };

  React.useEffect(() => {
    if (props.form.go_to_next === true) {
      // console.log("go now")
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);

      // code for saving recent stage....

      const current_stage = localStorage.getItem(
        `booking_status_${props.user.id}`
      );
      //console.log(current_stage)
      if (current_stage) {
        var next_stage = parseInt(current_stage) + 1;
        localStorage.setItem(`booking_status_${props.user.id}`, next_stage);
      }
      // if(current_stage === "3"){
      //    var stage = 0;
      //    var stage_ = parseInt(stage)
      //    localStorage.setItem("booking_status_", stage_);
      // }
      props.reset_go_to_next(); // Resetting go_to_next for next redirect
    }
  }, [props.form.go_to_next]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // code for saving recent stage....
    const current_stage_pet = localStorage.getItem(
      `booking_status_${props.user.id}`
    );
    if (current_stage_pet) {
      var next_stage = parseInt(current_stage_pet) - 1;
      localStorage.setItem(`booking_status_${props.user.id}`, next_stage);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const remove_id = () => {
    localStorage.removeItem(`stage_value_${props.user.id}`);
  };

  React.useEffect(() => {
    props.loadUser();
    props.get_payment_method();
    localStorage.setItem("booking_created", "false")
  }, []);

  React.useEffect(() => {
    // resort to setting it to 0
    var stage = 0;
    var stage_ = parseInt(stage);
    localStorage.setItem(`booking_status_${props.user.id}`, stage_);
  }, []);

  return (
    <Box className={classes.root}>
      <div>{props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ""}</div>
      <div>{!props.auth.token ? <GuestNavBar /> : ""}</div>
      <div>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  <span style={{ fontFamily: "Dosis", fontSize: "17px" }} >{label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Container 
            maxWidth={activeStep === 1 ? "sm" : "md"}
            // maxWidth={"sm"}
            className={classes.wizardContainer
        }>
          {activeStep === 0 ?
          <Typography variant="h5" className={classes.formHeader}>
            Booking information
          </Typography>: null}
          {activeStep === 1 ?
          <Typography variant="h5" className={classes.formHeader}>
            Price Breakdown
          </Typography>: null}
          <form noValidate className={classes.form} autoComplete="off">
            {props?.payment_methods ? (
              <DashboardWizardStepsBooking
                payment_methods={props?.payment_methods}
                id={props?.id}
                step={activeStep}
                setActiveStep={setActiveStep}
              />
            ) : (
              <Loading />
            )}
          </form>

         { localStorage.getItem("booking_created") !== "true" ? null :
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Link to="/dashboard">
                  <Button
                    id="finishLink"
                    variant="contained"
                    color="primary"
                    onClick={remove_id}
                    className={classes.buttonNext}
                  >
                    Go to dashboard
                  </Button>
                </Link>
              </div>
            ) : (
              <div className={classes.buttonSection}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.buttonBack}
                >
                  <span style={{ fontFamily: "Dosis", fontSize: "17px" }}>Back</span>
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    <span style={{ fontFamily: "Dosis", fontSize: "17px" }}>Skip</span>
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  
                    <Button
                      id="finishLink2"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.location.href = '/dashboard';
                    }}
                      className={classes.buttonNext}
                    >
                      <span style={{ fontFamily: "Dosis", fontSize: "17px" }}>Go to dashboard</span>
                    </Button>
                
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    style={{
                      maxWidth: "2000px",
                      maxHeight: "35px",
                      minWidth: "200px",
                      minHeight: "35px",
                    }}
                    className={
                      isSafari ? clsx(classes.buttonNext, classes.sized) :
                        classes.buttonNext
                    }>
                    {(props.form.signal_save || loading_card) ? 
                      <Loader /> : 
                      <span style={{ fontFamily: "Dosis", fontSize: "17px" }}>
                      Complete booking
                    </span>}
                  </Button>
                )}
              </div>
            )}
          </div>}
        </Container>
      </div>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services,
  payment_methods: state.services.payment_methods,
});
export default connect(mapStateToProps, {
  sendUserData,
  loadUser,
  call_save,
  clear_error,
  make_booking_first_signal,
  reset_go_to_next,
  get_payment_method,
})(WizardPetOwner);
