import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

export default function SimpleSnackbar() {
  const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleClose_ = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    localStorage.setItem("user_acknowlege", "OK")
  };

  const action = (
    <React.Fragment>
      <Button style={{ color: "#FF3D00", marginRight: '40px', marginTop: '3px' }} size="small" onClick={handleClose_}>
        OK
      </Button>
     {/* <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>*/}
    </React.Fragment>
  );

  return (
    <div>
     {/* <Button onClick={handleClick}>Open simple snackbar</Button>*/}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Please use google chrome for best experience"
        action={action}
      />
    </div>
  );
}