import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DashboardSinglePetAccordian from "./DashboardSinglePetAccordian_";
import {
  getPetData,
  user_delete_pet,
  init_fetch,
  deinit_fetch,
  get_breeds,
} from "../actions/form.js";

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  helpText2: {
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(3),
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  spin: {
    height: 50,
    width: 50,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
}));

function DashboardViewPetSection(props) {
  const classes = useStyles();
  const [one_pet, setOne_pet] = React.useState(false);
  const [one_pet_id, setOne_pet_id] = React.useState("");

  const pets = props.pets;
  const len = pets.length;

  React.useEffect(() => {
    if (props.pets) {
      if (props.pets.length === 1) {
        setOne_pet(true);
        setOne_pet_id(props.pets[0].id);
      } else {
        setOne_pet(false);
      }
    }
  }, [props.pets]);

  // sorting them
  if (len > 0) {
    pets.sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  React.useEffect(() => {
    const id = props.user.id;
    props.getPetData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.id]);

  const fetch_again = (e) => {
    const usid = props.user.id;
    props.getPetData(usid);
    props.getPetData(usid);
  };
  React.useEffect(() => {
    props.get_breeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.fetch === true) {
      const usid = props.user.id;
      props.getPetData(usid);
      props.deinit_fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.id, props.fetch]);

  return (
    <div className={classes.parentDiv}>
      {pets.slice(0, len).map((pet, i) => (
        <div>
          {pet.pet_type.id === 2 ? (
            <div>
              {props.breeds_cats !== null ? (
                <div>
                  {props.breeds_cats.filter((pet_) => pet_ === pet.breed)
                    .length === 0 ? (
                    <DashboardSinglePetAccordian
                      pet={pet}
                      one_pet={one_pet}
                      one_pet_id={one_pet_id}
                      key={i}
                      api_name={false}
                      fetch_again={fetch_again}
                      pet_added={props.pet_added}
                    />
                  ) : (
                    <DashboardSinglePetAccordian
                      pet={pet}
                      one_pet={one_pet}
                      one_pet_id={one_pet_id}
                      key={i}
                      api_name={true}
                      fetch_again={fetch_again}
                      pet_added={props.pet_added}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {pet.pet_type.id === 1 ? (
            <div>
              {props.breeds_types !== null ? (
                <div>
                  {props.breeds_types.filter((pet_) => pet_ === pet.breed)
                    .length === 0 ? (
                    <DashboardSinglePetAccordian
                      pet={pet}
                      one_pet={one_pet}
                      one_pet_id={one_pet_id}
                      key={i}
                      api_name={false}
                      fetch_again={fetch_again}
                      pet_added={props.pet_added}
                    />
                  ) : (
                    <DashboardSinglePetAccordian
                      pet={pet}
                      one_pet={one_pet}
                      one_pet_id={one_pet_id}
                      key={i}
                      api_name={true}
                      fetch_again={fetch_again}
                      pet_added={props.pet_added}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {pet.pet_type.id === 3 ? (
            <div>
              {props.breeds_types !== null ? (
                <div>
                  {props.breeds_types.filter((pet_) => pet_ === pet.breed)
                    .length === 0 ? (
                    <DashboardSinglePetAccordian
                      pet={pet}
                      key={i}
                      api_name={false}
                      fetch_again={fetch_again}
                      pet_added={props.pet_added}
                    />
                  ) : (
                    <DashboardSinglePetAccordian
                      pet={pet}
                      key={i}
                      api_name={true}
                      fetch_again={fetch_again}
                      pet_added={props.pet_added}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  fetch: state.pets.fetch,
  types: state.pets.pet_types,
  loading: state.pets.isLoading,
  breeds_types: state.pets.breeds,
  breeds_cats: state.pets.breeds_cats,
});

export default connect(mapStateToProps, {
  getPetData,
  user_delete_pet,
  init_fetch,
  deinit_fetch,
  get_breeds,
})(DashboardViewPetSection);
