import React from "react";
// import Chip from "@material-ui/core/Chip";
import ServiceCardDetails from "./ServiceCardDetails";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

const useStyles = makeStyles((theme) => ({
  chip: {
    width: '50%',
    [theme.breakpoints.down("950")]: {
      width: '90%',
    },
    [theme.breakpoints.down("1250")]: {
      width: '80%',
    },
    [theme.breakpoints.down("1110")]: {
      width: '80%',
    },
    
    [theme.breakpoints.down("550")]: {
      margin: 'auto',
      width: 'auto'
    },
  },
  custom_chip: {
    width: '80%',
    height: '40px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    transition: 'background-color 0.2s ease-in',
    backgroundColor: '#e0e0e0',
    alignItems: 'center',
    justifyContent: "center",
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#cccccc',
    },
    [theme.breakpoints.down("950")]: {
      width: '100%',
    },
    [theme.breakpoints.down("1250")]: {
      width: '90%',
    },
    [theme.breakpoints.down("1110")]: {
     width: '100%',
    },
    
    [theme.breakpoints.down("750")]: {
      width: '100%',
    },
  },
  titleNormal: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    color: "#222222", 
    width: '100%',
    padding: '10px',
    marginLeft: '10px',
    whiteSpace: 'nowrap',      
    overflow: 'hidden',        
    textOverflow: 'ellipsis', 
  },
  image: {
    padding: '10px',
    height: "20px", 
    width: "20px"
  }
}));

export default function DetailsService({ label, image, has_additional_rates, service }) {
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <span>
      <div onClick={handleClick} className={classes.custom_chip}>
         <img style={{ marginLeft: '12.5px' }} src={image} height="20px" width="20px" alt="" />
         <div className={classes.titleNormal}>{label}
              {has_additional_rates ? <span style={{ marginLeft: '5px'}} >
                <AddBoxRoundedIcon fontSize="inherit" />
               </span> : null}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ServiceCardDetails 
        	service={service} 
        	has_additional_rates={has_additional_rates}
        />
      </Popover>
    </span>
  );
}
