/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';

import { Grid, TextField, IconButton } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/semantic-ui.css'
import InputAdornment from '@material-ui/core/InputAdornment';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { 
  sendUserData, 
  user_editing, 
  reset_save_signal, 
  clear_error, 
  user_missing_info_turn_off,
  user_missing_info 
} from '../actions/form.js';
import { loadUser } from '../actions/auth.js';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  stepper: {
    width: '100%',
    margin: theme.spacing(8, 'auto', 4),
    paddingLeft: '0',
    paddingRight: '0'
  },
  input: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  inputSelect: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputBio: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  radioLabel: {
    '& span': {
      fontSize: '13px!important',
      color: '#1b1f23'
    }
  },
  wizardContainer: {
    margin: theme.spacing(2, 'auto', 8)
  },
  form: {
    margin: 'auto',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  formHeader: {
    margin: theme.spacing(2, 'auto', 4),
    textAlign: 'center'
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  },
  formLabel_: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(0)
  },
  formLabel_phone: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
  },
  formLabelPetPrefs: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    margin: theme.spacing(1, 0)
  },
  formLabelPetExperience: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    lineHeight: '2.5em'
  },
  formGroupLabel: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('960')]: {
      marginTop: "20px"
    }

  },
  formGroupLabelPetPrefs: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    marginBottom: theme.spacing(4)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(6)
  },
  imageSelector: {
    fontSize: '8rem',
    width: theme.spacing(14),
    height: theme.spacing(14),
    paddingLeft: theme.spacing(0)
  },
  selector: {
    paddingLeft: theme.spacing(0)
  },
  small: {
    margin: theme.spacing(1, 'auto', 0),
    display: 'block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: '#663399!important'
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: 'auto'
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#663399!important',
    marginRight: 'auto'
  },
  buttonSection: {
    margin: 'auto',
    float: 'right',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  fieldSetRadio: {
    marginLeft: theme.spacing(1)
  },
  adornment: {
    '& p': {
      fontSize: '13px'
    }
  },
  root_: {
    margin: theme.spacing(2, 0)
  }
}));

function DashboardContactSectionWizard(props) {
  const classes = useStyles();

  const { user } = props;
  const user_id = user.id;

  const format_contact = (contact) => {
    if(contact !== null && contact !== ""){
      contact.replace(/[-\s]/g, "")
      let new_number = contact;
      if(!new_number.includes("+")){
          new_number = `+${new_number}`
      }
      if(!new_number.includes("+") && new_number[0] !== '1'){
          new_number = `+1${new_number}`
      }
      if(new_number.includes("+") && new_number[1] !== '1'){
          new_number = new_number.replace(/[+\s]/g, "");
          new_number = `+1${new_number}`
      }
      return new_number
    }else{
      return null
    }
  }

  // data
  const [phone_number, setPhone_number] = useState(
    format_contact(props.user.phone_number)
  );
  const [alternate_phone_number, setAlternate_Phone_number] = useState(
    format_contact(props.user.alternate_phone_number)
  );
  const [emergency_contact_phone_number, setEmergency_Contact_Phone_Number] = useState(
    format_contact(props.user.emergency_contact_phone_number)
  );

  const [email] = useState(props.user.email);
  const [emergency_contact_name, setEmergency_Contact_Name] = useState(
    props.user.emergency_contact_name
  );
  const [emergency_contact_email, setEmergency_Contact_Email] = useState(
    props.user.emergency_contact_email
  );

  const [edited, setEdited] = useState(false);
  const [social_number, setSocial_number] = useState(props.user.ssn);
  const [error_email, setError_email] = useState(false);
  const [error_phone1, setError_phone1] = useState(false);
  const [error_phone2, setError_phone2] = useState(false);
  const [error_phone3, setError_phone3] = useState(false);
  const [error_ssn, setError_ssn] = useState(false);
  const [required_inputs, setAllrequired] = useState(false);

  React.useEffect(() => {
    props.loadUser();
    props.loadUser();
    props.clear_error()
  }, []);

  React.useEffect(() => {
    if (props.user.account_type === 'service_provider') {
      if(phone_number === null  || phone_number === "" || 
        social_number === null  || social_number === "" ||        
        alternate_phone_number === null  || alternate_phone_number === ""  
      ){
        props.user_missing_info();
      }else{
        props.user_missing_info_turn_off()
      }
    }else{
      if(phone_number === null  || phone_number === "" ||         
        alternate_phone_number === null  || alternate_phone_number === ""  
      ){
        props.user_missing_info();
      }else{
        props.user_missing_info_turn_off()
      }
    }

    if (props.form.signal_save === true) {
      console.log("Here4444")
      var mailFormat =
        /^([a-zA-Z0-9_\.\-!#$%&'*+/=?^`{|}~"(),:;<>[\]])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

      if (!mailFormat.test(emergency_contact_email)) {
        setError_email(true);
        props.reset_save_signal();
      } else setError_email(false);

      const isValidPhoneNumber1 = validator.isMobilePhone(phone_number);
      if (isValidPhoneNumber1 === false) {
        setError_phone1(true);
        props.reset_save_signal();
      } else setError_phone1(false);

      const isValidPhoneNumber2 = validator.isMobilePhone(alternate_phone_number);
      if (isValidPhoneNumber2 === false) {
        setError_phone2(true);
        props.reset_save_signal();
      } else setError_phone2(false);

      const isValidPhoneNumber3 = validator.isMobilePhone(emergency_contact_phone_number);
      if (isValidPhoneNumber3 === false) {
        setError_phone3(true);
        props.reset_save_signal();
      } else setError_phone3(false);

      if (props.user.account_type === 'service_provider') {
        if (social_number) {
          if (
            social_number.search(/[A-Z]/) !== -1 ||
            social_number.search(/[a-z]/) !== -1 ||
            social_number.search(/^(?=.*[!@#$%^&*()/_+'=?`\{|}~",:;</>])/) !== -1 ||
            social_number.length !== 9
          ) {
            setError_ssn(true);
            props.reset_save_signal();
          } else setError_ssn(false);
        }
      }

      if (props.user.account_type === 'service_provider') {
        if (social_number === null || social_number === undefined || social_number === '') {
          setError_ssn(true);
          props.reset_save_signal();
        }
      }

      // if(phone_number && alternate_phone_number && email && emergency_contact_name && emergency_contact_phone_number && emergency_contact_email){
      // console.log("Save data")
      let object;

      const new_phone_number =  !phone_number.includes("+") ? `+${phone_number}` : phone_number
      const new_emergency =  !emergency_contact_phone_number.includes("+") ? `+${emergency_contact_phone_number}` : emergency_contact_phone_number
      const new_alternate =  !alternate_phone_number.includes("+") ? `+${alternate_phone_number}` : alternate_phone_number


      if (props.user.account_type === 'service_provider') {
        if(
            phone_number !== null  && phone_number !== "" && 
            email !== null  && email !== "" && 
            social_number !== null  && social_number !== "" &&
            alternate_phone_number !== null  && alternate_phone_number !== "" &&  
            emergency_contact_name !== null  && emergency_contact_name !== "" && 
            new_emergency !== null  && new_emergency !== "" &&  
            emergency_contact_email !== null  && emergency_contact_email !== "" 
          ){
            setAllrequired(false)
            object = {
              phone_number: new_phone_number.replace(/ /g, ''),
              alternate_phone_number: new_alternate.replace(/ /g, ''),
              email: email,
              ssn: social_number,
              emergency_contact_name: emergency_contact_name,
              emergency_contact_phone_number: new_emergency.replace(/ /g, ''),
              emergency_contact_email: emergency_contact_email
            };
            const timer = sendReq(object, user_id);
            return () => clearTimeout(timer);
        }else{
          setAllrequired(true)
          props.reset_save_signal();
        }
        
      } else {
        if(
            phone_number !== null  && phone_number !== "" && 
            email !== null  && email !== "" && 
            alternate_phone_number !== null  && alternate_phone_number !== "" &&  
            emergency_contact_name !== null  && emergency_contact_name !== "" && 
            new_emergency !== null  && new_emergency !== "" &&  
            emergency_contact_email !== null  && emergency_contact_email !== ""
          ){
           setAllrequired(false)
            object = {
              phone_number: new_phone_number.replace(/ /g, ''),
              alternate_phone_number: new_alternate.replace(/ /g, ''),
              email: email,
              // ssn:                            social_number,
              emergency_contact_name: emergency_contact_name,
              emergency_contact_phone_number: new_emergency.replace(/ /g, ''),
              emergency_contact_email: emergency_contact_email
            };
            console.log("object owner")
            const timer = sendReq(object, user_id);
            return () => clearTimeout(timer);
        }else{
           setAllrequired(true)
           props.reset_save_signal();
        }
      }
      // }
    }
  }, [
    phone_number,
    social_number,
    alternate_phone_number,
    emergency_contact_name,
    emergency_contact_phone_number,
    emergency_contact_email,
    props.form.signal_save
  ]);
  
  // React.useEffect(() => {
  //   if (phone_number || alternate_phone_number || emergency_contact_phone_number) {
  //     let new_number;
  //     // number does not have a + 
  //     if(!phone_number.includes("+")){
  //       new_number = `+${phone_number}`
  //       if(new_number[1] !== '1'){
  //         new_number = `+1${phone_number}`
  //       }
  //       setPhone_number(new_number)
  //     }
  //     console.log(new_number)
  //     console.log(phone_number)
  //     // console.log(alternate_phone_number)
  //     // console.log(emergency_contact_phone_number)
  //     // setPhone_number
  //     // setAlternate_Phone_number
  //     // setEmergency_Contact_Phone_Number
  //   }
  // }, [phone_number, setPhone_number, alternate_phone_number, emergency_contact_phone_number]);

  React.useEffect(() => {
    if (edited === true) {
      if (props.form.user_editing === false) {
        // avoiding multiple dispatches when boolean is already true
        props.user_editing();
      } else {
        setEdited(false);
      }
    }
  }, [props.form.user_editing, edited]);

  const dispatch = useDispatch();
  const [showSSN, setShowSSN] = useState(false);
  const handleClickShowSSN = () => setShowSSN(!showSSN);

  function sendReq(data, user_id) {
    const timer = setTimeout(() => {
      dispatch(sendUserData(data, user_id));
    }, 0);

    return timer;
  }

  const [msg, setMsg] = React.useState(null);

  React.useEffect(() => {
    if (props.error.msg) {
      if (props.error.msg.phone_number) {
        setAllrequired(true)
        setMsg(props.error.msg.phone_number)
        props.reset_save_signal();
        props.user_missing_info()
      }
    }
  }, [props.error.msg])

  // React.useEffect(() => {
  //   props.user_missing_info_turn_off()
  // }, [])

  return (
    <div>
      <FormGroup className={classes.formGroupProfileSection}>
        <FormLabel component="label" className={classes.formGroupLabel}>
          Your Contact Information
        </FormLabel>
        {
          msg !== null ?
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMsg(null)
                    props.clear_error()
                    // {/*+12064446672*/}
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msg}
          </Alert> : null
        }
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel_phone}>
              Phone Number*
            </FormLabel>
            <div style={{ height: '10px'}} />
            <PhoneInput
              country='us'
              onlyCountries={['us','ca']}
              preferredCountries={['us']}
              placeholder="2063334444"
              countryCodeEditable={false}
              prefix="+"
              inputProps={{ style : {
                height: '45px',
                fontSize: '13px',
                width: '100%',
                color: '#1b1f23',
                border: '1px solid #cfd7de',
                borderRadius: '5px'
              }}}
              value={phone_number}
              onChange={(phone) => {
                setPhone_number('+' + phone);
                setEdited(true);
                setAllrequired(false)
                props.user_missing_info_turn_off()
              }}
            />

            {/*<Input
              id="phone_number"
              defaultValue={props.user.phone_number}
              onChange={(e) => {
                setPhone_number(e.target.value.trim());
                setEdited(true);
                setAllrequired(false)
              }}
              autoComplete="new-password"
              error={error_phone1}
              disableUnderline
              fullWidth
              placeholder="12063334567"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />*/}

            {error_phone1 ? (
              <FormHelperText error>Please enter a valid phone number. e.g 1 206 333 4444 </FormHelperText>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel_}>
              Alternative Phone Number
            </FormLabel>
            <div style={{ height: '10px'}} />
            <PhoneInput
              country='us'
              onlyCountries={['us','ca']}
              preferredCountries={['us']}
              placeholder="2063334444"
              countryCodeEditable={false}
              prefix="+"
              inputProps={{ style : {
                height: '45px',
                fontSize: '13px',
                width: '100%',
                color: '#1b1f23',
                border: '1px solid #cfd7de',
                borderRadius: '5px'
              }}}
              value={alternate_phone_number}
              onChange={(phone) => {
                setAlternate_Phone_number('+' + phone);
                setEdited(true);
                setAllrequired(false)
              }}
            />

            {/*<Input
              id="alt_phone_number"
              defaultValue={props.user.alternate_phone_number}
              onChange={(e) => {
                setAlternate_Phone_number(e.target.value.trim());
                setEdited(true);
                setAllrequired(false)
              }}
              autoComplete="new-password"
              disableUnderline
              error={error_phone2}
              fullWidth
              placeholder="12063334567"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />*/}
            {error_phone2 ? (
              <FormHelperText error>Please enter a valid phone number. e.g 1 206 333 4444</FormHelperText>
            ) : (
              ''
            )}
          </Grid>
          {/*<Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Email Address
            </FormLabel>
            <Input
              id="email"
              type="email"
              value={props.user.email}
              disableUnderline
              fullWidth
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-password"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>*/}
          {props.user.account_type === 'service_provider' ? (
            <Grid item xs={12} sm={12} lg={12}>
              <FormLabel component="label" className={classes.formLabel}>
                Social Security Number
              </FormLabel>
              <TextField
                className={classes.inputSmall}
                fullWidth
                error={error_ssn}
                //value={value}
                placeholder="222334444"
                onChange={(e) => {
                  // handleCardNumber(e.target.value)
                  setSocial_number(e.target.value.replace(/[-\s]/g, ""));
                  setEdited(true);
                  setAllrequired(false)
                }}
                defaultValue={props.user.ssn}
                required
                type={showSSN ? 'text' : 'password'}
                variant="standard"
                id="ssn"
                //label="Social Security Number"
                name="ssn"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowSSN}
                      >
                        {showSSN ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  disableUnderline: true
                }}
              />
              <FormHelperText >Please enter your SSN without any spaces or dashes in between digits. </FormHelperText>
              {error_ssn ? <FormHelperText error>Please enter a valid ssn. </FormHelperText> : ''}
            </Grid>
          ) : null}
        </Grid>
      </FormGroup>
      <FormGroup className={classes.formGroupProfileSection}>
        <FormLabel component="label" className={classes.formGroupLabel}>
          Emergency Contact Information
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Full Names
            </FormLabel>
            <Input
              onChange={(e) => {
                setEmergency_Contact_Name(e.target.value);
                setEdited(true);
              }}
              id="emerg_contact_name"
              defaultValue={props.user.emergency_contact_name}
              autoComplete="new-password"
              disableUnderline
              fullWidth
              placeholder="Full Names"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Phone Number
            </FormLabel>
            <div style={{ height: '10px'}} />
            <PhoneInput
              country='us'
              onlyCountries={['us','ca']}
              preferredCountries={['us']}
              placeholder="2063334444"
              countryCodeEditable={false}
              prefix="+"
              inputProps={{ style : {
                height: '45px',
                fontSize: '13px',
                color: '#1b1f23',
                width: '100%',
                border: '1px solid #cfd7de',
                borderRadius: '5px'
              }}}
              value={emergency_contact_phone_number}
              onChange={(phone) => {
                setEmergency_Contact_Phone_Number('+' + phone);
                setEdited(true);
              }}
            />

            {/*<Input
              onChange={(e) => {
                setEmergency_Contact_Phone_Number(e.target.value.trim());
                setEdited(true);
              }}
              id="emerg_phone_number"
              defaultValue={props.user.emergency_contact_phone_number}
              autoComplete="new-password"
              disableUnderline
              fullWidth
              error={error_phone3}
              placeholder="1 206 333 4567"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />*/}

            {error_phone3 ? (
              <FormHelperText error>Please enter a valid phone number. e.g 1 206 333 4444</FormHelperText>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <FormLabel component="label" className={classes.formLabel}>
              Email Address
            </FormLabel>
            <Input
              onChange={(e) => {
                setEmergency_Contact_Email(e.target.value);
                setEdited(true);
              }}
              id="emerg_email"
              defaultValue={props.user.emergency_contact_email}
              autoComplete="new-password"
              error={error_email}
              type="email"
              disableUnderline
              fullWidth
              placeholder="Email Address"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
            {error_email ? (
              <FormHelperText error>Please enter a valid email address. </FormHelperText>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {required_inputs ? (
          <FormHelperText error>Please provide all the information above to continue to the next step.</FormHelperText>
        ) : (
          ''
        )}
      </FormGroup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services
});
export default connect(mapStateToProps, {
  sendUserData,
  loadUser,
  user_editing,
  reset_save_signal,
  clear_error,
  user_missing_info_turn_off,
  user_missing_info
})(DashboardContactSectionWizard);