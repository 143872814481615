/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PetPreferencesSection from '../components/DashboardPetPreferencesSection';
import PetExperiencesSection from '../components/DashboardPetExperienceSection';
import { useDispatch } from 'react-redux';
import { sendUserData, getServiceData, sendServiceData } from '../actions/form';
import { makeStyles } from '@material-ui/core/styles';

import { logout, loadUser } from '../actions/auth.js';

import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  petSection: {
    marginTop: theme.spacing(8)
  }
}));

function ResponsiveDrawer(props) {
  const { user } = props;
  const user_id = user.id;
  const classes = useStyles();

  const [years, setYears] = useState('');
  React.useEffect(() => {
    if (years !== '') {
      const object = {
        experience_years: years
      };
      const timer = sendReq(object, user_id);
      return () => clearTimeout(timer);
    }
  }, [years]);

  const [months, setMonths] = useState('');

  React.useEffect(() => {
    scroll.scrollToTop();
  }, []);

  React.useEffect(() => {
    if (months !== '') {
      const object = {
        experience_months: months
      };
      const timer = sendReq(object, user_id);
      return () => clearTimeout(timer);
    }
  }, [months]);

  const [pet_preference, setPetPreference] = useState();
  React.useEffect(() => {
    if (pet_preference !== undefined) {
      const object = {
        pet_preference: pet_preference
      };
      const timer = sendReq(object, user_id);
      return () => clearTimeout(timer);
    }
  }, [pet_preference]);

  React.useEffect(() => {
    if (props.form.fetch === true) {
      props.loadUser();
    }
  }, [props.form.fetch]);

  const dispatch = useDispatch();
  function sendReq(data, user_id) {
    const timer = setTimeout(() => {
      dispatch(sendUserData(data, user_id));
    }, 2000);

    return timer;
  }


  return (
    <div className={classes.petSection}>
      <PetPreferencesSection
        setPetPreference={setPetPreference}
        pet_preference={user.pet_preference}
      />
      <PetExperiencesSection
        setYears={setYears}
        setMonths={setMonths}
        years={user.experience_years}
        months={user.experience_months}
      />
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  services: state.services
});

export default connect(mapStateToProps, {
  logout,
  loadUser,
  sendUserData,
  getServiceData,
  sendServiceData
})(ResponsiveDrawer);
