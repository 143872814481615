import React from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
  inline: {
    display: "inline",
  },
});

export default function HelpDropdownMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorHelpEl, setAnchorHelpEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHelpClose = () => {
    setAnchorHelpEl(null);
  };

  return (
    <Paper className={classes.root}>
      <MenuList
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        className={classes.helpDropDown}
        onClose={handleClose}
      >
        <MenuItem anchorEl={anchorHelpEl} onClick={handleHelpClose}>
          <a
            id="helpAchor"
            href="/faqs"
            style={{ textDecoration: "none", color: "black" }}
          >
            Help
          </a>
        </MenuItem>
        <MenuItem>
          <a
            id="tipsAnchor"
            href="/tips"
            style={{ textDecoration: "none", color: "black" }}
          >
            Tips
          </a>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
