import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(4),
  },
  inline: {
    display: "inline",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginTop: "64px",
    backgroundColor: "#fafafa",
    color: "black",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: "64px",
  },
  content: {
    flexGrow: 1,
    marginTop: "20px",
    [theme.breakpoints.down("550")]: {
      padding: "10px",
    },
  },
  ul: {
    width: "100%",
  },
  text: {
    color: "grey",
    marginBottom: "6px",
    fontSize: "15px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#FF3D00 !important",
  },
}))(LinearProgress);

function DashboardProfileCompleteness(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [open_, setOpen_] = React.useState(true);
  const [open__, setOpen__] = React.useState(true);

  const save_route = () => {
    const route = "services";
    localStorage.setItem("routing_to", route);
  };
  const save_route_ = () => {
    const route = "payment";
    localStorage.setItem("routing_to", route);
  };

  const pecent = props.user.profile_completedness.toFixed(0);

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          {props.user.account_type === "pet_owner" &&
            !localStorage.getItem("notification_closed") ? (
            <Collapse in={open}>
              <Alert
                severity={"info"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      localStorage.setItem("notification_closed", "true");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography className={classes.text}>
                  <span className={classes.text}>
                    Would you like to{" "}
                    <Link
                      to="/"
                      onClick={() => {
                        const scroll_home = "true";
                        localStorage.setItem("scroll_home", scroll_home);
                      }}
                      style={{ textDecoration: "none", color: "#FF3D00" }}
                    >
                      book
                    </Link>{" "}
                    a provider?
                  </span>
                </Typography>
              </Alert>
            </Collapse>
          ) : null}
          {props.user.account_type === "service_provider" &&
            !localStorage.getItem("notification_closed_provider") &&
            props.user.services.length === 0 ? (
            <Collapse in={open_}>
              <Alert
                severity={"info"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen_(false);
                      localStorage.setItem(
                        "notification_closed_provider",
                        "true"
                      );
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography className={classes.text}>
                  <span className={classes.text}>
                    Have you taken time to add your services and additional
                    rates? If not, please add them{" "}
                    <Link
                      to="/dashboard"
                      style={{ textDecoration: "none", color: "#FF3D00" }}
                    >
                      <span onClick={save_route}>here.</span>
                    </Link>
                  </span>
                </Typography>
              </Alert>
            </Collapse>
          ) : null}
          {props.user.account_type === "service_provider" &&
            props.user.bank_accounts_count === 0 ? (
            <Collapse in={open__} style={{ marginTop: '5px' }} >
              <Alert
                severity={"info"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen__(false);
                      // localStorage.setItem(
                      //   "notification_closed_provider",
                      //   "true"
                      // );
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography className={classes.text}>
                  <span className={classes.text}>
                    Your payments will be deposited into your Bank Account after providing services. Please add your Bank Account {" "}
                    <Link
                      to="/dashboard"
                      style={{ textDecoration: "none", color: "#FF3D00" }}
                    >
                      <span onClick={save_route_}>here.</span>
                    </Link>
                  </span>
                </Typography>
              </Alert>
            </Collapse>
          ) : null}
          {pecent === "100" ? null : (
            <main className={classes.content}>
              <Typography className={classes.text}>
                <span className={classes.text}>Profile Complete Progress</span>
              </Typography>
              <Typography className={classes.text}>
                <span className={classes.text}>
                  You have completed {pecent}% of your profile, please complete
                  your profile{" "}
                  <Link
                    to="/wizard"
                    style={{ textDecoration: "none", color: "#FF3D00" }}
                  >
                    here
                  </Link>
                </span>
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={props.user.profile_completedness}
              />
              <br />
            </main>
          )}
        </main>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(DashboardProfileCompleteness);
