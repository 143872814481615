import axios from 'axios';
import {
    PAYMENTS_FETCHED,
    OUTGOING_PAYMENTS_FETCHED
} from './types';
import { tokenConfig } from './auth';


export const getPayments = (url) => async (dispatch, getState) => {
    if(url){
        await axios
        .get(`${url}`, tokenConfig(getState))
        .then((res) => {
            dispatch({
              type: PAYMENTS_FETCHED,
              payload: res.data
            });
          })
        .catch((e) => {
            console.log(e);
        });
    }else{
        await axios
        .get(`${process.env.REACT_APP_API_URL}payments/incoming`, tokenConfig(getState))
        .then((res) => {
            console.log("res.data: Payment History")
            console.log(res.data)
            dispatch({
              type: PAYMENTS_FETCHED,
              payload: res.data
            });
          })
        .catch((e) => {
            console.log(e);
        });
    }
};

export const getPaymentsOutGoing = (url) => async (dispatch, getState) => {
    if(url){
        await axios
        .get(`${url}`, tokenConfig(getState))
        .then((res) => {
            dispatch({
              type: OUTGOING_PAYMENTS_FETCHED,
              payload: res.data
            });
          })
        .catch((e) => {
            console.log(e);
        });
    }else{
        await axios
        .get(`${process.env.REACT_APP_API_URL}payments/outgoing`, tokenConfig(getState))
        .then((res) => {
            console.log("res.data: Payment History")
            console.log(res.data)
            dispatch({
              type: OUTGOING_PAYMENTS_FETCHED,
              payload: res.data
            });
          })
        .catch((e) => {
            console.log(e);
        });
    }
};