import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { verify_email, initiate_reset, aws_cognito_reset } from '../actions/auth.js';
import Footprint from '../assets/footprint.svg';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/lock-reset.svg';
import Alert from '@material-ui/lab/Alert';

import Spinner from '../components/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    minHeight: '100vh',
    [theme.breakpoints.down('800')]: {
      alignItems: 'flex-start'
    }
  },
  rootGrid: {
    margin: '144px 0',
    padding: '33px 0',
    paddingBottom: '150px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('800')]: {
      padding: '36px 0 60px 0',
      maxWidth: '347px',
      margin: '80px 0 0'
    }
  },
  image: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '70px',
    height: '55px'
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '367px',
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '367px'
    },
    [theme.breakpoints.down('800')]: {
      padding: '0 25.5px',
      margin: '0'
    }
  },
  decor: {
    position: 'absolute',
    bottom: '-165px',
    right: '-28%',
    zIndex: -1,
    [theme.breakpoints.down('1260')]: {
      maxWidth: '93px',
      bottom: '-65px',
      right: '2%'
    }
  },
  avatar: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '90px',
    height: '60px',
    '& img': {
      width: '56px',
      height: '55.5px'
    }
  },
  logo: {
    cursor: 'pointer',
    width: '52px',
    height: '52px'
  },
  title: {
    fontFamily: 'Dosis',
    fontWeight: 800,
    fontSize: '51px',
    lineHeight: '58px',
    color: '#383F45',
    [theme.breakpoints.down('800')]: {
      fontSize: '33px',
      lineHeight: '42px'
    }
  },
  form: {
    maxWidth: '328px',
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    }
  },
  buttonWrap: {
    position: 'relative'
    // pointerEvents: 'none'
  },
  input: {
    margin: theme.spacing(3, 0, 3),
    borderRadius: '5px',
    '& input': {
      padding: '23px 52px 17px 14px'
    },
    '& label': {
      top: '8px',
      color: 'black'
    },
    '&:first-child': {
      marginTop: '10px'
    },
    '& > fieldset': {
      border: '1px solid rgba(25, 25, 25, 0.32) !impotant',
      marginTop: '100px',
      background: 'red'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '29px'
  },
  formLabel: {
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000'
  },
  rulles: {
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    '& a': {
      color: '#327287',
      textDecoration: 'none'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    height: '56px',
    textTransform: 'none',
    fontSize: '13px!important',
    background: '#FF5722',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    lineHeight: '16px',
    color: '#FFFFFF!important',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0)
    }
  },
  already: {
    marginTop: '61px',
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45'
    // justifyContent: 'center',
  },
  alreadyText: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#000000',
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    // justifyContent:'center',
    '& a': {
      fontFamily: 'Dosis',
      color: '#327287',
      fontWeight: '600',
      textDecoration: 'none',
      fontSize: '18px',
      lineHeight: '22px'
    },
    '& span': {
      color: '#327287',
      textDecoration: 'none',
      cursore: 'pointer'
    }
  },
  radioWrap: {
    margin: '0 auto',
    marginBottom: '37px',
    border: '1px solid #B5B5B5'
  },
  radioButton: {
    minWidth: '155px',
    [theme.breakpoints.down('800')]: {
      minWidth: '142px'
    }
  },
  signWrap: {
    margin: '0 auto',
    marginTop: '15px',
    maxWidth: '328px',
    [theme.breakpoints.down('800')]: {
      marginTop: '0px'
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  social: {
    margin: theme.spacing(3, 0, 2)
  },
  paper2: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  body2: {
    lineHeight: '1.5',
    color: '#444444'
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  checkbox: {
    fontSize: '11px',
    '& > *': {
      fontSize: '11px'
    }
  },
  typeOfAccount: {
    marginTop: theme.spacing(1)
  },
  fogot: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '16px',
    color: '#156981',
    fontFamily: 'Dosis',
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    marginBottom: '54px'
  },
  alert: {
    padding: '15px 16px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '16px',
    color: '#156981',
    fontFamily: 'Averia Sans Libre',
    textAling: 'center'
  }
}));

function ForgotPassword(props) {
  const classes = useStyles();

  const [email, setEmail] = useState('');

  const handleSendResetCode = (e) => {
    e.preventDefault();
    if (email) {
      console.log('Initiating process...');
      props.aws_cognito_reset(email);
      makeAlert();
    } else {
      makeAlert();
      return false;
    }
  };

  if (props.auth.token) {
    return <Redirect to="/dashboard" />;
  }

  let alert;
  function makeAlert() {
    console.log('makeing alert');
    if (props.auth.data) {
      let msg = props.auth.data.status;
      alert = (
        <div className="alerts">
          <Alert severity="info">{msg}. Check Your Email</Alert>
        </div>
      );
    } else if (props.error.msg.detail) {
      let msg = props.error.msg.detail;
      alert = (
        <div className="alerts">
          <Alert severity="error">The Email Entered is {msg}</Alert>
        </div>
      );
    } else {
      alert = (
        <div className="alerts">
          <Alert icon={false} severity="info" className={classes.alert}>
            Enter your email address below
          </Alert>
        </div>
      );
    }
  }

  makeAlert();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        xl={3}
        className={classes.rootGrid}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <img className={classes.decor} src={Footprint} alt="" />
          <Avatar className={classes.avatar}>
            <img
              src={logo}
              className={classes.logo}
              onClick={(e) => (window.location.href = '/')}
              alt="logo"
              width="30"
            />
          </Avatar>
          <Typography className={classes.title} component="h1" variant="h5">
            Reset Password
          </Typography>

          <form className={classes.form} noValidate>
            <Grid item xs={12}>
              <div className={classes.message}>{alert}</div>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              className={classes.input}
              size="small"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSendResetCode}
            >
              {/* call resend API */}
              {props.auth.isLoading ? <Spinner /> : 'Reset'}
            </Button>
            <Grid container>
              <Grid item xs justify="flex-start" className={classes.alreadyText}>
                <Link href="/login" variant="body2">
                  {'Sign in'}
                </Link>
              </Grid>
              <Grid className={classes.alreadyText} item justify="flex-end">
                <Link href="/signup" variant="body2">
                  {'Sign up'}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  error: state.errors
});

export default connect(mapStateToProps, {
  verify_email,
  initiate_reset,
  aws_cognito_reset
})(ForgotPassword);
