/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import Footer from '../components/Footer';
import Footprint from '../assets/footprint.svg';
import AcceptReject from "../views/AcceptReject";
import { getBooking } from '../actions/booking';
import Spinner from '../assets/home_load.gif';
// 1072 
// 1071

const BookingDetails = (props) => {
    const [component_ready, setReady] = React.useState(false)

    React.useEffect(() => {
        const id = props?.match?.params?.id;
        console.log(props?.match) 
        console.log(id) 
        props.getBooking(id);
    }, [])

    React.useEffect(() => {
        if(!props.auth?.token){
            window.location.href = '/login'
        }
    }, [props])

    React.useEffect(() => {
        if(props.booking.booking !== null){
            // console.log(props.booking.booking)
            var booking_object = {
              start_time: props.booking.booking.start_time,
              end_time: props.booking.booking.end_time,
              start_date: props.booking.booking.start_date,
              end_date: props.booking.booking.end_date,
              service: props.booking.booking.service.id,
              status: props.booking.booking.status,
              booking: props.booking.booking
            };
            localStorage.setItem('service_object', JSON.stringify(props.booking.booking.service));
            localStorage.setItem('booking_object', JSON.stringify(booking_object));
            setReady(true)
        }
    }, [props.booking])

    return (
        <>
            {
                component_ready ? (<AcceptReject />) :
                (
                <div>
                    <div>{props.auth?.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
                    <div>{!props.auth?.token ? <GuestNavBar /> : ''}</div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        minHeight: '100vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '73px',
                        background: '#fff'
                      }}>
                      <div
                        style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <img
                          style={{
                            position: 'absolute',
                            marginBottom: '41px',
                            marginRight: '31px'
                          }}
                          src={Footprint}
                          alt="logo"
                        />
                        <img src={Spinner} alt="logo" height="100px" width="100px" />
                      </div>
                    </div>
                </div>
                )
            }
        <Footer/>
        </>
    )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  booking: state.booking
});

export default connect(mapStateToProps, {
  getBooking
})(BookingDetails);
