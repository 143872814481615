/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import Image from '../assets/logo_placeholder_transparent_grey.png';
import Avatar from '@material-ui/core/Avatar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Box } from '@material-ui/core';
import Footer from '../components/FooterWizard';

import Notifications from './NotificationsPage';
import DashboardPetOwner from './DashboardPetOwner';
import Profile from './Profile';
import PaymentsPetOwner from './PaymentsPetOwner';
import Spinner from '../assets/home_load.gif';
import { logout } from '../actions/auth.js';
import { get_breeds, get_breeds_cats, get_payment_method } from '../actions/form.js';
import { get_bookings } from '../actions/booking';
import { getPayments } from '../actions/payments';
import PetAdd from './PetAdd';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginTop: '64px',
    backgroundColor: '#fafafa',
    color: 'black',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.03), 0px 1px 1px 0px rgba(0,0,0,0.03), 0px 1px 3px 0px rgba(0,0,0,0.03)',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '64px',
    height: '50%'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("450")]: {
      padding: "24px 0 ",
    },
  },
  petIcon: {
    width: '1em',
    height: '1em'
  },
  sidebarHeadSection: {
    backgroundColor: '#fafafa'
  },
  sidebarHeader: {
    '& span': {
      fontSize: '1.5em',
      fontWeight: '600'
    }
  },
  list: {
    paddingTop: '0'
  },
  sidebarLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    '& span': {
      fontSize: '13px',
      color: 'rgba(0, 0, 0, 0.7)',
      fontWeight: '600'
    }
  }
}));

function AuthAppContainer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [go_to_dash, setGo_to_dash] = useState(true);
  const [go_to_apnt, setGo_to_apnt] = useState(false);
  const [go_to_notf, setGo_to_notf] = useState(false);
  const [go_to_prof, setGo_to_prof] = useState(false);
  const [go_to_paym, setGo_to_paym] = useState(false);
  const [go_to_cancel, setGo_to_cancel] = useState(false);
  const [go_to_petsAdd, setGo_to_petsAdd] = useState(false);

  // Redirecting user
  React.useEffect(() => {
    if (
      props.current_route !== null &&
      props.current_route !== undefined &&
      props.current_route === 'profile'
    ) {
      // console.log(props.current_route)  // value of current page
      setGo_to_dash(false);
      setGo_to_apnt(false);
      setGo_to_petsAdd(false);
      setGo_to_notf(false);
      setGo_to_cancel(false);
      setGo_to_prof(true);
      setGo_to_paym(false);
      localStorage.removeItem('routing_to');
    }
    if (
      props.current_route !== null &&
      props.current_route !== undefined &&
      props.current_route === 'payment'
    ) {
      // console.log(props.current_route)  // value of current page
      props.get_payment_method();
      props.getPayments();
      setGo_to_dash(false);
      setGo_to_apnt(false);
      setGo_to_notf(false);
      setGo_to_cancel(false);
      setGo_to_prof(false);
      setGo_to_paym(true);
      setGo_to_petsAdd(false);
      localStorage.removeItem('routing_to');
    }
  }, [props.current_route]);

  React.useEffect(() => {
    props.get_breeds();
    props.get_breeds_cats();
    props.get_payment_method();
    props.get_payment_method();
    props.getPayments();
    props.get_bookings();
  }, []);
  
  const dashboard = (e) => {
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_prof(true);
    setGo_to_paym(false);
    setGo_to_petsAdd(false);
    setMobileOpen(false);
  };
  const payments = (e) => {
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_prof(false);
    setGo_to_paym(true);
    setGo_to_petsAdd(false);
    setMobileOpen(false);
  };
  const appointments = (e) => {
    setGo_to_dash(false);
    setGo_to_apnt(true);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_prof(false);
    setGo_to_paym(false);
    setGo_to_petsAdd(false);
    setMobileOpen(false);
  };
  // const notifications = (e) => {
  //   setGo_to_dash(false);
  //   setGo_to_apnt(false);
  //   setGo_to_notf(true);
  //   setGo_to_cancel(false);
  //   setGo_to_prof(false);
  //   setGo_to_paym(false);
  //   setGo_to_petsAdd(false);
  // };
  const profiles = (e) => {
    setGo_to_prof(true);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_paym(false);
    setGo_to_petsAdd(false);
    setMobileOpen(false);
  };
  const petsAdd = (e) => {
    setGo_to_petsAdd(true);
    setGo_to_prof(false);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_paym(false);
    setMobileOpen(false);
  };

  // const cancellation = (e) => {
  //   setGo_to_petsAdd(false);
  //   setGo_to_prof(false);
  //   setGo_to_dash(false);
  //   setGo_to_apnt(false);
  //   setGo_to_notf(false);
  //   setGo_to_cancel(true);
  //   setGo_to_paym(false);
  // };

  const drawer = (
    <div>
      <Divider />
      <List className={classes.list}>
        <ListItem
          onClick={dashboard}
          selected={go_to_prof}
          disabled
          button
          className={classes.sidebarHeadSection}
        >
          <ListItemText primary="Dashboard" className={classes.sidebarHeader} />
        </ListItem>

        <ListItem onClick={appointments} selected={go_to_apnt} button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={payments} selected={go_to_paym} button>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" disabled className={classes.sidebarLabel} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem onClick={petsAdd} selected={go_to_petsAdd} button>
          <ListItemIcon>
            <Avatar src={Image} className={classes.petIcon} />
          </ListItemIcon>
          <ListItemText primary="Your Pets" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={profiles} selected={go_to_prof} button>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" className={classes.sidebarLabel} />
        </ListItem>
        {props.auth.user.profile_completedness === 100 ? (
          ''
        ) : (
          <Link to="/wizard" style={{ textDecoration: 'none', color: 'grey' }}>
            <ListItem button>
              <ListItemIcon>
                <ArrowForwardIcon />
              </ListItemIcon>
              <ListItemText primary="Wizard" className={classes.sidebarLabel} />
            </ListItem>
          </Link>
        )}
        {/*<ListItem onClick={cancellation}  selected={go_to_cancel} button>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText primary="Cancellation Policy" className={classes.sidebarLabel} />
        </ListItem>*/}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (!props.auth.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  // if (props.auth.user.profile_completedness < 50){
  //     return <Redirect to="/wizard" />;
  // }

  return (
    <Box>
      <div>
        <PrimarySearchAppBar />
        <div className={classes.root}>
          <CssBaseline />
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              {go_to_dash ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Dashboard
                </Typography>
              ) : (
                ''
              )}
              {go_to_apnt ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Home
                </Typography>
              ) : (
                ''
              )}
              {go_to_notf ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Notifications
                </Typography>
              ) : (
                ''
              )}
              {go_to_prof ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Profile
                </Typography>
              ) : (
                ''
              )}
              {go_to_paym ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Payments
                </Typography>
              ) : (
                ''
              )}
              {go_to_petsAdd ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Your Pets
                </Typography>
              ) : (
                ''
              )}
              {go_to_cancel ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Cancellation Policy
                </Typography>
              ) : (
                ''
              )}
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="Dashboard">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  // paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {go_to_dash ? <DashboardPetOwner bookings={props.bookings} /> : ''}
            {go_to_apnt ? <DashboardPetOwner bookings={props.bookings} /> : ''}
            {go_to_notf ? <Notifications /> : ''}
            {go_to_prof ? <Profile /> : ''}
            {go_to_paym ? (
              <div>
                {props.payment_methods !== null && props.payment_methods !== undefined ? (
                  <PaymentsPetOwner />
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        minHeight: '50vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '13px',
                        background: '#fff'
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img src={Spinner} alt="" height="100px" width="100px" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {go_to_petsAdd ? <PetAdd /> : ''}
            {/*{go_to_cancel ? (
              <div>
                <CancellationPolicy />
              </div>
            ) : (
              ''
            )}*/}
          </main>
        </div>
      </div>
      <Footer />
    </Box>
  );
}

AuthAppContainer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  payment_methods: state.services.payment_methods,
  bookings: state.booking.bookings
});

export default connect(mapStateToProps, {
  logout,
  get_breeds,
  get_breeds_cats,
  get_payment_method,
  get_bookings,
  getPayments
})(AuthAppContainer);
