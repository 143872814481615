import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import Container from '@material-ui/core/Container';
import Card from '../views/atoms/Card';

// import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  wizardContainer: {
    [theme.breakpoints.down('700')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
      marginBottom: '20px'
    }
  },
  root: {
    // width: '100%',
    minHeight: '36vh',
    // paddingTop: '33px',
    background: '#D4F2F2',
    [theme.breakpoints.down('700')]: {
      paddingTop: '20px'
    }
  },
  page: {
    display: 'flex',
    minHeight: '50px',
    paddingBottom: '20px',
    marginTop: '3px',
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('960')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '400px',
      margin: '0 auto',
      minHeight: 'auto'
    },
    [theme.breakpoints.down('450')]: {
      maxWidth: '370px'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  aside: {
    width: '100%',
    maxWidth: '302px',
    height: 'auto',
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('960')]: {
      maxWidth: '100%',
      order: 3,
      height: 'auto',
    }
  },
  asideHeader: {
    background: 'white',
    height: '112px',
    // height: '274px',
    fontFamily: 'Averia Sans Libre',
    fontWeight: 'normal',
    fontSize: '26px',
    lineHeight: '45px',
    color: '#23286B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '2px solid #BDBDBD',
    borderBottom: '2px solid #BDBDBD',
    [theme.breakpoints.down('1150')]: {
      flex: 1,
    },
    [theme.breakpoints.down('960')]: {
      display: 'none'
    }
  },
  asideTitle: {
    fontWeight: '350',
    fontSize: '16px',
    color: '#23286B'
  },
  title: {
    alignItems: 'center',
    fontWeight: '350',
    fontSize: '16px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  asideMain: {
    padding: '16px 47px 16px',
    background: 'white',
    minHeight: '113px',
    borderRight: '2px solid #BDBDBD',
    [theme.breakpoints.down('960')]: {
      padding: '16px 20px 16px',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 'auto',
      order: '3'
    },
    [theme.breakpoints.down('700')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around'
    }
  },

  content: {
    width: '100%',
    [theme.breakpoints.down('960')]: {
      order: '0'
    }
  },
  headerContent: {
    width: '100%',
    background: 'white',
    height: '112px',
    fontFamily: 'Averia Sans Libre',
    fontWeight: 'normal',
    fontSize: '36px',
    lineHeight: '45px',
    color: '#4F4F4F',
    borderBottom: '2px solid #BDBDBD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('700')]: {
      height: '100%',
      padding: '0 20px'
    }
  },
  contantMain: {
    // paddingTop: '17px',
    // paddingBottom: '17px',
    height: '100%',
    minHeight: '80px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('700')]: {
      minHeight: 'auto',
      background: '#fff',
      paddingBottom: '0px'
    }
  },
  alter_height: {
    height: '274px'
  }
}));

export default function SingleResult(props) {
  const classes = useStyles();
  // const [rating, setRating] = useState(0);
  // const [state, setState] = React.useState({
  //   age: '',
  //   name: 'hai'
  // });

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setState({
  //     ...state,
  //     [name]: event.target.value
  //   });
  // };

  // console.log(props.result)

  return (
    <div>
      <Container maxWidth="lg" className={classes.wizardContainer}>
        <div className={classes.page}>
          <div className={classes.aside}>
            <div
              className={props.result?.services?.length >= 7 ? clsx(classes.asideHeader, classes.alter_height) : classes.asideHeader}
            >
              <a href={"/providers/" + props.result?.uid}>{props.result?.first_name} {props.result?.last_name}</a>
            </div>
            <div className={classes.asideMain}>
              <div className={classes.asideTitle}>{props.result?.tagline}</div>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.contantMain}>
              <Card data={props.result} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
