/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import BioSection from '../components/DashboardProfilePicSection';
import AddressSection from '../components/DashboardAddressSection';
import ContactSection from '../components/DashboardContactSection';
import ChangePassword from '../components/ProfileChangePassword';
import { loadUser } from '../actions/auth.js';
import { deactivateUser, sendUserData } from '../actions/form.js';
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Alert } from '@material-ui/lab';
import Container from "@material-ui/core/Container";
import FormLabel from "@material-ui/core/FormLabel";
import * as Scroll from 'react-scroll';
import { Button } from '@material-ui/core';

import ToggleNotifications from './notifications/toggleNotifications.js';
import Spinner from "../assets/Spinner.gif";

var scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  wizardContainer: {
    margin: theme.spacing(1, "auto"),
  },
  formGroupLabel: {
    fontSize: "17px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(3),
  },
  button_: {
    backgroundColor: "#FF3D00!important",
    width: "245px",
    height: "40px",
    marginTop: "15px",
    marginBottom: "10px",
  },
  button: {
    marginTop: '10px',
    alignItems: 'center',
    color: '#FF3D00',
    justifyContent: 'center',
    backgroundColor: '#fff!important',
    border: '1.5px solid #FF3D00',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#F0FFF0!important'
    }
  },

}))

function ResponsiveDrawer(props) {

  const classes = useStyles();
  const [showConfirm, setShowConfirm] = useState(false);
  const [submitted_status, setSStatus] = useState(false);
  const { user } = props;

  const handleAccountDeactivation = async () => {
    console.log(user.id)
    await props.deactivateUser(user.id)
  };

  const toggle_notifications = async (toggleNotifications) => {
    setSStatus(true)
    const object = { sms_notifications_enabled: toggleNotifications }
    await props.sendUserData(object, user.id);
    await props.loadUser();
    const timer = setTimeout(() => {
      setSStatus(false)
    }, 3000);
  }

  React.useEffect(() => {
    if (props.form.fetch === true) props.loadUser();
  }, [props.form.fetch]);

  React.useEffect(() => {
    if (props.form.account_deactivated) { window.location.href = "/login" }
  }, [props.form.account_deactivated]);

  // React.useEffect(() => {
  //   scroll.scrollToTop();
  // }, []);

  return (
    <div>
      <BioSection
        firstName={user.first_name}
        lastName={user.last_name}
        tagline={user.tagline}
        bio={user.bio}
        dob={user.date_of_birth}
      />
      <AddressSection
        address_line_1={user.address_line_1}
        address_line_2={user.address_line_2}
        city={user.city}
        state={user.state}
        country={user.country}
        zipcode={user.zipcode}
      />
      <ContactSection
        phone_number={user.phone_number}
        alternate_phone_number={user.alternate_phone_number}
        email={user.email}
        emergency_contact_name={user.emergency_contact_name}
        emergency_contact_phone_number={user.emergency_contact_phone_number}
        emergency_contact_email={user.emergency_contact_email}
      />
      {!user.is_social_user ? <ChangePassword /> : ''}

      <Paper className={classes.root} elevation={0}>
        <Container maxWidth="md" className={classes.wizardContainer}>
          <div>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Deactivate your Account
            </FormLabel>
          </div>
          {showConfirm ?
            <Alert
              severity="info"
              icon={false}
            >
              <div className={classes.message}>
                {"Please confirm that you want to deactivate your account."}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }} >
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  style={{ marginRight: '30px' }}
                  onClick={handleAccountDeactivation}
                >
                  <span>{'Yes'}</span>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  style={{ marginRight: '30px' }}
                  onClick={() => setShowConfirm(false)}
                >
                  <span>{'Cancel'}</span>
                </Button>
              </div>
            </Alert> : null}
          <Button
            onClick={() => setShowConfirm(true)}
            variant="contained"
            color="primary"
            className={classes.button_}
          >
            Deactivate account
          </Button>
        </Container>
      </Paper>

      {user !== null ?
        <Paper className={classes.root} elevation={0}>
          <Container maxWidth="md" className={classes.wizardContainer}>
            <div style={{ marginBottom: '5px' }}>
              <FormLabel component="label" className={classes.formGroupLabel}>
                SMS Notifications ({user?.sms_notifications_enabled ? "Enabled" : "Disabled"})
              </FormLabel>
            </div>
            <ToggleNotifications
              toggle_notifications={toggle_notifications}
              user={user}
            />
            {submitted_status ? <img src={Spinner} alt="" height="40px" width="40px" /> : null}
          </Container>
        </Paper> : null}

    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user
});

export default connect(mapStateToProps, {

  loadUser,
  deactivateUser,
  sendUserData

})(ResponsiveDrawer);
