/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PaymentMethods from '../components/PaymentMethodList';
import PaymentBankAccounts from '../components/PaymentBankAccounts';
// import PaymentBankAccountsList from '../components/PaymentBankAccountsList';
import PaymentsList from '../components/PaymentsList';
import PaymentsViewList from '../components/PaymentsViewList';
import PaymentsViewListHistory from '../components/PaymentsViewListHistory';
import { get_payment_method, clear_state_payment } from '../actions/form.js';
import { get_bookings, get_payout_schedule } from '../actions/booking';
import { getBankAccounts } from '../actions/bank-accounts';
import { getPayments, getPaymentsOutGoing } from '../actions/payments';
import * as Scroll from 'react-scroll';
import Spinner from '../assets/home_load.gif';

var scroll = Scroll.animateScroll;

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fafafa',
    width: '100%'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginTop: '64px',
    backgroundColor: '#fafafa',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '64px'
  },
  content: {
    flexGrow: 1
  },
  addRowButton: {
    marginTop: theme.spacing(4)
  },
  mainArea: {
    width: 'inherit'
  }
}));

function Payments(props) {
  const classes = useStyles();

  React.useEffect(() => {
    props.get_payment_method();
    props.get_payout_schedule();
    props.getPayments();
    props.getPaymentsOutGoing();
    scroll.scrollToTop();
  }, []);

  React.useEffect(() => {
   if(props.payments_history_next !== null){
     // console.log("Getting more payments...")
     props.getPayments(props.payments_history_next);
   }
  }, [props.payments_history_next]);

  
  React.useEffect(() => {
   if(props.payments_history_out_next !== null){
     // console.log("Getting more payments...")
     props.getPaymentsOutGoing(props.payments_history_out_next);
   }
  }, [props.payments_history_out_next]);

  

  React.useEffect(() => {
    if (props.form.isLoading === true) {
      props.get_payment_method();
    }
  }, [props.form.isLoading]);

  React.useEffect(() => {
    if (props.form.isLoading === true) {
      props.get_payment_method();
    }
  }, [props.form.isLoading]);

  let results;
  let payouts;
  let schedules;
  if (props.payment_methods !== undefined && props.payment_methods !== null) {
    results = props.payment_methods;
  }
  if (props.bank_accounts !== undefined && props.bank_accounts !== null) {
    payouts = props.bank_accounts;
  }
  if (props.schedule !== undefined && props.schedule !== null) {
    schedules = props.schedule;
  }
  let payment_history = [];
  if(props.payments_history !== null){
    payment_history = props.payments_history
    // console.log(payment_history)
  }

  let payment_history_out = [];
  if(props.payments_data_out !== null){
    payment_history_out = props.payments_data_out
    // console.log(payment_history)
  }

  const fetch_again = (e) => {
    props.get_payment_method();
    props.getBankAccounts();
    props.getPayments();
    props.getPaymentsOutGoing();
    props.get_payout_schedule();
  };

  return (
    <div>
      <div className={classes.root}>
        <main className={classes.mainArea}>
          <div className={classes.toolbar} />
         {results !== null &&
          results !== undefined &&
          payouts !== null &&
          payouts !== undefined &&
          schedules !== null &&
          schedules !== undefined ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
               <PaymentsViewList payment_methods={results} fetch_again={fetch_again} />
              </div>
              <div>
               <PaymentMethods payment_methods={results} fetch_again={fetch_again} />
              </div>
              <div>
               <PaymentBankAccounts 
                  wizard={false} 
                  payment_methods={results}
                  bank_accounts={payouts}
                  schedules={schedules}
                  fetch_again={fetch_again} 
               />
              </div>
              {/*<div>
               <PaymentBankAccountsList
                  payment_methods={results}
                  bank_accounts={payouts}
                  schedules={schedules}
                  fetch_again={fetch_again}
                />
              </div>*/}
              <div>
                {((payment_history !== null && payment_history?.length !== 0) ||
                 (payment_history_out !== null && payment_history_out?.length !== 0)) ? 
                  <PaymentsViewListHistory 
                     payments={payment_history} 
                     payment_history_out={payment_history_out} 
                     fetch_again={fetch_again} 
                  /> :
                  <PaymentsList />
                }
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  minHeight: '50vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '13px',
                  background: '#fff'
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img src={Spinner} alt="" height="100px" width="100px" />
                </div>
              </div>
            </div>
          )}

          {/*<BillingAddressSection
          // address_line_1={user.address_line_1}
          // address_line_2={user.address_line_2}
          // city={user.city}
          // state={user.state}
          // country={user.country}
          // zipcode={user.zipcode}
          />*/}
        </main>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user,
  payment_methods: state.services.payment_methods,
  bank_accounts: state.booking.bank_accounts,
  bookings: state.booking.bookings,
  payments_history_next: state.booking.payments_history_next,
  payments_history_out_next: state.booking.payments_history_out_next,
  schedule: state.booking.schedule_data,
  payments_history: state.booking.payments_data,
  payments_data_out: state.booking.payments_data_out,
});


export default connect(mapStateToProps, {
  get_payment_method,
  clear_state_payment,
  getBankAccounts,
  get_bookings,
  get_payout_schedule,
  getPayments,
  getPaymentsOutGoing
})(Payments);