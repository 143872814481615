import { CARD_LOADING, CARD_CREATED_FAIL, CARD_CREATED_SUCCESS } from '../actions/types';

const initialState = {
  isLoading: false,
  success: false,
  results: null
};

export default function (state = initialState, action) {
  switch (action) {
    case CARD_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case CARD_CREATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        results: action.payload
      };
    case CARD_CREATED_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false
      };
    default:
      return {
        ...state
      };
  }
}
