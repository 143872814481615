import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TodayIcon from '@material-ui/icons/Today';
import Notes from '@material-ui/icons/Notes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoopIcon from '@material-ui/icons/Loop';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { create_availability_instance, get_availability, clear_aval } from '../actions/form.js';
import FormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles((theme) => ({
  container: {
    width: theme.spacing(68),
    padding: 0,
    paddingBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
    maxWidth: '80%',
    [theme.breakpoints.down('550')]: {
      maxWidth: '100%',
    },
  },
  header: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0.5),
    maxWidth: '80%',
    [theme.breakpoints.down('550')]: {
      maxWidth: '100%',
    },
  },
  closeButton: {
    float: 'right'
  },
  custom: {
    margin: '0',
    padding: '18px 16px 14px',
    maxWidth: '210px',
    minHeight: '56px',
    border: '1px solid rgba(25, 25, 25, 0.32)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('680')]: {
      marginBottom: '10px',
      maxWidth: '300px',
      width: '300px'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    },
    '& > .MuiPickersToolbar': {
      toolbar: 'none'
    }
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    maxWidth: '80%',
    [theme.breakpoints.down('550')]: {
      maxWidth: '100%',
    },
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  picker: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0
    },
    width: '50%'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    // padding: theme.spacing(1, 0)
  },
  wrapper2: {
    display: 'flex',
    // justifyContent: 'space-between',
    // padding: theme.spacing(1, 0)
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2)
  },
  textField: {
    width: '100%'
  },
  toolbar: {
    marginTop: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(8)
  },

  input: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  },
  line: {
    textAlign: 'center',
    backgroundColor: '#fafafa',
    width: '100%',
    borderRadius: '3px',
    border: '1px solid #cfd7de',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    '& > label': {
      paddingLeft: theme.spacing(2)
    }
  },
  underline: {
    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    }
  }
}));

const theme = createTheme({
  overrides: {
    MuiPickersClock: {
      pin: {
        backgroundColor: '#3a9643'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#3a9643'
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#3a9643',
        thumb: {
          backgroundColor: '#3a9643'
        },
        '& > div': {
          backgroundColor: '#3a9643',
          border: '14px solid #3a9643'
        }
      },
      thumb: {
        backgroundColor: '#3a9643'
      },
      noPoint: {
        backgroundColor: '#3a9643'
      }
    },
    MuiFormHelperText: {
      root: {
        position: 'apsolute'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3a9643'
      }
    }
  }
});

function AppointtmentForm(props) {
  const classes = useStyles();
  // const current = new Date() 
  // const obj =  `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate() - 1}`;
  // const time_obj = `${current.getHours()}:${current.getMinutes() - 5}`;
  // // console.log(time_obj)

  // var obj_ = {
  //         'Date/Time': `2018-09-30 ${time_obj}` // A little bit of hard coding but we are targeting the time not the date...
  //       };
  // const dat_ = moment(obj_['Date/Time']).toDate();

  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedTime_, setSelectedTime_] = useState(new Date());

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedDate_, setSelectedDate_] = useState(new Date());
  const [notes, setNotes] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);

    let dt = new Date(date);

    let formatted_start_date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    const field = 'start_date';
    props.onChange(field, formatted_start_date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);

    let dt = new Date(date);

    let formatted_start_date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    const field = 'end_repeat_date';
    props.onChange(field, formatted_start_date);
  };


  const handleDateChange_ = (date) => {
    setSelectedDate_(date);

    let dt = new Date(date);

    let formatted_end_date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    const field = 'end_date';
    props.onChange(field, formatted_end_date);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    const field = 'notes';
    props.onChange(field, e.target.value);
  };

  const handleTimeChange = (date) => {
    // function convertTo12Hour(oldFormatTime) {
    //   var oldFormatTimeArray = oldFormatTime.split(':');
    //   var HH = parseInt(oldFormatTimeArray[0]);
    //   var min = oldFormatTimeArray[1];
    //   var AMPM = HH >= 12 ? 'PM' : 'AM';
    //   var hours;
    //   if (HH == 0) {
    //     hours = HH + 12;
    //   } else if (HH > 12) {
    //     hours = HH - 12;
    //   } else {
    //     hours = HH;
    //   }
    //   var newFormatTime = hours + ':' + min;
    //   return newFormatTime;
    // }

    setSelectedTime(date);

    let dt = new Date(date);
    const field = 'start_time';
    let formatted_start = `${dt.getHours()}:${dt.getMinutes()}`;
    props.onChange(field, formatted_start);
  };

  const handleTimeChange_ = (date) => {
    // function convertTo12Hour(oldFormatTime) {
    //   var oldFormatTimeArray = oldFormatTime.split(':');
    //   var HH = parseInt(oldFormatTimeArray[0]);
    //   var min = oldFormatTimeArray[1];
    //   var AMPM = HH >= 12 ? 'PM' : 'AM';
    //   var hours;
    //   if (HH == 0) {
    //     hours = HH + 12;
    //   } else if (HH > 12) {
    //     hours = HH - 12;
    //   } else {
    //     hours = HH;
    //   }
    //   var newFormatTime = hours + ':' + min;
    //   return newFormatTime;
    // }

    setSelectedTime_(date);

    let dt = new Date(date);
    const field = 'end_time';
    let formatted_end = `${dt.getHours()}:${dt.getMinutes()}`;
    props.onChange(field, formatted_end);
  };

  const [recur, setRecur] = React.useState(false);
  const [interval, setInterva] = React.useState("none");

  const setInterval_ = (value) => {
    const field = 'interval';
    setInterva(value)
    props.onChange(field, value);
  };

  const handleRecur = (e) => {
    const new_value = !recur;
    const field = 'recurring';
    props.onChange(field, new_value);
    if (recur === true) {
      setRecur(false);
    } else {
      setRecur(true);
    }
  };

  // const handleTimeChange = () => {
  //   const field = "notes"
  //   const value = "His a good boy"
  //   props.onChange(field, value)
  // }

  return (
    <div className={classes.content}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.wrapper}>
          <CalendarToday className={classes.icon} color="action" />

          <Grid item xs={12} sm={6} style={{ marginRight: '10px' }}>
            <FormLabel component="label" className={classes.formLabel}>
              Start time
            </FormLabel>
            <ThemeProvider theme={theme}>
              <KeyboardTimePicker
                keyboardIcon={<AccessAlarmIcon />}
                className={classes.line}
                InputProps={{ classes: { underline: classes.underline } }}
                label=""
                placeholder="Start time AM"
                mask="__:__ _M"
                value={selectedTime}
                onChange={(date) => handleTimeChange(date)}
              />
            </ThemeProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              End time
            </FormLabel>
            <ThemeProvider theme={theme}>
              <KeyboardTimePicker
                keyboardIcon={<AccessAlarmIcon />}
                className={classes.line}
                InputProps={{ classes: { underline: classes.underline } }}
                placeholder="Start time AM"
                mask="__:__ _M"
                value={selectedTime_}
                onChange={(date) => handleTimeChange_(date)}
              />
            </ThemeProvider>
          </Grid>
        </div>
        <div className={classes.wrapper}>
          <TodayIcon className={classes.icon} color="action" />
          <Grid item xs={12} sm={6} style={{ marginRight: '10px' }}>
            <FormLabel component="label" className={classes.formLabel}>
              Start Date
            </FormLabel>
            <KeyboardDatePicker
              className={classes.line}
              InputProps={{ classes: { underline: classes.underline } }}
              placeholder="08:00 AM"
              id="date-picker-dialog"
              format="MM/dd/yyyy"
              value={selectedDate}
              //  defaultValue={props.booking.start_date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              End Date
            </FormLabel>
            <KeyboardDatePicker
              className={classes.line}
              InputProps={{ classes: { underline: classes.underline } }}
              placeholder="08:00 AM"
              id="date-picker-dialog"
              format="MM/dd/yyyy"
              value={selectedDate_}
              // defaultValue={props.booking.start_time}
              //  defaultValue={props.booking.end_date}
              onChange={handleDateChange_}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Grid>
        </div>

        <div className={classes.wrapper}>
          <LoopIcon className={classes.icon} color="action" />

          <Grid item xs={12} sm={6} style={{ marginRight: '10px' }}>
            <FormLabel component="label" className={classes.formLabel}>
              Recurring
            </FormLabel>
            <FormGroup className={classes.formGroup__}>
              <FormControlLabel
                control={<Checkbox checked={recur} onChange={handleRecur} name="small" />}
                label="Yes"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            {/*<LoopIcon className={classes.icon} color="action" />*/}
            <FormLabel component="label" className={classes.formLabel}>
              Interval
            </FormLabel>
            <Select
              onChange={(e) => setInterval_(e.target.value)}
              disableUnderline
              displayEmpty
              fullWidth
              value={interval}
              // defaultValue={props.schedules.occurrence}
              className={classes.inputSmall}
              inputProps={{
                'aria-label': 'Select Expiry year'
              }}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="biweekly">Bi-weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="annually">Annually</MenuItem>
            </Select>
          </Grid>
        </div>

        {/* <div className={classes.wrapper2}>
          <LoopIcon className={classes.icon} color="action" />
          <Grid item xs={6} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Recurring
            </FormLabel>
             <FormGroup className={classes.formGroup__}>
              <FormControlLabel
                control={<Checkbox checked={recur} onChange={handleRecur} name="small" />}
                label="Yes"
              />
            </FormGroup>
          </Grid>
        </div>*/}

        {/*<div className={classes.wrapper2}>
        <TimelineIcon className={classes.icon} color="action" />
        <Grid item xs={12} sm={12}>
          <FormLabel component="label" className={classes.formLabel}>
            Interval
          </FormLabel>
          <Select
            onChange={(e) => setInterval_(e.target.value)}
            disableUnderline
            displayEmpty
            fullWidth
            value={interval}
            // defaultValue={props.schedules.occurrence}
            className={classes.inputSmall}
            inputProps={{
              'aria-label': 'Select Expiry year'
            }}
          >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="annually">Annually</MenuItem>
        </Select>
        </Grid>
      </div>*/}
        {
          recur === true ?
            (
              <div className={classes.wrapper}>
                <TodayIcon className={classes.icon} color="action" />
                <Grid item xs={12} sm={12} style={{ marginRight: '10px' }}>
                  <FormLabel component="label" className={classes.formLabel}>
                    End repeat date
                  </FormLabel>
                  <KeyboardDatePicker
                    className={classes.line}
                    InputProps={{ classes: { underline: classes.underline } }}
                    placeholder="08:00 AM"
                    id="date-picker-dialog"
                    format="MM/dd/yyyy"
                    value={selectedEndDate}
                    //  defaultValue={props.booking.start_date}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </Grid>
              </div>
            ) : (null)
        }

        <div className={classes.wrapper}>
          <Notes className={classes.icon} color="action" />
          <Grid item xs={12}>
            <FormLabel component="label" className={classes.formLabel}>
              Notes
            </FormLabel>
            <Input
              fullWidth
              id="notes"
              name="notes"
              disableUnderline
              onChange={handleNotesChange}
              value={notes}
              multiline
              placeholder="Important information pet owners should know about this appointment"
              rows="2"
              className={classes.inputSmall}
            />
          </Grid>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user
});
export default connect(mapStateToProps, {
  create_availability_instance,
  get_availability,
  clear_aval
})(AppointtmentForm);
