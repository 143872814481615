import {
  SERVICE_LOADED,
  SERVICE_LOADING,
  ALL_SERVICE_LOADED,
  SERVICE_DELETED,
  ALL_RATES_LOADED,
  CLEAR_STATE_,
  FETCH_,
  FETCH_DONE_,
  CREATE,
  GETTING_DONE,
  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD,
  PAYMENT_METHOD_DELETED,
  CLEAR_STATE_PAYMENT,
  GET_ADDITIONAL_RATES_TYPES,
  CREATE_ADDITIONAL,
  CREATED_ADDITIONAL,
  ADDITIONAL_RATES_LOADED,
  CLEAR_BACKGROUNDCHECK_ERROR
} from '../actions/types';

const initialState = {
  isLoading: false,
  services: null,
  progress: false,
  results: null,
  fetch: false,
  admin_services: null,
  additional_rate_types: null,
  progress_additional: false,
  additional_rates: [],
  additional_rates_next: null,
  rates: null,
  payment_methods: null,
  card_created: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PAYMENT_METHOD:
      return {
        ...state,
        ...action.payload,
        card_created: true
      };
    case CLEAR_BACKGROUNDCHECK_ERROR:
      return {
        ...state,
        card_created: false
      };
    case GET_PAYMENT_METHOD:
      return {
        ...state,
        payment_methods: action?.payload?.results,
        isLoading: false
      };
    case PAYMENT_METHOD_DELETED:
      return {
        ...state,
        isLoading: false,
        progress: false
      };
    case FETCH_:
      return {
        ...state,
        fetch: true
      };
    case CREATE:
      return {
        ...state,
        progress: true
      };
    case CREATE_ADDITIONAL:
      return {
        ...state,
        progress_additional: true
      };
    case CREATED_ADDITIONAL:
      return {
        ...state,
        progress_additional: false
      };
    // case ADDITIONAL_RATES_LOADED:
    //   return {
    //     ...state,
    //     additional_rates: action.payload
    //   };

    case ADDITIONAL_RATES_LOADED: 
      const existingPaymentIds = new Set(state?.additional_rates?.map(payment => payment.id));
      const uniqueResultsPayments = action.payload?.results?.filter(result => !existingPaymentIds.has(result.id));
      return {
        ...state, 
        additional_rates: [...state.additional_rates, ...uniqueResultsPayments],
        additional_rates_next: action.payload.next
      };

    case SERVICE_DELETED:
      const idToDelete = action.payload;
      const updatedAdditionalRates = state.additional_rates.filter(rate => rate.id !== idToDelete);
      return {
        ...state,
        isLoading: false,
        additional_rates: updatedAdditionalRates
      };

    case GETTING_DONE:
      return {
        ...state,
        progress: false
      };
    case CLEAR_STATE_:
      return {
        ...state,
        services: null
      };
    case CLEAR_STATE_PAYMENT:
      return {
        ...state,
        payment_methods: []
      };
    case FETCH_DONE_:
      return {
        ...state,
        fetch: false
      };
    
    case ALL_RATES_LOADED:
      return {
        ...state,
        rates: action.payload,
        isLoading: false
      };
    case ALL_SERVICE_LOADED:
      return {
        ...state,
        isLoading: false,
        admin_services: action.payload
      };
    case GET_ADDITIONAL_RATES_TYPES:
      return {
        ...state,
        isLoading: false,
        additional_rate_types: action.payload
      };
    case SERVICE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case SERVICE_LOADED:
      return {
        ...state,
        isLoading: false,
        services: action.payload,
        results: action?.payload?.results
      };
    default:
      return {
        ...state
      };
  }
}
