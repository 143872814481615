import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getallresults, getsuggestions } from '../actions/results';
import AlignItemsList from '../components/SearchItem';

import ErrorInfor from '../components/ErrorInfor.js';
import CustomizedInputBase from '../components/SearchBarSearchPage';
import CustomizedTabs from '../components/SearchFilterTabHost';
import ImageAvatars from '../components/LogoSearchPage.js';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';

class ErrorsPage extends React.Component {
  static propTypes = {
    results: PropTypes.array.isRequired,
    getallresults: PropTypes.func.isRequired,

    autocompletes: PropTypes.object.isRequired,
    getsuggestions: PropTypes.func.isRequired
  };

  refresh = (e) => {
    window.location.href = '/';
  };
  componentDidMount() {
    this.props.getsuggestions();
    //this.props.getallresults();
    //console.log(this.props.results)
  }

  render() {
    return (
      <Fragment>
        <div>{this.props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
        <div>{!this.props.auth.token ? <GuestNavBar /> : ''}</div>
        <Container className="container" disableGutters={true}>
          <Paper className="search-section" elevation={0}>
            <ImageAvatars />
            <CustomizedInputBase
              search={this.props.location.state.detail}
              auto_complete={this.props.autocompletes}
            />
            <CustomizedTabs />
            <Container className="main-content-container px-4 pb-4">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '10vh', backgroundColor: '#fbfbfb' }}
              >
                <Grid item xs={12}>
                  <div className="error">
                    <br />
                    <br />
                    <div className="error__content">
                      <ErrorInfor
                        statusCode={this.props.location.state.status}
                        search_query={this.props.location.state.detail}
                      />
                      <br />
                      <br />
                      {/*  <Button  variant="contained" onClick={this.refresh} color="primary" disableElevation>
					     &larr; Go Back
					    </Button> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Paper>
          {/* Instead of the back button, put the rest of the results displayed below */}
          <List className="search-list">
            {this.props.results &&
              this.props.results.map((result, idx) =>
                result.full_name === null ? (
                  ''
                ) : (
                  <AlignItemsList
                    full_name={result.full_name}
                    description={result.brief_description}
                    location={result.city}
                    rate={result.price}
                    rating={result.rating}
                    image={result.image}
                    key={result.id}
                    id={result.id}
                  />
                )
              )}
          </List>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  autocompletes: state.results.autocompletes,
  results: state.results.all
});

export default connect(mapStateToProps, { getallresults, getsuggestions })(ErrorsPage);
