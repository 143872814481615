import React from 'react';
import { connect } from 'react-redux';
import { logout, notify } from '../actions/auth.js';

import WizardPetProvider from './Wizard';
import WizardPetOwner from './WizardPetOwner';
import IdleTimer from 'react-idle-timer';
import Footer from '../components/FooterWizard';
import { getProvider_photo } from '../actions/form.js';

class RenderWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 900 * 1,
      userLoggedIn: false,
      isTimedOut: false
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.props.notify();
      this.props.logout();
    } else {
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }
  componentDidMount() {
    this.props.getProvider_photo();
    if (this.props.form.photo_deleted === true) {
      console.log('Fetching photos...');
      this.props.getProvider_photo();
    }
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <div>
          {this.props.auth.user ? (
            <div>
              {this.props.auth.user.account_type === 'service_provider' ? (
                <div>
                  <WizardPetProvider wizard_stage={this.props.auth.user.stage_value} />
                  <Footer />
                </div>
              ) : (
                <div>
                  <WizardPetOwner wizard_stage={this.props.auth.user.stage_value} />
                  <Footer />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms
});

export default connect(mapStateToProps, { logout, notify, getProvider_photo })(RenderWizard);
