import axios from 'axios';
import {
  GET_SEARCH_RESULTS,
  GET_ALL_SEARCH_RESULTS,
  GET_SUGGESTIONS,
  GET_PROVIDER_DETAILS,
  FETCH,
  FETCH_DONE,
  CLEAR_STATE,
  SET_PROFILE_ID
} from './types';
import { returnError } from './errors';

export const getresults = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}services/${id}/providers/`) // API endpoint
    .then((res) => {
      dispatch({
        type: GET_SEARCH_RESULTS,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch(returnError(err.response, null));
    });
};

export const set_current_profile_id = (id) => (dispatch) => {
  console.log(id);
  dispatch({
    type: SET_PROFILE_ID,
    payload: id
  });
};

export const clear_state = (query) => (dispatch) => {
  dispatch({
    type: CLEAR_STATE
  });
};
export const init_fetch = (query) => (dispatch) => {
  console.log(query);
  dispatch({
    type: FETCH,
    payload: query
  });
};
export const deinit_fetch = () => (dispatch) => {
  dispatch({
    type: FETCH_DONE
  });
};

export const getresults_query = (paginate_url, query, miles, zip, latitude, longitude) => (dispatch) => {
  let miles_ = miles;
  let zip_ = zip;
  let latitude_ = latitude;
  let longitude_ = longitude;

  // console.log(miles)
  if (miles === 'null' || miles === 'undefined' || miles === null) {
    miles_ = '';
  }
  if (zip === 'null' || zip === 'undefined' || zip === null) {
    zip_ = '';
  }
  if (latitude === 'null' || latitude === 'undefined' || latitude === null) {
    latitude_ = '';
  }
  if (longitude === 'null' || longitude === 'undefined' || longitude === null) {
    longitude_ = '';
  }

  if(zip_){
    latitude_ = '';
    longitude_ = '';
  }

  let fetch_api;
  if(paginate_url === null){
    fetch_api = `${process.env.REACT_APP_API_URL}service-providers/?q=${query}&zipcode=${zip_}&proximity=${miles_}&latitude=${latitude_}&longitude=${longitude_}`
  }else{
    fetch_api = paginate_url
  }

  axios
    .get(fetch_api)
    .then((res) => {
      dispatch({
        type: GET_SEARCH_RESULTS,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined || err.response !== null) {
        console.log(err.response)
        dispatch(returnError(err.response.data, err.response.status));
      } else {
        alert('Something maybe wrong with your connection...');
      }
    });
};

// GET AUTO COMPLETE SUGGESTIONS
export const getsuggestions = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}v1/prodiders/auto_complete`) // API endpoint
    .then((res) => {
      dispatch({
        type: GET_SUGGESTIONS,
        payload: res.data
      });
    })
    .catch((err) => console.log(err));
};

// GET SINGLE PET PROVIDER DETAILS
export const getproviderdetails = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}users/${id}/`) // API endpoint
    .then((res) => {
      dispatch({
        type: GET_PROVIDER_DETAILS,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch(returnError(err.response, null));
    });
};

export const getUser = (uid) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}user/${uid}/`) 
    .then((res) => {
      dispatch({
        type: GET_PROVIDER_DETAILS,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch(returnError(err.response, null));
    });
};

// GET ALL RESULTS ON SYSTEM
export const getallresults = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}v1/providers/all/`) // API endpoint
    .then((res) => {
      dispatch({
        type: GET_ALL_SEARCH_RESULTS,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch(returnError(err.response.data, err.response.status));
    });
};
