/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { sendUserData, clear_error } from "../actions/form.js";
import { loadUser } from '../actions/auth.js';
import Spinner from "./Spinner";
import FormHelperText from '@material-ui/core/FormHelperText';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import PhoneInput from 'react-phone-input-2'
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  imageSelector: {
    fontSize: "8rem",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    justifyContent: "center",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(4),
  },
  selectFormControl: {
    width: "100%",
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  gridSubsection: {
    marginBottom: theme.spacing(1),
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "none",
    },
    "&::before": {
      borderBottom: "none",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  wizardContainer: {
    margin: theme.spacing(1, "auto"),
  },
  form: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginTop: theme.spacing(2),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#663399!important",
    marginRight: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  button_: {
    backgroundColor: "#FF3D00!important",
    width: "245px",
    height: "40px",
    marginBottom: "10px",
    marginLeft: theme.spacing(1),
  }
}));

function ContactSection(props) {
  const classes = useStyles();
  const { user } = props;
  const user_id = user.id;

  const format_contact = (contact) => {
    if(contact !== null && contact !== ""){
      contact.replace(/[-\s]/g, "")
      let new_number = contact;
      if(!new_number.includes("+")){
          new_number = `+${new_number}`
      }
      if(!new_number.includes("+") && new_number[0] !== '1'){
          new_number = `+1${new_number}`
      }
      if(new_number.includes("+") && new_number[1] !== '1'){
          new_number = new_number.replace(/[+\s]/g, "");
          new_number = `+1${new_number}`
      }
      return new_number
     }else{
      return null
    }
  }

  // data
  const [phone_number, setPhone_number] = useState(
    format_contact(props.phone_number)
  );
  const [alternate_phone_number, setAlternate_Phone_number] = useState(
    format_contact(props.alternate_phone_number)
  );
  const [emergency_contact_phone_number, setEmergency_Contact_Phone_Number] = useState(
    format_contact(props.emergency_contact_phone_number)
  );

  // const [email, setEmail] = useState(user.email);
  const [emergency_contact_name, setEmergency_Contact_Name] = useState(user.emergency_contact_name);
  const [emergency_contact_email, setEmergency_Contact_Email] = useState(user.emergency_contact_email);

  const [error_email, setError_email] = useState(false);
  const [submitted_status, setSStatus] = useState(false);

  const handleSubmit = () => {
    var mailFormat =
        /^([a-zA-Z0-9_\.\-!#$%&'*+/=?^`{|}~"(),:;<>[\]])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

    if (!mailFormat.test(emergency_contact_email)) {
      setError_email(true);
      return false;
    }else{
      setError_email(false);
    } 

    const userData = {};

    if (phone_number) userData.phone_number = phone_number;
    if (alternate_phone_number) userData.alternate_phone_number = alternate_phone_number;
    if (emergency_contact_name) userData.emergency_contact_name = emergency_contact_name;
    if (emergency_contact_phone_number) userData.emergency_contact_phone_number = emergency_contact_phone_number;
    if (emergency_contact_email) userData.emergency_contact_email = emergency_contact_email;

    setSStatus(true)
    props.sendUserData(userData, user_id);
    props.loadUser();
    props.loadUser();
    
    const timer = setTimeout(() => {
      setSStatus(false)
    }, 3000);
  } 

  let alert;
  if (props.error?.msg?.phone_number) {
    let msg = props.error?.msg?.phone_number;
    alert = <div className="alerts">{msg[0]}</div>;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="md" className={classes.wizardContainer}>
        <div>
          <FormGroup className={classes.formGroupProfileSection}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Your Contact Information
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Phone Number*
                </FormLabel>

                <div style={{ height: '10px'}} />
                <PhoneInput
                  country='us'
                  onlyCountries={['us','ca']}
                  preferredCountries={['us']}
                  placeholder="2063334444"
                  countryCodeEditable={false}
                  prefix="+"
                  inputProps={{ style : {
                    height: '45px',
                    fontSize: '13px',
                    width: '100%',
                    color: '#1b1f23',
                    border: '1px solid #cfd7de',
                    borderRadius: '5px'
                  }}}
                  value={phone_number}
                  onChange={(phone) => {
                    setPhone_number('+' + phone)
                  }}
                />

                {/*<Input
                  id="phone_number"
                  defaultValue={phone_number}
                  onChange={(e) => props.setPhone_number(e.target.value)}
                  autoComplete="new-password"
                  disableUnderline
                  fullWidth
                  placeholder="Phone Number"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />*/}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Alternative Phone Number
                </FormLabel>
                <div style={{ height: '10px'}} />
                <PhoneInput
                  country='us'
                  onlyCountries={['us','ca']}
                  preferredCountries={['us']}
                  placeholder="2063334444"
                  countryCodeEditable={false}
                  prefix="+"
                  inputProps={{ style : {
                    height: '45px',
                    fontSize: '13px',
                    width: '100%',
                    color: '#1b1f23',
                    border: '1px solid #cfd7de',
                    borderRadius: '5px'
                  }}}
                  value={alternate_phone_number}
                  onChange={(phone) => {
                    setAlternate_Phone_number('+' + phone)
                  }}
                />
                {/*<Input
                  id="alt_phone_number"
                  defaultValue={alternate_phone_number}
                  onChange={(e) =>
                    props.setAlternate_Phone_number(e.target.value)
                  }
                  autoComplete="new-password"
                  disableUnderline
                  fullWidth
                  placeholder="Alternative Phone Number"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />*/}
              </Grid>
              {/*<Grid item xs={12} sm={12}>
                <FormLabel component="label" className={classes.formLabel}>
                  Email Address
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  value={props.email}
                  disableUnderline
                  fullWidth
                  placeholder="Email Address"
                  // onChange={(e) => props.setEmail(e.target.value)}
                  autoComplete="new-password"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />
              </Grid>*/}
            </Grid>
          </FormGroup>
          <FormGroup className={classes.formGroupProfileSection}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Emergency Contact Information
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Full Names
                </FormLabel>
                <Input
                  onChange={(e) => {
                    setEmergency_Contact_Name(e.target.value);
                  }}
                  id="emerg_contact_name"
                  defaultValue={props.emergency_contact_name}
                  autoComplete="new-password"
                  disableUnderline
                  fullWidth
                  placeholder="Full Names"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Phone Number
                </FormLabel>
                <div style={{ height: '10px'}} />
                <PhoneInput
                  country='us'
                  onlyCountries={['us','ca']}
                  preferredCountries={['us']}
                  placeholder="2063334444"
                  countryCodeEditable={false}
                  prefix="+"
                  inputProps={{ style : {
                    height: '45px',
                    fontSize: '13px',
                    color: '#1b1f23',
                    width: '100%',
                    border: '1px solid #cfd7de',
                    borderRadius: '5px'
                  }}}
                  value={emergency_contact_phone_number}
                  onChange={(phone) => {
                    setEmergency_Contact_Phone_Number('+' + phone);
                  }}
                />
                {/*<Input
                  onChange={(e) => {
                    props.setEmergency_Contact_Phone_Number(e.target.value);
                  }}
                  id="emerg_phone_number"
                  defaultValue={emergency_contact_phone_number}
                  autoComplete="new-password"
                  disableUnderline
                  fullWidth
                  placeholder="Phone Number"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />*/}
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormLabel component="label" className={classes.formLabel}>
                  Email Address
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  defaultValue={props.emergency_contact_email}
                  disableUnderline
                  fullWidth
                  error={error_email}
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmergency_Contact_Email(e.target.value);
                  }}
                  autoComplete="new-password"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />
                
                {error_email ? <FormHelperText error>Please enter a valid email address. </FormHelperText>: null}
              </Grid>

              <Button
                variant="contained"
                color="primary"
                className={classes.button_}
                onClick={handleSubmit}
              >
                {props.form.loading_collective && submitted_status
                ? <Spinner /> : "Update contact information"}
              </Button>

              {props.error?.msg?.phone_number ? (
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          props.clear_error();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <div className={classes.message}>{alert}</div>
                  </Alert>
                </Grid>
              ) : null}

            </Grid>
          </FormGroup>
        </div>
      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  sendUserData,
  clear_error,
  loadUser
})(ContactSection);