import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";

import { DropzoneDialog } from "material-ui-dropzone";

import { useDispatch } from "react-redux";
import { loadUser } from "../actions/auth.js";

import {
  sendUserData,
  sendCertificate,
  getCertificate,
  deleteCertificate,
  user_editing,
  clear_error,
} from "../actions/form";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),

    "& > *": {
      margin: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  imageSelector: {
    fontSize: "8rem",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(4),
  },
  selectFormControl: {
    width: "100%",
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  dropzone: {
    marginTop: theme.spacing(1),
  },
  cardContainer: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 0, 1),
  },
  cardRoot: {
    maxWidth: 245,
  },
  media: {
    height: 0,
    borderRadius: "5px",
    paddingTop: "56.25%", // 16:9
  },
  delete: {
    marginLeft: "auto",
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  radioLabel: {
    "& span": {
      fontSize: "13px!important",
      color: "#1b1f23",
    },
  },
  wizardContainer: {},
  form: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formLabelPetPrefs: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    margin: theme.spacing(1, 0),
  },
  formLabelPetExperience: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    lineHeight: "2.5em",
  },
  formLabelPetExperience_: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    lineHeight: "2.5em",
    marginLeft: theme.spacing(2),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
  },
  formGroupLabelPetPrefs: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    marginBottom: theme.spacing(0),
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#663399!important",
    marginRight: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  buttonCertificate: {
    margin: theme.spacing(2, 0, 1),
    backgroundColor: "#FF3D00!important",
  },
  fieldSetRadio: {
    marginLeft: theme.spacing(1),
  },
  adornment: {
    "& p": {
      fontSize: "13px",
    },
  },
  cardHeader: {
    "& span": {
      fontSize: "14px",
      fontWeight: "600",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "225px",
    },
  },
}));

function DashboardPetExperienceWizard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user_id = props.user.id;

  const [years, setYears] = useState(props.user.experience_years);
  const [months, setMonths] = useState(props.user.experience_months);
  const [edited, setEdited] = useState(false);
  const [negative_error, setNegativeError] = React.useState(false);
  const [negative_error_, setNegativeError_] = React.useState(false);

  React.useEffect(() => {
    if (props.form.signal_save === true) {
      const object = {
        experience_years: years,
        experience_months: months,
      };

      const sendReq = (data, user_id) => {
        const timer = setTimeout(() => {
          dispatch(sendUserData(data, user_id));
        }, 2000);

        return timer;
      };

      sendReq(object, user_id);

      return () => clearTimeout(sendReq);
    }
  }, [years, months, props.form.signal_save, user_id, dispatch]);

  React.useEffect(() => {
    if (edited === true) {
      if (props.form.user_editing === false) {
        // avoiding multiple dispatches when boolean is already true
        props.user_editing();
      } else {
        setEdited(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.user_editing, edited]);

  React.useEffect(() => {
    if (props.form.fetch === true) {
      props.loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.fetch]);

  React.useEffect(() => {
    props.loadUser();
    props.loadUser();
    props.clear_error();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(props.form.reload_certificates){
      props.getCertificate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.reload_certificates]);

  

  const [count, setCount] = useState([]);
  const [openDropzone, setOpenDropzone] = React.useState(false);
  const certificateResults = props.certificates;

  function handleDropzoneChange(e) {
    setCount(e.length);

    if (e[count] !== undefined) {
      props.sendCertificate(e[count]);
    }
    setTimeout(() => {
      props.getCertificate();
      // window.location.reload()
    }, 500);
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="md" className={classes.wizardContainer}>
        <div>
          <FormGroup className={classes.formGroup}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Pet experience
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Years
                </FormLabel>
                <Input
                  onChange={(e) => {
                    var value = parseInt(e.target.value);
                    if (e.target.value >= 0) {
                      setYears(e.target.value);
                      setNegativeError(false);
                      setEdited(true);
                    } else {
                      value = value * -1;
                      setYears(value);
                      setNegativeError(true);
                      setEdited(false);
                    }
                  }}
                  id="years"
                  defaultValue={props.user.experience_years}
                  disableUnderline
                  fullWidth
                  placeholder="0"
                  type="number"
                  inputProps={{ "aria-label": "description" }}
                  endAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.adornment}
                    >
                      Year(s)
                    </InputAdornment>
                  }
                  className={classes.inputSmall}
                />
                {negative_error === true ? (
                  <FormLabel style={{ color: "red" }}>
                    Please add a valid number of year(s)
                  </FormLabel>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Months
                </FormLabel>
                <Input
                  onChange={(e) => {
                    var value = parseInt(e.target.value);
                    if (e.target.value >= 0) {
                      setMonths(e.target.value);
                      setNegativeError_(false);
                      setEdited(true);
                    } else {
                      value = value * -1;
                      setMonths(value);
                      setNegativeError_(true);
                      setEdited(false);
                    }
                  }}
                  id="months"
                  defaultValue={props.user.experience_months}
                  disableUnderline
                  fullWidth
                  placeholder="0"
                  type="number"
                  inputProps={{ "aria-label": "description" }}
                  endAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.adornment}
                    >
                      Month(s)
                    </InputAdornment>
                  }
                  className={classes.inputSmall}
                />
                {negative_error_ === true ? (
                  <FormLabel style={{ color: "red" }}>
                    Please add a valid number of months(s)
                  </FormLabel>
                ) : null}
              </Grid>
            </Grid>
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Grid item xs={12} className={classes.dropzone}>
              <FormLabel
                component="label"
                className={classes.formLabelPetExperience}
              >
                Do you have any professional certificates working with pets?
              </FormLabel>
              <div>
                <Button
                  className={classes.buttonCertificate}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenDropzone(true)}
                >
                  Add Certificate
                </Button>
                <FormLabel
                  component="label"
                  id="labelCert"
                  className={classes.formLabelPetExperience_}
                >
                  Add certificate as an image
                </FormLabel>
                <DropzoneDialog
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  onChange={(e) => handleDropzoneChange(e)}
                  cancelButtonText={"cancel"}
                  submitButtonText={"submit"}
                  maxFileSize={10000000}
                  open={openDropzone}
                  onClose={() => setOpenDropzone(false)}
                  onSave={(files) => {
                    handleDropzoneChange(files);
                    setOpenDropzone(false);
                  }}
                />
              </div>
            </Grid>
          {certificateResults !== null ?
            <div>
              {
                certificateResults.length > 0 ? 
                <FormLabel component="label" className={classes.formGroupLabel}>
                    Certificates Submitted
                </FormLabel>: null
              }
            </div>
            : null
          }
            <Grid container spacing={2} className={classes.cardContainer}>
              {certificateResults !== null && certificateResults !== undefined
                ? certificateResults.map((certificate) =>
                    certificate.certificate !== null ? (
                      <Grid item xs={12} sm={6} md={4} key={certificate.id}>
                        <Card className={classes.cardRoot}>
                          <CardMedia
                            className={classes.media}
                            image={certificate.certificate}
                            title={certificate.certificate
                              .substring(63)
                              .split("?")
                              .shift()}
                          />
                          <CardActions disableSpacing>
                            <IconButton aria-label="download">
                              <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton
                              className={classes.delete}
                              aria-label="delete"
                            >
                              <DeleteIcon
                                onClick={() => {
                                  props.deleteCertificate(certificate.id);
                                  setTimeout(() => {
                                    props.getCertificate();
                                  }, 1000);
                                }}
                              />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} md={4} key={certificate.id}>
                        <Card className={classes.cardRoot}>
                          <CardHeader
                            title="No Name"
                            subheader={certificate.created.split("T").shift()}
                          />
                          <CardMedia
                            className={classes.media}
                            image={certificate.certificate}
                            title="No Name"
                          />
                          <CardActions disableSpacing>
                            <IconButton aria-label="download">
                              <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton
                              className={classes.delete}
                              aria-label="delete"
                            >
                              <DeleteIcon
                                onClick={() =>
                                  props.deleteCertificate(certificate.id)
                                }
                              />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  )
                : ""}
            </Grid>
          </FormGroup>
        </div>
      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  certificates: state.certificates.results,
  error: state.errors,
  form: state.forms,
  results: state.services.results,
  all_services: state.services.admin_services,
});
export default connect(mapStateToProps, {
  sendUserData,
  loadUser,
  sendCertificate,
  getCertificate,
  deleteCertificate,
  user_editing,
  clear_error,
})(DashboardPetExperienceWizard);
