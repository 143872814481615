import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";

import Checkbox from "@material-ui/core/Checkbox";
import { loadUser } from "../actions/auth.js";
import {
  pet_preference,
  pet_preference_del,
  sendUserData_pref,
  sendProvider_photo,
  getProvider_photo,
} from "../actions/form.js";
// import ProviderGallery from "../components/ProviderGallery";
import ProviderGalleryViewWizard from "../components/ProviderGalleryViewWizard";
import DialogGallery from "./Dialog_Gallery.js";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { createMessage } from "../actions/messages";
import { clear_error } from "../actions/form";
import Spinner from "../assets/home_load.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginBottom: theme.spacing(2),

    "& > *": {
      margin: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(4),
  },
  selectFormControl: {
    width: "100%",
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  radioLabel: {
    "& span": {
      fontSize: "13px!important",
      color: "#1b1f23",
    },
  },
  wizardContainer: {
    margin: theme.spacing(0, "auto"),
  },
  form: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formLabelPetPrefs: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    margin: theme.spacing(1, 0),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroupLabelPetPrefs: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroupLabelPetPrefs__: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  formGroupLabelPetPrefs_: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(0),
  },
  formGroup: {
    marginBottom: theme.spacing(0),
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  imageSelector: {
    fontSize: "8rem",
    paddingLeft: theme.spacing(0),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#663399!important",
    marginRight: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  fieldSetRadio: {
    marginLeft: theme.spacing(1),
  },
  formGroup_: {
    display: "block",
    marginRight: theme.spacing(2),
  },
  formGroup__: {
    display: "flex",
  },
}));

function PetPreferencesSection(props) {
  const classes = useStyles();

  const user_id = props.user.id;

  const [cat_, setCat] = React.useState(false);
  const [dog_, setDog] = React.useState(false);
  const [bird_, setBird] = React.useState(false);

  const [small_, setSmall] = React.useState(false);
  const [medium_, setMedium] = React.useState(false);
  const [large_, setLarge] = React.useState(false);

  React.useEffect(() => {
    props.loadUser();
    props.loadUser();
    props.getProvider_photo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlecat = (e) => {
    const new_value = !cat_;

    const object = {
      pet_preference_1: new_value,
    };
    if (cat_ === true) {
      setCat(false);
      props.loadUser();
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    } else {
      setCat(true);
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    }
  };

  const handledog = (e) => {
    const new_value = !dog_;

    const object = {
      pet_preference_2: new_value,
    };
    if (dog_ === true) {
      setDog(false);
      props.loadUser();
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    } else {
      setDog(true);
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    }
  };

  const handlebird = (e) => {
    const new_value = !bird_;

    const object = {
      pet_preference_3: new_value,
    };
    if (bird_ === true) {
      setBird(false);
      props.loadUser();
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    } else {
      setBird(true);
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    }
  };

  const handleSmall = (e) => {
    const new_value = !small_;

    const object = {
      weight_preference_1: new_value,
    };
    if (small_ === true) {
      setSmall(false);
      props.loadUser();
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    } else {
      setSmall(true);
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    }
  };

  const handleMedium = (e) => {
    const new_value = !medium_;

    const object = {
      weight_preference_2: new_value,
    };
    if (medium_ === true) {
      setMedium(false);
      props.loadUser();
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    } else {
      setMedium(true);
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    }
  };

  const handleLarge = (e) => {
    const new_value = !large_;

    const object = {
      weight_preference_3: new_value,
    };
    if (large_ === true) {
      setLarge(false);
      props.loadUser();
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    } else {
      setLarge(true);
      props.sendUserData_pref(object, user_id);
      props.loadUser();
    }
  };

  React.useEffect(() => {
    // pet_preferences
    // cat
    if (props.user.pet_preference_1 === false) {
      setCat(false);
    } else {
      setCat(true);
    }
    // Dog
    if (props.user.pet_preference_2 === false) {
      setDog(false);
    } else {
      setDog(true);
    }
    // Bird
    if (props.user.pet_preference_3 === false) {
      setBird(false);
    } else {
      setBird(true);
    }

    // weight_preferences
    // small
    if (props.user.weight_preference_1 === false) {
      setSmall(false);
    } else {
      setSmall(true);
    }
    // medium
    if (props.user.weight_preference_2 === false) {
      setMedium(false);
    } else {
      setMedium(true);
    }
    // large
    if (props.user.weight_preference_3 === false) {
      setLarge(false);
    } else {
      setLarge(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPhotos = props.form.fetch_photos;
  const photoDeleted = props.form.photo_deleted;

  React.useEffect(() => {
    if (fetchPhotos === true || photoDeleted === true) {
      props.getProvider_photo();
      props.clear_error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPhotos, photoDeleted]);

  // Images upload
  // eslint-disable-next-line no-unused-vars
  const [photo, setPhoto] = React.useState(null);
  const [save, setSave] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [notification, setNotification] = React.useState(null);

  const send_save_signal = (value) => {
    setSave(value)
  }

  // uploading provider photos
  const setting = (image) => {
    if (image) {
      setNotification(true);
      setPhoto(image);
    }
  };

  React.useEffect(() => {
    if (photo !== null && save) {
      const uploadData = new FormData();
      uploadData.append("image", photo, photo.name);
      uploadData.append("user", user_id);
      props.sendProvider_photo(uploadData);
      setSave(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo, save, user_id]);

  let alert;
  if (props.messages.notify_timeout !== null) {
    alert = <div className="alerts">{props.messages.notify_timeout}</div>;
  }

  const [image_data, setImageData] = React.useState(null);
  React.useEffect(() => {
    const images_list = [];
    if (props.form.photos) {
      if(props.form.photos?.length !== 0){
        const first_image = props.form.photos[0].id;
        // eslint-disable-next-line array-callback-return
        props.form.photos.filter((single_image) => {
          if (single_image.id === first_image) {
            const image_object = {
              id: single_image.id,
               src: single_image.image,
               thumbnail: single_image.image,
               isSelected: false,
            };
            images_list.push(image_object);
          } else {
            const image_object = {
              id: single_image.id,
               src: single_image.image,
               thumbnail: single_image.image,
               isSelected: false,
            };
            images_list.push(image_object);
          }
        });
      }else{
        setImageData(null);
      }
    }
    setImageData(images_list);
  }, [props.form.photos]);

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="md" className={classes.wizardContainer}>
        <div>
          <FormGroup className={classes.formGroup}>
            <FormLabel
              component="label"
              className={classes.formGroupLabelPetPrefs}
            >
              Tell us about your preferences
            </FormLabel>

            <FormGroup className={classes.formGroup_}>
              <FormLabel
                component="label"
                className={classes.formGroupLabelPetPrefs}
              >
                Pet preferences
              </FormLabel>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cat_}
                    onChange={handlecat}
                    id="catCheck"
                    name="cat"
                  />
                }
                label="Cat "
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dog_}
                    onChange={handledog}
                    id="dogCheck"
                    name="dog"
                  />
                }
                label="Dog"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bird_}
                    onChange={handlebird}
                    id="birdCheck"
                    name="bird"
                  />
                }
                label="Bird"
              />
            </FormGroup>
            <br />
            <FormGroup className={classes.formGroup__}>
              <FormLabel
                component="label"
                className={classes.formGroupLabelPetPrefs_}
              >
                Weight preferences
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={small_}
                    onChange={handleSmall}
                    id="smallCheck"
                    name="small"
                  />
                }
                label="Small Pets (Up to 15 lbs)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medium_}
                    onChange={handleMedium}
                    id="mediumCheck"
                    name="medium"
                  />
                }
                label="Medium Pets (Between 16 to 50 lbs)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={large_}
                    onChange={handleLarge}
                    id="largeCheck"
                    name="large"
                  />
                }
                label="Large Pets (Over 50 lbs)"
              />
            </FormGroup>
          </FormGroup>
        </div>
        <div className={classes.formGroupLabelPetPrefs__}>
          <FormLabel
            component="label"
            className={classes.formGroupLabelPetPrefs}
          >
            Upload photos of you with pets for pet owners to see.
          </FormLabel>
        </div>
        {props.messages.notify_timeout ? (
          <Grid item xs={12}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    props.clear_error();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div className={classes.message}>{alert}</div>
            </Alert>
          </Grid>
        ) : null}
        <DialogGallery setting={setting} send_save_signal={send_save_signal} />
        <FormLabel component="label" className={classes.formGroupLabelPetPrefs}>
          Photos
        </FormLabel>
        {/*{!props.form.photos_loading ? (
             <img 
                src={Spinner} 
                alt="" 
                style={{ marginLeft: '20px', top: '20px' }} 
                height="20px" 
                width="20px" /> 
            )
        : null}
*/}
        <div style={{ marginTop: '20px' }} >
          {props?.form?.photos && fetchPhotos === false ? (
            <div>
              {image_data !== null ? (
                <ProviderGalleryViewWizard images={image_data} />
              ) : null}
            </div>
          ) : (
            <div
              style={{
                marginLeft: "10px",
                marginTop: "5px",
              }}
            >
              <img src={Spinner} alt="" height="35px" width="35px" />
            </div>
          )}
        </div>
      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  messages: state.messages,
  admin_pets: state.pets.pet_types,
  results: state.services.results,
  all_services: state.services.admin_services,
});

export default connect(mapStateToProps, {
  pet_preference,
  pet_preference_del,
  sendUserData_pref,
  clear_error,
  createMessage,
  loadUser,
  sendProvider_photo,
  getProvider_photo,
})(PetPreferencesSection);