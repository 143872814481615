import {
  CREATE_BOOKING,
  BOOKING_LOADING,
  BOOKING_LOADING_,
  BOOKING_LOADED,
  GET_BOOKING_LOADED,
  PAYOUT_LOADING,
  CREATE_PAYOUT,
  PAYOUT_LOADED,
  PAYOUT_DATA_LOADED,
  BOOKING_DATA_LOADED,
  UPCOMING_RECURRING,
  PAST_NON_RECURRING,
  PAST_RECURRING,
  GET_SCHEDULE_DATA,
  SCHEDULE_LOADING,
  SCHEDULE_CREATED,
  UPDATE_BOOKING_LOADING,
  UPDATE_BOOKING_FAILED,
  UPDATE_BOOKING,
  CREATE_REVIEW,
  CREATE_REVIEW_LOADING,
  REVIEWS_LOADING,
  REVIEWS,
  REVIEWS_FAILED,
  CONFIRM_LOADING,
  REJECTED_LOADING,
  CANCELLED_LOADING,
  PAYMENTS_TIPS_LOADING,
  PAYMENTS_TIPS_CREATE,
  PAYMENTS_TIPS_FAILED,
  PAYMENTS_FETCHED,
  OUTGOING_PAYMENTS_FETCHED,
  UPDATE_MESSAGE_CLEAR,
  UPDATE_MESSAGE_LOADING,
  UPDATE_MESSAGE_BOOKING,
  UPDATE_MESSAGE_BOOKING_FAILED,
  ACCOUNT_CREATED_SUCCESS,
  ADD_TO_CALENDAR_LOAD,
  ADDED_TO_CALENDAR,
  CLEAR_ADD_CALENDAR_ID,
} from '../actions/types';

const initialState = {
  isLoading: false,
  isLoading2: false,
  isLoading_tips_payments: false,
  review_made: false,
  tips_payment_made: false,
  loading_review: false,
  loading_update: false,
  booking_data: null, // connected with some notification on UI
  loading_payout: false,
  payouts_data: null,
  bank_accounts: null,
  
  bookings: [],
  bookings_next: null,
  upcoming_recur_bookings: [],
  upcoming_recur_bookings_next: null,
  past_nonrecur_bookings: [],
  past_nonrecur_bookings_next: null,
  past_recur_bookings: [],
  past_recur_bookings_next: null,


  payments_data: [],
  payments_history_next: null,

  payments_data_out: [],
  payments_history_out_next: null,

  schedule_data: null,
  loading_schedule: false,
  reviews: null,
  reviews_loading: false,
  booking: null,
  // statuses
  confirmed_loading: false,
  confirmed: false,
  confirm_id: null,
  cancelled_loading: false,
  cancel_id: null,
  rejected_loading: false,
  rejected_id: false,
  loading_update_message: false,
  update_message_id: null,
  success_update_message: false,
  failed_update_message: false,
  bank_account_wizard: false,

  add_to_calendar_load: false,
  add_to_calender_id: null,
  add_to_calendar_success: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    
    case CLEAR_ADD_CALENDAR_ID: 
      return {
        ...state, 
        add_to_calendar_load: false,
        add_to_calendar_success: false,
        add_to_calender_id: null,
    }
    case ADDED_TO_CALENDAR: 
      return {
        ...state, 
        add_to_calendar_load: false,
        add_to_calendar_success: true
    }
    case ADD_TO_CALENDAR_LOAD: 
      return {
        ...state, 
        add_to_calendar_load: true,
        add_to_calender_id: action.payload
    }
    case UPDATE_MESSAGE_LOADING: 
      return {
        ...state, 
        loading_update_message: true,
        update_message_id: action.payload
      }
    case ACCOUNT_CREATED_SUCCESS: 
      return {
        ...state, 
        bank_account_wizard: true
      }
    case UPDATE_MESSAGE_BOOKING: 
      return {
        ...state, 
        loading_update_message: false,
        success_update_message: true
      }
    case UPDATE_MESSAGE_BOOKING_FAILED: 
      return {
        ...state, 
        loading_update_message: false,
        success_update_message: false,
        failed_update_message: true,
      }
    case UPDATE_MESSAGE_CLEAR: 
      return {
        ...state, 
        loading_update_message: false,
        success_update_message: false,
        failed_update_message: false,
        update_message_id: null
      }
    
  
    case PAYMENTS_FETCHED: 
      const existingPaymentIds = new Set(state.payments_data?.map(payment => payment.id));
      const uniqueResultsPayments = action.payload?.results?.filter(result => !existingPaymentIds.has(result.id));
      return {
        ...state, 
        payments_data: [...state.payments_data, ...uniqueResultsPayments],
        payments_history_next: action.payload.next
      };
    case OUTGOING_PAYMENTS_FETCHED: 
      const existingPaymentIds_ = new Set(state.payments_data_out?.map(payment => payment.id));
      const uniqueResultsPayments_ = action.payload?.results?.filter(result => !existingPaymentIds_.has(result.id));
      return {
        ...state, 
        payments_data_out: [...state.payments_data_out, ...uniqueResultsPayments_],
        payments_history_out_next: action.payload.next
      };
  
    case BOOKING_DATA_LOADED:
      console.log("Here 3")
      console.log(action.payload)
      return {
        ...state,
        bookings: action.payload?.results,
        bookings_next: action.payload.next,
        bookings_prev: action.payload.previous,
      };
    // case BOOKING_DATA_LOADED:
    //   const existingDataIds = new Set(state.bookings?.map(i => i.id));
    //   const uniqueResultsBookings = action.payload?.results?.filter(result => !existingDataIds.has(result.id));
    //   return {
    //     ...state,
    //     bookings: [...state.bookings, ...uniqueResultsBookings],
    //     bookings_next: action.payload.next,
    //   };

    case PAST_NON_RECURRING:
      
      return {
        ...state,
        past_nonrecur_bookings: action.payload?.results,
        past_nonrecur_bookings_next: action.payload.next,
        past_bookings_prev: action.payload.previous,
      };

    // case PAST_NON_RECURRING:
    //   const existingIDs1 = new Set(state.past_nonrecur_bookings?.map(i => i.id));
    //   const uniqueResultsBookings2 = action.payload?.results?.filter(result => !existingIDs1.has(result.id));
    //   return {
    //     ...state,
    //     past_nonrecur_bookings: [...state.past_nonrecur_bookings, ...uniqueResultsBookings2],
    //     past_nonrecur_bookings_next: action.payload.next,
    //   };

    case UPCOMING_RECURRING:
      const existingIDs = new Set(state.upcoming_recur_bookings?.map(i => i.id));
      const uniqueResultsBookings1 = action.payload?.results?.filter(result => !existingIDs.has(result.id));
      return {
        ...state,
        upcoming_recur_bookings: [...state.upcoming_recur_bookings, ...uniqueResultsBookings1],
        upcoming_recur_bookings_next: action.payload.next,
      };

    case PAST_RECURRING:
      const existingIDs2 = new Set(state.past_recur_bookings?.map(i => i.id));
      const uniqueResultsBookings3 = action.payload?.results?.filter(result => !existingIDs2.has(result.id));
      return {
        ...state,
        past_recur_bookings: [...state.past_recur_bookings, ...uniqueResultsBookings3],
        past_recur_bookings_next: action.payload.next,
      };

    case PAYMENTS_TIPS_LOADING: 
      return {
        ...state, 
        isLoading_tips_payments: true
      }
    case PAYMENTS_TIPS_CREATE: 
      return {
        ...state, 
        isLoading_tips_payments: false,
        tips_payment_made: true
      }
    case PAYMENTS_TIPS_FAILED: 
      return {
        ...state, 
        isLoading_tips_payments: false,
      }
    case CONFIRM_LOADING: 
      return {
        ...state, 
        confirmed_loading: true,
        confirm_id: action.payload
      }
    case REJECTED_LOADING: 
      return {
        ...state, 
        rejected_loading: true,
        rejected_id: action.payload
      }
    case CANCELLED_LOADING: 
      return {
        ...state, 
        cancelled_loading: true,
        cancel_id: action.payload
      }
    case CREATE_BOOKING:
      return {
        ...state,
        booking_data: action.payload,
        isLoading: false
      };
    case REVIEWS_LOADING:
      return {
        ...state,
        reviews_loading: true
      };
    case REVIEWS_FAILED:
      return {
        ...state,
        reviews_loading: false
      };
    case REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        reviews_loading: false,
        review_made: false
      };
    case UPDATE_BOOKING_LOADING:
      return {
        ...state,
        loading_update: true
      };
    case UPDATE_BOOKING_FAILED:
    case UPDATE_BOOKING:
      return {
        ...state,
        loading_update: false
      };
    case CREATE_REVIEW_LOADING:
      return {
        ...state,
        loading_review: true
      };
    case CREATE_REVIEW:
      return {
        ...state,
        loading_review: false,
        review_made: true
      };
    case GET_SCHEDULE_DATA:
      return {
        ...state,
        schedule_data: action.payload.results
        // isLoading: false,
      };
    case PAYOUT_DATA_LOADED:
      return {
        ...state,
        bank_accounts: action.payload.results
      };
    case PAYOUT_LOADING:
      return {
        ...state,
        loading_payout: true
      };
    case SCHEDULE_LOADING:
      return {
        ...state,
        loading_schedule: true
      };
    case SCHEDULE_CREATED:
      return {
        ...state,
        loading_schedule: false
      };
    case CREATE_PAYOUT:
      return {
        ...state,
        payouts_data: action.payload,
        loading_payout: false
      };
    case PAYOUT_LOADED:
      return {
        ...state,
        loading_payout: false
      };
    case BOOKING_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case BOOKING_LOADING_:
      return {
        ...state,
        isLoading2: true
      };
    case BOOKING_LOADED:
      return {
        ...state,
        isLoading: false,
        isLoading2: false,
        confirmed_loading: false,
        confirmed: true,
        confirm_id: null,
        cancelled_loading: false,
        cancel_id: null,
        rejected_loading: false,
        rejected_id: false
      };
    case GET_BOOKING_LOADED:
      return {
        ...state,
        booking: action.payload
      };
    default:
      return {
        ...state
      };
  }
}
