import axios from 'axios';
import {
  CREATE_BOOKING,
  BOOKING_LOADING,
  BOOKING_LOADED,
  GET_BOOKING_LOADED,
  BOOKING_RELOAD_PAGE,
  BOOKING_DATA_LOADED,
  UPCOMING_RECURRING,
  PAST_NON_RECURRING,
  PAST_RECURRING,
  GET_SCHEDULE_DATA,
  SCHEDULE_LOADING,
  SCHEDULE_CREATED,
  UPDATE_BOOKING_LOADING,
  UPDATE_BOOKING,
  UPDATE_BOOKING_FAILED,
  CREATE_REVIEW,
  CREATE_REVIEW_LOADING,
  REVIEWS_LOADING,
  REVIEWS,
  REVIEWS_FAILED,
  CONFIRM_LOADING,
  REJECTED_LOADING,
  CANCELLED_LOADING,
  PAYMENTS_TIPS_LOADING,
  PAYMENTS_TIPS_CREATE,
  PAYMENTS_TIPS_FAILED,
  ADD_TO_CALENDAR_LOAD,
  ADDED_TO_CALENDAR,
  CLEAR_ADD_CALENDAR_ID,

  UPDATE_MESSAGE_LOADING,
  UPDATE_MESSAGE_BOOKING,
  UPDATE_MESSAGE_BOOKING_FAILED,
  UPDATE_MESSAGE_CLEAR

} from './types';
import { returnError } from './errors';
import { tokenConfig } from './auth';
import { createMessage, createMessage_schedules, createMessage_status } from './messages';

export const create_review = (rating, comment, pet_provider, rated_by) => (dispatch, getState) => {
  dispatch({ type: CREATE_REVIEW_LOADING });

  const body = JSON.stringify({
    rating,
    comment,
    pet_provider,
    rated_by
  });
   // console.log(body)
  axios
    .post(`${process.env.REACT_APP_API_URL}reviews/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CREATE_REVIEW
      });
      dispatch(createMessage('Thank you for adding a review!'));
      // console.log(res.data)
    })
    .catch((err) => {
      dispatch({ type: CREATE_REVIEW });
      if (err.response) {
        console.log(err.response);
        dispatch(returnError(err.response, null));
      } else {
        console.log(err);
      }
    });
};

export const fetch_reviews = (id) => (dispatch, getState) => {
  dispatch({ type: REVIEWS_LOADING });

  axios
    .get(`${process.env.REACT_APP_API_URL}users/${id}/reviews/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: REVIEWS,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({ type: REVIEWS_FAILED });
      if (err.response) {
        console.log(err.response);
        dispatch(returnError(err.response, null));
      } else {
        console.log(err);
      }
    });
};

export const create_booking = (start_time, start_date, end_time, end_date, notes, service, created_by, pets, provider, additional_rates, recurring, interval) => (dispatch, getState) => {
    dispatch({ type: BOOKING_LOADING });
    // const payment_method = localStorage.getItem("owner_primary_card")

    const body = JSON.stringify({
      start_time,
      start_date,
      end_time,
      end_date,
      notes,
      service,
      created_by,
      pets,
      // payment_method,
      additional_rates,
      recurring, 
      interval
    });
    console.log("create_booking: Booking Object")
    console.log(body)

    axios
      .post(`${process.env.REACT_APP_API_URL}bookings/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: CREATE_BOOKING,
          payload: res.data
        });

        var booking_object = {
          start_time: start_time,
          end_time: end_time,
          start_date: start_date,
          end_date: end_date,
          service: service
        };
        localStorage.setItem('booking_object', JSON.stringify(booking_object));
        localStorage.setItem('booking_api_object', JSON.stringify(res.data));
        localStorage.setItem('booking_created', "true");
        localStorage.setItem('booking_created', "true");
        console.log("res.data: create_booking")
        // console.log(res.data)

        dispatch(
          createMessage(
            `Booking has been created successfully.`
          )
          // createMessage(
          //   `Your booking has been created and a notification has been sent to ${provider}. Please add a payment method.`
          // )
        );
      })
      .catch((err) => {
        dispatch({ type: BOOKING_LOADED });
        if (err.response) {
          console.log(err.response);
          dispatch(returnError(err.response, null));
        } else {
          console.log(err);
        }
      });
  };

export const clear_error_bookings = () => (dispatch, getState) => {
    dispatch({ type: UPDATE_MESSAGE_CLEAR });
  }

export const update_message_booking = (notes, booking_id) =>  (dispatch, getState) => {
    dispatch({ 
      type: UPDATE_MESSAGE_LOADING,
      payload: booking_id
    });
    const body = JSON.stringify({
      notes,
    });
    
   axios
    .patch(`${process.env.REACT_APP_API_URL}bookings/${booking_id}/`, body, tokenConfig(getState))
      .then((res) => {
        console.log("res.data")
        console.log(res.data)
        dispatch({
          type: UPDATE_MESSAGE_BOOKING,
          payload: res.data
        });

      })
      .catch((err) => {
        if (err.response) {
          // console.log(err.response);
          dispatch({ 
            type: UPDATE_MESSAGE_BOOKING_FAILED,
            payload: booking_id
          });
        } else {
          console.log(err);
        }
      });
  };

export const update_booking =
  (start_time, start_date, end_time, end_date, notes, provider, booking_id, recurring, interval) =>
  (dispatch, getState) => {
    dispatch({ type: UPDATE_BOOKING_LOADING });

    const body = JSON.stringify({
      start_time,
      start_date,
      end_time,
      end_date,
      notes,
      recurring,
      interval
    });
    // console.log(body)
    axios
      .patch(`${process.env.REACT_APP_API_URL}bookings/${booking_id}/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_BOOKING,
          payload: res.data
        });
        dispatch(
          createMessage(
            `Your booking has been updated and a notification has been sent to ${provider}.`
          )
        );
        // console.log(res.data)
      })
      .catch((err) => {
        dispatch({ type: UPDATE_BOOKING_FAILED });
        if (err.response) {
          console.log(err.response);
          dispatch(returnError(err.response, null));
        } else {
          console.log(err);
        }
      });
  };

export const add_to_google_calendar = (booking) => (dispatch, getState) => {
  console.log("here...")
  dispatch({ 
    type: ADD_TO_CALENDAR_LOAD, 
    payload: booking?.id 
  });

  dispatch({ 
    type: ADDED_TO_CALENDAR
  });

};

export const clear_add_calendar_id = () => (dispatch, getState) => {
  
  dispatch({ 
    type: CLEAR_ADD_CALENDAR_ID
  });
};

export const confirm_booking = (id, dynamic_status, appointment_object) =>  (dispatch, getState) => {
  if(dynamic_status === 'confirmed'){
    dispatch({ type: CONFIRM_LOADING, payload: id });
  }else if(dynamic_status === 'rejected'){
    dispatch({ type: REJECTED_LOADING, payload: id });
  }else if(dynamic_status === 'cancelled'){
    dispatch({ type: CANCELLED_LOADING, payload: id  });
  }

  const status = dynamic_status;
  const body = JSON.stringify({
    status
  });
  // console.log(body)
   axios
    .patch(`${process.env.REACT_APP_API_URL}bookings/${id}/`, body, tokenConfig(getState))
    .then((res) => {
        if(res.data){
          if(dynamic_status === "confirmed"){
            if(appointment_object !== null){
              axios.post(`${process.env.REACT_APP_API_URL}appointments/`, appointment_object, tokenConfig(getState))
              .then((res) => {
                if (res.data) {
                   // appointment created
                  console.log(res.data)
                   dispatch({ type: BOOKING_LOADED });
                   dispatch(createMessage_status("Thank you for confirming the booking.", id));
                   dispatch({ type: BOOKING_RELOAD_PAGE });
                   // localStorage.setItem("reload_confirm", true)
                }})
              .catch((err) => {
                dispatch({ type: BOOKING_LOADED });
                if (err) {
                  console.log(err.response.data);
                } 
              });
            }
          }
        }
        if(dynamic_status === "cancelled"){
          dispatch({ type: BOOKING_LOADED });
          dispatch(createMessage_status("You have successfully cancelled the booking.", id));
        }
        if(dynamic_status === "rejected"){
          dispatch({ type: BOOKING_LOADED });
          dispatch(createMessage_status("You have successfully declined the booking.", id));
        }
      
    })
    .catch((err) => {
      dispatch({ type: BOOKING_LOADED });
      if(err?.response?.data){
        if(err?.response?.data[0] === "Please add a bank account where earnings from bookings will be deposited before confirming a booking"){
          dispatch(createMessage_status("Please add a bank account where earnings from bookings will be deposited before confirming a booking", id)); 
        } 
      }

      if(err?.response?.data?.payment_method){
        dispatch(createMessage_status(err?.response?.data?.payment_method, id)); 
      }
      
    });
    

};

export const cant_cancel = (id) => (dispatch, getState) => {
  dispatch(createMessage_status("You can only cancel a booking before 12 hours from the time it's scheduled to start.", id)); 
};


export const get_bookings = (url) => (dispatch, getState) => {
  if(url){
       // dispatch({ type: BOOKING_DATA_LOADED });
       axios
      .get(`${url}`, tokenConfig(getState))
      .then((res) => {
          // console.log("Here 2")
          // console.log(res.data)
          dispatch({
            type: BOOKING_DATA_LOADED,
            payload: res.data
          });
        })
      .catch((e) => {
          console.log(e);
      });
  }else{ 
       axios
      .get(`${process.env.REACT_APP_API_URL}bookings/upcoming?ordering=created_at`, tokenConfig(getState))
      .then((res) => {
        console.log("Here 2")
        console.log(res.data)
        dispatch({
          type: BOOKING_DATA_LOADED,
          payload: res.data
        });
      })
      .catch((e) => {
        console.log(e);
      });
    }
};

// Get Upcoming recurring 
export const get_upcoming_recur_bookings = (url) => (dispatch, getState) => {
  if(url){
       axios
      .get(`${url}`, tokenConfig(getState))
      .then((res) => {
          dispatch({
            type: UPCOMING_RECURRING,
            payload: res.data
          });
        })
      .catch((e) => {
          console.log(e);
      });
  }else{ 
       axios
      .get(`${process.env.REACT_APP_API_URL}bookings/upcoming/recurring/`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPCOMING_RECURRING,
          payload: res.data
        });
      })
      .catch((e) => {
        console.log(e);
      });
    }
};

// Get Past non recurring 
export const get_past_nonrecur_bookings = (url) => (dispatch, getState) => {
  if(url){
       axios
      .get(`${url}`, tokenConfig(getState))
      .then((res) => {
          dispatch({
            type: PAST_NON_RECURRING,
            payload: res.data
          });
        })
      .catch((e) => {
          console.log(e);
      });
  }else{ 
       axios
      .get(`${process.env.REACT_APP_API_URL}bookings/past?ordering=created_at`, tokenConfig(getState))
      .then((res) => {

        dispatch({
          type: PAST_NON_RECURRING,
          payload: res.data
        });
      })
      .catch((e) => {
        console.log(e);
      });
    }
};

export const get_past_recur_bookings = (url) => (dispatch, getState) => {
  if(url){
       axios
      .get(`${url}`, tokenConfig(getState))
      .then((res) => {
          dispatch({
            type: PAST_RECURRING,
            payload: res.data
          });
        })
      .catch((e) => {
          console.log(e);
      });
  }else{ 
       axios
      .get(`${process.env.REACT_APP_API_URL}bookings/past/recurring/`, tokenConfig(getState))
      .then((res) => {
        
        dispatch({
          type: PAST_RECURRING,
          payload: res.data
        });
      })
      .catch((e) => {
        console.log(e);
      });
    }
};

export const getBooking = (id) => (dispatch, getState) => {
  dispatch({ type: BOOKING_LOADING });

  axios
  .get(`${process.env.REACT_APP_API_URL}bookings/${id}/`, tokenConfig(getState))
  .then((res) => {
    // console.log(res.data);
    localStorage.setItem('booking_api_object', JSON.stringify(res.data));
    dispatch({ type: BOOKING_LOADED });
    dispatch({ type: GET_BOOKING_LOADED, payload: res.data })
  }).catch((err) => {
    console.log(err.response);
  });
}; 

export const delete_booking = (id) => (dispatch, getState) => {
  dispatch({ type: BOOKING_LOADING });

  axios
    .delete(`${process.env.REACT_APP_API_URL}bookings/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: BOOKING_LOADED
      });
      // dispatch(createMessage("You have confirmed the appointment."));
    })
    .catch((err) => {
      dispatch({ type: BOOKING_LOADED });
      console.log(err.response);
    });
};

// Payments-Tips
export const create_payment_tips = (payment_object) => (dispatch, getState) => {
  dispatch({ type: PAYMENTS_TIPS_LOADING });

  // console.log(payment_object); 
  axios
    .post(`${process.env.REACT_APP_API_URL}payments/`, payment_object, tokenConfig(getState))
    .then((res) => {
      // console.log(res.data)
      dispatch({
        type: PAYMENTS_TIPS_CREATE,
        payload: res.data
      });
      // Success message
       dispatch(createMessage('Your payment went through successfully.'));
    })
    .catch((err) => {
      dispatch({ type: PAYMENTS_TIPS_FAILED });
      if (err.response) {
        // Handling error 
        console.log("Error:");
        console.log(err.response);
        dispatch(returnError(err.response, null));
      } else {
        console.log("err:");
        console.log(err);
      }
    });
};



export const get_payout_schedule = () => (dispatch, getState) => {
   axios
    .get(`${process.env.REACT_APP_API_URL}payout-schedules/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SCHEDULE_DATA,
        payload: res.data
      });
    })
    .catch((e) => {
      // dispatch({ type: PAYOUT_LOADED });
      console.log(e);
    });
};

export const create_schedule =
  (occurrence, day_of_the_week, day_of_the_month, user, action, schedule_id) =>
  (dispatch, getState) => {
    dispatch({ type: SCHEDULE_LOADING });

    const body = JSON.stringify({
      occurrence,
      day_of_the_week,
      day_of_the_month,
      user
    });
    // console.log(body);
    if (action === 'create') {
      axios
        .post(`${process.env.REACT_APP_API_URL}payout-schedules/`, body, tokenConfig(getState))
        .then((res) => {
          // console.log(res.data);
          dispatch(createMessage_schedules('Your Payout Schedule has been saved.'));
          dispatch({
            type: SCHEDULE_CREATED,
            payload: res.data
          });
        })
        .catch((err) => {
          // dispatch({ type: PAYOUT_LOADED });
          console.log(err.response);
          // dispatch(returnError(err.response, null))
        });
    }
    if (action === 'update') {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}payout-schedules/${schedule_id}/`,
          body,
          tokenConfig(getState)
        )
        .then((res) => {
          // console.log(res.data);
          dispatch(createMessage_schedules('Your Payout Schedule has been updated.'));
          dispatch({
            type: SCHEDULE_CREATED,
            payload: res.data
          });
        })
        .catch((err) => {
          // dispatch({ type: PAYOUT_LOADED });
          console.log(err.response);
          // dispatch(returnError(err.response, null))
        });
    }
  };

