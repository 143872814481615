import {
  PETS_LOADED,
  PETS_LOADING,
  GOT_PET_TYPES,
  CLEAR_STATE_,
  FETCH_,
  FETCH_DONE_,
  CLEAR_STATE_PET,
  GET_BREEDS,
  GET_BREEDS_CAT
} from '../actions/types';

const initialState = {
  isLoading: false,
  pets: null,
  fetch: false,
  pet_types: null,
  breeds: null,
  breeds_cats: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE_PET:
      return {
        ...state,
        pets: null
      };
    case PETS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case GET_BREEDS:
      return {
        ...state,
        breeds: action.payload
      };
    case GET_BREEDS_CAT:
      return {
        ...state,
        breeds_cats: action.payload
      };
    case FETCH_:
      return {
        ...state,
        fetch: true
      };
    case CLEAR_STATE_:
      return {
        ...state,
        pets: null
      };
    case FETCH_DONE_:
      return {
        ...state,
        fetch: false
      };
    case PETS_LOADED:
      return {
        ...state,
        isLoading: false,
        pets: action.payload
      };
    case GOT_PET_TYPES:
      return {
        ...state,
        isLoading: false,
        pet_types: action.payload
      };
    default:
      return {
        ...state
      };
  }
}
