import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserHomepageNavbar';
import kate_boswell from '../assets/kate_boswell.png';
import kusiima_boswell from '../assets/kusiima_boswell.png';
// import jeremy from '../assets/jeremy.png';
// import monique from '../assets/monique.jpeg';
import pearl from '../assets/pearl.png';
import roy_manzi from '../assets/roy_manzi.png';
import bryn_peterson from '../assets/bryn_peterson.png';

import Art from '../assets/Art.png';
import Josh from '../assets/joshie.png';
import Abdul from '../assets/Abdu.png';
import marjun from '../assets/marjun.jpg';


import Footer from '../components/Footer';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '43px',
    paddingBottom: '59px',
    fontFamily: 'Dosis',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
    background: '#F5F5EF',
    position: 'relative',
    zIndex: '2',
    [theme.breakpoints.down('650')]: {
      paddingTop: '27px',
      paddingBottom: '40px'
    }
  },
  image: {
    margin: theme.spacing(1)
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '50%'
  },
  paper: {
    margin: theme.spacing(1)
  },
  wizardContainer: {
    margin: theme.spacing(12, 'auto', 2)
  },
  title: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '520',
    fontSize: '46px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  teamSection: {
    margin: theme.spacing(3, 0, 1)
  },
  descriptions: {
    textAlign: 'center'
  },
  body: {
    // letterSpacing: '0.02em',
    // lineHeight: '2em',
    // marginBottom: theme.spacing(3),
    // fontSize: '1em',

    fontSize: '1.1em',
    lineHeight: '30px',
    //alignItems: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    fontWeight: '600',
    color: '#383F49',
    [theme.breakpoints.down('850')]: {
      fontSize: '20px',
      lineHeight: '28px'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '16px',
      lineHeight: '19.8px',
      maxWidth: '309px',
      margin: '0 auto'
    }
  },
  descriptions_: {
    fontFamily: 'Averia Sans Libre',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '500',
    color: '#737373',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('850')]: {
      fontSize: '18px',
      lineHeight: '24px'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '14px',
      lineHeight: '17px'
    }
  }
}));

function AboutUs(props) {
  const classes = useStyles();

  const handleSignupLinkClick = () => {
    window.location.href = '/signup';
  };

  return (
    <Box>
      <div>
        <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
        <div>{!props.auth.token && <GuestNavBar />}</div>
      </div>

      <div className={classes.root}>
        <Container maxWidth="md" className={classes.wizardContainer}>
          <h2 className={classes.title}>Who We Are:</h2>

          <p variant="body2" className={classes.body} gutterBottom>
            On a plane ride a few years ago, I sat next to a young lady that owned a cockapoo who
            sat comfortably in her lap for the duration of the 5-hour flight. At first, I grumbled
            to myself about the fact I’d be next to a pet, but before long, the cute cockapoo had
            decided to rest its head on my shoulder and I instantly fell in love. I asked the young
            lady where she got the puppy, and when I returned back home, I called the breeder the
            young lady had referred to asking for a puppy. We got her, named her Koko, and she
            quickly became part of the family. Having Koko always carries mixed emotions. She chews
            unexpected items like my sister’s new shoes that she left by the door when she came for
            Christmas dinner. Yet she jumps up and down when we come home with excitement and makes
            us feel we are all that matters in this world. Most importantly though, she divides
            every single night into equal amounts of time to make sure she cuddles with each member
            of the family in our separate rooms. You see, for us having Koko is personal.
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            Then the journey began to train her, groom her, and take her for walks. She enjoyed the
            training classes because it was like a structured play date. Time came for her first
            grooming which took place at the same location she had trained. Koko jumped out of the
            car expecting to have a structured playdate, but as we turned to the pet grooming salon,
            she lost it. She started crying and whimpering and before I knew it, there was a small
            yellow puddle in the middle of the grooming salon. She didn’t like the visit. A few
            months later, I bought a giant tub and gave her a warm bath at home and proceeded to
            groom her myself. I got lots of kisses and she was calm and peaceful.
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            It has been very clear that home is the best place to get Koko cared for. At Homepetvet,
            we offer a platform to take care of your pet from the comfort of your home.
          </p>

          <p variant="h4" className={classes.body} 
            // className={classes.title} gutterBottom
            >

            What it means to be a part of Homepetvet community:
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            Do you like taking care of pets? Do you like taking control of your destiny? Do you like
            feeling empowered and developing new skills to propel you to greater heights in your
            career?
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            Being a part of Homepetvet will help you do just that. Caring for pets is something that
            you can do in the comfort of your home. Doing what you like to do and getting paid for
            it makes life worth living. You have total and complete control of your schedule. You
            are a master of your own destiny. We empower you to use the Homepetvet platform by
            providing you the tools for you to do your business without having to worry about the
            nitty gritty details of the mundane day-to-day tasks.
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            Koko’s veterinary doctor was laid off at the beginning of the pandemic. He had recently
            graduated from college and as the saying goes, last in first out. It was heartbreaking
            for me as a pet owner. With Homepetvet, he can continue to take care of Koko from my
            home and keep the bond strong.
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            Join the group of pet lovers now. All you have to do is{' '}
            <Link href="#" onClick={handleSignupLinkClick} variant="body2">
              sign up
            </Link>{' '}
            and let your dreams come true.
          </p>

          <h2 variant="h4" className={classes.title} gutterBottom>
            Homepetvet Leadership:
          </h2>

          <div className={classes.teamSection}>
            <Grid
              container
              justify="space-around"
              alignItems="center"
              alignContent="space-around"
              spacing={5}
            >
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={kate_boswell} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Kate Boswell
                  </p>
                  <p variant="subtitle2">Chief Executive Officer</p>
                  <p variant="subtitle2">MBA Seattle Pacific University</p>
                </div>
              </Grid>
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={roy_manzi} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Roy Manzi
                  </p>
                  <p variant="subtitle2">Chief Technology Advisor</p>
                  <p variant="subtitle2">University of San Francisco</p>
                </div>
              </Grid>
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={bryn_peterson} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Byrn Peterson
                  </p>
                  <p variant="subtitle2">General Counsel</p>
                  <p variant="subtitle2">University of Washington</p>
                </div>
              </Grid>
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={kusiima_boswell} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Kusiima Boswell
                  </p>
                  <p variant="subtitle2">Marketing Director</p>
                  <p variant="subtitle2">Senior UPenn Wharton Business School</p>
                </div>
              </Grid>
              {/*<Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={jeremy} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Jeremy Jupiter
                  </p>
                  <p variant="subtitle2">Customer Engagement Manager</p>
                  <p variant="subtitle2">University of Washington</p>
                </div>
              </Grid>*/}
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={pearl} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Pearl Kitimbo
                  </p>
                  <p variant="subtitle2">Customer Engagement Manager</p>
                  <p variant="subtitle2">Makerere University</p>
                </div>
              </Grid>
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={marjun} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Marjun Maagad
                  </p>
                  <p variant="subtitle2">Social Media Marketing Manager</p>
                  <p variant="subtitle2">Capitol University</p>
                </div>
              </Grid>
            </Grid>
          </div>

          <h2 variant="h4" className={classes.title} gutterBottom>
            Homepetvet Dev Team:
          </h2>

          <div className={classes.teamSection}>
            <Grid
              container
              justify="space-around"
              alignItems="center"
              alignContent="space-around"
              spacing={5}
            >
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={Abdul} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Abdul Ssekalala
                  </p>
                  <p variant="subtitle2">Backend Developer</p>
                  <p variant="subtitle2">Makerere University</p>
                </div>
              </Grid>
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={Art} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Artem Abovian
                  </p>
                  <p variant="subtitle2">Chief Designer</p>
                  <p variant="subtitle2">Lyceum of Information Technologies University</p>
                </div>
              </Grid>
              <Grid item sm={3} className={classes.image}>
                <ButtonBase>
                  <img className={classes.img} alt="complex" src={Josh} />
                </ButtonBase>
                <div className={classes.descriptions}>
                  <p className={classes.descriptions_} variant="subtitle1">
                    Joshua Mwesigwa
                  </p>
                  <p variant="subtitle2">Frontend Developer</p>
                  <p variant="subtitle2">Makerere University</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  availability: state.forms.availability
});

export default connect(mapStateToProps, null)(AboutUs);
