import { SENDING_SCREENING, SENT_SCREENING_FAIL, SENT_SCREENING_SUCCESS } from '../actions/types';

const initialState = {
  isLoading: false,
  beingProcessed: false,
  results: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SENDING_SCREENING:
      return {
        ...state,
        isLoading: true
      };
    case SENT_SCREENING_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case SENT_SCREENING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        beingProcessed: true,
        results: action.payload
      };
    default:
      return state;
  }
}
