/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ServicesSection from '../components/DashboardServicesSection';
import { sendUserData, getServiceData, sendServiceData } from '../actions/form';

import { logout, loadUser } from '../actions/auth.js';

import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  settingsSection: {
    marginTop: theme.spacing(8),
  }
}));

function Services(props) {
  // const { user } = props;
  // const user_id = user.id;
  const classes = useStyles();

  React.useEffect(() => {
    if (props.form.fetch === true) {
      props.loadUser();
    }
  }, [props.form.fetch]);

  React.useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <div className={classes.settingsSection}>
      <ServicesSection />
    </div>
  );
}

Services.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  services: state.services
});

export default connect(mapStateToProps, {
  logout,
  loadUser,
  sendUserData,
  getServiceData,
  sendServiceData
})(Services);
