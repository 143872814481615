import { CERTIFICATE_LOADED, CERTIFICATE_LOADING } from '../actions/types';

const initialState = {
  isLoading: false,
  results: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CERTIFICATE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case CERTIFICATE_LOADED:
      return {
        ...state,
        isLoading: false,
        results: action.payload.results
      };
    default:
      return {
        ...state
      };
  }
}
