/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Container from "@material-ui/core/Container";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "./Dialog.js";
import { sendUserData, clear_error, sendUserData_photo, } from "../actions/form.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import { IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment"
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import { loadUser } from '../actions/auth.js';
import Spinner from "./Spinner";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const theme = createTheme({
  overrides: {
    MuiPickersClock: {
      pin: {
        backgroundColor: "#3a9643",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#3a9643",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#3a9643",
        thumb: {
          backgroundColor: "#3a9643",
        },
        "& > div": {
          backgroundColor: "#3a9643",
          border: "14px solid #3a9643",
        },
      },
      thumb: {
        backgroundColor: "#3a9643",
      },
      noPoint: {
        backgroundColor: "#3a9643",
      },
    },
    MuiFormHelperText: {
      root: {
        position: "apsolute",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3a9643",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  center: {
    [theme.breakpoints.down("550")]: {
      margin: '32px auto !important'
    },
  },
  tagline: {
    float: "left",
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    width: "90%",
    paddingLeft: theme.spacing(1),
    "& > label": {
      paddingLeft: theme.spacing(1),
      color: "#5A5A5A",
    },
  },
  bio: {
    float: "left",
    backgroundColor: "#fafafa",
    marginTop: theme.spacing(1),
    width: "90%",
    borderRadius: "10px",
    height: "auto!important",
    paddingLeft: theme.spacing(1),
    "& > label": {
      paddingLeft: theme.spacing(1),
      color: "#5A5A5A",
    },
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  headers: {
    color: "black",
  },
  root_: {
    display: "flex",
    justifyContent: 'center',
    "& > *": {
      margin: theme.spacing(4, 0),
    },
  },
  large: {
    maxWidth: theme.spacing(16),
    maxHeight: theme.spacing(16),
    paddingLeft: theme.spacing(0),
    width: "100%",
    height: '100%'
  },
  small: {
    margin: theme.spacing(3, "auto", 0),
    display: "block",
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  wizardContainer: {
    margin: theme.spacing(1, "auto"),
  },
  form: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "18px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginTop: theme.spacing(2),
    width: '120%',
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#663399!important",
    marginRight: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "3px",
    border: "1px solid #cfd7de",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  characters: {
    fontSize: '11px',
    color: 'grey'
  },
  imageSelector: {
    fontSize: '8rem',
    width: theme.spacing(23),
    height: theme.spacing(23),
    paddingLeft: theme.spacing(0)
  },
  button_: {
    backgroundColor: "#FF3D00!important",
    width: "245px",
    height: "40px",
    marginLeft: theme.spacing(1),
  }
}));

function BioSection(props) {
  const classes = useStyles();

  const { user } = props;
  const user_id = user.id;

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [tagline, setTaglin] = useState(user.tagline);
  const [bio, setBio] = useState(user.bio);
  const [dob, setDob] = useState(user.date_of_birth);

  const [photo, setPhoto] = useState(null);
  const [save, setSave] = useState(false);
  const [early_load, setEarlyLoad] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = React.useState(true);

  const [error_bio_length, setBioLengthError] = useState(false);
  const [bio_length, setLengthBio] = useState(props.user.bio !== null ? props.user.bio.length : 0);
  const [error_tag_length, setTagLengthError] = useState(false);
  const [tag_length, setLengthTagline] = useState(props.user.tagline !== null ? props.user.tagline.length : 0);

  const upload = (e) => {
    document.getElementById("selectImage").click();
  };

  const send_save_signal = (value) => {
    setSave(value)
    setEarlyLoad(true)
  }

  const setting = (image) => {
    if (image) {
      setUpdating(true);
      setPhoto(image);
    }
  };

  const [submitted_status, setSStatus] = useState(false);
  const handleSubmit = () => {
    const object = {
        tagline: tagline,
        bio: bio,
        date_of_birth: dob,
        first_name: firstName,
        last_name: lastName
      };
      setSStatus(true)
      props.sendUserData(object, user_id);
      props.loadUser();
      props.loadUser();
      const timer = setTimeout(() => {
        setSStatus(false)
      }, 3000);
  }

  React.useEffect(() => {
    if (photo !== null && save) {
      const uploadData = new FormData();
      uploadData.append("photo", photo, photo.name);

      const timer = sendReq(uploadData, user_id);
      return () => clearTimeout(timer);
      function sendReq(object, user_id) {
        const timer = setTimeout(() => {
          // props.sendUserData(object, user_id);
          props.sendUserData_photo(object, user_id);
          setSave(false)
          setEarlyLoad(false)
          // console.log("Saving;;;")
        }, 2000);
        return timer;
      }
    }
  }, [photo, props, save, user_id]);

  // Changing to new Date time design implementation
  const [formatedDate, setFormattedDate] = useState(moment(props.user.date_of_birth, 'YYYY-MM-DD').toDate());
  // const [formatedDate, setFormattedDate] = useState(props.user.date_of_birth);
  // const [selectedDate, setSelectedDate] = useState(
  //   props.user.date_of_birth === null ? new Date() : moment(props.user.date_of_birth, 'YYYY-MM-DD').toDate()
  // );

  const handleDOB = (dateInput) => {
    const date_object = new Date(dateInput);
    setFormattedDate(date_object);
    var date = date_object.getDate();
    var month = date_object.getMonth() + 1;
    var year = date_object.getFullYear();
    var req_date_format = year + "-" + month + "-" + date;
    // 1997-06-25
    setDob(req_date_format);
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="md" className={classes.wizardContainer}>
        <div>
          <FormGroup className={classes.formGroupProfileSection}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Personal Information
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} className={classes.selector}>
                <FormLabel component="label" className={classes.formLabel}>
                  Select a picture
                </FormLabel>

                <div className={classes.root_}>
                  {props.user.photo ? (
                    updating === false ? (
                      <div className={classes.center}>
                        <Avatar
                          className={classes.imageSelector}
                          variant="circle"
                          alt="profile picture"
                          src={props.user.photo}
                        />
                      </div>
                    ) : (
                      <div>
                        {photo ? (
                          <div>
                            <Avatar
                              className={classes.large}
                              variant="circle"
                              alt="profile picture"
                              src={URL.createObjectURL(photo)}
                            />
                          </div>
                        ) : (
                          <div>
                            <Skeleton
                              variant="circle"
                              className={classes.large}
                            />
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <div>
                      {photo ? (
                        <Avatar
                          className={classes.imageSelector}
                          variant="circle"
                          alt="profile picture"
                          src={URL.createObjectURL(photo)}
                        />
                      ) : (
                        <Avatar variant="circle" className={classes.imageSelector} onClick={upload}>
                          <FolderIcon />
                        </Avatar>
                      )}
                    </div>
                  )}
                </div>

                <Dialog 
                   setting={setting} 
                   send_save_signal={send_save_signal} 
                   early_load={early_load}
                   />
              </Grid>

              <Grid item xs={12} sm={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      fullWidth
                      disableUnderline
                      id="firstname"
                      onChange={(e) => setFirstName(e.target.value)}
                      label=""
                      placeholder="First Name"
                      defaultValue={props.firstName}
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      fullWidth
                      disableUnderline
                      id="lastname"
                      onChange={(e) => setLastName(e.target.value)}
                      label=""
                      placeholder="Last Name"
                      defaultValue={props.lastName}
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      disableUnderline
                      id="tagline"
                      // onChange={(e) => props.setTaglin(e.target.value)}
                      onChange={(e) => {
                        if(e?.target?.value?.length <= 100){
                          setTaglin(e.target.value)
                          setTagLengthError(false)
                          setLengthTagline(e?.target?.value?.length)
                        }else{
                           setTagLengthError(true)
                        }
                      }}
                      label="Tagline *"
                      placeholder="Write a short tagline about yourself"
                      defaultValue={props.tagline}
                      className={classes.input}
                    />
                    {error_tag_length ? (
                      <FormHelperText error>Tagline must not exceed 100 characters.</FormHelperText>
                    ): null}
                    <span className={classes.characters} >{tag_length}/100</span>
                    {props?.error?.msg?.tagline ? (
                      <Grid style={{ marginTop: '10px' }} item xs={12}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                props.clear_error();
                                setOpen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          <div className={classes.message}>{props?.error?.msg?.tagline}</div>
                        </Alert>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      disableUnderline
                      fullWidth
                      // onChange={(e) => props.setBio(e.target.value)}
                      // onChange={(e) => {
                      //   if(e?.target?.value?.length <= 255){
                      //     setBioLengthError(false)
                      //     props.setBio(e.target.value)
                      //   }else{
                      //      setBioLengthError(true)
                      //   }
                      // }}
                      onChange={(e) => {
                        if(e?.target?.value?.length <= 160){
                          setBioLengthError(false)
                          setBio(e.target.value)
                          setLengthBio(e?.target?.value?.length)
                        }else{
                           setBioLengthError(true)
                        }
                      }}
                      multiline
                      rows={4}
                      id="about"
                      label="Bio"
                      placeholder="Tell us about yourself"
                      defaultValue={props.bio}
                      className={classes.input}
                    />
                    <span className={classes.characters} >{bio_length}/160</span>
                    {props?.error?.msg?.bio ? (
                      <Grid style={{ marginTop: '10px' }} item xs={12}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                props.clear_error();
                                setOpen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          <div className={classes.message}>{props?.error?.msg?.bio}</div>
                        </Alert>
                      </Grid>
                    ) : null}
                    {error_bio_length ? (
                        <FormHelperText error>Bio must not exceed 160 characters.</FormHelperText>
                      ): null}
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Date of Birth
                    </FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={theme}>
                        <KeyboardDatePicker
                          className={classes.line}
                          InputProps={{
                            classes: { underline: classes.underline },
                          }}
                          id="date-picker-dialog"
                          format="MM/dd/yyyy"
                          // value={selectedDate}
                          value={formatedDate}
                          onChange={handleDOB}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button_}
                    onClick={handleSubmit}
                  >
                    {props.form.loading_collective && submitted_status
                      ? <Spinner /> : "Update personal information"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
        </div>
      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services,
});

export default connect(mapStateToProps, {
  sendUserData,
  clear_error,
  sendUserData_photo,
  loadUser
})(BioSection);
