import { combineReducers } from "redux";

import results from "./results";
import errors from "./errors";
import auth from "./auth";
import messages from "./messages";
import forms from "./forms";
import services from "./services";
import pets from "./pets";
import certificates from "./certificates";
import screening from "./screening";
import cards from "./cards";
import booking from "./booking";
import blog from "./blog";

export default combineReducers({
  results,
  errors,
  messages,
  auth,
  forms,
  services,
  pets,
  certificates,
  screening,
  cards,
  booking,
  blog,
});
