import React from "react";
import Footprint from "../assets/pawprint 2.svg";
import Chip from "@material-ui/core/Chip";
import PetCardDetails from "./PetCardDetails";
import Popover from "@material-ui/core/Popover";

export default function PetCardDetailsPet(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <span>
      <Chip
        style={{ marginRight: "10px" }}
        label={props.pet.name}
        onClick={handleClick}
        icon={<img src={Footprint} height="23px" width="23px" alt="" />}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PetCardDetails pet={props.pet} />
      </Popover>
    </span>
  );
}
