import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import {
  verify,
  verify_email,
  register,
  aws_cognito_amplify_verify,
  aws_cognito_amplify_resend
} from '../actions/auth.js';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import cover from '../assets/home_pet_vet_cover.jpeg';
import logo from '../assets/logo_15.png';
import Alert from '@material-ui/lab/Alert';

import Spinner from '../components/Spinner';

// var AWS = require('aws-sdk');
// var CryptoJS = require('crypto-js');

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
  },
  image: {
    backgroundImage: `url(${cover})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '16rem',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 'auto')
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '20rem'
    }
  },
  avatar: {
    margin: theme.spacing(4, 1, 0),
    backgroundColor: 'white'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
    height: '2.5rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0 0.5rem'
    }
  },
  email: {
    color: 'blue',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  social: {
    margin: theme.spacing(1, 0, 1),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0.2rem auto'
    },
    '& span': {
      width: '100%'
    }
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function VerifySignupCode(props) {
  const classes = useStyles();

  const [sucess, setSucess] = useState(false);
  const code = props.match.params.code;
  console.log('Code is ' + code);

  let value;
  let alert;
  makeAlert();

  function makeAlert(isSuccess, detail) {
    console.log('Success is ' + isSuccess);
    let msg;
    switch (isSuccess) {
      case true:
        msg = detail;
        console.log('Detail is ' + detail);

        alert = (
          <div className="alerts">
            <Alert icon={false} severity="info">
              The Email Entered is authenticated {msg}
            </Alert>
          </div>
        );
        setSucess(true);
        console.log('makeing alert true');
        break;
      case false:
        msg = detail;
        alert = (
          <div className="alerts">
            <Alert severity="error">Please Try again {msg}</Alert>
          </div>
        );
        setSucess(false);
        console.log('makeing alert false');
        break;
      default:
        console.log('undefined Sucess');
        // alert = (
        //   <div className="alerts">
        //     <Alert severity="info">
        //       Please Wait While Account is being activated
        //     </Alert>
        //   </div>
        // );
        break;
    }
    value = msg;
    console.log(value);
  }

  if (sucess) {
    alert = (
      <div className="alerts">
        <Alert icon={false} severity="info">
          The Email Entered is authenticated {value}
        </Alert>
      </div>
    );
  } else {
    alert = (
      <div className="alerts">
        <Alert severity="info">Please Wait While Account is being activated</Alert>
      </div>
    );
  }

  const sendReq = async (code) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const key = {
      activation_key: code
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}auth/accounts/confirm/`, key, config)
      .then((res) => {
        console.log(res.data);
        makeAlert(true, res.data);
      })
      .catch((e) => {
        console.log(e.data);
        makeAlert(false, e.data);
      });
  };

  sendReq(code);

  if (props.auth.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={6} md={7} xl={9} className={classes.image} />
      <Grid item xs={12} sm={6} md={5} xl={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img src={logo} onClick={(e) => (window.location.href = '/')} alt="logo" width="30" />
          </Avatar>

          <Typography component="h1" variant="h5" style={{ align: 'center' }}>
            Click the link that was sent to your email address
          </Typography>
          <Grid item xs={12}>
            <div className={classes.message}>{alert}</div>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#5E14A3' }}
            className={classes.submit}
            // onClick={handleVerifyCode}
            href="/login"
          >
            {sucess ? 'Continue to login' : <Spinner />}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                <span> Resend email</span>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/login" variant="body2">
                {'Sign in'}
              </Link>
            </Grid>
          </Grid>
          <br />
          <br />
          {/* </form> */}
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  auth: state.auth,
  error: state.errors
});

export default connect(mapStateToProps, {
  verify,
  verify_email,
  register,
  aws_cognito_amplify_verify,
  aws_cognito_amplify_resend
})(VerifySignupCode);
