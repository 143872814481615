/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
// import Chip from "@material-ui/core/Chip";
import ServiceCardDetails from "../components/ServiceCardDetails";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Grid from '@material-ui/core/Grid';
import Mark from '../assets/checked.svg';

const useStyles = makeStyles((theme) => ({
  chip: {
    width: '50%',
    [theme.breakpoints.down("950")]: {
      width: '90%',
    },
    [theme.breakpoints.down("1250")]: {
      width: '80%',
    },
    [theme.breakpoints.down("1110")]: {
      width: '80%',
    },
    
    [theme.breakpoints.down("550")]: {
      margin: 'auto',
      width: 'auto'
    },
  },
  title: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    color: "#222222", 
  },

  servicesTitle: {
    marginBottom: '4px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '19px',
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    color: '#1B5E20',
    [theme.breakpoints.down('768')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  servicesPrice: {
    // marginBottom: '13px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    // lineHeight: '23px',
    color: '#000000',
    [theme.breakpoints.down('768')]: {
      marginBottom: '5px'
    }
  },
  servicesAdditional: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '13px',
    color: '#FF3D00',
    [theme.breakpoints.down('768')]: {
      marginBottom: '5px'
    }
  },
  servicesTitle_: {
    marginBottom: '4px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '40px',
    color: '#1B5E20',
    [theme.breakpoints.down('768')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  servicesItem: {
    marginBottom: '5px',
    // marginLeft: '10px',
    marginRight: '10px',
    display: 'flex',
    minHeight: '100px',
    padding: '10px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#F2F2F2',
    border: '2px solid #E0E0E0',
    borderRadius: '10px',
    '&:hover': {
      borderColor: 'blue'
    },
  },
}));

const DetailServiceBooking = ({
	getService,
	service,
	service_id,
	label,
	image,
  has_additional_rates,
  pets,
  re_render,
  setAgain,
  setPetRemoved,
  pet_remove
}) => {
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [additional_rate_price, setARP] = React.useState(null);
  const [additional_rate_name, setARN] = React.useState(null);
  const [puppy_rate_price, setPRP] = React.useState(null);
  const [puppy_rate_name, setPRN] = React.useState(null);
  const [over_10_miles, setOver10miles] = React.useState(null);
  const [over_name, setOverName] = React.useState(null);

  const [additional_dog, setAdditionalDog] = React.useState(null);
  const [additional_dog_name, setAdditionalDogName] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;



  React.useEffect(() => {
    if(re_render || pet_remove){
        

        // Small Dog (up to 15 pounds). Medium dog (15 - 50).  Large (Over 50) pounds
        // getService(service)
        if(has_additional_rates){
          // console.log(service.additional_rates)
          const pets_array = JSON.parse(localStorage.getItem('pets_array'));
          const selected_pet = pets?.results.filter((pet) => pet.id === pets_array[0])

          if(selected_pet.length >= 1 && (selected_pet[0]?.pet_type?.name === "Dog" || selected_pet[0]?.pet_type?.name === "Cat")){ // is a Dog
            const pet_size = (parseInt(selected_pet[0]?.size))
            
            if(pet_size <= 15){
              const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
              if(arate_type.length >= 1){
                setARP(arate_type[0]?.price)
                setARN('Small pet (up to 15) lbs')
              }
            }
            if(pet_size >= 16 && pet_size <= 50){
              const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
              if(arate_type.length >= 1){
                setARP(arate_type[0]?.price)
                setARN('Medium pet (15 - 50) lbs')
              }
            }
            if(pet_size > 50){
              const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
              if(arate_type.length >= 1){
                setARP(arate_type[0]?.price)
                setARN('Large pet (Over 50) lbs')
              }
            }

            const pet_age = (parseInt(selected_pet[0]?.age))
            if(pet_age <= 2){
              const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Puppy Rate")
              if(arate_type.length >= 1){
                setPRP(arate_type[0]?.price)
                setPRN('Puppy Rate')
              }
            }

            // Over Milage Rate 
            if(service.service.name === "Grooming"){
              let distance = 20
              if(distance > 10){
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Over 10 miles")
                if(arate_type.length >= 1){
                  setOver10miles(arate_type[0]?.price)
                  setOverName('Over 10 miles')
                }
              }
            }

            // Additional pet 
            if(service.service.name === "Grooming"){
              if(pets_array?.length > 1){
                const additional_pet_length = pets_array?.length - 1

                const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")  
                
                if(check_size_billing.length === 0 && check_size_billing2.length === 0 && check_size_billing3.length === 0){
                  const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                  if(arate_type.length >= 1){
                    const total_addition_dog = arate_type[0]?.price * additional_pet_length
                    setAdditionalDog(total_addition_dog.toFixed(0))
                    setAdditionalDogName('Additional pet')
                  }
                }

                
              }
            }else{

              if(pets_array?.length > 1){
                const additional_pet_length = pets_array?.length - 1
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                if(arate_type.length >= 1){
                  const total_addition_dog = arate_type[0]?.price * additional_pet_length
                  setAdditionalDog(total_addition_dog.toFixed(0))
                  setAdditionalDogName('Additional pet')
                }
              }

          }

          }
        }else{
          setARP(null)
          setARN(null)
        }   
     }

    setAgain(false)
  }, [re_render, pet_remove])


  const pets_removed = () => {
    const pets_array = JSON.parse(localStorage.getItem('pets_array'));
    if(pets_array?.length === 0){
      setAdditionalDog(null)
      setAdditionalDogName(null)
      setARP(null)
      setARN(null)
      setPRP(null)
      setPRN(null)
      setOver10miles(null)
      setOverName(null)
    }else if(pets_array?.length === 1){
      setAdditionalDog(null)
      setAdditionalDogName(null)
    }
    setPetRemoved(false)
  }

  React.useEffect(() => {
    if(pet_remove){
      pets_removed()
    }else{
      setPetRemoved(false)
    }
  }, [pet_remove])

  
  const handleServiceChecked = (e) => {
    getService(service)
    if(has_additional_rates){
      handleClick(e)

      // console.log(service.additional_rates)
      const pets_array = JSON.parse(localStorage.getItem('pets_array'));
      const selected_pet = pets?.results.filter((pet) => pet.id === pets_array[0])

      if(selected_pet.length >= 1 && (selected_pet[0]?.pet_type?.name === "Dog" || selected_pet[0]?.pet_type?.name === "Cat")){ // is a Dog
        const pet_size = (parseInt(selected_pet[0]?.size))
        
        if(pet_size <= 15){
          const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
          if(arate_type.length >= 1){
            setARP(arate_type[0]?.price)
            setARN('Small pet (up to 15) lbs')
          }
        }
        if(pet_size >= 16 && pet_size <= 50){
          const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
          if(arate_type.length >= 1){
            setARP(arate_type[0]?.price)
            setARN('Medium pet (15 - 50) lbs')
          }
        }
        if(pet_size > 50){
          const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
          if(arate_type.length >= 1){
            setARP(arate_type[0]?.price)
            setARN('Large pet (Over 50) lbs')
          }
        }

        const pet_age = (parseInt(selected_pet[0]?.age))
        if(pet_age <= 2){
          const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Puppy Rate")
          if(arate_type.length >= 1){
            setPRP(arate_type[0]?.price)
            setPRN('Puppy Rate')
          }
        }

        // Over Milage Rate 
        if(service.service.name === "Grooming"){
          let distance = 20
          if(distance > 10){
            const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Over 10 miles")
            if(arate_type.length >= 1){
              setOver10miles(arate_type[0]?.price)
              setOverName('Over 10 miles')
            }
          }
        }

        // Additional pet for Walking
          if(pets_array?.length > 1){
              const additional_pet_length = pets_array?.length - 1

              const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
              const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
              const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")  
              
              if(check_size_billing.length === 0 && check_size_billing2.length === 0 && check_size_billing3.length === 0){
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                if(arate_type.length >= 1){
                  const total_addition_dog = arate_type[0]?.price * additional_pet_length
                  setAdditionalDog(total_addition_dog.toFixed(0))
                  setAdditionalDogName('Additional pet')
                }
              }
            }

      }
    }else{
      setARP(null)
      setARN(null)
    } 
    setAgain(false)
  }

  return (
  	<>
	    <Grid item xs={12} sm={4}>
	      <span
	          onClick={handleServiceChecked}
	          className={classes.servicesItem}
	        >
	          {service.id === service_id ? <img src={Mark} alt="" /> : null}
	          {service.service.icon !== null ? <img
	            src={service.service.icon}
	            height="20px"
	            width="20px"
	            alt=""
	          />: null}
	          <div className={classes.servicesTitle}>
              {service.service.name === "Grooming" && additional_rate_price !== null ? 
                <span style={{ marginLeft: '5px' }} >
                   {service.service.name}: {additional_rate_name}
                </span>

                :
                
                <>
                  {service.service.name}{has_additional_rates ? <span style={{ marginLeft: '5px'}} >
                    <AddBoxRoundedIcon fontSize="inherit" />
                   </span> : null}
                </>
              }
	          </div>

            {service.service.name === "Grooming" && additional_rate_price !== null ? 
                <span style={{ marginLeft: '5px', color: '#FF3D00', marginBottom: '10px' }} >
                   ${additional_rate_price}/{service.rate.name}
                </span>

                :
                
                <>
                  <div className={classes.servicesPrice}>{' '}
                    <span style={{ marginBottom: '10px' }}>${service.price}/</span>{service.rate.name}
                  </div>
                </>
              }

	          
	          {/*{
	          	additional_rate_price !== null ? 
	          	<div className={classes.servicesAdditional}>{' '}
	             <span>+${additional_rate_price}, </span>{additional_rate_name}
	            </div>: null
	          }*/}
            {
              puppy_rate_price !== null ? 
              <div className={classes.servicesAdditional}>{' '}
               <span>+${puppy_rate_price}, </span>{puppy_rate_name}
              </div>: null
            }
            {
              over_10_miles !== null ? 
              <div className={classes.servicesAdditional}>{' '}
               <span>+${over_10_miles}, </span>{over_name}
              </div>: null
            }
            {
              additional_dog !== null ? 
              <div className={classes.servicesAdditional}>{' '}
               <span>+${additional_dog}, </span>{additional_dog_name}
              </div>: null
            }
            
	        </span>
	    </Grid>

	    <span>
	      <Popover
	        id={id}
	        open={open}
	        anchorEl={anchorEl}
	        onClose={handleClose}
	        anchorOrigin={{
	          vertical: "bottom",
	          horizontal: "center",
	        }}
	        transformOrigin={{
	          vertical: "top",
	          horizontal: "center",
	        }}
	      >
	        <ServiceCardDetails 
	        	service={service} 
	        	has_additional_rates={has_additional_rates}
	        />
	      </Popover>
	    </span>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  pets: state.pets.pets
});

export default connect(mapStateToProps, null)(DetailServiceBooking);
