/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Spinner from "../assets/home_load.gif";
import { connect } from "react-redux";
import { deleteProvider_photo } from "../actions/form.js";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "120%",
    width: "120%",
    borderRadius: 10,
    marginBottom: '5px',
    cursor: 'pointer'
  },
  gridList: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  image_container: {
    height: "40%",
    width: "40%",
  }
}));


const ProviderGalleryViewWizard = (props) => {
  const classes = useStyles();
  const [hoveredImageId, setHoveredImageId] = React.useState(null);

  const handleImageClick = (imageId) => {
	  console.log('Clicked image ');
   };

  const handleDelete = (imageId) => {
  	  props.deleteProvider_photo(imageId)
	  console.log('Deleting image with ID:', imageId);
	};


  return (
      <div>
        <div className={classes.gridList}>
          {props.images.map((tile) => (
			  <div
			    className={classes.image_container}
			    key={tile.id}
			    onMouseEnter={() => setHoveredImageId(tile.id)}
			    onMouseLeave={() => setHoveredImageId(null)}
			  >
			    <div >
				    <img
				      className={classes.image}
				      src={tile.src}
				      alt={tile.src}
				      onClick={() => handleImageClick(tile.id)}
				    />
				    {hoveredImageId === tile.id ? (
				      <div style={{ cursor: 'pointer' }} onClick={() => handleDelete(tile.id)}>
				        {props.form.photo_delete_loading === true ? (
				            <div
				              style={{
				                marginLeft: "5x",
				                marginTop: "5px",
				              }}
				            >
				              <img src={Spinner} alt="" height="25px" width="25px" />
				            </div>
				         ) : <DeleteIcon />}
				      </div>
				    ): 
				    <div style={{ marginBottom: '20px'}}> <span style={{ opacity: 0 }} >Hello</span></div>
				   }
			    </div>
			  </div>
			))}
        </div>
      </div>
  );
}

const mapStateToProps = (state) => ({
  form: state.forms
});

export default connect(mapStateToProps, { deleteProvider_photo })(ProviderGalleryViewWizard);


