import {
  GET_SEARCH_RESULTS,
  GET_SUGGESTIONS,
  GET_PROVIDER_DETAILS,
  GET_ALL_SEARCH_RESULTS,
  FETCH,
  FETCH_DONE,
  CLEAR_STATE,
  SET_PROFILE_ID
} from '../actions/types';

const initialState = {
  results: [],
  autocompletes: {},
  petproviderdetails: null,
  all: [],
  fetch: false,
  query: null,
  current_profile_id: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        results: []
      };
    case SET_PROFILE_ID:
      return {
        ...state,
        current_profile_id: action.payload
      };
    case FETCH:
      return {
        ...state,
        fetch: true,
        query: action.payload
      };
    case FETCH_DONE:
      return {
        ...state,
        fetch: false
      };
    case GET_SEARCH_RESULTS:
      return {
        ...state,
        results: action.payload
      };
    case GET_SUGGESTIONS:
      return {
        ...state,
        autocompletes: action.payload
      };
    case GET_PROVIDER_DETAILS:
      return {
        ...state,
        petproviderdetails: action.payload
      };
    case GET_ALL_SEARCH_RESULTS:
      return {
        ...state,
        all: action.payload
      };
    default:
      return state;
  }
}
