/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/key.svg';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Facebook from '../assets/facebook.svg';
import Google from '../assets/google.svg';
import GuestNavBar from '../components/GuestNavBar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Spinner from '../components/Spinner';
import Footer from '../components/Footer';
import AppleSignin from 'react-apple-signin-auth';


import {
  register,
  login_google,
  login_facebook,
  login_apple,
  verify_email,
  aws_cognito_amplify_reg,
  aws_cognito_amplify_resend
} from '../actions/auth.js';

import { Box, Divider } from '@material-ui/core';

import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    [theme.breakpoints.down('800')]: {
      alignItems: 'flex-start'
    }
  },
  rootGrid: {
    margin: '144px 0',
    padding: '25px 0',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('800')]: {
      padding: '36px 0 60px 0',
      maxWidth: '347px',
      margin: '80px 0 0'
    }
  },
  avatar: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '70px',
    height: '55px'
  },
  logo: {
    cursor: 'pointer',
    width: '52px',
    height: '52px'
  },
  title: {
    marginBottom: '23px',
    fontFamily: 'Dosis',
    fontWeight: 800,
    fontSize: '51px',
    lineHeight: '58px',
    color: '#383F45',
    [theme.breakpoints.down('800')]: {
      fontSize: '33px',
      lineHeight: '42px'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    }
  },
  buttonWrap: {
    position: 'relative'
    // pointerEvents: 'none'
  },
  iconFacebook: {
    position: 'absolute',
    top: '50%',
    left: '16.83px',
    transform: 'translateY(-50%)'
  },
  iconGoogle: {
    position: 'absolute',
    top: '50%',
    left: '16.83px',
    transform: 'translateY(-50%)'
  },
  google: {
    margin: theme.spacing(2, 0, 1),
    width: '100%',
    background: '#346CF0!important',
    opacity: '1!important',
    height: '56px!important',
    color: '#ffffff !important',
    cursor: 'pointer!important',
    transitions: 'background 0.5s ease-in',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: theme.spacing(1, 'auto')
    },
    '&:active': {
      background: 'rgb(116 141 199)!important'
    },
    '& span': {
      width: '100%',
      fontWeight: 'bold!important',
      fontSize: '19px!important',
      fontFamily: 'Dosis',
      textAlign: 'right!important',
      paddingRight: '25%!important'
    }
  },
  or: {
    margin: '5px 0',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    textAlign: 'center'
  },
  input: {
    marginTop: theme.spacing(2),
    borderRadius: '5px',
    '& input': {
      padding: '18px 52px 12px 14px'
    },
    '& label': {
      top: '4px'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '29px',
    '& hr': {
      // display: 'none'
      color: 'red',
      background: 'red'
    }
  },
  formLabel: {
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    '& hr': {
      // display: 'none'
      color: 'red',
      background: 'red'
    }
  },
  rulles: {
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    '& a': {
      color: '#327287',
      textDecoration: 'none'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    height: '56px',
    textTransform: 'none',
    // fontSize: '13px!important',
    background: '#FF5722',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '16px',
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0)
    }
  },
  decor: {
    position: 'absolute',
    bottom: '-41px',
    right: '-28%',
    zIndex: -1
  },
  already: {
    marginTop: '45px',
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    justifyContent: 'center'
  },
  alreadyText: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#000000',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('1000')]: {
      fontWeight: '600',
      fontSize: '17px'
    },
    '& a': {
      fontFamily: 'Dosis',
      color: '#327287',
      fontWeight: '600',
      textDecoration: 'none',
      fontSize: '18px',
      lineHeight: '22px',
      [theme.breakpoints.down('1000')]: {
        display: 'contents'
      }
    },
    '& span': {
      color: '#327287',
      textDecoration: 'none',
      cursore: 'pointer'
    }
  },
  radioWrap: {
    margin: '0 auto',
    marginBottom: '37px',
    border: '1px solid #B5B5B5'
  },
  radioButton: {
    minWidth: '155px',
    [theme.breakpoints.down('800')]: {
      minWidth: '142px'
    }
  },
  signWrap: {
    margin: '0 auto',
    marginTop: '20px',
    maxWidth: '300px',
    [theme.breakpoints.down('800')]: {
      marginTop: '0px'
    }
  },
  // formControl: {
  //   width: '100%'
  // },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  social: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    margin: theme.spacing(4, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '16rem',
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 'auto'),
      maxWidth: '20rem'
    },
    [theme.breakpoints.down('1300')]: {
      margin: theme.spacing(0, 0),
      maxWidth: '350px'
    },
    [theme.breakpoints.down('800')]: {
      maxWidth: '100%',
      padding: '0 25.5px',
      margin: '0'
    }
  },
  paper2: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  // decor: {
  //   position: 'absolute',
  //   bottom: '-41px',
  //   right: '-28%',
  //   [theme.breakpoints.down('1260')]: {
  //     maxWidth: '93px',
  //     bottom: '-65px',
  //     right: '2%'
  //   }
  // },
  body2: {
    lineHeight: '1.5',
    color: '#444444'
  },

  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },

  checkbox: {
    fontSize: '11px',
    '& > *': {
      fontSize: '11px'
    }
  },

  typeOfAccount: {
    marginTop: theme.spacing(1)
  }
}));

function SignUp(props) {
  // const [formats, setFormats] = React.useState(() => ['bold', 'italic']);
  // const handleFormat = (event, newFormats) => {
  //   setFormats(newFormats);
  // };

  const classes = useStyles();

  // register params
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password_one, setPassword_one] = useState('');
  const [password_conf, setPassword_conf] = useState('');
  const [account_type, setAccount_type] = useState();
  // const [checked, setChecked] = useState(true);

  const [error_name, setError_name] = useState(false);
  const [error_email, setError_email] = useState(false);
  const [gmail_format, setError_gmail_format] = useState(false);
  const [error_password, setError_password] = useState(false);
  const [error_passwordNotMatch, setError_passwordNotMatch] = useState(false);
  const [error_usertype, setError_user] = useState(false);
  // const [error_terms, setError_terms] = useState(false);

  const [capital_error, setCapital_error] = useState(false);
  const [small_error, setSmall_error] = useState(false);
  const [number_error, setNumber_error] = useState(false);
  const [character_error] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isChecked, setIsChecked] = useState(false);
  const [scrollup, setScroll] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleChange = (event, newAlignment) => {
    //console.log(newAlignment)
    if (newAlignment) {
      setAccount_type(newAlignment);
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    function containsGmail(value) {
      return value.includes("gmail.com");
    }

    var mailFormat =
      /^([a-zA-Z0-9_\.\-!#$%&'*+/=?^`{|}~"(),:;<>[\]])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

    if (!firstName || !lastName) {
      setError_name(true);
      scroll.scrollToTop();
      return false;
    } else setError_name(false);

    if (!mailFormat.test(email)) {
      setError_email(true);
      return false;
    } else {
      if(containsGmail(email)){
        setScroll(true);
        setError_gmail_format(true);
        return false
      }else{
        setError_gmail_format(false);
        setError_email(false);
      }
    }

    if (password_one.length <= 5) {
      setError_password(true);
      return false;
    } else setError_password(false);

    if (password_one.search(/[A-Z]/) === -1) {
      setCapital_error(true);
      return false;
    } else setCapital_error(false);

    if (password_one.search(/[a-z]/) === -1) {
      setSmall_error(true);
      return false;
    } else setSmall_error(false);

    if (password_one.search(/\d/) === -1) {
      setNumber_error(true);
      return false;
    } else setNumber_error(false);

    // if (password_one.search(/^(?=.*[!@#$%^&*()/_+'=?`\{|}~",:;</>])/) === -1) {
    //   setCharacter_error(true);
    //   return false;
    // } else setCharacter_error(false);

    if (password_one !== password_conf) {
      setError_passwordNotMatch(true);
      return false;
    } else setError_passwordNotMatch(false);

    if (!account_type) {
      setError_user(true);
      return false;
    } else setError_user(false);

    // if (checked !== true) {
    //   setError_terms(true);
    //   return false;
    // } else setError_terms(false);

    // var encry_ = CryptoJS.AES.encrypt(password_conf, 'my-secret-key@123').toString();
    // submitting register request
    const registerUser = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password_one,
      account_type: account_type
    };
    // props.aws_cognito_amplify_reg(registerUser)
    // console.log(registerUser)
    props.register(registerUser);
    setScroll(true);
  };

  React.useEffect(() => {
    if (scrollup === true && props.auth.isLoading === false) {
      // console.log("Scroll up")
      // window.scrollTo(0, 0);
      scroll.scrollToTop();
      setScroll(false);
    }
  }, [scrollup, props.auth.isLoading]);

  const responseFacebook = (response) => {
    if (response) {
      setIsChecked(true);
      props.login_facebook(response);
    }
  };

  const responseGoogle = (response) => {
    if (response) {
      setIsChecked(true);
      props.login_google(response);
    }
  };

  const handleAppleLoginSuccess = (response) => {
    console.log('success', response)
    const id_token = response.authorization.id_token;

    props.login_apple(id_token);
  }
  
  const handleAppleLoginError = (error) => {
    console.log('error', error);
  }

  // const handleResendCode = (e) => {
  //   props.aws_cognito_amplify_resend();
  // };

  let alert;
  if (props.error.msg.data) {
    let msg = props.error.msg.data.non_field_errors;
    alert = (
      <div className="alerts">
        <Alert severity="error">{msg}</Alert>
      </div>
    );
  }
  if (props.error.msg.non_field_errors) {
    let msg = props.error.msg.non_field_errors;
    alert = (
      <div className="alerts">
        <Alert severity="error">{msg}</Alert>
      </div>
    );
  }
  // user with email already exists
  if (props.error.msg.email) {
    let msg = props.error.msg.email[0];
    alert = (
      <div className="alerts">
        <Alert severity="error">{msg}</Alert>
      </div>
    );
  }

  if (props.messages.notify_timeout !== null) {
    alert = (
      <div className="alerts">
        <Alert severity="info">{props.messages.notify_timeout}</Alert>
      </div>
    );
  }
  if (
    props.auth.user !== undefined &&
    props.auth.user !== null &&
    props.auth.isAuthenticated === true &&
    props.auth.user_register_social === false
  ) {
    if (
      props.auth.user.account_type === '' ||
      props.auth.user.account_type === null ||
      props.auth.user.account_type === undefined
    ) {
      return <Redirect to="/login" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }

  if (props.auth.user_register_social === true) {
    // if (!isChecked) {
    console.log('Redirect to account type');
    return <Redirect to="/select_account" />;
    //  }
    // else {
    //   return <Redirect to="/dashboard" />;
    // }
  }
  // console.log(isChecked);
  return (
    <Box>
      <div>
        <GuestNavBar />
      </div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* <Grid item xs={false} sm={6} md={7} xl={8} className={classes.image} /> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          xl={4}
          component={Paper}
          elevation={0}
          square
          className={classes.rootGrid}
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <img
                className={classes.logo}
                onClick={(e) => (window.location.href = '/')}
                src={logo}
                alt="logo"
                width="30"
              />
            </Avatar>
            <Typography component="h1" variant="h5" className={classes.title}>
              Sign Up
            </Typography>
            <form className={classes.form} noValidate>
              <div className={classes.buttonWrap}>
                <FacebookLogin
                  appId={facebookAppId}
                  disableMobileRedirect={true}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  className={classes.social}
                  textButton="Sign up with Facebook "
                  cssClass="facebook-button-class"
                />
                <img className={classes.iconFacebook} src={Facebook} alt="" />
              </div>
              <div className={classes.buttonWrap}>
                <GoogleLogin
                  clientId={googleClientId}
                  buttonText="Sign up with Google"
                  onSuccess={responseGoogle}
                  className={classes.google}
                  icon=""
                />
                <img className={classes.iconGoogle} src={Google} alt="" />
              </div>

              <div className={classes.buttonWrap}>
                <AppleSignin
                  authOptions={{
                    clientId: 'com.homepetvet.mobile',
                    scope: 'email name',
                    redirectURI: `${process.env.REACT_APP_SERVER_URL}/signup/`,
                    state: '',
                    nonce: 'nonce',
                    usePopup: true,
                  }}
                  uiType="dark"
                  className="apple-auth-btn"
                  style={{
                    marginTop: '8px',
                    fontSize: '19px',
                    fontFamily: 'Dosis',
                    fontWeight: 'bold',
                    width: '100%'
                  }}
                  buttonExtraChildren="Sign Up with Apple"
                  onSuccess={handleAppleLoginSuccess}
                  onError={handleAppleLoginError}
                />
              </div>

              <p className={classes.or}>- or -</p>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.message}>{alert}</div>
                </Grid>
                {
                  gmail_format ? 
                  <Grid item xs={12}>
                   <div className="alerts">
                    <Alert severity="info">Please click the "Sign up with Google" button to join with your existing Gmail Account.</Alert>
                  </div>
                 </Grid>: null
                }
                

                
                <Grid item xs={12}>
                  <TextField
                    error={error_name}
                    variant="outlined"
                    className={classes.input}
                    autoComplete="fname"
                    name="firstName"
                    size="small"
                    required
                    fullWidth
                    id="firstNames"
                    label="First Name"
                    autoFocus
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error_name}
                    variant="outlined"
                    className={classes.input}
                    autoComplete="lname"
                    name="lastName"
                    size="small"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoFocus
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                {error_name ? (
                  <Grid item xs={12}>
                    <FormHelperText error>Please Fill In Both Name fields</FormHelperText>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12}>
                  <TextField
                    error={error_email}
                    variant="outlined"
                    className={classes.input}
                    size="small"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error_email ? (
                    <FormHelperText error>Email address is not valid. </FormHelperText>
                  ) : (
                    ''
                  )}
  
                  
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error_password}
                    variant="outlined"
                    className={classes.input}
                    size="small"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    placeholder="Min. 6 characters"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword_one(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {error_password ||
                  capital_error ||
                  small_error ||
                  number_error ||
                  character_error ? (
                    <FormHelperText error>
                      Must contain capital and small letters, a special character and numbers{' '}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error_passwordNotMatch}
                    variant="outlined"
                    className={classes.input}
                    size="small"
                    required
                    fullWidth
                    name="repeatPassword"
                    label="Repeat Password"
                    type={showPassword ? 'text' : 'password'}
                    id="repeatPassword"
                    placeholder="Min. 6 characters"
                    autoComplete="repeat-password"
                    onChange={(e) => setPassword_conf(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password2 visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {error_passwordNotMatch ? (
                    <FormHelperText error>Passwords do not match!</FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>

                <div className={classes.signWrap}>
                  <Grid item xs={12} className={classes.typeOfAccount}>
                    <FormControl
                      className={classes.formControl}
                      component="fieldset"
                      error={error_usertype}
                    >
                      <FormLabel className={classes.formLabel} component="legend">
                        Sign up as a PET:
                      </FormLabel>
                      <ToggleButtonGroup
                        className={classes.radioWrap}
                        size="small"
                        value={account_type}
                        exclusive
                        onChange={handleChange}
                      >
                        <ToggleButton
                          className={classes.radioButton}
                          value="service_provider"
                          aria-label="Provider"
                        >
                          <h2>Provider</h2>
                        </ToggleButton>
                        <ToggleButton
                          className={classes.radioButton}
                          value="pet_owner"
                          aria-label="Owner"
                        >
                          {' '}
                          {/* first release for pet providers */}
                          <h2>Owner</h2>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    {error_usertype ? (
                      <FormHelperText error>Please Choose a user type.</FormHelperText>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <p className={classes.rulles}>
                      By signing in or signing up I agree to HomePetVet’s{' '}
                      <a href="/terms">Terms&nbsp;</a>and <a href="/privacy">Privacy Policy</a>
                    </p>
                  </Grid>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handlesubmit}
                  >
                    {props.auth.isLoading ? <Spinner /> : 'Sign Up'}
                  </Button>
                </div>
              </Grid>
              <Grid className={classes.already} container justify="flex-end">
                <Grid item>
                  <p className={classes.alreadyText}>
                    Already have an account?&nbsp;
                    <Link href="/login" variant="body2">
                      {'Sign In'}
                    </Link>
                    &nbsp;now!
                  </p>
                  {/* <Link className={classes.alreadyText} href="/login"><a href="">Sign In&nbsp;</a></Link> */}
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  messages: state.messages
});

export default connect(mapStateToProps, {
  register,
  verify_email,
  login_google,
  login_facebook,
  login_apple,
  aws_cognito_amplify_reg,
  aws_cognito_amplify_resend
})(SignUp);
