import React from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

// import DashboardViewSingleBookingProvider from "./DashboardViewSingleBookingProvider";
import { styled } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import {
  delete_payment_method,
  get_payment_method,
  set_primary,
  clear_error,
} from "../actions/form.js";
import moment from "moment";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF3D00",
  },
});


const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 50,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(5),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FF3D00",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#FF3D00",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#FF3D00",
    },
  })
);

const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  // parentDiv: {
  //   margin: theme.spacing(3),
  // },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  text: {
    marginLeft: theme.spacing(3),
  },
  button: {
    backgroundColor: "#FF3D00!important",
    float: "right",
    margin: theme.spacing(0, 0, 1),
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    fontSize: "25px",
    color: "black",
  },
  titleBlock_: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    fontSize: "15px",
    color: "black",
  },
  formLabel: {
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formLabel_: {
    fontSize: "14px",
    color: "#23286B",
    fontWeight: "300",
    marginBottom: theme.spacing(1),
  },
  gridItem: {
    alignItems: 'flex-start',
    [theme.breakpoints.down("750")]: {
      marginRight: '20px !important'
    },
  },
  gridItem_: {
    alignItems: 'flex-end',
    [theme.breakpoints.down("750")]: {
      marginRight: '20px !important'
    },
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  parentDiv: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("350")]: {
      margin: 0,
    },
  },
  pageTitle: {
    maxWidth: "624px",
    marginBottom: "2px",
    marginTop: "5px",
    marginLeft: "15px",
    fontWeight: "200",
    fontSize: "22px",
    lineHeight: "58px",
    color: "#23286B",
  },
}));

function PaymentsViewListHistory(props) {
  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = React.useState("");
  const classes = useStyles();

  const payments = props?.payments;
  const len = payments?.length;
  // sorting them
  if (len > 0) {
    payments.sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  const payment_history_out = props?.payment_history_out;
  const len_ = payment_history_out?.length;
  // sorting them
  if (len_ > 0) {
    payment_history_out.sort((a, b) => (a.id > b.id ? 1 : -1));
  }


  const format_date = (date) => {
    const new_date = moment(date).toDate();
    const new_date_ = new_date.toString().substring(0, 24);
    return new_date_;
  };

  const [value, setValue] = React.useState(0);
  const handleChange_tab = (event, newValue) => {
    setValue(newValue);
    props.clear_error();
  };

  //   {
  //     "amount": 20.91,
  //     "reason": null,  
  //     "service": {
  //         "id": 1004,
  //         "additional_rates": [],
  //         "name": null,
  //         "description": "",
  //         "price": 20,
  //         "parent": null,
  //         "service": {
  //             "id": 2,
  //             "created": "2022-04-19T06:17:26.646682",
  //             "modified": "2022-04-19T06:17:26.641104",
  //             "name": "Walking",
  //             "icon": "https://homepetvet.s3.amazonaws.com/media/media/service-icons/dog_walking.png",
  //             "parent": null
  //         },
  //         "rate": {
  //             "id": 3,
  //             "created": "2021-01-06T16:21:10.551163",
  //             "modified": "2021-01-06T16:21:10.551163",
  //             "name": "Per Hour",
  //             "enabled": true
  //         }
  //     },
  //     "payment_method": {
  //         "id": 1169,
  //         "is_used": true,
  //         "is_expired": false,
  //         "created": "2023-12-08T16:59:07.736638",
  //         "modified": "2024-03-09T09:29:49.494759",
  //         "brand": "visa",
  //         "exp_month": 6,
  //         "exp_year": 2025,
  //         "last4": "4242",
  //         "is_primary": true
  //     },
  // }

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.parentDiv}>
        <span className={classes.titleBlock}>Payments</span>
        {(len > 0 || len_ > 0) ? (

          <div>
            <div>
              <Box sx={{ width: "100%" }}>
                <AntTabs
                  className={classes.scroll}
                  value={value}
                  onChange={handleChange_tab}
                  aria-label="ant example"
                >
                  <AntTab label="Incoming payments" />
                  <AntTab label="Outgoing payments" />
                </AntTabs>
              </Box>
            </div>
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              className={classes.AccordionSum}
            >
              <div style={{
                width: `${window.innerWidth - 700}px`,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}>
                <div style={{ width: '100%' }}>
                  <Grid className={classes.gridItem} item>
                    <FormLabel
                      component="label"
                      className={classes.formLabel}
                    >
                      Amount
                    </FormLabel>
                  </Grid>
                </div>

                <div style={{ width: '100%' }}>
                  <Grid className={classes.gridItem} item>
                    <FormLabel className={classes.formLabel}>
                      Payment date
                    </FormLabel>
                  </Grid>
                </div>

                <div style={{ width: '100%' }}>
                  <Grid className={classes.gridItem} item>
                    <FormLabel className={classes.formLabel}>
                      Service
                    </FormLabel>
                  </Grid>
                </div>

                <div style={{ width: '100%' }}>
                  <Grid className={classes.gridItem} item>
                    <FormLabel className={classes.formLabel}>
                      Card
                    </FormLabel>
                  </Grid>
                </div>

                <div style={{ width: '100%' }}>
                  <Grid className={classes.gridItem_} item>
                    <FormLabel
                      component="label"
                      className={classes.formLabel}
                    >
                      Status
                    </FormLabel>
                  </Grid>
                </div>
              </div>
            </AccordionSummary>
            {value === 0 ? (
              <div>
                {payments.length > 0 ? (
                  <div>
                    <div>
                      {payments?.slice(0, len)
                        .map((payment) => (
                          <Accordion className={classes.accordion} key={payment.id}>
                            <AccordionSummary
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                            >
                              <div style={{
                                width: `${window.innerWidth - 700}px`,
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',

                              }}>
                                <div style={{ width: '100%' }}>
                                  <Grid className={classes.gridItem} item>
                                    <FormLabel
                                      component="label"
                                      className={classes.formLabel_}
                                    >
                                      $ {payment.amount}
                                    </FormLabel>

                                  </Grid>
                                </div>
                                <div style={{ width: '100%' }}>
                                  <Grid className={classes.gridItem} item>
                                    <FormLabel
                                      component="label"
                                      className={classes.formLabel_}
                                    >
                                      {format_date(payment.created)}
                                    </FormLabel>
                                  </Grid>
                                </div>

                                <div style={{ width: '100%' }}>
                                  <Grid className={classes.gridItem} item>
                                    <FormLabel
                                      component="label"
                                      className={classes.formLabel_}
                                    >
                                      {payment?.service?.service?.name}
                                    </FormLabel>

                                  </Grid>
                                </div>

                                <div style={{ width: '100%' }}>
                                  <Grid className={classes.gridItem} item>
                                    <FormLabel
                                      component="label"
                                      className={classes.formLabel_}
                                    >
                                      **** {payment?.payment_method?.last4}
                                    </FormLabel>

                                  </Grid>
                                </div>

                                <div style={{ width: '100%' }}>
                                  <Grid className={classes.gridItem_} item>
                                    <FormLabel
                                      component="label"
                                      style={{
                                        color: payment?.status === "completed" ? "green" :
                                          payment?.status === "pending" ? "blue" : "red"
                                      }}
                                      className={classes.formLabel_}
                                    >
                                      {payment?.status}
                                    </FormLabel>
                                  </Grid>
                                </div>

                              </div>
                            </AccordionSummary>

                            <AccordionDetails>
                              <Grid md={12} sm={12}>
                                <FormLabel className={classes.formLabel}>
                                  Description
                                </FormLabel>
                                <Input
                                  disableUnderline
                                  fullWidth
                                  multiline
                                  rows={3}
                                  value={payment.description}
                                  className={classes.input}
                                />
                              </Grid>
                            </AccordionDetails>
                            <br />
                          </Accordion>
                        ))
                        .reverse()}
                    </div>
                  </div>
                ) : (
                  <div className={classes.pageTitle}>
                    You currently have no incoming payments.
                  </div>
                )}
              </div>
            ) : null}

            {value === 1 ? (
              <div>
                {payment_history_out.length > 0 ? (
                  <div>
                    {payment_history_out?.slice(0, len_)
                      .map((payment) => (
                        <Accordion className={classes.accordion} key={payment.id}>
                          <AccordionSummary
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                          >
                            <div style={{
                              width: `${window.innerWidth - 700}px`,
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',

                            }}>
                              <div style={{ width: '100%' }}>
                                <Grid className={classes.gridItem} item>
                                  <FormLabel
                                    component="label"
                                    className={classes.formLabel_}
                                  >
                                    $ {payment.amount}
                                  </FormLabel>

                                </Grid>
                              </div>
                              <div style={{ width: '100%' }}>
                                <Grid className={classes.gridItem} item>
                                  <FormLabel
                                    component="label"
                                    className={classes.formLabel_}
                                  >
                                    {format_date(payment.created)}
                                  </FormLabel>
                                </Grid>
                              </div>

                              <div style={{ width: '100%' }}>
                                <Grid className={classes.gridItem} item>
                                  <FormLabel
                                    component="label"
                                    className={classes.formLabel_}
                                  >
                                    {payment?.service?.service?.name}
                                  </FormLabel>

                                </Grid>
                              </div>

                              <div style={{ width: '100%' }}>
                                <Grid className={classes.gridItem} item>
                                  <FormLabel
                                    component="label"
                                    className={classes.formLabel_}
                                  >
                                    **** {payment?.payment_method?.last4}
                                  </FormLabel>

                                </Grid>
                              </div>

                              <div style={{ width: '100%' }}>
                                <Grid className={classes.gridItem_} item>
                                  <FormLabel
                                    component="label"
                                    style={{
                                      color: payment?.status === "completed" ? "green" :
                                        payment?.status === "pending" ? "blue" : "red"
                                    }}
                                    className={classes.formLabel_}
                                  >
                                    {payment?.status}
                                  </FormLabel>
                                </Grid>
                              </div>

                            </div>
                          </AccordionSummary>

                          <AccordionDetails>
                            <Grid md={12} sm={12}>
                              <FormLabel className={classes.formLabel}>
                                Description
                              </FormLabel>
                              <Input
                                disableUnderline
                                fullWidth
                                multiline
                                rows={3}
                                value={payment.description}
                                className={classes.input}
                              />
                            </Grid>
                          </AccordionDetails>
                          <br />
                        </Accordion>
                      ))
                      .reverse()}
                  </div>
                ) : (
                  <div className={classes.pageTitle}>
                    You currently have no outgoing payments.
                  </div>
                )}
              </div>
            ) : null}
          </div>

        ) : (
          <div>
            <span className={classes.titleBlock_}>
              You currently have no payment history.
            </span>
          </div>
        )}
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  booking: state.booking,
  user: state.auth.user,
  messages: state.messages,
  results: state.services.results,
  all_services: state.services.admin_services,
});
export default connect(mapStateToProps, {
  delete_payment_method,
  get_payment_method,
  set_primary,
  clear_error,
})(PaymentsViewListHistory);








