/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
// import { countryList, stateList } from "../actions/lists";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import GoogleMapsAutoComplete from "./GoogleMapsAutoComplete";
import Spinner from "./Spinner";
import { sendUserData } from "../actions/form.js";
import { loadUser } from '../actions/auth.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  imageSelector: {
    fontSize: "8rem",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    justifyContent: "center",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(4),
  },
  selectFormControl: {
    width: "100%",
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  gridSubsection: {
    marginBottom: theme.spacing(1),
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  wizardContainer: {
    margin: theme.spacing(1, "auto"),
  },
  form: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginTop: theme.spacing(2),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#663399!important",
    marginRight: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  button_: {
    backgroundColor: "#FF3D00!important",
    width: "245px",
    height: "40px",
    marginLeft: theme.spacing(1),
  }
}));

function AddressSection(props) {
  const classes = useStyles();
  const { user } = props;
  const user_id = user.id;

  const [address_line_1, setAddress_Line1] = useState(user.address_line_1);
  const [address_line_2, setAddress_Line2] = useState(user.address_line_2);
  const [city, setCity] = useState(user.city);
  const [state, setState_] = useState(user.state);
  const [country, setCountry] = useState(user.country);
  const [zipcode, setZipcode] = useState(user.zipcode);

  const [submitted_status, setSStatus] = useState(false);
  const handleSubmit = () => {
    const object = {
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: city,
        state: state,
        country: country,
        zipcode: zipcode,
      };
      setSStatus(true)
      props.sendUserData(object, user_id);
      props.loadUser();
      props.loadUser();
      const timer = setTimeout(() => {
        setSStatus(false)
      }, 3000);
  }

  function compIsType(t, s) {
    // eslint-disable-next-line eqeqeq
    for (let z = 0; z < t.length; ++z) if (t[z] == s) return true;
    return false;
  }

  // const [google_form, setGoogle_form] = useState(true);
  const [address_value, setAddress_value] = useState(props.user.address_line_1);
  const [address_value2, setAddress_value2] = useState(
    props.user.address_line_2
  );
  const [store, setStore] = useState(null);
  const [store2, setStore2] = useState(null);
  const [length_results, setLength] = useState(null);
  const [length_results2, setLength2] = useState(null);

  const not_found = (options) => {
    setLength(options.length);
  };

  const not_found_ = (options) => {
    setLength2(options.length);
  };

  const typed_value = (value) => {
    setAddress_value(value);
  };
  const typed_value_ = (value) => {
    setAddress_value2(value);
  };

  React.useEffect(() => {
    if (address_value2 !== "") {
      setStore2(address_value2);
    }
    if (address_value2 === "") {
      setAddress_value2(store2);
    }
    // eslint-disable-next-line
  }, [address_value2]);

  React.useEffect(() => {
    if (address_value !== "") {
      setStore(address_value);
    }
    if (address_value === "") {
      setAddress_value(store);
    }
    // eslint-disable-next-line
  }, [address_value]);

  React.useEffect(() => {
    if (store !== null && length_results === 0) {
      setAddress_Line1(store);
    }
    // eslint-disable-next-line
  }, [store, length_results]);

  React.useEffect(() => {
    if (store2 !== null && length_results === 0) {
      setAddress_Line2(store2);
    }
    // eslint-disable-next-line
  }, [store2, length_results2]);

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="md" className={classes.wizardContainer}>
        <div>
          <FormGroup className={classes.formGroup}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Your address
            </FormLabel>
            <FormLabel component="label" className={classes.formLabel}>
              Address Line 1
            </FormLabel>
            <GoogleMapsAutoComplete
              id="address_1"
              placeholder="Physical Address *"
              variant={"filled"}
              not_found={not_found}
              get_typed_value={typed_value}
              defaultValue={address_line_1}
              onChange={(value) => {
                // console.log(value);
                // console.log(value.placeDetails.address_components)
                if (value !== null) {
                  // console.log(value.placeDetails.address_components);

                  if (value.placeDetails.address_components !== undefined) {
                    let addrComp = value.placeDetails.address_components;
                    for (let i = 0; i < addrComp.length; ++i) {
                      var typ = addrComp[i].types;
                      if (compIsType(typ, "administrative_area_level_1")) {
                        var state_ = addrComp[i].long_name; // store the state
                        // console.log(state_);
                        setState_(state_);
                      } else if (
                        compIsType(typ, "locality") ||
                        compIsType(typ, "sublocality")
                      ) {
                        var city_ = addrComp[i].long_name; // store the city
                        // console.log(city_);
                        setCity(city_);
                      } else if (compIsType(typ, "country")) {
                        var country_ = addrComp[i].long_name; // store the country
                        // console.log(country_);
                        setCountry(country_);
                      } else if (compIsType(typ, "postal_code")) {
                        var zipcode_ = addrComp[i].long_name; // store the zip code
                        // console.log(zipcode_);
                        setZipcode(zipcode_);
                      }
                      //we can break early if we find all three data
                      if (
                        state_ != null &&
                        city_ != null &&
                        country_ != null &&
                        zipcode_ != null
                      ) {
                        break;
                      }
                    }
                  }

                  setAddress_Line1(value.structured_formatting.main_text);
                }
              }}
            />
            {/*<Input
              disableUnderline
              fullWidth
              id="address1"
              defaultValue={props.address_line_1}
              placeholder="Physical Address *"
              autoComplete="new-password"
              inputProps={{ "aria-label": "description" }}
              className={classes.input}
              onChange={(e) => setAddress_Line1(e.target.value)}
            />*/}
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <FormLabel component="label" className={classes.formLabel}>
              Address Line 2
            </FormLabel>
            <GoogleMapsAutoComplete
              id="address_2"
              placeholder="Physical Address *"
              variant={"filled"}
              not_found={not_found_}
              get_typed_value={typed_value_}
              defaultValue={address_line_2}
              onChange={(value) => {
                if (value !== null) {
                  setAddress_Line2(value.structured_formatting.main_text);
                }
              }}
            />
            {/*<Input
              id="address2"
              defaultValue={props.address_line_2}
              disableUnderline
              fullWidth
              placeholder="This can be your apartment number"
              autoComplete="new-password"
              inputProps={{ "aria-label": "description" }}
              className={classes.input}
              onChange={(e) => setAddress_Line2(e.target.value)}
            />*/}
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  City
                </FormLabel>
                <Input
                  id="city"
                  value={city}
                  disableUnderline
                  fullWidth
                  placeholder="City"
                  inputProps={{ "aria-label": "description" }}
                  autoComplete="new-password"
                  className={classes.inputSmall}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  State
                </FormLabel>
                <FormControl className={classes.selectFormControl}>
                  <Input
                    onChange={(e) => setState_(e.target.value)}
                    id="zip_code"
                    value={state}
                    disableUnderline
                    autoComplete="new-password"
                    fullWidth
                    placeholder="State"
                    inputProps={{ "aria-label": "description" }}
                    className={classes.inputSmall}
                  />
                  {/*<Select
                    name="state"
                    autoComplete="new-password"
                    disableUnderline
                    defaultValue={state}
                    onChange={(e) => setState_(e.target.value)}
                    displayEmpty
                    className={classes.inputSelect}
                    inputProps={{ "aria-label": "Select State" }}
                  >
                    {stateList != null ? (
                      stateList.map((state, i) => (
                        <MenuItem key={i} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="Seattle">Seattle</MenuItem>
                    )}
                  </Select>*/}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Country
                </FormLabel>
                <FormControl className={classes.selectFormControl}>
                  <Input
                    onChange={(e) => setCountry(e.target.value)}
                    id="zip_code"
                    value={country}
                    disableUnderline
                    autoComplete="new-password"
                    fullWidth
                    placeholder="Country"
                    inputProps={{ "aria-label": "description" }}
                    className={classes.inputSmall}
                  />
                  {/*<Select
                    autoComplete="new-password"
                    name="country"
                    disableUnderline
                    defaultValue={props.country}
                    onChange={(e) => setCountry(e.target.value)}
                    displayEmpty
                    className={classes.inputSelect}
                    inputProps={{ "aria-label": "Select Country" }}
                  >
                    {countryList != null ? (
                      countryList.map((country, i) => (
                        <MenuItem key={i} value={country.country_name}>
                          {country.country_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="United States">United States</MenuItem>
                    )}
                  </Select>*/}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="label" className={classes.formLabel}>
                  Zip/Postal code
                </FormLabel>
                <Input
                  onChange={(e) => setZipcode(e.target.value)}
                  id="zip_code"
                  value={zipcode}
                  disableUnderline
                  autoComplete="new-password"
                  fullWidth
                  placeholder="Zip / Postal / Post code"
                  inputProps={{ "aria-label": "description" }}
                  className={classes.inputSmall}
                />
              </Grid>

              <Button
                variant="contained"
                color="primary"
                className={classes.button_}
                onClick={handleSubmit}
              >
                {props.form.loading_collective && submitted_status
                ? <Spinner /> : "Update address information"}
                
              </Button>
            </Grid>
          </FormGroup>
        </div>
      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  sendUserData,
  loadUser
})(AddressSection);

