import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserHomepageNavbar';
import Footer from '../components/Footer';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '43px',
    paddingBottom: '59px',
    fontFamily: 'Dosis',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
    background: '#F5F5EF',
    position: 'relative',
    zIndex: '2',
    [theme.breakpoints.down('650')]: {
      paddingTop: '27px',
      paddingBottom: '40px'
    }
  },
  image: {
    margin: theme.spacing(1)
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '50%'
  },
  paper: {
    margin: theme.spacing(1)
  },
  wizardContainer: {
    margin: theme.spacing(12, 'auto', 2)
  },
  title: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '520',
    fontSize: '46px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  title_: {
    maxWidth: '1024px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '520',
    fontSize: '36px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  teamSection: {
    margin: theme.spacing(3, 0, 1)
  },
  descriptions: {
    textAlign: 'center'
  },
  body: {
    // letterSpacing: '0.02em',
    // lineHeight: '2em',
    // marginBottom: theme.spacing(3),
    // fontSize: '1em',

    fontSize: '1.1em',
    lineHeight: '30px',
    //alignItems: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    fontWeight: '600',
    color: '#383F49',
    [theme.breakpoints.down('850')]: {
      fontSize: '20px',
      lineHeight: '28px'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '16px',
      lineHeight: '19.8px',
      maxWidth: '309px',
      margin: '0 auto'
    }
  },
  descriptions_: {
    fontFamily: 'Averia Sans Libre',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '500',
    color: '#737373',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('850')]: {
      fontSize: '18px',
      lineHeight: '24px'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '14px',
      lineHeight: '17px'
    }
  }
}));

function AboutUs(props) {
  const classes = useStyles();


  return (
    <Box>
      <div>
        <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
        <div>{!props.auth.token && <GuestNavBar />}</div>
      </div>

      <div className={classes.root}>
        <Container maxWidth="md" className={classes.wizardContainer}>
          <h2 className={classes.title}>Privacy Policy</h2>

          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ fontWeight: '800' }}>
              {' '}
              TAKESAVILLAGE.COM, LLC (“TAV” or “us” or “we”)
            </span>{' '}
            respects the privacy of every individual who visits our web site. This Privacy Policy &
            Terms of Use (“Agreement”) covers the site at http://www.homepetvet.com (the “Site”).
            TAV reserves the right to change this Agreement at any time, therefore you are
            responsible for periodically checking the Agreement for changes. Your continued access
            or use of this Site shall be deemed your conclusive acceptance to be bound by this
            Agreement.
          </p>
          <br />
          <h2 className={classes.title}>Information Collection and Use</h2>

          <p variant="body2" className={classes.body} gutterBottom>
            The primary goal of TAV in collecting information is to provide and improve our Site,
            TAV services and to enable users to enjoy and easily navigate the Site.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            When you register with TAV and create an account, we may ask that you provide us with
            some personally identifiable information – information about you that may be used to
            contact or identify you, and information on your use or potential use of the Site and
            TAV services – and establish a username and password (“Personal Information”). Personal
            Information that we might collect includes information like your name, e-mail address,
            home telephone number, credit card information, home address, username, password,
            business ame, business telephone number, and other information. Personal Information may
            also include her information you supply to us concerning your preferences and interest
            expressed when using the Site and TAV services.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            TAV will not collect any Personal Information about you, unless you provide it to us
            voluntarily. If you do not want your Personal Information collected, please do not
            submit it to us. We may use your Personal Information to assist you, better understand
            your needs and to determine how we can improve our services and Site. We may also use
            that Personal Information to contact you to answer a question or respond to a comment
            you have submitted.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            In some cases, we may collect information that is not personally identifiable. Examples
            of this type of information include the type of internet browser or computer operating
            system you use, the date and time you visited our Site, the pages you accessed, and the
            name of the web site from which you accessed our Site. This information is used only for
            internal review purposes such as tracking the total number of visitors to our Site on an
            aggregate, non-personally identifiable basis. This information may also be used to help
            analyze site traffic and improve our Site and TAV services. We may also aggregate such
            information to create statistical data and we may share that information with potential
            advertisers, partners, affiliates and others.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            Cookies are small pieces of information stored on your computer. We use cookies, which
            may include your IP address, to better understand how you use our Site and TAV services
            and to help us tailor our Site and TAV services to better match your needs and
            interests. You have the option whether to accept a cookie by resetting your browser to
            notify you whenever a cookie is being stored on your web browser. You may also delete
            cookies manually from your hard drive. Please refer to your browser instructions or
            “help” screen to learn more about these functions.
          </p>

          <p variant="h6" className={classes.title_} gutterBottom>
            Compliance with Google's Limited Use Policy
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            Homepetvet’s use and transfer to any other app of information received from Google
            APIs will adhere to 
            <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" rel="noopener noreferrer">
              {' '}
              Google API Services User Data Policy{' '}
            </a>
            , including
            the Limited Use requirements.
          </p>


          <p variant="h4" className={classes.title_} gutterBottom>
            Information Sharing and Disclosure
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            We do not share Personal Information with third parties, except as follows:
            <br />
            As of January of 2021, we share Personal Information with <br />
            <span style={{ fontWeight: '800' }}> Sterling: </span>
            <a href="https://sterlingcheck.com" target="_blank" rel="noopener noreferrer">
              {' '}
              sterlingcheck.com{' '}
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Stripe: </span>
            <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
              {' '}
              stripe.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Twilio: </span>
            <a href="https://twilio.com" target="_blank" rel="noopener noreferrer">
              {' '}
              twilio.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> The Dog API:</span>
            <a href="https://thedogapi.com" target="_blank" rel="noopener noreferrer">
              {' '}
              thedogapi.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> CRISP Chat: </span>
            <a href="https://crisp.chat/en/" target="_blank" rel="noopener noreferrer">
              {' '}
              crisp.chat/en
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> AWS S3: </span>
            <a href="https://aws.amazon.com" target="_blank" rel="noopener noreferrer">
              {' '}
              aws.amazon.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Material UI: </span>
            <a href="https://material-ui.com" target="_blank" rel="noopener noreferrer">
              {' '}
              material-ui.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Twitter:</span>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              {' '}
              twitter.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Facebook: </span>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              {' '}
              facebook.com
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Google: </span>
            <a href="https://google.com/landing/2step" target="_blank" rel="noopener noreferrer">
              {' '}
              google.com/landing/2step
            </a>
            <br />
            <span style={{ fontWeight: '800' }}> Shopify: </span>
            <a href="https://shopify.com" target="_blank" rel="noopener noreferrer">
              {' '}
              shopify.com
            </a>
            <br />
          </p>
          <br />
          <p variant="h5" className={classes.title_} gutterBottom>
            Please contact us for a current list.
          </p>

          <p variant="body2" className={classes.body} gutterBottom>
            We may also use your Personal Information in connection with the specific services they
            provide for us (e.g., fulfillment of an order, responding to your questions or comment,
            providing a sweepstakes prize, deployment of a consumer survey, investigation of a
            complaint, etc.).
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            We may employ third party companies and individuals to facilitate the Site and to
            perform Site-related tasks (e.g., without limitation, maintenance, database management,
            web analytics and improvement of the Site’s features) or to assist us in analyzing how
            our Site is being used. These third parties will have access to your Personal
            Information only to perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            TAV may sell, transfer or otherwise share some or all of its assets, including your
            Personal Information, in connection with a merger, acquisition, reorganization or sale
            of assets or in the event of bankruptcy.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            TAV cooperates with government and law enforcement officials and private parties to
            enforce and comply with the law. We will disclose Personal Information and information
            about you to government or law enforcement officials or private parties as we, in our
            sole discretion, believe necessary or appropriate to respond to claims and legal process
            (including but not limited to subpoenas), to protect the property and rights of TAV or a
            third party, to protect the safety of the public or any person, or to prevent or stop
            activity we may consider to be, or to pose a risk of being, any illegal, unethical or
            legally actionable activity.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Use of Site and TAV services; Suspension
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            You understand and agree that we have no obligation to provide this Site or TAV services
            in any specific location or territory, nor to continue providing it once we have begun.
            We reserve the right to suspend or terminate your access to the Site or TAV services for
            any reason.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Your Choices
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            You may update, correct or delete certain information about you at any time by logging
            into your account on the Site or mobile application. If you need assistance, please
            contact us. When you first install or launch any of our mobile applications that collect
            location information, you will be asked to consent to the application’s collection of
            this information. We require location information to use certain features of the Site
            and TAV services, so if you do not consent to this collection, you cannot use those
            features. You may choose whether you wish to receive marketing e-mails from the Site or
            TAV, by updating your preferences on the Site. If you receive marketing e-mails and you
            wish to opt-out, you may do so by clicking “Unsubscribe” in those e-mails. We may send
            push notifications or alerts to your mobile device. You can deactivate these messages at
            any time by changing the notification settings on your mobile device. With your consent,
            as required by applicable law, we may send marketing and/or service- related text
            messages to your mobile device. You may opt-out of receiving these text messages.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Submitted ideas
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            All comments, suggestions, ideas, notes, drawings, concepts, recipes or other
            information disclosed or offered to us by you (your “Content”) through this Site or in
            response to solicitations on our Site shall be deemed and shall remain our property. By
            posting any Content, you represent and warrant that you have all right, title, and
            interest to such posted Content, including but not limited to any consent,
            authorization, release, clearance or license from any third party (such as, but not
            limited to, any release related to rights of privacy or publicity) necessary for you to
            provide, post, upload, input or submit the Content, and that posting such Content does
            not violate or constitute the infringement of any patent, copyright, trademark, trade
            secret, right of privacy, right of publicity, moral rights, or other intellectual
            property right recognized by any applicable jurisdiction of any person or entity, or
            otherwise constitute the breach of any agreement with any other person or entity. By
            submitting your Content, you further acknowledge and agree that TAV owns all right,
            title and interest to your Content and we may use your Content for any purpose
            whatsoever, commercial or otherwise, without compensation to you.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Changing or Deleting Your Information
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            All registered users may review, update, correct or delete the Personal Information
            provided in their registration or account profile by contacting us at{' '}
            <a href="/">homepetvet.com </a>
            If you would like us to remove your records from our system, please contact us and we
            will attempt to accommodate your request, if we do not have any legal obligation to
            retain the record. We reserve the right to terminate your account or restrict access to
            your account, to delete any content posted through your account, or to delete any
            content posted by you, with or without notice, for any or for no reason, and without any
            liability to you.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Security
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            We employ administrative, physical and electronic measures designed to protect your
            Personal Information and information from unauthorized access. We will make any legally
            required disclosures for any breach of the security, confidentiality, or integrity of
            your unencrypted electronically stored “personal data” (as defined in applicable state
            statutes on security breach notification) to you via email or conspicuous posting
            through the Site in the most expedient time possible and without unreasonable delay,
            insofar as consistent with (i) the legitimate needs of law enforcement or (ii) any
            measures necessary to determine the scope of the breach and restore the reasonable
            integrity of the data system.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Links to Other Websites
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            Our Site may contain links to other websites. The fact that we link to a website is not
            an endorsement, authorization or representation of our affiliation with that third
            party. We do not exercise control over third party websites. These other websites may
            place their own cookies or other files on your computer, collect data or solicit
            Personal Information from you. Other sites follow different rules regarding the use or
            disclosure of Personal Information you submit to them. We encourage you to read the
            privacy policies or statements of the other websites you visit.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Children under 13
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            The Site is not directed to children under 13. We do not knowingly collect Personal
            Information from children under 13. If a parent or guardian becomes aware that their
            child has provided us with Personal Information without their consent, contact us and we
            will delete such information from our files. If we become aware that a child under 13
            has provided us with Personal Information, we will delete such information from our
            files.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            United States only
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            This Site is intended for use by residents of the United States of America only. All
            matters relating to this Site are governed by the laws of the State of Washington in the
            United States. If you are located outside of the United States and you contact us,
            please be advised that any information you provide us will be transferred to the United
            States and that by submitting information you explicitly authorize this transfer and the
            processing of this information within the United States.
          </p>

          <br />
          <h2 variant="h4" className={classes.title_} gutterBottom>
            California Privacy Rights & Notices
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            If the processing of your personal information is subject to the California Consumer
            Privacy Act (the “CCPA”), you have certain rights with respect to that information:{' '}
            <br />
            <span style={{ fontWeight: '800' }}> • Right to Know. </span> You can request access to
            the Personal Information we collected about you in the last 12 months. You can also
            request information about the categories of Personal Information we collected, the
            sources of collection, the purposes for collection and sharing of this information, the
            categories of information we shared, and the categories of third parties with whom we
            shared this information. Note that we have provided much of this information above in
            this Agreement.
            <br />
            <span style={{ fontWeight: '800' }}> • Right to Request Deletion. </span>You can request
            that we delete Personal Information under certain circumstances, subject to a number of
            exceptions.
            <br />
            <span style={{ fontWeight: '800' }}> • Right to Opt-Out. </span>You can opt-out from any
            sharing of your Personal Information with third parties to the extent that such sharing
            constitutes a “sale” of that data under the CCPA. You can find more information on these
            practices, including how to exercise your opt-out right, in this Agreement.
            <br />
            <span style={{ fontWeight: '800' }}> • Right to Non-Discrimination. </span> You will not
            be discriminated against by TAV for exercising rights set out in the CCPA.
          </p>
          <br />

          <p variant="body2" className={classes.body} gutterBottom>
            The CCPA defines the “sale” of personal information very broadly, and some of our use of
            third-party cookies and tracking technologies as described in this Agreement may be
            considered a “sale” under that definition.
          </p>
          <br />

          <h2 variant="h4" className={classes.title_} gutterBottom>
            Contacting Us
          </h2>

          <p variant="body2" className={classes.body} gutterBottom>
            If you have any questions about this Agreement, please contact us at{' '}
            <a href="/">homepetvet.com </a>
          </p>
          <br />
          <p variant="body2" style={{ fontStyle: 'italic' }} className={classes.body} gutterBottom>
            Last Updated: February 1, 2021
          </p>
        </Container>
      </div>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  availability: state.forms.availability
});

export default connect(mapStateToProps, null)(AboutUs);
