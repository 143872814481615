import React, { Component } from "react";
import { connect } from "react-redux";
// import ReactCrop from "react-image-crop";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
// import { getProvider_photo } from "../actions/form.js";
import "react-image-crop/dist/ReactCrop.css";
import Spinner from "../assets/home_load.gif";

class PicUploadNoCrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fullWidth: true,
      check_image_validity: false,
      maxWidth: "md",

      src: null,
      crop: {
        unit: "%",
        width: 0,
        aspect: 0,
      },
      croppedImageUrl: null,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMaxWidthChange = this.handleMaxWidthChange.bind(this);
    this.handleFullWidthChange = this.handleFullWidthChange.bind(this);

    this.handleFile = this.handleFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
  }

  handleFile = (e) => {
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.onloadend = () => {
        this.setState({ src: fileReader.result });
      };
      fileReader.readAsDataURL(e.target.files[0]);
      this.props.setting(e.target.files[0])
    } else {
      console.log("Wrong image format..")
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.send_save_signal(true)
  };


  error = (e) => {
    console.log("Error")
    this.setState({ check_image_validity: true });
  }

  close_error = () => {
    this.setState({ check_image_validity: false })
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false, check_image_validity: false });
  }

  handleMaxWidthChange(event) {
    this.setState({ maxWidth: event.target.value });
  }

  handleFullWidthChange(event) {
    this.setState({ fullWidth: event.target.checked });
  }

  render() {
    const { profile_pic, src } = this.state;

    return (
      <React.Fragment>
        <div style={{ display: 'flex', flexDirection: 'row'}} >
        <div style={{ width: '6%'}}/>
        <Button
          style={{
            margin: "auto",
            display: "block",
            alignItems: 'center',
            justifyContent: 'center'
          }}
          color="primary"
          onClick={this.handleClickOpen}
        >
          {"upload"}{" "}
          {this.props.form.photos_loading === true || this.props.early_load  ? (
            <div
              style={{
                marginLeft: "10px",
                marginTop: "5px",
              }}
            >
              <img src={Spinner} alt="" height="25px" width="25px" />
            </div>
          ) : null}

        </Button>
        </div>
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Upload a profile image
            {this.state.check_image_validity ? (<Grid item xs={12}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={this.close_error}
                  >
                    <CloseIcon onClick={this.close_error} fontSize="inherit" />
                  </IconButton>}>
                <div >{"Please upload a valid image."}</div>
              </Alert>
            </Grid>) : null}

            {src && <Button
              type="submit"
              onClick={(e) => {
                this.handleSubmit(e)
                this.handleClose()
              }}
              disabled={this.state.check_image_validity ? true : false}
              variant="outlined"
              style={{ display: "block", marginBottom: '10px' }}
            >
              save
            </Button>}

          </DialogTitle>
          <DialogContent>
            <div>
              <form onSubmit={this.handleSubmit}>
                <label htmlFor="profile_pic"></label>
                {src && <img height="305px" width="305px" src={src} onError={this.error} alt="upload" />}
                <br />
                <input
                  type="file"
                  id="profile_pic"
                  style={{ border: "2px solid #cfd7de", display: "block" }}
                  value={profile_pic}
                  onChange={this.handleFile}
                />
                <br />
              </form>
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
});

export default connect(mapStateToProps, null)(PicUploadNoCrop);

