import React from "react";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import discover from "payment-icons/min/flat/discover.svg";
import diners from "payment-icons/min/flat/diners.svg";
import jcb from "payment-icons/min/flat/jcb.svg";
import unionpay from "payment-icons/min/flat/unionpay.svg";

import {
  delete_payment_method,
  get_payment_method,
  set_primary,
  clear_error,
  backgroundcheck_payment,
} from "../actions/form.js";
import Spinner from "../assets/home_load.gif";

import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#BEE2BD",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    borderRadius: '1px !important',
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    background: "white",
    minHeight: "60px",
    borderRadius: "10px",
    overflow: "hidden",
    [theme.breakpoints.down("768")]: {
      // maxWidth: "375px",
      margin: "0 ",
      // padding: "45px ",
    },
    [theme.breakpoints.down("450")]: {
      padding: "0px ",
    },
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  parentDiv: {
    margin: theme.spacing(0),
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  helpText2: {
    paddingTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  accordion: {
    // margin: theme.spacing(1),
    marginBottom: theme.spacing(0),
    borderRadius: "1px",
  },
  text: {
    marginLeft: theme.spacing(3),
  },
  button: {
    backgroundColor: "#b53f3fbd",
    float: "right",
    margin: theme.spacing(0, 0, 1),
  },
  submitButton: {
    marginLeft: theme.spacing(2),
    float: "left",
    marginTop: theme.spacing(2),
  },
  svg_image: {
    height: "35px",
    width: "35px",
  },
  checkbox: {
    paddingLeft: theme.spacing(4),
  },
  amount: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
}));

function BookingPaymentList(props) {
  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = React.useState(false);
  const [expiredError, setExpiredError] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [open_, setOpen_] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [payment_load, setPayment_load] = React.useState(false);
  const [status_load, setStatus] = React.useState(false);
  const [load_id, setloadID] = React.useState(null);
  const classes = useStyles();
  const [bool] = React.useState(true);
  const payment_methods = props.payment_methods;

  // sorting them
  payment_methods.sort((a, b) => (a.id > b.id ? 1 : -1));
  const len = payment_methods.length;
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [has_primary_card] = React.useState(payment_methods.filter((payment) => payment.is_primary === true));
  

  const setting_primary = (id, value) => {
    setloadID(id);
    console.log('Changing card status...');
    setPayment_load(false);
    setStatus(true);
    const new_value = !value;
    let set_id;
    const check = payment_methods.filter((payment) => payment.is_primary === bool);
    if (check.length >= 1) {
      set_id = check[0].id;
    }

    if (check.length === 1 && id !== set_id) {
      setError(true);
      //window.scrollTo(0, 0);
      scroll.scrollTo();
      setOpen(true);
      // props.primary_error(true)
    }
    if (check.length === 1 && id === set_id) {
      if(new_value === true){
        const primary = payment_methods.filter((payment) => payment.id === id);
        if(primary[0].is_expired === false){
          props.set_primary(id, new_value);
        }else{
          setExpiredError(true);
          setOpen_(true);
        }
      }else{
        props.set_primary(id, new_value);
      }
      // console.log("condition 2")
      setError(false);
      props.fetch_again();
    }
    if (check.length === 0) {
      if(new_value === true){
        const primary = payment_methods.filter((payment) => payment.id === id);
        if(primary[0].is_expired === false){
          props.set_primary(id, new_value);
        }else{
          setExpiredError(true);
          setOpen_(true);
        }
      }else{
        props.set_primary(id, new_value);
      }
      // console.log("condition 3")
      setError(false);
      props.fetch_again();
    }

    setTimeout(() => {
      setStatus(false);
    }, 3000);
    // props.set_primary(id, new_value)
    // props.fetch_again();
  };

  // React.useEffect(() => {
  //   localStorage.removeItem("owner_primary_card")
  // }, []);

  React.useEffect(() => {
    const check = payment_methods.filter((payment) => payment.is_primary === bool);
    if (check.length >= 1) {
      if(check[0].is_expired === true){
          setExpiredError(true);
          setOpen_(true);
        }
    }
  }, [payment_methods, bool]);

  let alert;
  let error_message;
  if (props.messages.notify_timeout !== null) {
    alert = <div className="alerts">{props.messages.notify_timeout}</div>;
  }
  if (props.error.msg.user) {
    let msg = props.error.msg.user;

    error_message = <div className="alerts">{msg}</div>;
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      {error ? (
        <Collapse in={open} style={{ marginBottom: '10px' }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {
              "You can not have more than one primary card, please uncheck the current primary card then make any of your choice primary."
            }
          </Alert>
        </Collapse>
      ) : null}
      {expiredError ? (
        <Collapse in={open_} style={{ marginBottom: '10px' }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen_(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {
              "Your primary card is expired, please set another card as primary to continue."
            }
          </Alert>
        </Collapse>
      ) : null}

      {props.error.msg.user ? (
          <Alert
            severity="error"
            style={{ marginBottom: '10px' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  props.clear_error();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <div>{error_message}</div>
          </Alert>
        ) : null}
      
      {props.messages.notify_timeout ? (
        <Grid item xs={12}>
          <Alert
            severity="info"
            style={{ marginBottom: '10px' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.clear_error();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <div className={classes.message}>{alert} 
              {has_primary_card?.length > 0 ? null : " Please add a payment method to complete your booking."}
              
            </div>
          </Alert>
        </Grid>
      ) : null}
      <div className={classes.parentDiv}>
        <div>
          
        </div>
        {payment_methods.length > 0 ? (
          <div>
            {payment_methods.slice(0, len).map((payment_method) => (
              <Accordion
                className={classes.accordion}
                key={payment_method.id}
                square
                expanded
                style={{ paddingBottom: '10px' }}
                onChange={handleChange(`panel${payment_method.id}`)}
              >
                {payment_method.is_primary ? (
                  <span style={{ fontFamily: "Dosis", fontSize: "17px", paddingBottom: '10px', display: "block" }}>
                    This is the card that will be charged for the service
                  </span>
                ) : null}
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  style={{ paddingBottom: '10px' }}
                  >
                  <Checkbox
                    checked={payment_method.is_primary}
                    defaultValue={payment_method.is_primary}
                    onChange={(e) => {
                      if(props.form.isLoading === false){
                        setting_primary(
                          payment_method.id,
                          payment_method.is_primary
                        )
                      }
                    }}
                    color="primary"
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleChecked />}
                  />
                  {payment_method.brand === "visa" ? (
                    <img src={visa} className={classes.svg_image} alt="Logo" />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "mastercard" ? (
                    <img
                      src={mastercard}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "discover" ? (
                    <img
                      src={discover}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "diners" ? (
                    <img
                      src={diners}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "jcb" ? (
                    <img src={jcb} className={classes.svg_image} alt="Logo" />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "unionpay" ? (
                    <img
                      src={unionpay}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  <FormControlLabel
                    className={classes.checkbox}
                    value="end"
                    control={<div></div>}
                    label={
                      payment_method.brand +
                      "   **** **** **** " +
                      payment_method.last4
                    }
                    labelPlacement="end"
                  />

                  {props.form.isLoading === true &&
                    status_load === true &&
                    load_id === payment_method.id ? (
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      <img src={Spinner} alt="" height="25px" width="25px" />
                    </div>
                  ) : null}
                </AccordionSummary>

              </Accordion>
            ))}
          </div>
        ) : (
          <Accordion
            className={classes.accordion}
            key={"unique"}
            square
            style={{ marginTop: '10px' }}
            expanded
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                No cards added yet. Please add a payment method to proceed to
                booking.
              </Typography>
            </AccordionSummary>
          </Accordion>
        )}
        <br />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  messages: state.messages,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services,
});
export default connect(mapStateToProps, {
  delete_payment_method,
  get_payment_method,
  clear_error,
  set_primary,
  backgroundcheck_payment,
})(BookingPaymentList);
