import { CREATE_MESSAGES, CREATE_MESSAGES_SCHEDULE, CREATE_MESSAGES_STATUS } from './types';

// CREATE MESSAGE
export const createMessage = (msg) => {
  return {
    type: CREATE_MESSAGES,
    payload: msg
  };
};

// CREATE MESSAGE
export const createMessage_status = (msg, id) => {
  return {
    type: CREATE_MESSAGES_STATUS,
    payload: msg,
    payload_id: id
  };
};

// CREATE MESSAGE
export const createMessage_schedules = (msg) => {
  return {
    type: CREATE_MESSAGES_SCHEDULE,
    payload: msg
  };
};
