import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  verify,
  aws_cognito_reset,
  initiate_reset,
  aws_cognito_amplify_resend,
  aws_cognito_amplify_verify,
  register
} from '../actions/auth.js';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import cover from '../assets/home_pet_vet_cover.jpeg';
import logo from '../assets/logo_15.png';
import Alert from '@material-ui/lab/Alert';

import Spinner from '../components/Spinner';

// var AWS = require('aws-sdk');
// var CryptoJS = require('crypto-js');

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
  },
  image: {
    backgroundImage: `url(${cover})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '16rem',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 'auto')
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '20rem'
    }
  },
  avatar: {
    margin: theme.spacing(4, 1, 0),
    backgroundColor: 'white'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
    height: '2.5rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0 0.5rem'
    }
  },
  email: {
    color: 'blue',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  social: {
    margin: theme.spacing(1, 0, 1),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0.2rem auto'
    },
    '& span': {
      width: '100%'
    }
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function Verifyemailcode(props) {
  const classes = useStyles();

  const [code, setCode] = useState('');
  // const [error_code, setError_code] = useState('');

  const handleVerifyCode = (e) => {
    e.preventDefault();
    if (code) {
      localStorage.setItem('reset_code', code);
      props.initiate_reset();
    } else return false;
  };

  const handleResendCode = (e) => {
    const email = localStorage.getItem('email_reset_pass');
    props.aws_cognito_reset(email);
  };

  // if(!props.error.msg.CodeDeliveryDetails){ look later
  //   return <Redirect to="/signup" />;
  // }
  // if(props.error.msg.message === 'Correct code'){
  //     return  <Redirect to="/login" />;
  // }

  if (props.auth.forgot_password === true) {
    return <Redirect to="/reset_password" />;
  }

  let alert;
  if (props.error.msg.data) {
    let msg = props.error.msg.data.non_field_errors;
    alert = (
      <div class="alerts">
        <Alert severity="error">
          {msg}
          <Link variant="body2">
            {' '}
            <span onClick={handleResendCode}>Click here to resend link</span>{' '}
            {/* resend code => params {email}  maynot work coz user may get the code but may never know what password the last user registered the email with.*/}
          </Link>
        </Alert>
      </div>
    );
  }

  switch (props.error.msg.code) {
    case 'CodeMismatchException':
      alert = (
        <div class="alerts">
          <Alert severity="error">
            Code you entered is wrong. Try again or
            <Link variant="body2">
              <span onClick={handleResendCode}> Resend link</span>{' '}
              {/* resend code => params {email}  maynot work coz user may get the code but may never know what password the last user registered the email with.*/}
            </Link>
          </Alert>
        </div>
      );
      break;
    case 'LimitExceededException':
      alert = (
        <div class="alerts">
          <Alert severity="error">Please try after some time.</Alert>
        </div>
      );
      break;
    default:
      break;
  }
  if (props.auth.CodeDeliveryDetails) {
    alert = (
      <div class="alerts">
        <Alert severity="info">A new verification code has been sent to your email.</Alert>
      </div>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={6} md={7} xl={9} className={classes.image} />
      <Grid item xs={12} sm={6} md={5} xl={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img src={logo} onClick={(e) => (window.location.href = '/')} alt="logo" width="30" />
          </Avatar>
          {
            <Typography component="h1" variant="h5">
              Enter code sent to your email
            </Typography>
          }
          <form className={classes.form} noValidate>
            <Grid item xs={12}>
              <div className={classes.message}>{alert}</div>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Verification Code"
              name="code"
              autoComplete="code"
              size="small"
              autoFocus
              onChange={(e) => setCode(e.target.value)}
            />
            <br />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#5E14A3' }}
              className={classes.submit}
              onClick={handleVerifyCode}
            >
              {props.auth.isLoading ? <Spinner /> : 'Verify Code'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  <span onClick={handleResendCode}> Resend link</span>
                </Link>
              </Grid>
              <Grid item>
                <Link href="/login" variant="body2">
                  {'Sign in'}
                </Link>
              </Grid>
            </Grid>
            <br />
            <br />
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  auth: state.auth,
  error: state.errors
});

export default connect(mapStateToProps, {
  verify,
  aws_cognito_reset,
  initiate_reset,
  aws_cognito_amplify_resend,
  aws_cognito_amplify_verify,
  register
})(Verifyemailcode);
