import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


import { get_availability_data, clear_aval } from '../actions/form.js';
import { getproviderdetails } from '../actions/results';

import GuestNavBar from '../components/GuestNavBar';
import { Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Popper from '@material-ui/core/Popper';
import Share from '../components/Share.js';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '10px',
    width: '100%',
    minHeight: '100vh',
    paddingTop: '73px',
    background: '#fff'
  },
  page: {
    margin: '40px 0',
    padding: '10px 0px 0px 0px',
    width: '100%',
    background: 'white',
    minHeight: '400px',
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('680')]: {
      padding: '50px 0px 0px 0px',
      maxWidth: '400px',
      margin: '0 auto',
      marginTop: '20px',
      marginBottom: '20px'
    }
  },
  custom: {
    margin: '0',
    padding: '18px 16px 14px',
    maxWidth: '210px',
    minHeight: '56px',
    border: '1px solid rgba(25, 25, 25, 0.32)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('680')]: {
      marginBottom: '10px',
      maxWidth: '300px',
      width: '300px'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    },
    '& > .MuiPickersToolbar': {
      toolbar: 'none'
    }
  },
  toolbar: {
    backgroundColor: 'red'
  },
  pageTitle: {
    marginBottom: '5px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '36px',
    color: '#222222',
    [theme.breakpoints.down('680')]: {
      marginBottom: '40px',
      fontSize: '28px',
      lineHeight: '28px'
    }
  },
  contant: {
    maxWidth: '1024px',
    marginBottom: '48px',
    textAlign: 'center',
    padding: '0 52px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('680')]: {
      padding: '0 15px'
    }
  },
  input: {
    display: 'none'
  },
  inputLabel: {
    padding: '10px 22px 10px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '495px',
    height: '93px',
    background: '#E7E7E7',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  inputText: {
    marginLeft: '34px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    order: '1',
    [theme.breakpoints.down('680')]: {
      marginLeft: '15px'
    }
  },
  petName: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '29px',
    lineHeight: '36px',
    color: '#156981',
    [theme.breakpoints.down('680')]: {
      fontSize: '22px'
    }
  },
  petType: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#68AC82'
  },
  circle: {
    marginLeft: 'auto',
    width: '32px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #4F4F4F',
    borderRadius: '50%',
    position: 'relative',
    display: 'block',
    order: '2',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '18px',
      height: '7px',
      top: '8px',
      right: '4px',
      display: 'block'
    }
  },
  formgroup: {
    marginBottom: '63px',
    maxWidth: '600px',
    margin: '0 auto',
    [theme.breakpoints.down('1030')]: {
      maxWidth: '450px'
    },
    [theme.breakpoints.down('680')]: {
      marginBottom: '40px'
    }
  },
  grid: {
    marginBottom: '43px',
    [theme.breakpoints.down('680')]: {
      marginBottom: '20px'
    }
  },
  buttonForm: {
    marginTop: '36px',
    marginBottom: '38px',
    display: 'block',
    width: '100%',
    padding: '16.5px 0',
    background: '#fff',
    borderRadius: '10px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '24px',
    // color: '#FFFFFF',
    margin: '0 auto',
    outline: 'none',
    cursor: 'pointer',
    border: '1px solid #BDBDBD',
    // borderRadius: '10px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#156981',
    transition: 'background 0.4s',
    [theme.breakpoints.down('680')]: {
      marginTop: '20px',
      marginBottom: '40px'
    },
    '&:hover': {
      backgroundColor: '#cce5e7'
    }
  },
  submit: {
    display: 'block',
    height: '39px',
    width: '141px',
    background: '#156981',
    borderRadius: '10px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '21px',
    lineHeight: '27px',
    color: '#FFFFFF',
    margin: '0 auto',
    marginTop: '36px',
    outline: 'none',
    cursor: 'pointer',
    border: '1px solid #BDBDBD',
    // borderRadius: '10px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    // color: '#fff',
    transition: 'background 0.4s',
    '&:hover': {
      backgroundColor: '#52a3bb'
    }
  },
  titleBlock: {
    marginBottom: '30px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '30px',
    color: '#000000',
    textAlign: 'left',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('680')]: {
      marginBottom: '20px',
      fontSize: '24px',
      lineHeight: '24px'
    }
  },
  textarea: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    height: '161px',
    padding: '8px 22px 8px 14px',
    background: 'rgba(51, 51, 51, 0.06)',
    borderRadius: '4px 4px 0px 0px',
    border: 'none',
    borderBottom: 'solid 2px #00BCD4',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#4F4F4F',
    resize: 'none',
    outline: 'none'
  },
  form: {
    width: '100%'
  },
  imgWrap: {
    maxWidth: '73px',
    maxHeight: '73px',
    order: '0',
    '& > img': {
      width: '100%'
    },
    [theme.breakpoints.down('680')]: {
      maxWidth: '62px',
      maxHeight: '62px'
    }
  },
  desc: {
    width: '416px',
    padding: '22px 39px 45px 26px',
    border: '1px solid #BDBDBD',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#000000',
    [theme.breakpoints.down('680')]: {
      width: '370px'
    }
  },
  descTitle: {
    marginBottom: '50px',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '24px'
  },
  priceItem: {
    marginBottom: '22px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  priceItemPromo: {
    marginTop: '40px',
    color: '#EB5757',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  priceItemTotal: {
    marginTop: '40px',
    fontWeight: '600',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapBlocks: {
    marginBottom: '40px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('1030')]: {
      flexDirection: 'column',
      maxWidth: '440px',
      margin: '0 auto',
      alignItems: 'center',
      marginBottom: '40px'
    }
  },
  notesWrap: {
    maxWidth: '600px',
    margin: '0 auto'
  },
  calendar: {
    marginBottom: '20px'
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  },
  container: {
    minHeight: '120vh',
    alignItems: 'center',
    justifyContent: 'center',
    left: "0",
    bottom: "0",
  },
  // root: {
  //   padding: theme.spacing(1),
  //   marginTop: '100px',
  //   color: '#5c5c5c',
  //   fontSize: '2.5rem',
  //   lineHeight: '2.5rem',
  //   textAlign: 'left'
  // },
  paper: {
    padding: theme.spacing(2)
  },
  info: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '200',
    fontSize: '32px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  alert: {
    fontSize: '20px'
  },
  root_: {
    flexGrow: 1,
    padding: '0 18px',
    marginTop: '10px',
    background: '#383F45',
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  copyright: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '25px',
    color: '#000000',
    [theme.breakpoints.down('550')]: {
      fontSize: '14px',
      lineHeight: '17.7px'
    }
  },
  linkList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  link: {
    marginRight: '19px',
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '25px',
    color: '#FFFFF6',
    [theme.breakpoints.down('550')]: {
      fontSize: '14px',
      lineHeight: '17.7px'
    },
    '&:last-child': {
      marginRight: 0
    }
  }
}));

function PetProvider(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const redirect_signup = (e) => {
    const bool = true;
    localStorage.setItem('user_from_booking', bool);
    window.location.href = '/signup';
  };

  const redirect_login = (e) => {
    const bool = true;
    localStorage.setItem('user_from_booking', bool);
    window.location.href = '/login';
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <Fragment>
      <div>
        <GuestNavBar />
      </div>
      <Container className={classes.container} disableGutters="true">
        <div className={classes.root}>
          <span className={classes.info}>
            {`Welcome, `}{' '}
            <span onClick={redirect_login}>
              <Link>Sign in or Register</Link>
            </span>{' '}
            {` to continue with your booking process.`}
          </span>
          <br />
          <br />
          <Alert severity="info" icon={false}>
            <AlertTitle>
              {' '}
              <span className={classes.alert}>
                {'Do you work with pets or know any people that do? '}{' '}
              </span>
              <Link
                variant="h6"
                className={classes.alert}
                color="primary"
                onClick={redirect_signup}
              >
                Join us
              </Link>{' '}
              <span className={classes.alert}>{' or '} </span>
              <Link variant="h6" className={classes.alert} color="primary" onClick={handleClick}>
                {' '}
                Invite{' '}
              </Link>
              <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper className={classes.paper}>
                  <Share />
                </Paper>
              </Popper>
              <span className={classes.alert}>{' people who work with pets '} </span>
            </AlertTitle>
          </Alert>
        </div>
      </Container>
      {/*<Footer/>*/}
      <div className={classes.root_}>
        {/* <h3 className={classes.copyright}>Copyright HPV</h3> */}
        <div className={classes.linkList}>
          <a href="/about" className={classes.link}>
            About
          </a>
          <a href="/privacy" className={classes.link}>
            Privacy policy
          </a>
          <a href="/terms" className={classes.link}>
            Terms of use
          </a>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  availability: state.forms.availability_data,
  details: state.results.petproviderdetails,
  payment_methods: state.services.payment_methods
});

export default connect(mapStateToProps, { get_availability_data, clear_aval, getproviderdetails })(
  PetProvider
);
