import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: '23px',
    color: "#222222",
  },
  title_add: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: '18px',
    color: "#222222",
  },
  pos: {
    marginBottom: 6,
  },
});

export default function ServiceCardDetails({ service, has_additional_rates }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.title}>
          {service?.service?.name}
        </div>
        <Typography className={classes.pos} color="textSecondary">
          <span
            style={{
              fontSize: "16px",
              fontFamily: "Dosis",
              fontWeight: "bold",
              color: "#23286G",
            }}
          >
            Price:{" "}
          </span>{" "}
          ${service?.price} / {service?.rate.name}
        </Typography>
        
        {has_additional_rates ? 
          <div className={classes.title_add}>
           {"Additional rates"}

           {service?.additional_rates?.map((additional_rate, idx) => (
	         <>
	            <Typography className={classes.pos} color="textSecondary">
		          <span
		            style={{
		              fontSize: "16px",
		              fontFamily: "Dosis",
		              fontWeight: "bold",
		              color: "#23286G",
		            }}
		          >
		            Rate:{" "}
		          </span>{" "}
		          {additional_rate?.rate_type?.name} 
		        </Typography>
		        <Typography className={classes.pos} color="textSecondary">
		          <span
		            style={{
		              fontSize: "16px",
		              fontFamily: "Dosis",
		              fontWeight: "bold",
		              color: "#23286G",
		            }}
		          >
		            Price:{" "}
		          </span>{" "}
		          ${additional_rate?.price} 
		        </Typography>
		     </>
            ))}
          </div>: null
        }
        
      </CardContent>
    </Card>
  );
}
