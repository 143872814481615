import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NotFound from '../../assets/404.png';


import Avatarr from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
  wrap: {
    minHeight: '310px'
    // margin: '0px 14px 17px',
    // padding: '15px',
    // // width: '98%',
    // border: '1px solid #E0E0E0',
    // borderRadius: '10px',
    // display: 'flex',
    // alignItems: 'flex-start',
    // [theme.breakpoints.down("1150")]: {
    //   flexWrap: 'wrap',
    //   height: '174px',
    //   padding: '25px 15px 15px 15px',
    // },
    // [theme.breakpoints.down("700")]: {
    //   flexWrap: 'wrap',
    //   height: '174px',
    //   padding: '15px 15px 15px 15px',
    // },
  },

  avatar: {
    padding: '0 10px',
    // maxWidth: '125px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('700')]: {
      maxWidth: '70px',
      padding: '0 0px'
    }
  },
  imgWrap: {
    //  maxWidth: '98px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '70px',
      padding: '0 0px'
    }
  },
  personName: {
    marginTop: '10px',
    marginLeft: '15px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#000000',
    [theme.breakpoints.down('700')]: {
      fontSize: '12px',
      marginTop: '0px'
    }
  },
  info: {
    width: '142px',
    marginLeft: '51px',
    [theme.breakpoints.down('1150')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('700')]: {
      width: '200px',
      marginLeft: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('450')]: {
      width: '183px'
      // marginLeft: '10px',
    }
  },
  infoItem: {
    marginBottom: '13px',
    '& > p': {
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#383F45'
    }
  },
  infoText: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#88CCDD'
  },
  skills: {
    MaxWidth: '580px',
    width: '100%',
    marginLeft: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    [theme.breakpoints.down('1150')]: {
      width: '400px',
      marginLeft: 'auto'
    },
    [theme.breakpoints.down('1100')]: {
      width: '290px'
    },
    [theme.breakpoints.down('1000')]: {
      width: '260px'
    },
    [theme.breakpoints.down('960')]: {
      width: '51%'
    },
    [theme.breakpoints.down('700')]: {
      width: '100%',
      marginLeft: '0px',
      marginTop: '8px',
      marginBottom: '8px'
    },
    [theme.breakpoints.up('700')]: {
      justifyContent: 'flex-end'
    }
  },
  skillsItem: {
    marginRight: '10px',
    marginTop: '10px',
    padding: '10px 0',
    textAlign: 'center',
    width: 'calc((100% - 52px) / 3)',
    height: '65px',
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: '10px',
    '&:nth-child(3)': {
      marginRight: '0',
      marginTop: '0',
      [theme.breakpoints.down('700')]: {
        marginTop: '0',
        marginRight: '10px'
      }
    },
    '&:nth-child(4n)': {
      [theme.breakpoints.down('700')]: {
        marginRight: '0px'
      }
    },
    '&:nth-child(4)': {
      [theme.breakpoints.down('700')]: {
        marginTop: '0'
      }
    },
    '&:nth-child(1)': {
      marginTop: '0'
    },
    '&:nth-child(2)': {
      marginTop: '0'
    },
    '&:nth-child(3n)': {
      [theme.breakpoints.up('700')]: {
        marginRight: '0'
      }
    },
    [theme.breakpoints.down('1150')]: {
      width: 'calc((100% - 52px) / 3)'
    },
    [theme.breakpoints.down('1050')]: {
      width: 'calc((100% - 20px) / 3)'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: 'calc((100% - 30px) / 4)'
    }
  },
  skillsName: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#1B5E20'
  },
  skillsPrice: {
    fontSize: '14px',
    '& > span': {
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#000000'
    }
  },
  button: {
    marginLeft: '47px',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('1150')]: {
      marginLeft: '0px'
    },
    [theme.breakpoints.down('1150')]: {
      marginLeft: 'auto'
    },
    '& > button': {
      width: '124px',
      height: '38px',
      background: '#156981',
      borderRadius: '10px',
      color: 'white',
      border: 'none',
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '23px',
      textAlign: 'center',
      cursor: 'pointer',
      transition: 'background 0.4s',
      [theme.breakpoints.down('700')]: {
        fontSize: '14px',
        width: '104px',
        height: '24px'
      },
      [theme.breakpoints.up('700')]: {
        marginTop: '7px'
      },
      [theme.breakpoints.up('1150')]: {
        marginTop: '20px'
      },
      '&:hover': {
        backgroundColor: '#88CCDD'
      }
    }
  },
  star: {
    MuiSvgIconRoot: {
      marginTop: '200px'
    },
    MuiSvgIcon: {
      root: {
        marginTop: '200px'
      }
    },
    // background: 'red',
    '& > .MuiSvgIcon': {
      width: '9px'
    },
    '& > .MuiSvgIcon-root': {
      width: '9px'
    }
  },
  imageSelector: {
    fontSize: '8rem',
    width: theme.spacing(55),
    height: theme.spacing(20),
    paddingLeft: theme.spacing(0)
  }
}));

export default function Card(props) {
  const classes = useStyles();

  // // const [rating, setRating] = useState(0)
  // console.log(props.data)

  // const openProvider = (e) => {
  //   // props.set_current_profile_id(props.id)
  //   localStorage.setItem('profile_id', props.data.id);
  //   window.location.href = `/pet_provider`;
  // };

  return (
    <div className={classes.wrap}>
      <div className={classes.avatar}>
        <div className={classes.imgWrap}>
          {/*<img src={props.data.photo} alt=""/>*/}
          <Avatarr
            className={classes.imageSelector}
            variant="square"
            alt="profile picture"
            src={NotFound}
          />
          <h2 className={classes.personName}>{/*props.data.city*/}</h2>
        </div>
      </div>
      <div className={classes.info}>
        {/* <div className={classes.infoItem}>
              <div className={classes.infoText}>Raiting</div>
              <Rating
                size="small"
                classes={{ root: classes.star }}
                name="customized-empty"
                defaultValue={2}
                precision={1}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
            </div>*/}

        {/* <div className={classes.infoItem}>
              <div className={classes.infoText}>Located</div>
              <p>Distance</p>
            </div>*/}

        {/* <div className={classes.infoItem}>
              <div className={classes.infoText}>Distance</div>
              <p>{Math.floor((Math.random()*10) + 1)} miles away</p>
            </div>*/}
      </div>

      {/*<div className={classes.skills}>
           
          </div>*/}

      {/*  <div className={classes.button}>
            <button onClick={openProvider} type="button">DETAILS</button>
          </div>*/}
    </div>
  );
}
