import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-image-crop/dist/ReactCrop.css";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginBottom: "10px",
    display: "flex",
    maxWidth: "120px",
    maxHeight: "35px",
    minWidth: "120px",
    minHeight: "35px",
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "400",
    // marginBottom: theme.spacing(2)
  },
  buttonDiv: {
    display: "flex",
  },
}));

export default function DialogConfirmation(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>Price Confirmation</DialogTitle>

        <DialogContent>
          <div>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Are you sure your charging {props.price} dollars {props.service}
            </FormLabel>
          </div>

          <div className={classes.buttonDiv}>
            <Button onClick={props.confirmed} color="primary">
              Yes
            </Button>
            <Button onClick={props.not_confirmed} color="primary">
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
