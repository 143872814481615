import React from "react";
// import Gallery from "react-grid-gallery";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  image: {
    height: "100%",
    width: "100%",
    borderRadius: 10
  },
  gridList: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  image_container: {
    height: "40%",
    width: "40%",
  }
}));


const ProviderGalleryView = (props) => {
  const classes = useStyles();
  return (
      <div>
        <div className={classes.gridList}>
          {props.images.slice(0, 4).map((tile) => (
            <div className={classes.image_container}  key={tile.id}>
              <img className={classes.image} src={tile.src} alt={tile.src} />
            </div>
          ))}
        </div>
      </div>
  );
}

export default ProviderGalleryView
