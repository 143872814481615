/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import { Grid, Typography, IconButton } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import FolderIcon from '@material-ui/icons/Folder';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch } from 'react-redux';
import {
  sendUserData,
  user_editing,
  sendUserData_photo,
  reset_save_signal,
  user_missing_info,
  user_missing_info_turn_off,
  clear_error
} from '../actions/form.js';
import { loadUser } from '../actions/auth.js';
// import PicUploadCrop from "./PicUploadCrop.js";
import Dialog from './Dialog.js';


import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

const theme = createTheme({
  overrides: {
    MuiPickersClock: {
      pin: {
        backgroundColor: '#3a9643'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#3a9643'
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#3a9643',
        thumb: {
          backgroundColor: '#3a9643'
        },
        '& > div': {
          backgroundColor: '#3a9643',
          border: '14px solid #3a9643'
        }
      },
      thumb: {
        backgroundColor: '#3a9643'
      },
      noPoint: {
        backgroundColor: '#3a9643'
      }
    },
    MuiFormHelperText: {
      root: {
        position: 'apsolute'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3a9643'
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  stepper: {
    width: '100%',
    margin: theme.spacing(8, 'auto', 4),
    paddingLeft: '0',
    paddingRight: '0'
  },
  input: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSelect: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputBio: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  radioLabel: {
    '& span': {
      fontSize: '13px!important',
      color: '#1b1f23'
    }
  },
  wizardContainer: {
    margin: theme.spacing(2, 'auto', 8)
  },
  form: {
    margin: 'auto',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  formHeader: {
    margin: theme.spacing(2, 'auto', 4),
    textAlign: 'center'
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  },
  formLabelPetPrefs: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    margin: theme.spacing(1, 0)
  },
  formLabelPetExperience: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    lineHeight: '2.5em'
  },
  formGroupLabel: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    marginBottom: theme.spacing(2)
  },
  formGroupLabelPetPrefs: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    marginBottom: theme.spacing(4)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(3),
    width: '120%'
  },
  imageSelector: {
    fontSize: '8rem',
    width: theme.spacing(20),
    height: theme.spacing(20),
    paddingLeft: theme.spacing(-2)
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  small: {
    margin: theme.spacing(1, 'auto', 0),
    display: 'block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: '#663399!important'
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: 'auto'
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#663399!important',
    marginRight: 'auto'
  },
  buttonSection: {
    margin: 'auto',
    float: 'right',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  fieldSetRadio: {
    marginLeft: theme.spacing(1)
  },
  adornment: {
    '& p': {
      fontSize: '13px'
    }
  },
  root_: {
    margin: theme.spacing(2, 0),
  },
  line: {
    textAlign: 'center',
    backgroundColor: '#fafafa',
    width: '100%',
    borderRadius: '3px',
    border: '1px solid #cfd7de',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    '& > label': {
      paddingLeft: theme.spacing(2)
    }
  },
  underline: {
    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    }
  },
  characters: {
    fontSize: '11px',
    color: 'grey'
  }
}));

function DashboardProfilePicSectionWizard(props) {
  const classes = useStyles();

  const { user } = props;
  const user_id = user.id;

  const [photo, setPhoto] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = React.useState(true);

  const upload = (e) => {
    document.getElementById('selectImage').click();
    // setInitiate(true)
  };
  const upload_new = (e) => {
    setUpdating(true);
    // setInitiate(true)
    document.getElementById('selectImage').click();
  };

  const setting = (image) => {
    // console.log("Image")
    // console.log(image)
    if (image) {
      setUpdating(true);
      setPhoto(image);
    }
  };

  React.useEffect(() => {
    if (photo !== null) {
      const uploadData = new FormData();
      uploadData.append('photo', photo, photo.name);
      console.log(uploadData);

      const timer = sendReq_(uploadData, user_id);
      return () => clearTimeout(timer);
      function sendReq_(object, user_id) {
        props.sendUserData_photo(object, user_id);
      }
    }
  }, [photo]);

  // data
  const [edited, setEdited] = useState(false);
  const [required_inputs, setAllrequired] = useState(false);
  const [tagline, setTaglin] = useState(props.user.tagline);
  const [bio, setBio] = useState(props.user.bio);
  const [dob, setDob] = useState(props.user.date_of_birth);
  const [first, setFirstName] = useState(props.user.first_name);
  const [last, setLastName] = useState(props.user.last_name);

  const [selectedDate, setSelectedDate] = useState(
    props.user.date_of_birth === null ? new Date() : moment(props.user.date_of_birth, 'YYYY-MM-DD').toDate()
  );
  const [error_dob, setError_dob] = useState(false);
  const [error_bio_length, setBioLengthError] = useState(false);
  const [bio_length, setLengthBio] = useState(props.user.bio !== null ? props.user.bio.length : 0);
  const [error_tag_length, setTagLengthError] = useState(false);
  const [tag_length, setLengthTagline] = useState(props.user.tagline !== null ? props.user.tagline.length : 0);

  const handleDateChange = (date) => {
    setAllrequired(false)
    setSelectedDate(date);
    let dt = new Date(date);
    let date_Obj = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    var obj = {
      'Date/Time': `${date}`
    };

    const test_date = moment(obj['Date/Time']).format('YYYY-MM-DD');
    var time = moment(test_date).fromNow();
    setDob(test_date);
    const boole_an = true;
    localStorage.setItem(`dob_edit_${props.user.id}`, boole_an);
    setEdited(true);
  };

  const dispatch = useDispatch();
  
  React.useEffect(() => {
    if(dob === null || dob === "" || 
        first === null || first === "" || 
        last === null || last === "" ||  
        tagline === null || tagline === "" ||  
        bio === null || bio === "" 
      ){
        props.user_missing_info();
    }else{
      props.user_missing_info_turn_off()
    }

    if (first && last) {
      if (props.form.signal_save === true) {
         if (dob === null || dob === undefined || dob === '' || dob === 'Invalid date') {
            setError_dob(true);
            props.reset_save_signal(); // setting forms.signal_save boolean to false
            return;
          } else {
            if (dob && first && last && tagline && bio && error_bio_length === false) {
              setAllrequired(false)
              // props.user_missing_info_turn_off();
              const object = {
                tagline: tagline,
                bio: bio,
                date_of_birth: dob,
                first_name: first,
                last_name: last
              };
              props.sendUserData(object, user_id);
              props.loadUser();
              props.loadUser();
            } else {
              setAllrequired(true)
              props.reset_save_signal();
              props.user_missing_info();
            }
          }
      }
    }else{
      setAllrequired(true)
      props.reset_save_signal();
    }

    // if (props.form.signal_save === true) {
    //   if (dob === null || dob === undefined || dob === '' || dob === 'Invalid date') {
    //     setError_dob(true);
    //     props.reset_save_signal(); // setting forms.signal_save boolean to false
    //     return;
    //   } else {
    //     if (dob && first && last && tagline && bio && error_bio_length === false) {
    //       setAllrequired(false)
    //       const object = {
    //         tagline: tagline,
    //         bio: bio,
    //         date_of_birth: dob,
    //         first_name: first,
    //         last_name: last
    //       };
    //       props.sendUserData(object, user_id);
    //       props.loadUser();
    //       props.loadUser();
    //     } else {
    //       setAllrequired(true)
    //       props.reset_save_signal();
    //     }
    //   }
    // }

  }, [tagline, first, last, bio, dob, props.form.signal_save]);

  React.useEffect(() => {
    if (edited === true) {
      if (props.form.user_editing === false) {
        // avoiding multiple dispatches when boolean is already true
        props.user_editing();
      } else {
        setEdited(false);
      }
    }
  }, [props.form.user_editing, edited]);

  function sendReq(data, user_id) {
    const timer = setTimeout(() => {
      dispatch(sendUserData(data, user_id));
    }, 500);

    return timer;
  }

  React.useEffect(() => {
    props.loadUser();
    props.loadUser();

    const state_dob = localStorage.getItem(`dob_edit_${props.user.id}`);
    if (state_dob === null || state_dob === undefined) {
      const bool = false;
      localStorage.setItem(`dob_edit_${props.user.id}`, bool);
    }
  }, []);
  
  let alert;
  if (props?.error?.msg?.date_of_birth) {
    let msg = props.error.msg.date_of_birth;
    // console.log(msg);
    alert = <div className="alerts">{msg}</div>;
  }

  return (
    <div>
      <FormGroup className={classes.formGroupProfileSection}>
        <FormLabel component="label" className={classes.formGroupLabel}>
          Your Profile Picture
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} className={classes.selector}>
            <FormLabel component="label" className={classes.formLabel}>
              Select a picture
            </FormLabel>

            <div className={classes.root_}>
              {props.user.photo ? (
                updating === false ? (
                  <div>
                    <Avatar
                      className={classes.imageSelector}
                      variant="circle"
                      alt="profile picture"
                      src={props.user.photo}
                    />
                    {/*<Button onClick={upload_new} className={classes.small} color="primary">
                                       change
                                    </Button>*/}
                  </div>
                ) : (
                  <div>
                    {photo ? (
                      <div>
                        <Avatar
                          className={classes.imageSelector}
                          variant="circle"
                          alt="profile picture"
                          src={URL.createObjectURL(photo)}
                        />
                        {/*<Button onClick={upload_new} className={classes.small} color="primary">
                                          change
                                        </Button>*/}
                      </div>
                    ) : (
                      <div>
                        <Skeleton variant="circle" className={classes.imageSelector} />
                        {/*<Button disabled onClick={upload_new} className={classes.small} color="primary">
                                           change
                                        </Button>*/}
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div>
                  {photo ? (
                    <div>
                      <Avatar
                        className={classes.imageSelector}
                        variant="circle"
                        alt="profile picture"
                        src={URL.createObjectURL(photo)}
                      />
                      <div>
                        {/*<Button  onClick={upload_new} className={classes.small} color="primary">
                                          change
                                        </Button>*/}
                      </div>
                    </div>
                  ) : (
                    <Avatar
                      className={classes.imageSelector}
                      variant="circle"
                      // onClick={upload}
                    >
                      <FolderIcon /> 
                      {/*<AddIcon />*/}
                    </Avatar>
                  )}
                </div>
              )}
            </div>

            <Dialog 
              setting={setting} 
              send_save_signal={() => {}} 
            />

            {/*<input 
                type="file"
                id='selectImage' 
                hidden
                onChange={(evt) => setPhoto(evt.target.files[0])}
            />*/}
          </Grid>

          <Grid item xs={12} sm={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Input
                  fullWidth
                  disableUnderline
                  id="firstname"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setEdited(true);
                    setAllrequired(false)
                  }}
                  label=""
                  placeholder="First Name *"
                  defaultValue={props.user.first_name}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  fullWidth
                  disableUnderline
                  id="lastname"
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setEdited(true);
                    setAllrequired(false)
                  }}
                  label=""
                  placeholder="Last Name *"
                  defaultValue={props.user.last_name}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  disableUnderline
                  id="tagline"
                  onChange={(e) => {
                    if(e?.target?.value?.length <= 100){
                      setTaglin(e.target.value);
                      setEdited(true);
                      setTagLengthError(false)
                      setAllrequired(false)
                      setLengthTagline(e?.target?.value?.length)
                    }else{
                       setTagLengthError(true)
                    }
                  }}
                  label="Tagline *"
                  placeholder="Write a short tagline about yourself *"
                  defaultValue={props.user.tagline}
                  className={classes.input}
                />
                {error_tag_length ? (
                    <FormHelperText error>Tagline must not exceed 100 characters.</FormHelperText>
                ): null}
               <span className={classes.characters} >{tag_length}/100</span>
              {props?.error?.msg?.tagline ? (
                  <Grid style={{ marginTop: '10px' }} item xs={12}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            props.clear_error();
                            setOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div className={classes.message}>{props?.error?.msg?.tagline}</div>
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Input
                  disableUnderline
                  fullWidth
                  onChange={(e) => {
                    if(e?.target?.value?.length <= 160){
                      setBioLengthError(false)
                      setBio(e.target.value);
                      setEdited(true);
                      setAllrequired(false)
                      setLengthBio(e?.target?.value?.length)
                    }else{
                       setBioLengthError(true)
                    }
                  }}
                  multiline
                  rows={4}
                  id="about"
                  label="Bio"
                  placeholder="Tell us about yourself *"
                  defaultValue={props.user.bio}
                  className={classes.input}
                />
                {error_bio_length ? (
                    <FormHelperText error>Bio must not exceed 160 characters.</FormHelperText>
                  ): null}
                <span className={classes.characters} >{bio_length}/160</span>
                {props?.error?.msg?.bio ? (
                  <Grid style={{ marginTop: '10px' }} item xs={12}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            props.clear_error();
                            setOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div className={classes.message}>{props?.error?.msg?.bio}</div>
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Typography style={{ fontWeight: 'bold', fontSize: '20px' }} >Inside the date time picker, click on the year to scroll to your birth year.</Typography>
                  <FormLabel component="label" className={classes.formLabel}>
                    Date of Birth
                  </FormLabel>
                  <ThemeProvider theme={theme}>
                    <KeyboardDatePicker
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                      id="date-picker-dialog"
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      //defaultValue={}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </ThemeProvider>
                  {error_dob ? (
                    <FormHelperText error>Date of birth is required.</FormHelperText>
                  ): null}
                  {required_inputs ? (
                    <FormHelperText error>Please provide all the information above to continue to the next step.</FormHelperText>
                  ) : (
                    ''
                  )}
                </MuiPickersUtilsProvider>
                {props?.error?.msg?.date_of_birth ? (
                  <Grid style={{ marginTop: '10px' }} item xs={12}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            props.clear_error();
                            setOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div className={classes.message}>{props.error.msg.date_of_birth}</div>
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services
});
export default connect(mapStateToProps, {
  sendUserData,
  loadUser,
  user_editing,
  sendUserData_photo,
  reset_save_signal,
  user_missing_info,
  user_missing_info_turn_off,
  clear_error
})(DashboardProfilePicSectionWizard);