import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import Container from '@material-ui/core/Container';
import { Box, Grid, Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  stepper: {
    width: '100%',
    margin: theme.spacing(8, 'auto', 4),
    paddingLeft: '0',
    paddingRight: '0'
  },
  input: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSelect: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputBio: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  radioLabel: {
    '& span': {
      fontSize: '13px!important',
      color: '#1b1f23'
    }
  },
  wizardContainer: {
    margin: theme.spacing(4, 'auto', 12)
  },
  form: {
    margin: 'auto',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  formHeader: {
    margin: theme.spacing(2, 'auto', 4),
    textAlign: 'center'
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  },
  formLabelPetPrefs: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    margin: theme.spacing(1, 0)
  },
  formLabelPetExperience: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    lineHeight: '2.5em'
  },
  formGroupLabel: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    marginBottom: theme.spacing(2)
  },
  formGroupLabelPetPrefs: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    marginBottom: theme.spacing(4)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  gridGroup: {
    margin: theme.spacing(8, 0, 3),
    padding: '0'
  },
  formGroupProfileSection: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6)
  },
  imageSelector: {
    fontSize: '8rem',
    paddingLeft: theme.spacing(0)
  },
  selector: {
    paddingLeft: theme.spacing(0)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: '#663399!important'
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: 'auto'
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#663399!important',
    marginRight: 'auto'
  },
  buttonSection: {
    margin: 'auto',
    float: 'right',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  fieldSetRadio: {
    marginLeft: theme.spacing(1)
  },
  adornment: {
    '& p': {
      fontSize: '13px'
    }
  }
}));

function ContactPetProvider(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm" className={classes.wizardContainer}>
        <Typography variant="h5" className={classes.formHeader}>
          Contact Pet Provider
        </Typography>
        <form noValidate className={classes.form}>
          <DateSection />
          <PetInfo />
        </form>

        <Button
          variant="contained"
          color="primary"
          //onClick={handleNext}
          className={classes.buttonNext}
        >
          {' '}
          Book Now
        </Button>
        <Typography variant="body2">
          Don’t worry, we won’t charge you until the sitter confirms the booking. You can cancel
          anytime up to 12:00pm the day before the booking begins for a full refund.
        </Typography>
      </Container>
    </Box>
  );
}

function DateSection(props) {
  const classes = useStyles();

  return (
    <div>
      <FormGroup className={classes.formGroup}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Date of drop off
            </FormLabel>
            <Input
              disableUnderline
              type="date"
              fullWidth
              placeholder="Date of drop off"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Time
            </FormLabel>
            <Input
              disableUnderline
              type="time"
              defaultValue="07:00"
              fullWidth
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Date of pick up
            </FormLabel>
            <Input
              disableUnderline
              type="date"
              fullWidth
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Time
            </FormLabel>
            <Input
              disableUnderline
              type="time"
              defaultValue="07:00"
              fullWidth
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
}

function PetInfo(props) {
  const classes = useStyles();

  return (
    <div>
      <FormGroup className={classes.gridGroup}>
        <FormLabel component="label" className={classes.formGroupLabelPetPrefs}>
          Tell us about your pet
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Select Pet
            </FormLabel>
            <Select
              //onChange={(e) => setPet_type(e.target.value)}
              disableUnderline
              displayEmpty
              value="Dog"
              className={classes.inputSelect}
              inputProps={{
                'aria-label': 'Select Pet'
              }}
            >
              {/* {props.admin_pets.map((p, i) => (
                                <MenuItem value={p.name} key={i}>
                                {p.name}
                                </MenuItem>
                            ))} */}
              <MenuItem value="Dog">Dog</MenuItem>
              <MenuItem value="Dog">Cat</MenuItem>
              <MenuItem value="Dog">Bird</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Name
            </FormLabel>
            <Input
              disableUnderline
              fullWidth
              placeholder="Name"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel component="label" className={classes.formLabel}>
              Age
            </FormLabel>
            <Input
              disableUnderline
              fullWidth
              type="number"
              placeholder="Age"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel component="label" className={classes.formLabel}>
              Colour
            </FormLabel>
            <Input
              disableUnderline
              fullWidth
              placeholder="Colour"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel component="label" className={classes.formLabel}>
              Size
            </FormLabel>
            <Input
              disableUnderline
              fullWidth
              type="number"
              placeholder="Size"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Breed
            </FormLabel>
            <Input
              disableUnderline
              fullWidth
              placeholder="Breed"
              inputProps={{ 'aria-label': 'description' }}
              className={classes.inputSmall}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="label" className={classes.formLabel}>
              Gender
            </FormLabel>
            <Select
              //onChange={(e) => setPet_type(e.target.value)}
              disableUnderline
              displayEmpty
              value="Female"
              className={classes.inputSelect}
              inputProps={{
                'aria-label': 'Select Pet'
              }}
            >
              {/* {props.admin_pets.map((p, i) => (
                                <MenuItem value={p.name} key={i}>
                                {p.name}
                                </MenuItem>
                            ))} */}
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="male">Male</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container className={classes.gridGroup}>
          <Grid item xs={12} className={classes.selector}>
            <FormGroup className={classes.formGroup}>
              <FormLabel component="label" className={classes.formLabel}>
                Description
              </FormLabel>
              <Input
                disableUnderline
                fullWidth
                placeholder="This can be your apartment number"
                inputProps={{ 'aria-label': 'description' }}
                className={classes.input}
                multiline
                rows={4}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
}


const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  all_services: state.services.admin_services
});
export default connect(mapStateToProps, null)(ContactPetProvider);
