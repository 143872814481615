import React from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

import { styled } from "@material-ui/core/styles";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GuestNavBar from "../components/GuestNavBar";
import { Box } from "@material-ui/core";

import Footer from "../components/Footer";
import PrimarySearchAppBar from "../components/AuthorizedUserHomepageNavbar";

import Spinner from "../assets/home_load.gif";
import Footprint from "../assets/footprint.svg";

import { get_blog_posts } from "../actions/blog";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    paddingTop: "43px",
    paddingBottom: "59px",
    fontFamily: "Dosis",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
    background: "#fff",
    position: "relative",
    zIndex: "2",
    [theme.breakpoints.down("650")]: {
      paddingTop: "27px",
      paddingBottom: "40px",
    },
  },
  image: {
    margin: theme.spacing(1),
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  paper: {
    margin: theme.spacing(1),
  },
  wizardContainer: {
    margin: theme.spacing(12, "auto", 2),
  },
  title: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "520",
    fontSize: "46px",
    lineHeight: "58px",
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  title_small: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "320",
    fontSize: "20px",
    // lineHeight: '58px',
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  teamSection: {
    margin: theme.spacing(3, 0, 1),
  },
  descriptions: {
    textAlign: "center",
  },
  body: {
    // letterSpacing: '0.02em',
    // lineHeight: '2em',
    // marginBottom: theme.spacing(3),
    // fontSize: '1em',

    fontSize: "1.1em",
    lineHeight: "30px",
    //alignItems: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    fontWeight: "600",
    color: "#383F49",
    [theme.breakpoints.down("850")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down("650")]: {
      fontSize: "16px",
      lineHeight: "19.8px",
      maxWidth: "309px",
      margin: "0 auto",
    },
  },
  descriptions_: {
    fontFamily: "Averia Sans Libre",
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: "500",
    color: "#737373",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("850")]: {
      fontSize: "18px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("650")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
}));

function Tips(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    props.get_blog_posts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <div>
        <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
        <div>{!props.auth.token && <GuestNavBar />}</div>
      </div>

      <div className={classes.root}>
        <Container maxWidth="md" className={classes.wizardContainer}>
          <h2 className={classes.title}>Pet Tips</h2>
          <div>
          </div>
          {props.tips.isLoading === true ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                minHeight: "60vh",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "73px",
                background: "#fff",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    //bottom: '-11px',
                    // right: '-28%',
                    // zIndex: -1,
                    marginBottom: "41px",
                    marginRight: "31px",
                  }}
                  src={Footprint}
                  alt="Footprint"
                />
                <img src={Spinner} alt="Spinner" height="100px" width="100px" />
              </div>
            </div>
          ) : (
            <div>
              {props.tips.results !== null ? (
                <div>
                  {props.tips.results
                    .map((tip, i) => (
                      <div>
                        <Accordion
                          expanded={expanded === `panel${i}`}
                          onChange={handleChange(`panel${i}`)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{tip.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <Typography>{parse(tip.content)}</Typography>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                        <br />
                      </div>
                    ))
                    .reverse()}
                </div>
              ) : (
                <div>
                  <h2 className={classes.title_small}>
                    No Pet Tips Currently.
                  </h2>
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tips: state.blog,
});

export default connect(mapStateToProps, { get_blog_posts })(Tips);
