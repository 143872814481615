import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUser } from '../actions/results';

import PetProviderInfo from '../components/PetProviderInfo';
import { Box } from '@material-ui/core';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import Footer from '../components/Footer';
import Spinner from '../assets/home_load.gif';
import Footprint from '../assets/footprint.svg';
// import CryptoJS from 'crypto-js';


class SinglePetServiceProvider extends Component {

  componentDidMount() {
    const id = this.props?.match?.params?.id;
    localStorage.setItem('provider_view_details_id', id);
    // console.log()
    // console.log(id)
    // console.log(typeof id)
    // // Decode and decrypt the userID
    // const secretKey = '0j9$%)m46vym@vuov_@o1mby*4s6q3#q0+pb=9c3*!d0ocjdzt'
    // const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(id), secretKey);
    // const decryptedUserID = decryptedBytes.toString(CryptoJS.enc.Utf8);
    // console.log(decryptedUserID)
    // const user_id = decryptedUserID.toString()
    // console.log(user_id)
    // console.log(this.props?.match?.params?.id)

    this.props.getUser(id);
    localStorage.removeItem('user_from_booking');

  }

  render() {

    if(this.props.petproviderdetails){
      // console.log('this.props.petproviderdetails')
    }


    return (
      <Box className="provider-root">
        <div>{this.props.auth?.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
        <div>{!this.props.auth?.token ? <GuestNavBar /> : ''}</div>
        {this.props.petproviderdetails?.id ? (
          <PetProviderInfo
            first_name={this.props.petproviderdetails?.first_name}
            id={this.props.petproviderdetails?.id}
            user_id={this.props.petproviderdetails?.id}
            last_name={this.props.petproviderdetails?.last_name}
            avatar={this.props.petproviderdetails?.photo}
            address_line_1={this.props.petproviderdetails?.address_line_1}
            experience_years={this.props.petproviderdetails?.experience_years}
            experience_months={this.props.petproviderdetails?.experience_months}
            country={this.props.petproviderdetails?.country}
            images={this.props.petproviderdetails?.photos}
            photos_count={this.props.petproviderdetails?.photos_count}
            certificates={this.props.petproviderdetails?.certificates}
            state={this.props.petproviderdetails?.state}
            description={this.props.petproviderdetails?.bio}
            isMobile={localStorage.getItem("isMobile") === "true"}
            rating={this.props.petproviderdetails?.rating}
            tagline={this.props.petproviderdetails?.tagline}
            services={this.props.petproviderdetails?.services}
            background_check_status={this.props.petproviderdetails?.background_check_status}
            background_check_complete={this.props.petproviderdetails?.background_check_complete}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              minHeight: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '73px',
              background: '#fff'
            }}
          >
            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
              <img
                style={{
                  position: 'absolute',
                  marginBottom: '41px',
                  marginRight: '31px'
                }}
                src={Footprint}
                alt=""
              />
              <img src={Spinner} alt="" height="100px" width="100px" />
            </div>
          </div>
        )}
        <Footer />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  petproviderdetails: state.results.petproviderdetails
});

export default connect(mapStateToProps, { getUser })(withRouter(SinglePetServiceProvider));
