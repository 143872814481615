import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Toolbar,
  TodayButton,
  MonthView,
  DayView,
  WeekView,
  ViewSwitcher,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  EditRecurrenceMenu,
  AllDayPanel,
  DateNavigator
} from '@devexpress/dx-react-scheduler-material-ui';
import { connectProps } from '@devexpress/dx-react-core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Createappointment from '../components/Createappointment';
import Editappointment from '../components/Editappointment';
import AppointtmentForm from './AppointtmentForm';
import AppointtmentFormEdit from './AppointtmentFormEdit';

import { isEmpty } from 'lodash';

import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

const containerStyles = (theme) => ({
  container: {
    width: theme.spacing(68),
    padding: 0,
    paddingBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
    maxWidth: '80%',
    [theme.breakpoints.down('550')]: {
      maxWidth: '100%',
    },
  },
  header: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0.5),
    maxWidth: '80%',
    [theme.breakpoints.down('550')]: {
      maxWidth: '100%',
    },
  },
  closeButton: {
    float: 'right'
  },
  custom: {
    margin: '0',
    padding: '18px 16px 14px',
    maxWidth: '210px',
    minHeight: '56px',
    border: '1px solid rgba(25, 25, 25, 0.32)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('680')]: {
      marginBottom: '10px',
      maxWidth: '300px',
      width: '300px'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    },
    '& > .MuiPickersToolbar': {
      toolbar: 'none'
    }
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    maxWidth: '80%',
    [theme.breakpoints.down('550')]: {
      maxWidth: '100%',
    },
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  picker: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0
    },
    width: '50%'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0)
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2)
  },
  textField: {
    width: '100%'
  },
  toolbar: {
    marginTop: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(8)
  },

  input: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  }
});

class AppointmentFormContainerBasic extends React.PureComponent {
  constructor(props) {
    super(props);
    // const { appointmentData } = this.props;
    this.state = {
      appointmentChanges: {},
      start_time: '',
      start_time_: new Date(),
      end_time: '',
      notes: '',
      start_date: '',
      end_date: '',

      end_repeat_date: '',
      recurring: false,
      interval: 'none',

      start_date_edit: '',
      end_date_edit: '',
      start_time_edit: '',
      end_time_edit: '',
      interval_edit: '',
      recurring_edit: '',
      notes_edit: ''
    };

    this.getAppointmentData = () => {
      const { appointmentData } = this.props;
      return appointmentData;
    };
    this.getAppointmentChanges = () => {
      const { appointmentChanges } = this.state;
      return appointmentChanges;
    };

    this.changeAppointment = this.changeAppointment.bind(this);
    this.commitAppointment = this.commitAppointment.bind(this);
  }

  onChange = (field, value) => {
    // console.log(field)
    // console.log(value)
    this.setState({
      ...this.state,
      [field]: value
    });
  };

  onChange_test = (date) => {
    console.log(date);
  };

  // handleChangeST = (date) => {
  //     // function splitText(value, index){
  //     //   if (value.length < index) {return value;}
  //     //   return [value.substring(0, index)].concat(splitText(value.substring(index), index));
  //     // }
  //     // console.log(date)
  //     // console.log(new Date(date).toLocaleTimeString())
  //     // const test = splitText((new Date(date).toLocaleTimeString()), 5)[0];
  //     // console.log(test)
  //       this.setState({
  //         start_time : date,
  //         start_time_ : date
  //       })
  //     console.log(this.state.start_time)
  //   }

  onClicked = () => {
    // console.log(this.state.start_time)
    // console.log(this.state.end_time)
    // console.log(this.state.start_date)
    // console.log(this.state.end_date)
  };

  changeAppointment({ field, changes }) {
    const nextChanges = {
      ...this.getAppointmentChanges(),
      [field]: changes
    };
    this.setState({
      appointmentChanges: nextChanges
    });
  }

  commitAppointment(type) {
    const { commitChanges } = this.props;
    const appointment = {
      ...this.getAppointmentData(),
      ...this.getAppointmentChanges()
    };

    if (type === 'deleted') {
      commitChanges({ [type]: appointment.id });
    } else if (type === 'changed') {
      commitChanges({ [type]: { [appointment.id]: appointment } });
    } else {
      commitChanges({ [type]: appointment });
    }
    this.setState({
      appointmentChanges: {}
    });
  }

  render() {
    const {
      classes,
      visible,
      visibleChange,
      appointmentData,
      cancelAppointment,
      target,
      onHide,
      refresh_data
    } = this.props;
    // const { appointmentChanges } = this.state;

    // const displayAppointmentData = {
    //   ...appointmentData,
    //   ...appointmentChanges
    // };

    const isNewAppointment = appointmentData.id === undefined;
    // const applyChanges = isNewAppointment
    //   ? () => this.commitAppointment('added')
    //   : () => this.commitAppointment('changed');

    // const textEditorProps = (field) => ({
    //   variant: 'outlined',
    //   onChange: ({ target: change }) =>
    //     this.changeAppointment({
    //       field: [field],
    //       changes: change.value
    //     }),
    //   value: displayAppointmentData[field] || '',
    //   label: field[0].toUpperCase() + field.slice(1),
    //   className: classes.textField
    // });

    // const pickerEditorProps = (field) => ({
    //   className: classes.picker,
    //   // keyboard: true,
    //   ampm: false,
    //   value: displayAppointmentData[field],
    //   onChange: (date) =>
    //     this.changeAppointment({
    //       field: [field],
    //       changes: date ? date.toDate() : new Date(displayAppointmentData[field])
    //     }),
    //   inputVariant: 'outlined',
    //   format: 'HH:mm ',
    //   onError: () => null
    // });

    const cancelChanges = () => {
      this.setState({
        appointmentChanges: {}
      });
      visibleChange();
      cancelAppointment();
    };

    const fetch_again = () => {
      refresh_data();
    };

    const checking_state = isEmpty(appointmentData);
    if (
      checking_state === false &&
      appointmentData.title !== undefined &&
      appointmentData.location !== undefined
    ) {
      return (
        <AppointmentForm.Overlay visible={visible} target={target} fullSize onHide={onHide}>
          <div>
            <div className={classes.header}>
              <IconButton className={classes.closeButton} onClick={cancelChanges}>
                <Close color="action" />
              </IconButton>
            </div>
            <AppointtmentFormEdit
              current_start_time={new Date(appointmentData.startDate)}
              current_end_time={new Date(appointmentData.endDate)}
              current_start_date={new Date(appointmentData.startDate)}
              // current_end_date={new Date(appointmentData.endDate)}
              current_end_date={new Date(appointmentData.location.end)}
              current_notes={appointmentData.title}
              current_recurring={appointmentData.location.recurring}
              current_interval={appointmentData.location.interval}
              onChange={this.onChange}
            />

            <div className={classes.buttonGroup}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  visibleChange();
                  this.commitAppointment('deleted');
                }}
              >
                Delete
              </Button>

              <Editappointment
                start={appointmentData.startDate}
                end={appointmentData.location.recurring ? new Date(appointmentData.location.end) : appointmentData.endDate}
                // day_init   = {new Date(appointmentData.startDate).toLocaleDateString()}
                start_day_init={new Date(appointmentData.startDate)}
                end_day_init={appointmentData.location.recurring ? new Date(appointmentData.location.end) : new Date(appointmentData.endDate)}
                note={appointmentData.title}
                id={appointmentData.id}
                recurring_init={appointmentData.location.recurring}
                interval_init={appointmentData.location.interval}
                startime={this.state.start_time_edit}
                endtime={this.state.end_time_edit}
                startDate={this.state.start_date_edit}
                endDate={this.state.end_date_edit}
                recurring={this.state.recurring_edit}
                interval={this.state.interval_edit}
                // day={this.state.day_edit}
                notes={this.state.notes_edit}
                reload={fetch_again}
              />
            </div>
          </div>
        </AppointmentForm.Overlay>
      );
    } else {
      return (
        <AppointmentForm.Overlay visible={visible} target={target} fullSize onHide={onHide}>
          <div>
            <div className={classes.header}>
              <IconButton className={classes.closeButton} onClick={cancelChanges}>
                <Close color="action" />
              </IconButton>
            </div>

            {/*New date time pickers design...*/}
            <AppointtmentForm onChange={this.onChange} />

            <div className={classes.buttonGroup}>
              {!isNewAppointment && (
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    visibleChange();
                    this.commitAppointment('deleted');
                  }}
                >
                  Delete
                </Button>
              )}
              {/* <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={this.onClicked}
              >
                Test
              </Button>*/}
              <Createappointment
                startime={this.state.start_time}
                endtime={this.state.end_time}
                startdate={this.state.start_date}
                end_repeat_date={this.state.end_repeat_date}
                enddate={this.state.end_date}
                recurring={this.state.recurring}
                interval={this.state.interval}
                notes={this.state.notes}
                reload={fetch_again}
              />
              <br />
              <br />
            </div>
          </div>
        </AppointmentForm.Overlay>
      );
    }
  }
}

const AppointmentFormContainer = withStyles(containerStyles, { name: 'AppointmentFormContainer' })(
  AppointmentFormContainerBasic
);

const styles = (theme) => ({
  addButton: {
    position: 'absolute',
    bottom: theme.spacing(3) * 4,
    right: theme.spacing(1) * 4
  },
  paper: {
    marginTop: theme.spacing(8)
  }
});

/* eslint-disable-next-line react/no-multi-comp */
class Demo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      // data: appointments,
      currentDate: this.props.current,
      get_new_value: this.props.get_new,
      confirmationVisible: false,
      editingFormVisible: false,
      deletedAppointmentId: undefined,
      editingAppointment: undefined,
      previousAppointment: undefined,
      addedAppointment: {},
      startDayHour: 0,
      endDayHour: 24,
      interval: 2,
      isNewAppointment: false,
      reload: false
    };

    this.toggleConfirmationVisible = this.toggleConfirmationVisible.bind(this);
    this.commitDeletedAppointment = this.commitDeletedAppointment.bind(this);
    this.toggleEditingFormVisibility = this.toggleEditingFormVisibility.bind(this);

    this.commitChanges = this.commitChanges.bind(this);
    this.onEditingAppointmentChange = this.onEditingAppointmentChange.bind(this);
    this.onAddedAppointmentChange = this.onAddedAppointmentChange.bind(this);
    this.appointmentForm = connectProps(AppointmentFormContainer, () => {
      const {
        editingFormVisible,
        editingAppointment,
        data,
        addedAppointment,
        isNewAppointment,
        previousAppointment
      } = this.state;

      // console.log(editingAppointment)
      // console.log(data)
      // console.log("Here")

      const currentAppointment =
        data.filter(
          (appointment) => editingAppointment && appointment.id === editingAppointment.id
        )[0] || addedAppointment;

      const cancelAppointment = () => {
        if (isNewAppointment) {
          this.setState({
            editingAppointment: previousAppointment,
            isNewAppointment: false
          });
        }
      };

      const refresh_data = () => {
        // const { reload } = this.state;
        props.fetch();
      };

      return {
        visible: editingFormVisible,
        appointmentData: currentAppointment,
        commitChanges: this.commitChanges,
        visibleChange: this.toggleEditingFormVisibility,
        onEditingAppointmentChange: this.onEditingAppointmentChange,
        cancelAppointment,
        refresh_data
      };
    });
  }

  componentDidUpdate() {
    this.appointmentForm.update();
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  //  React.useEffect(() => {
  //   console.log("Running...")
  //   window.scrollTo(0, 0);
  // }, []);

  onEditingAppointmentChange(editingAppointment) {
    this.setState({ editingAppointment });
  }

  onAddedAppointmentChange(addedAppointment) {
    this.setState({ addedAppointment });
    const { editingAppointment } = this.state;
    if (editingAppointment !== undefined) {
      this.setState({
        previousAppointment: editingAppointment
      });
    }
    this.setState({ editingAppointment: undefined, isNewAppointment: true });
  }

  setDeletedAppointmentId(id) {
    this.setState({ deletedAppointmentId: id });
  }

  toggleEditingFormVisibility(e) {
    const { editingFormVisible } = this.state;
    // this.state.get_new_value(e.startDate)
    this.setState({
      editingFormVisible: !editingFormVisible
    });
  }

  toggleConfirmationVisible() {
    const { confirmationVisible } = this.state;
    this.setState({ confirmationVisible: !confirmationVisible });
  }

  commitDeletedAppointment() {
    this.setState((state) => {
      const { data, deletedAppointmentId } = state;
      // console.log(deletedAppointmentId)
      this.props.delete_aval(deletedAppointmentId);

      const nextData = data.filter((appointment) => appointment.id !== deletedAppointmentId);

      return { data: nextData, deletedAppointmentId: null };
    });
    this.toggleConfirmationVisible();
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed) {
        data = data.map((appointment) =>
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment
        );
      }
      if (deleted !== undefined) {
        this.setDeletedAppointmentId(deleted);
        this.toggleConfirmationVisible();
      }
      return { data, addedAppointment: {} };
    });
  }

  render() {
    const {
      currentDate,
      data,
      confirmationVisible,
      editingFormVisible,
      startDayHour,
      endDayHour,
      interval
    } = this.state;
    const { classes } = this.props;
    
    // const appointments = [
    // {
    //     title: 'Website Re-Design Plan',
    //     startDate: new Date(2022, 1, 17, 9, 35),
    //     endDate: new Date(2022, 1, 17, 11, 30),
    //     id: 0,
    //     rRule: 'FREQ=DAILY;COUNT=3',
    //     // exDate: '20180628T063500Z,20180626T063500Z',
    // }];
    
    console.log("data")

    return (
      <Paper className={classes.paper}>
        <Typography style={{
          fontSize: '13px',
          color: 'rgba(0, 0, 0, 0.7)',
          fontWeight: '300',
          marginLeft: '15px'
        }} noWrap>
          If you don't see your appointments updated, Please
          <span
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              const route = "availability";
              localStorage.setItem("routing_to", route);
              window.location.href = "/dashboard";
            }}
          > refresh </span>
          the page.
        </Typography>
        <Scheduler
          data={data}
          onCellClick={(e) => console.log(e)}
          height={460}>
          <ViewState defaultCurrentDate={currentDate} />
          <EditingState
            onCommitChanges={this.commitChanges}
            onEditingAppointmentChange={this.onEditingAppointmentChange}
            onAddedAppointmentChange={this.onAddedAppointmentChange}
          />
          <MonthView intervalCount={interval} />
          <WeekView startDayHour={startDayHour} endDayHour={endDayHour} />
          <DayView />
          <Toolbar />
          <DateNavigator />
          <AllDayPanel />
          <EditRecurrenceMenu
            availableOperations={""}
          />
          <Appointments />

          <AppointmentTooltip
            showOpenButton
            showCloseButton
          // showDeleteButton
          />
          <ViewSwitcher />
          <AppointmentForm
            overlayComponent={this.appointmentForm}
            visible={editingFormVisible}
            onVisibilityChange={this.toggleEditingFormVisibility}
          />
          <DragDropProvider />
          <TodayButton />
        </Scheduler>

        <Dialog open={confirmationVisible} onClose={this.cancelDelete}>
          <DialogTitle>Delete Appointment</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this appointment?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleConfirmationVisible} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={this.commitDeletedAppointment} color="secondary" variant="outlined">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Fab
          color="secondary"
          className={classes.addButton}
          onClick={() => {
            this.setState({ editingFormVisible: true });
            this.onEditingAppointmentChange(undefined);
            this.onAddedAppointmentChange({
              startDate: new Date(currentDate).setHours(startDayHour),
              endDate: new Date(currentDate).setHours(startDayHour + 1)
            });
          }}
        >
          <AddIcon />
        </Fab>
      </Paper>
    );
  }
}

export default withStyles(styles, { name: 'EditingDemo' })(Demo);
