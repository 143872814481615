import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Spinner from "../assets/home_load.gif";
import {
  create_availability_instance,
  get_availability,
  clear_aval,
} from "../actions/form.js";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
}));

function Createappointment(props) {
  const classes = useStyles();

  const handleCreate = (e) => {
    function get_currentdate() {
      var date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const return_date = year + "-" + month + "-" + day;
      return return_date;
    }
    function get_currenttime() {
      var dt = new Date();
      const formatted = `${dt.getHours()}:${dt.getMinutes()}`;

      var set_format = { "Date/Time": ` 2018-09-30 ${formatted}` };
      const return_time = moment(set_format["Date/Time"]).format("HH:mm:ss");
      return return_time;
    }
    let user_id = props.user.id;
    let recurring = props.recurring;
    let interval = props.interval;
    let notes;
    let end_repeat_date = props.end_repeat_date;
    let startdate = props.startdate;
    let enddate = props.enddate;

    let startime = props.startime;
    let endtime = props.endtime;

    if (props.startdate === "") {
      startdate = get_currentdate();
    }
    if (props.enddate === "") {
      enddate = get_currentdate();
    }
    if (props.end_repeat_date === "") {
      end_repeat_date = get_currentdate();
    }
    if (props.startime === "") {
      startime = get_currenttime();
    }
    if (props.endtime === "") {
      endtime = get_currenttime();
    }
    if (props.notes === "") {
      notes = null;
    } else {
      notes = props.notes;
    }

    if (props.recurring === true) {
      // make sure interval is not none
      if (user_id && startime && endtime && startdate && end_repeat_date) {
        props.create_availability_instance(
          startdate,
          end_repeat_date,
          startime,
          endtime,
          notes,
          user_id,
          recurring,
          interval
        );
        props.clear_aval();
        props.reload();
        props.get_availability();
      }
    } else {
      if (user_id && startime && endtime && startdate && enddate) {
        props.create_availability_instance(
          startdate,
          enddate,
          startime,
          endtime,
          notes,
          user_id,
          recurring,
          interval
        );
        props.clear_aval();
        props.reload();
        props.get_availability();
      }
    }
  };

  React.useEffect(() => {
    if (props.form.refresh === true) {
      setTimeout(() => {
        const route = "availability";
        localStorage.setItem("routing_to", route);
        window.location.href = "/dashboard";
      }, 250);
    }
  }, [props.form.refresh]);

  // React.useEffect(() => {
  //   if (props.form.isLoading === true) {
  //     props.get_availability();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.form.isLoading]);

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleCreate}
      >
        {"Create"}{" "}
        {props.form.isLoading === true ? (
          <img
            src={Spinner}
            alt=""
            height="20px"
            width="20px"
            style={{ marginLeft: "5px" }}
          />
        ) : null}
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user,
});
export default connect(mapStateToProps, {
  create_availability_instance,
  get_availability,
  clear_aval,
})(Createappointment);
