import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";
import Avatarr from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

import clsx from "clsx";
// import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  wrap: {
    height: "174px",
    margin: "0px 14px 17px",
    padding: "15px",
    width: "98%",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("1150")]: {
      flexWrap: "wrap",
      height: "auto",
      padding: "25px 15px 15px 15px",
    },
    [theme.breakpoints.down("700")]: {
      flexWrap: "wrap",
      minHeight: "274px",
      padding: "15px 15px 15px 15px",
    },
  },

  avatar: {
    padding: "0 10px",
    maxWidth: "125px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("700")]: {
      maxWidth: "100%",
      padding: "0 0px",
    },
  },
  imgWrap: {
    maxWidth: "98px",
    "& img": {
      width: "100%",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "100%",
      padding: "0 0px",
      margin: "auto"
    },
  },
  personName: {
    marginTop: "10px",
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
    display: 'none',
    [theme.breakpoints.down("960")]: {
      display: 'block',
      fontSize: "18px",
      marginTop: "10px",
    },
  },
  info: {
    width: "142px",
    marginLeft: "51px",
    [theme.breakpoints.down("1150")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("700")]: {
      width: "200px",
      marginLeft: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "0 20px",
    },
    [theme.breakpoints.down("450")]: {
      width: "100%",
      marginTop: '20px',
    },
  },
  infoItem: {
    marginBottom: "13px",
    "& > p": {
      fontFamily: "Dosis",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#383F45",
    },
  },
  infoText: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#88CCDD",
  },
  skills: {
    MaxWidth: "580px",
    width: "100%",
    gap: '10px',
    marginLeft: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("1150")]: {
      width: "400px",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("1100")]: {
      width: "290px",
    },
    [theme.breakpoints.down("1000")]: {
      width: "260px",
    },
    [theme.breakpoints.down("960")]: {
      width: "51%",
    },
    [theme.breakpoints.down("700")]: {
      width: "100%",
      marginLeft: "0px",
      marginTop: "8px",
      marginBottom: "8px",
      display: 'flex',
      justifyContent: 'center',
      gap: "10px"
    },
    [theme.breakpoints.up("700")]: {
      justifyContent: "flex-end",
    },
  },
  skillsItem: {
    marginRight: "0px",
    marginTop: "10px",
    padding: "15px",
    textAlign: "center",
    width: "auto",
    height: "100%",
    border: "1px solid #E0E0E0",
    boxSizing: "border-box",
    borderRadius: "10px",
    "&:nth-child(3)": {
      marginRight: "0",
      marginTop: "0",
      [theme.breakpoints.down("700")]: {
        marginTop: "0",
        margin: "0px",
        marginRight: "0px",
      },
    },
    "&:nth-child(4n)": {
      [theme.breakpoints.down("700")]: {
        marginRight: "0px",
      },
    },
    "&:nth-child(4)": {
      [theme.breakpoints.down("700")]: {
        marginTop: "0",
      },
    },
    "&:nth-child(1)": {
      marginTop: "0",
    },
    "&:nth-child(2)": {
      marginTop: "0",
    },
    "&:nth-child(3n)": {
      [theme.breakpoints.up("700")]: {
        marginRight: "0",
      },
    },
    [theme.breakpoints.down("1150")]: {
      width: "calc((100% - 52px) / 2)",
    },
    [theme.breakpoints.down("1050")]: {
      width: "calc((100% - 20px) / 2)",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "calc((100% - 30px) / 2)",
      marginRight: "0px",
    },
  },
  skillsName: {
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#1B5E20"
  },
  skillsPrice: {
    fontSize: "12px",
    "& > span": {
      fontFamily: "Dosis",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "18px",
      textAlign: "center",
      color: "#000000",
    },
  },
  button: {
    marginLeft: "47px",
    alignSelf: "flex-end",
    [theme.breakpoints.down("1150")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("1150")]: {
      marginLeft: "auto",
    },
    "& > button": {
      width: "124px",
      height: "38px",
      background: "#156981",
      borderRadius: "10px",
      color: "white",
      border: "none",
      fontFamily: "Dosis",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "23px",
      textAlign: "center",
      cursor: "pointer",
      transition: "background 0.4s",
      [theme.breakpoints.down("700")]: {
        fontSize: "14px",
        width: "104px",
        height: "24px",
      },
      [theme.breakpoints.up("700")]: {
        marginTop: "7px",
      },
      [theme.breakpoints.up("1150")]: {
        marginTop: "20px",
      },
      "&:hover": {
        backgroundColor: "#88CCDD",
      },
    },
  },
  star: {
    MuiSvgIconRoot: {
      marginTop: "200px",
    },
    MuiSvgIcon: {
      root: {
        marginTop: "200px",
      },
    },
    // background: 'red',
    "& > .MuiSvgIcon": {
      width: "9px",
    },
    "& > .MuiSvgIcon-root": {
      width: "9px",
    },
  },
  imageSelector: {
    fontSize: "8rem",
    width: theme.spacing(19),
    height: theme.spacing(19),
    paddingLeft: theme.spacing(0),
  },
  alter_height: {
    height: "auto",
  },
  addIcon: {
    marginTop: '10px'
  }
}));

export default function Card(props) {
  const classes = useStyles();

  const openProvider = (e) => {
    // const userID = props.data.id.toString()
    // const secretKey = '0j9$%)m46vym@vuov_@o1mby*4s6q3#q0+pb=9c3*!d0ocjdzt'
    // const encryptedUserID = CryptoJS.AES.encrypt(userID, secretKey).toString();
    // const encodedUserID = encodeURIComponent(encryptedUserID);
    // console.log(props.data.uid)
    // console.log(typeof props.data.uid)
    window.location.href = `/providers/${props.data?.uid}`;
  };

  return (
    <div
      // className={classes.wrap}
      className={
        props.data?.services?.length >= 7
          ? clsx(classes.wrap, classes.alter_height)
          : classes.wrap
      }
    >
      <div className={classes.avatar}>
        <div className={classes.imgWrap}>
          {/*<img src={props.data.photo} alt=""/>*/}
          <Avatarr
            className={classes.imageSelector}
            variant="circle"
            alt="profile picture"
            src={props.data?.photo}
            onClick={openProvider}
          />
          <h2 className={classes.personName}>{props.data?.first_name} {props.data?.last_name}</h2>          
        </div>
      </div>

      <div className={classes.info}>
        <div className={classes.infoItem}>
          <div className={classes.infoText}>Rating</div>
          <Rating
            size="small"
            classes={{ root: classes.star }}
            name="customized-empty"
            value={props.data?.rating}
            precision={1}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          />
        </div>

        <div className={classes.infoItem}>
          <div className={classes.infoText}>Located</div>
          <p>{props.data?.state}</p>
        </div>

        {/* <div className={classes.infoItem}>
              <div className={classes.infoText}>Distance</div>
              <p>{Math.floor((Math.random()*10) + 1)} miles away</p>
            </div>*/}
      </div>

      <div className={classes.skills}>
        {props.data?.services.map((p, i) => (
          <div key={i} className={classes.skillsItem}>
            <div className={classes.skillsName}>{p.service.name}
              {
                p?.additional_rates?.length > 0 ? 
                <Tooltip title="Has additional rates">
                  <AddBoxRoundedIcon fontSize="inherit" /> 
                </Tooltip>: null
              } 
            </div>
            <div className={classes.skillsPrice}>
              <span>${p.price}/</span>
              {p.rate.name}
            </div>
          </div>
        ))}
      </div>

      <div className={classes.button}>
        <button
          id={`for_${props?.data?.first_name}`}
          onClick={openProvider}
          type="button"
        >
          DETAILS
        </button>
      </div>
    </div>
  );
}
