import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logout, notify, loadUser } from '../actions/auth.js';

import Redirect from './Redirect';
import Loading from './Loading';
import WizardPetOwnerBook from './WizardPetOwnerBook';
import Footer from '../components/FooterWizard';
import { get_payment_method, clear_state_payment } from '../actions/form.js';
import CryptoJS from 'crypto-js';

const RenderBookingWizard = (props) => {
    
    const [encoded_user_id, setEncodedID] = React.useState(null)
    useEffect(() => {
      props.get_payment_method();
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      const id = props?.match?.params?.id
      // Decode and decrypt the ID
      const secretKey = '0j9$%)m46vym@vuov_@o1mby*4s6q3#q0+pb=9c3*!d0ocjdzt'
      const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(id), secretKey);
      const decryptedUserID = decryptedBytes.toString(CryptoJS.enc.Utf8);
      // console.log(decryptedUserID)
      setEncodedID(parseInt(decryptedUserID))
      // eslint-disable-next-line
    }, [])

    // console.log(encoded_user_id)

    let results;
    if (props.payment_methods !== undefined && props.payment_methods !== null) {
      results = props.payment_methods;
    }

    return (
      <div>
        {props.auth.user !== null && encoded_user_id !== null ? (
          <div>
            {props.auth.user.account_type === 'pet_owner' ? (
              <div>
                <WizardPetOwnerBook
                  payment_methods={results}
                  id={encoded_user_id}
                  wizard_stage={props.auth.user.stage_value}
                />
                <Footer />
              </div>
            ) : (
              <div>
                <WizardPetOwnerBook
                  payment_methods={results}
                  id={encoded_user_id}
                  wizard_stage={props.auth.user.stage_value}
                />
                <Footer />
              </div>
            )}
          </div>
        ) : (
          <div>
            {localStorage.getItem('token') !== null &&
            localStorage.getItem('token') !== undefined ? (
              <Loading />
            ) : (
              <Redirect />
            )}
          </div>
        )}
      </div>
    );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user,
  payment_methods: state.services.payment_methods
});

export default connect(mapStateToProps, {
  logout,
  notify,
  loadUser,
  get_payment_method,
  clear_state_payment
})(RenderBookingWizard);
