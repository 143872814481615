/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/lock-reset.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Footprint from '../assets/footprint.svg';
import Spinner from '../components/Spinner';

import { forgot_password, aws_cognito_reset_confirm, resetPass } from '../actions/auth.js';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    minHeight: '100vh',
    [theme.breakpoints.down('800')]: {
      alignItems: 'flex-start'
    }
  },
  rootGrid: {
    margin: '144px 0',
    padding: '33px 0',
    paddingBottom: '150px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('800')]: {
      padding: '36px 0 60px 0',
      maxWidth: '347px',
      margin: '80px 0 0'
    }
  },
  image: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '70px',
    height: '55px'
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '367px',
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '367px'
    },
    [theme.breakpoints.down('800')]: {
      padding: '0 25.5px',
      margin: '0'
    }
  },
  decor: {
    position: 'absolute',
    bottom: '-165px',
    right: '-28%',
    zIndex: -1,
    [theme.breakpoints.down('1260')]: {
      maxWidth: '93px',
      bottom: '-65px',
      right: '2%'
    }
  },
  avatar: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '90px',
    height: '60px',
    '& img': {
      width: '56px',
      height: '55.5px'
    }
  },
  logo: {
    cursor: 'pointer',
    width: '52px',
    height: '52px'
  },
  title: {
    fontFamily: 'Dosis',
    fontWeight: 800,
    fontSize: '51px',
    lineHeight: '58px',
    color: '#383F45',
    [theme.breakpoints.down('800')]: {
      fontSize: '33px',
      lineHeight: '42px'
    }
  },
  form: {
    maxWidth: '328px',
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    }
  },
  buttonWrap: {
    position: 'relative'
    // pointerEvents: 'none'
  },
  input: {
    margin: theme.spacing(0, 0, 0),
    borderRadius: '5px',
    '& input': {
      padding: '23px 52px 17px 14px'
    },
    '& label': {
      top: '8px',
      color: 'black'
    },
    '&:first-child': {
      marginTop: '10px'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '29px'
  },
  formLabel: {
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000'
  },
  rulles: {
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    '& a': {
      color: '#327287',
      textDecoration: 'none'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    height: '56px',
    textTransform: 'none',
    // fontSize: '13px!important',
    background: '#FF5722',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '16px',
    color: '#FFFFFF!important',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0)
    }
  },
  already: {
    marginTop: '61px',
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45'
    // justifyContent: 'center',
  },
  alreadyText: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#000000',
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    // justifyContent:'center',
    '& a': {
      fontFamily: 'Dosis',
      color: '#327287',
      fontWeight: '600',
      textDecoration: 'none',
      fontSize: '18px',
      lineHeight: '22px'
    },
    '& span': {
      color: '#327287',
      textDecoration: 'none',
      cursore: 'pointer'
    }
  },
  radioWrap: {
    margin: '0 auto',
    marginBottom: '37px',
    border: '1px solid #B5B5B5'
  },
  radioButton: {
    minWidth: '155px',
    [theme.breakpoints.down('800')]: {
      minWidth: '142px'
    }
  },
  signWrap: {
    margin: '0 auto',
    marginTop: '15px',
    maxWidth: '328px',
    [theme.breakpoints.down('800')]: {
      marginTop: '0px'
    }
  },
  // formControl: {
  //   width: '100%'
  // },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  social: {
    margin: theme.spacing(3, 0, 2)
  },
  paper2: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  body2: {
    lineHeight: '1.5',
    color: '#444444'
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  checkbox: {
    fontSize: '11px',
    '& > *': {
      fontSize: '11px'
    }
  },
  typeOfAccount: {
    marginTop: theme.spacing(1)
  },
  fogot: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '16px',
    color: '#156981',
    fontFamily: 'Dosis',
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    marginBottom: '54px'
  }
}));

function ResetPassward(props) {
  const classes = useStyles();

  const [password_one, setPassword_one] = useState('');
  const [password_conf, setPassword_conf] = useState('');

  const [error_password, setError_password] = useState(false);
  const [error_passwordNotMatch, setError_passwordNotMatch] = useState(false);

  const [capital_error, setCapital_error] = useState(false);
  const [small_error, setSmall_error] = useState(false);
  const [number_error, setNumber_error] = useState(false);
  const [character_error, setCharacter_error] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const handlesubmit = (e) => {
    e.preventDefault();
    if (password_one.length <= 5) {
      setError_password(true);
      return false;
    } else setError_password(false);

    if (password_one.search(/[A-Z]/) === -1) {
      setCapital_error(true);
      return false;
    } else setCapital_error(false);

    if (password_one.search(/[a-z]/) === -1) {
      setSmall_error(true);
      return false;
    } else setSmall_error(false);

    if (password_one.search(/\d/) === -1) {
      setNumber_error(true);
      return false;
    } else setNumber_error(false);

    if (password_one.search(/^(?=.*[!@#$%^&*()/_+'=?`\{|}~",:;<>])/) === -1) {
      setCharacter_error(true);
      return false;
    } else setCharacter_error(false);

    if (password_one !== password_conf) {
      setError_passwordNotMatch(true);
      return false;
    } else setError_passwordNotMatch(false);

    const email_for_reset = localStorage.getItem('email_reset_pass');
    let emailcode = query.get('code');
    console.log(emailcode);
    // submitting reset password request to aws_amplify_cognito...271797
    props.aws_cognito_reset_confirm(email_for_reset, emailcode, password_conf);
    props.resetPass(emailcode, password_conf);

    props.history.push('/login');
  };

  // submitting change password request to rails backend...
  React.useEffect(() => {
    if (props.auth.change_password === true) {
      const email_for_reset = localStorage.getItem('email_reset_pass');
      props.forgot_password(email_for_reset, password_conf);
    }
  }, [password_conf, props, props.auth.change_password]);

  let alert;

  if (props.error.msg.data) {
    let msg = props.error.msg.data.non_field_errors;
    alert = (
      <div class="alerts">
        <Alert severity="error">{msg}</Alert>
      </div>
    );
  }

  switch (props.error.msg.code) {
    case 'CodeMismatchException':
      alert = (
        <div class="alerts">
          <Alert severity="error">Invalid verification code provided, please try again.</Alert>
        </div>
      );
      break;
    case 'LimitExceededException':
      alert = (
        <div class="alerts">
          <Alert severity="error">Attempt limit exceeded! Please try after some time.</Alert>
        </div>
      );
      break;
    default:
      alert = <div></div>;
      break;
  }

  // if (props.auth.forgot_password === false) {
  //   return <Redirect to="/login" />;
  // }

  if (props.auth.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        xl={3}
        className={classes.rootGrid}
        component={Paper}
        elevation={1}
        square
      >
        <div className={classes.paper}>
          <img className={classes.decor} src={Footprint} alt="" />
          <Avatar className={classes.avatar}>
            <img onClick={(e) => (window.location.href = '/')} alt="avatar" src={logo} width="30" />
          </Avatar>
          <Typography className={classes.title} component="h1" variant="h5">
            Reset Password
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={classes.message}>
                  {/*<Alert severity="error">This is an error alert — check it out!</Alert>
                  <Alert severity="warning">This is a warning alert — check it out!</Alert>
                  <Alert {alert} severity="info">This is an info alert — check it out!</Alert>*/}
                  {alert}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={error_password}
                  variant="outlined"
                  size="small"
                  required
                  className={classes.input}
                  fullWidth
                  name="password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword_one(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={error_passwordNotMatch}
                  variant="outlined"
                  size="small"
                  required
                  className={classes.input}
                  fullWidth
                  name="repeatPassword"
                  label="Repeat Password"
                  type={showPassword ? 'text' : 'password'}
                  id="repeatPassword"
                  autoComplete="repeat-password"
                  onChange={(e) => setPassword_conf(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password2 visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <FormHelperText>Password(min. 6 characters)</FormHelperText>
                {error_password ||
                capital_error ||
                small_error ||
                number_error ||
                character_error ? (
                  <FormHelperText error>
                    Password must be atleast 6 characters and must contain capital, small letters, a
                    special character and preferably some numerics!{' '}
                  </FormHelperText>
                ) : (
                  ''
                )}
                {error_passwordNotMatch ? (
                  <FormHelperText error>New Passwords do not match!</FormHelperText>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handlesubmit}
            >
              {props.auth.isLoading ? <Spinner /> : 'Reset'}
            </Button>
            <Grid container>
              <Grid item xs justify="flex-start" className={classes.alreadyText}>
                <Link href="/login" variant="body2">
                  {'Sign in'}
                </Link>
              </Grid>
              <Grid className={classes.alreadyText} item justify="flex-end">
                <Link href="/signup" variant="body2">
                  {'Sign up'}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors
});

export default connect(mapStateToProps, {
  forgot_password,
  aws_cognito_reset_confirm,
  resetPass
})(ResetPassward);
