/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import EventIcon from '@material-ui/icons/Event';
import PaymentIcon from '@material-ui/icons/Payment';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import ProviderGalleryDashboard from '../components/ProviderGalleryDashboard';
import ListIcon from '@material-ui/icons/List';
import Appointments from './Appointments';
import Notifications from './NotificationsPage';
import Dashboard from './Dashboard';
import Profile from './Profile';
import Payments from './Payments';
import Services from './Services';
import {
  get_availability,
  get_breeds,
  get_breeds_cats,
  get_payment_method,
  delete_availability_instance,
  clear_aval,
  setToNull
} from '../actions/form.js';
import { get_payout_schedule } from '../actions/booking';
import { getBankAccounts } from '../actions/bank-accounts';
import { getPayments } from '../actions/payments';
import Pets from './Pets';
import Image from '../assets/logo_placeholder_transparent_grey.png';
import Avatar from '@material-ui/core/Avatar';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BackgroundCheck from './BackgroundCheck';
import CancellationPolicy from './CancellationPolicy';
import { Box } from '@material-ui/core';
import Footer from '../components/FooterWizard';
import Spinner from '../assets/home_load.gif';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginTop: '64px',
    backgroundColor: '#fafafa',
    color: 'black',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.03), 0px 1px 1px 0px rgba(0,0,0,0.03), 0px 1px 3px 0px rgba(0,0,0,0.03)',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '64px',
    height: '65%'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('980')]: {
      overflow: 'scroll'
    },
    [theme.breakpoints.down('450')]: {
      padding: theme.spacing(1),
    },
  },
  content_calendar: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8)
  },
  petIcon: {
    width: '1em',
    height: '1em'
  },
  sidebarHeadSection: {
    backgroundColor: '#fafafa'
  },
  sidebarHeader: {
    '& span': {
      fontSize: '1.5em',
      fontWeight: '600'
    }
  },
  list: {
    paddingTop: '0'
  },
  sidebarLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '600',
    '& span': {
      fontSize: '13px',
      color: 'rgba(0, 0, 0, 0.7)',
      fontWeight: '600'
    }
  },
  copyright: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '25px',
    color: '#000000',
    [theme.breakpoints.down('550')]: {
      fontSize: '14px',
      lineHeight: '17.7px'
    }
  },
  boxM: {
    [theme.breakpoints.down('550')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh'
    }
  },
  linkList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  link: {
    marginRight: '19px',
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '25px',
    color: '#FFFFF6',
    [theme.breakpoints.down('550')]: {
      fontSize: '14px',
      lineHeight: '17.7px'
    },
    '&:last-child': {
      marginRight: 0
    }
  }
}));

function AuthAppContainer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const deleting = (id) => {
    //console.log("Here is the id : " + id)
    props.delete_availability_instance(id);
    props.get_availability();
  };

  // const [refactor, setRefactor] = useState(false);
  const fetching = () => {
    // console.log("Refreshing secondary....")
    props.clear_aval();
    props.get_availability();
    // setRefactor(true);
  };

  const [go_to_dash, setGo_to_dash] = useState(true);
  const [go_to_apnt, setGo_to_apnt] = useState(false);
  const [go_to_notf, setGo_to_notf] = useState(false);
  const [go_to_cancel, setGo_to_cancel] = useState(false);
  const [go_to_prof, setGo_to_prof] = useState(false);
  const [go_to_gallery, setGo_to_gallery] = useState(false);
  const [go_to_paym, setGo_to_paym] = useState(false);
  const [go_to_services, setGo_to_services] = useState(false);
  const [go_to_pets, setGo_to_pets] = useState(false);
  const [go_to_background_check, setGo_to_background_check] = useState(false);
  const [data, setData] = useState('');

  React.useEffect(() => {
    props.get_payment_method();
    props.getBankAccounts();
    props.get_payout_schedule();
    props.get_breeds();
    props.get_breeds_cats();
    props.get_availability();
    props.getPayments();
  }, []);


  // React.useEffect(() => {
  //   if (props.form.isLoading === true) {
  //     props.get_availability();
  //     //console.log("setting to true")
  //   }
  // }, [props.form.isLoading]);

  // Redirecting user
  React.useEffect(() => {
    // console.log(props.current_route)
    if (
      props.current_route !== null &&
      props.current_route !== undefined &&
      props.current_route === 'availability'
    ) {
      // console.log(props.current_route)  // value of current page
      setGo_to_dash(false);
      setGo_to_apnt(true);
      setGo_to_services(false);
      setGo_to_notf(false);
      setGo_to_cancel(false);
      setGo_to_prof(false);
      setGo_to_paym(false);
      setGo_to_pets(false);
      setGo_to_gallery(false);
      setGo_to_background_check(false);
      localStorage.removeItem('routing_to');
    }
    if (
      props.current_route !== null &&
      props.current_route !== undefined &&
      props.current_route === 'profile'
    ) {
      // console.log(props.current_route)  // value of current page
      setGo_to_dash(false);
      setGo_to_apnt(false);
      setGo_to_services(false);
      setGo_to_notf(false);
      setGo_to_cancel(false);
      setGo_to_prof(true);
      setGo_to_paym(false);
      setGo_to_pets(false);
      setGo_to_gallery(false);
      setGo_to_background_check(false);
      localStorage.removeItem('routing_to');
    }
    if (
      props.current_route !== null &&
      props.current_route !== undefined &&
      props.current_route === 'payment'
    ) {
      props.get_payment_method();
      props.getPayments();
      props.get_payout_schedule();
      setGo_to_dash(false);
      setGo_to_apnt(false);
      setGo_to_services(false);
      setGo_to_notf(false);
      setGo_to_cancel(false);
      setGo_to_prof(false);
      setGo_to_paym(true);
      setGo_to_pets(false);
      setGo_to_gallery(false);
      setGo_to_background_check(false);
      localStorage.removeItem('routing_to');
    }
    if (
      props.current_route !== null &&
      props.current_route !== undefined &&
      props.current_route === 'services'
    ) {
      setGo_to_dash(false);
      setGo_to_apnt(false);
      setGo_to_services(true);
      setGo_to_notf(false);
      setGo_to_cancel(false);
      setGo_to_prof(false);
      setGo_to_paym(false);
      setGo_to_gallery(false);
      setGo_to_pets(false);
      setGo_to_background_check(false);
      localStorage.removeItem('routing_to');
    }
  }, [props.current_route]);

  React.useEffect(() => {
    if (props.availability_next !== null) {
      props.get_availability(props.availability_next);
    }
    if(props.availability_next === null){
      setTimeout(() => {
        console.log("Here::::")
        props.setToNull(); // Wait a bit for last fetch to complete...
      }, 1000)
    }
  }, [props.availability_next]);

  React.useEffect(() => {
    if (props.availability) {
      const list = [];
      function getWeeklyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 7);
        }
        return result;
      }
      function getBiWeeklyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 14);
        }
        return result;
      }

      function getMonthlyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 31);
        }
        return result;
      }

      function getYearlyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 365);
        }
        return result;
      }
      // PetProviderInfo
      // PetProvider
      props.availability.filter((availability) => {
        // console.log(availability)
        const end_time = availability.end_time;
        const start_time = availability.start_time;
        // const day = availability.day;
        const start_date = availability.start_date;
        const end_date = availability.end_date;

        var dateString_start = start_date + ' ' + start_time;
        var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArray = reggie.exec(dateString_start);
        var dateObject_start = new Date(
          +dateArray[1],
          +dateArray[2] - 1, // Careful, month starts at 0!
          +dateArray[3],
          +dateArray[4],
          +dateArray[5],
          +dateArray[6]
        );

        var dateString_start_endtime = start_date + ' ' + end_time;
        var reggie2 = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArra = reggie2.exec(dateString_start);
        // eslint-disable-next-line no-redeclare
        var dateObject_start = new Date(
          +dateArra[1],
          +dateArra[2] - 1, // Careful, month starts at 0!
          +dateArra[3],
          +dateArra[4],
          +dateArra[5],
          +dateArra[6]
        );

        var dateString_end = end_date + ' ' + end_time;
        var reggie3 = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArr = reggie3.exec(dateString_end);
        var dateObject_end = new Date(
          +dateArr[1],
          +dateArr[2] - 1, // Careful, month starts at 0!
          +dateArr[3],
          +dateArr[4],
          +dateArr[5],
          +dateArr[6]
        );

        const id = availability.id;
        const notes = availability.notes;
        const recur = availability.recurring
        const object_ = {
          recurring: availability.recurring,
          interval: availability.interval,
          end: dateString_end
        }
        let object;
        if (recur === true) {
          if (availability.interval === "weekly") {
            const count = getWeeklyDates(start_date, end_date).length
            object = {
              title: notes,
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=WEEKLY;COUNT=${count}`,
              location: object_
            }
            // console.log(object)
            list.push(object);
            // console.log(getWeeklyDates(start_date, end_date))
            // console.log(availability)
          } else if (availability.interval === "biweekly") {
            const count = getBiWeeklyDates(start_date, end_date).length
            object = {
              title: notes,
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=WEEKLY;COUNT=${count};INTERVAL=2`,
              location: object_
            }
            //console.log(count)
            list.push(object);
            //console.log(getMonthlyDates(start_date, end_date))
            //console.log(availability)
          } else if (availability.interval === "monthly") {
            const count = getMonthlyDates(start_date, end_date).length
            object = {
              title: notes,
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=MONTHLY;COUNT=${count}`,
              location: object_
            }
            //console.log(count)
            list.push(object);
            //console.log(getMonthlyDates(start_date, end_date))
            //console.log(availability)
          } else if (availability.interval === "annually") {
            const count = getYearlyDates(start_date, end_date).length
            object = {
              title: notes,
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=YEARLY;COUNT=${count}`,
              location: object_
            }
            //console.log(count)
            list.push(object);
            //console.log(getYearlyDates(start_date, end_date))
            //console.log(availability)
          } else { //daily
            object = {
              title: notes,
              startDate: dateObject_start,
              endDate: dateObject_end,
              id: id,
              location: object_
            };
            // console.log(object)
            list.push(object);
          }
        } else {
          object = {
            title: notes,
            startDate: dateObject_start,
            endDate: dateObject_end,
            id: id,
            location: object_
          };
          // console.log(object)
          list.push(object);
        }
        // console.log(object)
        // list.push(object);
      });
      setData(list);
    }
  }, [props.availability]);

  const [current, setCurrent] = useState('');
  React.useEffect(() => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var current_date = year + '-' + month + '-' + date;
    setCurrent(current_date);
  }, [data]);

  const dashboard = (e) => {
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_prof(true);
    setGo_to_paym(false);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const payments = (e) => {
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_services(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_prof(false);
    setGo_to_paym(true);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const appointments = (e) => {
    setGo_to_dash(false);
    setGo_to_apnt(true);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_prof(false);
    setGo_to_paym(false);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const notifications = (e) => {
    setGo_to_dash(true);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_prof(false);
    setGo_to_paym(false);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const services = (e) => {
    setGo_to_services(true);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_paym(false);
    setGo_to_prof(false);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const profiles = (e) => {
    setGo_to_prof(true);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_paym(false);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const pets = (e) => {
    setGo_to_pets(true);
    setGo_to_gallery(false);
    setGo_to_prof(false);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_paym(false);
    setGo_to_background_check(false);
    setMobileOpen(false);
  };
  const background_check = (e) => {
    setGo_to_background_check(true);
    setGo_to_pets(false);
    setGo_to_gallery(false);
    setGo_to_prof(false);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_paym(false);
    setMobileOpen(false);
  };
  const gallery = (e) => {
    setGo_to_background_check(false);
    setGo_to_pets(false);
    setGo_to_gallery(true);
    setGo_to_prof(false);
    setGo_to_dash(false);
    setGo_to_apnt(false);
    setGo_to_notf(false);
    setGo_to_cancel(false);
    setGo_to_services(false);
    setGo_to_paym(false);
    setMobileOpen(false);
  };

  // const cancellation = (e) => {
  //   setGo_to_background_check(false);
  //   setGo_to_pets(false);
  //   setGo_to_gallery(false);
  //   setGo_to_prof(false);
  //   setGo_to_dash(false);
  //   setGo_to_apnt(false);
  //   setGo_to_notf(false);
  //   setGo_to_cancel(true);
  //   setGo_to_services(false);
  //   setGo_to_paym(false);
  // };

  const drawer = (
    <div>
      <Divider />
      <List className={classes.list}>
        <ListItem
          onClick={dashboard}
          selected={go_to_prof}
          disabled
          button
          className={classes.sidebarHeadSection}
        >
          <ListItemText primary="Dashboard" className={classes.sidebarHeader} />
        </ListItem>
        <ListItem onClick={notifications} selected={go_to_notf} button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={appointments} selected={go_to_apnt} button>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Availability" className={classes.sidebarLabel} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem onClick={payments} selected={go_to_paym} button>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={services} selected={go_to_services} button>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Services" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={pets} selected={go_to_pets} button>
          <ListItemIcon>
            <Avatar src={Image} className={classes.petIcon} />
          </ListItemIcon>
          <ListItemText primary="Pets" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={profiles} selected={go_to_prof} button>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" className={classes.sidebarLabel} />
        </ListItem>
        <ListItem onClick={gallery} selected={go_to_gallery}  button>
          <ListItemIcon>
            <CollectionsBookmarkIcon />
          </ListItemIcon>
          <ListItemText primary="Gallery" className={classes.sidebarLabel} />
        </ListItem>
        <Divider />
        <ListItem onClick={background_check} selected={go_to_background_check} button>
          <ListItemIcon>
            <FindInPageIcon />
          </ListItemIcon>
          <ListItemText primary="Background Check" className={classes.sidebarLabel} />
        </ListItem>
        {props.auth.user.profile_completedness === 100 ? (
          ''
        ) : (
          <Link to="/wizard" style={{ textDecoration: 'none', color: 'grey' }}>
            <ListItem button>
              <ListItemIcon>
                <ArrowForwardIcon />
              </ListItemIcon>
              <ListItemText primary="Wizard" className={classes.sidebarLabel} />
            </ListItem>
          </Link>
        )}
        {/*<ListItem onClick={cancellation}  selected={go_to_cancel} button>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText primary="Cancellation Policy" className={classes.sidebarLabel} />
        </ListItem>*/}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (!props.auth.isAuthenticated) {
    return <Redirect to="/login" />;
  }
  // if (props.auth.user.profile_completedness < 50){
  //     return <Redirect to="/wizard" />;
  // }

  return (
    <Box className={classes.boxM}>
      <div>
        <PrimarySearchAppBar />
        <div className={classes.root}>
          <CssBaseline />
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              {go_to_dash ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Dashboard
                </Typography>
              ) : (
                ''
              )}
              {go_to_apnt ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Availability
                </Typography>
              ) : (
                ''
              )}
              {go_to_notf ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Notifications
                </Typography>
              ) : (
                ''
              )}
              {go_to_prof ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Profile
                </Typography>
              ) : (
                ''
              )}
              {go_to_paym ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Payments
                </Typography>
              ) : (
                ''
              )}
              {go_to_services ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Services
                </Typography>
              ) : (
                ''
              )}
              {go_to_gallery ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Provider Gallery
                </Typography>
              ) : (
                ''
              )}
              {go_to_pets ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Pets
                </Typography>
              ) : (
                ''
              )}
              {go_to_background_check ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Background Check
                </Typography>
              ) : (
                ''
              )}
              {go_to_cancel ? (
                <Typography className={classes.sidebarLabel} noWrap>
                  Cancellation Policy
                </Typography>
              ) : (
                ''
              )}
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="Dashboard">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={go_to_apnt ? classes.content : classes.content}>
            <div className={classes.toolbar} />
            {go_to_dash ? <Dashboard /> : ''}
            {go_to_apnt ? (
              <div>
                {/*data && props.availability_next === null ? (*/}
                {data && props.form.set_view_appointments_ready ? (
                  <div>
                    {/*<Scheduler/>*/}
                    <Appointments
                      data={data}
                      current={current}
                      delete_aval={deleting}
                      fetch={fetching}
                    />
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        minHeight: '30vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '13px'
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img src={Spinner} alt="" height="100px" width="100px" />
                      </div>
                    </div>
                  </div>
                )}
                {/* <Appointments  current={current} delete_aval={deleting} fetch={fetching}/> */}
              </div>
            ) : (
              ''
            )}
            {go_to_notf ? (
              <div>
                <Notifications />
              </div>
            ) : (
              ''
            )}
            {go_to_services ? (
              <div>
                <Services />
              </div>
            ) : (
              ''
            )}
            {go_to_prof ? (
              <div>
                <Profile />
              </div>
            ) : (
              ''
            )}
            {go_to_gallery ? (
              <div>
                <ProviderGalleryDashboard />
              </div>
            ) : (
              ''
            )}
            {go_to_pets ? (
              <div>
                <Pets />
              </div>
            ) : (
              ''
            )}
            {go_to_paym ? (
              <div>
                {props.payment_methods !== null && props.payment_methods !== undefined ? (
                  <Payments />
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        minHeight: '50vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '13px',
                        background: '#fff'
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img src={Spinner} alt="" height="100px" width="100px" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {go_to_background_check ? (
              <div>
                <BackgroundCheck />
              </div>
            ) : (
              ''
            )}
            {go_to_cancel ? (
              <div>
                <CancellationPolicy />
              </div>
            ) : (
              ''
            )}
          </main>
        </div>
      </div>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  availability: state.forms.availability,
  availability_next: state.forms.availability_next,
  payment_methods: state.services.payment_methods
});

export default connect(mapStateToProps, {
  get_availability,
  getBankAccounts,
  get_breeds,
  get_payout_schedule,
  clear_aval,
  get_breeds_cats,
  get_payment_method,
  delete_availability_instance,
  getPayments,
  setToNull
})(AuthAppContainer);
