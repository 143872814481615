import React from "react";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GuestNavBar from "../components/GuestNavBar";
import { Box } from "@material-ui/core";

import Footer from "../components/Footer";
import PrimarySearchAppBar from "../components/AuthorizedUserHomepageNavbar";


import { get_blog_posts } from "../actions/blog";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    paddingTop: "43px",
    paddingBottom: "59px",
    fontFamily: "Dosis",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
    background: "#fff",
    position: "relative",
    zIndex: "2",
    [theme.breakpoints.down("650")]: {
      paddingTop: "27px",
      paddingBottom: "40px",
    },
  },
  image: {
    margin: theme.spacing(1),
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  paper: {
    margin: theme.spacing(1),
  },
  wizardContainer: {
    margin: theme.spacing(12, "auto", 2),
  },
  title: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "520",
    fontSize: "46px",
    lineHeight: "58px",
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  title_small: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "320",
    fontSize: "20px",
    // lineHeight: '58px',
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  teamSection: {
    margin: theme.spacing(3, 0, 1),
  },
  descriptions: {
    textAlign: "center",
  },
  body: {
    // letterSpacing: '0.02em',
    // lineHeight: '2em',
    // marginBottom: theme.spacing(3),
    // fontSize: '1em',

    fontSize: "1.1em",
    lineHeight: "30px",
    //alignItems: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    fontWeight: "600",
    color: "#383F49",
    [theme.breakpoints.down("850")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down("650")]: {
      fontSize: "16px",
      lineHeight: "19.8px",
      maxWidth: "309px",
      margin: "0 auto",
    },
  },
  descriptions_: {
    fontFamily: "Averia Sans Libre",
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: "500",
    color: "#737373",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("850")]: {
      fontSize: "18px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("650")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  explanationText: {
    height: 'auto',
    display: 'flex',
    // fontWeight:'bold',
    textAlign: 'left',
    color: "#23286B",
    fontSize: '13px'
  },
  explanationText_: {
    fontWeight:'500',
    color: "#23286B",
    fontSize: '15px'
  }
}));

function CancellationPolicy(props) {
  const classes = useStyles();

  React.useEffect(() => {
    props.get_blog_posts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <div>
        <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
        <div>{!props.auth.token && <GuestNavBar />}</div>
      </div>

      <div className={classes.root}>
        <Container maxWidth="md" className={classes.wizardContainer}>
          <h2 className={classes.title}>Cancellation Policy</h2>
          {/*<div>
            <h2 className={classes.title_small}>
              When you ask a question, we respond to it here. We think someone
              else in our community may benefit from it. Below is what we have
              been asked so far.
            </h2> 
          </div>*/}
          <div >
            <Typography className={classes.explanationText}>
              For all bookings: 
            </Typography>
            <Typography className={classes.explanationText}>
              A full 100% refund will be provided to the Pet Owner if they cancel more than 24 
              hours before the booking begins.
            </Typography>
            <hs className={classes.explanationText_}>
              Any terms in this Cancellation Policy that are not defined here are in 
              the <span 
                style={{ 
                  color : '#FF5722', 
                  marginLeft: "5px", 
                  marginRight: "5px", 
                  cursor: "pointer" 
                }}
                onClick={() => {window.location.href = "/terms"}}
                >Terms of Service</span>
               for Homepetvet
            </hs>
          </div>
        </Container>
      </div>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tips: state.blog,
});

export default connect(mapStateToProps, { get_blog_posts })(CancellationPolicy);
