import { BLOG_POST_LOADING, BLOG_POST_LOADED } from "../actions/types";

const initialState = {
  isLoading: false,
  results: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BLOG_POST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case BLOG_POST_LOADED:
      return {
        ...state,
        isLoading: false,
        results: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
