/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import React, { useState } from "react";
import { connect } from 'react-redux';

import { sendUserData_account } from '../actions/form.js';
import { loadUser } from '../actions/auth.js';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import logo from '../assets/user.svg';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Box, Divider } from '@material-ui/core';
import Footprint from '../assets/footprint.svg';
import GuestNavBar from '../components/GuestNavBar';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    minHeight: '100vh',
    [theme.breakpoints.down('800')]: {
      alignItems: 'flex-start'
    }
  },
  rootGrid: {
    margin: '144px 0',
    padding: '33px 0',
    paddingBottom: '150px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('800')]: {
      padding: '36px 0 60px 0',
      maxWidth: '347px',
      margin: '80px 0 0'
    }
  },
  image: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '70px',
    height: '55px'
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '367px',
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    // [theme.breakpoints.down("sm")]: {
    //   margin: theme.spacing(4, "auto"),
    // },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '367px'
    },
    [theme.breakpoints.down('800')]: {
      padding: '0 25.5px',
      margin: '0'
    }
  },
  decor: {
    position: 'absolute',
    bottom: '-165px',
    right: '-28%',
    zIndex: -1,
    [theme.breakpoints.down('1260')]: {
      maxWidth: '93px',
      bottom: '-65px',
      right: '2%'
    }
  },
  avatar: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '90px',
    height: '60px',
    '& img': {
      width: '56px',
      height: '55.5px'
    }
  },
  logo: {
    cursor: 'pointer',
    width: '52px',
    height: '52px'
  },
  title: {
    fontFamily: 'Dosis',
    fontWeight: 800,
    fontSize: '51px',
    lineHeight: '58px',
    color: '#383F45',
    [theme.breakpoints.down('800')]: {
      fontSize: '33px',
      lineHeight: '42px'
    }
  },
  form: {
    maxWidth: '328px',
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    }
  },
  buttonWrap: {
    position: 'relative'
    // pointerEvents: 'none'
  },
  input: {
    margin: theme.spacing(0, 0, 0),
    borderRadius: '5px',
    '& input': {
      padding: '23px 52px 17px 14px'
    },
    '& label': {
      top: '8px',
      color: 'black'
    },
    '&:first-child': {
      marginTop: '10px'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '29px'
  },
  formLabel: {
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000'
  },
  rulles: {
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    '& a': {
      color: '#327287',
      textDecoration: 'none'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    height: '56px',
    textTransform: 'none',
    // fontSize: '13px!important',
    background: '#FF5722',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '16px',
    color: '#FFFFFF!important',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0)
    }
  },
  already: {
    marginTop: '61px',
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45'
    // justifyContent: 'center',
  },
  alreadyText: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#000000',
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    // justifyContent:'center',
    '& a': {
      fontFamily: 'Dosis',
      color: '#327287',
      fontWeight: '600',
      textDecoration: 'none',
      fontSize: '18px',
      lineHeight: '22px'
    },
    '& span': {
      color: '#327287',
      textDecoration: 'none',
      cursore: 'pointer'
    }
  },
  radioWrap: {
    margin: '0 auto',
    marginBottom: '25px',
    border: '1px solid #B5B5B5'
  },
  radioButton: {
    minWidth: '155px',
    [theme.breakpoints.down('800')]: {
      minWidth: '142px'
    }
  },
  signWrap: {
    margin: '0 auto',
    marginTop: '15px',
    maxWidth: '328px',
    [theme.breakpoints.down('800')]: {
      marginTop: '0px'
    }
  },
  // formControl: {
  //   width: '100%'
  // },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  social: {
    margin: theme.spacing(3, 0, 2)
  },
  paper2: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  body2: {
    lineHeight: '1.5',
    color: '#444444'
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  checkbox: {
    fontSize: '11px',
    '& > *': {
      fontSize: '11px'
    }
  },
  typeOfAccount: {
    marginTop: theme.spacing(1)
  },
  fogot: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '16px',
    color: '#156981',
    fontFamily: 'Dosis',
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    marginBottom: '54px'
  }
}));

function VerifySignupCode(props) {
  const classes = useStyles();
  // props.loadUser();
  // let history = useHistory();

  // eslint-disable-next-line no-unused-vars
  let user_id;

  if (props.user !== null && props.user !== undefined) {
    user_id = props.user.id;
    // console.log("Okay Goo...");
  } else {
    props.loadUser();
    // console.log("Not yet");
  }

  const [error_usertype, setError_user] = useState(false);
  // const [error_terms, setError_terms] = useState(false);
  const [type, setType] = useState({ account_type: '' });
  // const [checked, setChecked] = useState(false);

  function handleChange(e, new_type) {
    props.loadUser();
    if (e !== undefined && e !== '') {
      setType({ account_type: new_type });
    }
    //console.log(user_id);
  }

  function handlesubmit(e) {
    props.loadUser();
    // console.log('submit ', type)
    // console.log(type)
    e.preventDefault();
    // if (type === null) {
    if (type.account_type === null || type.account_type === '') {
      setError_user(true);
      return false;
    } else {
      setError_user(false);
      // console.log("Select account Flag: ");
      // console.log(type);
      // console.log(user_id);
      const user_id = props.user.id;
      props.sendUserData_account(type, user_id);
      props.loadUser();
      //  console.log("Redirect in method");
      //  window.location.reload();
      //  history.push("/login");
      // // return <Redirect to="/login" />;
    }
  }

  React.useEffect(() => {
    props.loadUser();
  }, []);

  React.useEffect(() => {
    props.loadUser();
    if (props.auth.user_register_social === false) {
      props.loadUser();
      // console.log("Redirect in useEffect");
      window.location.href = '/login';
      // return <Redirect to="/login" />;
    }
  }, [props.auth.user_register_social]);

  // if (props.auth.user.account_type == "") {
  //       return <Redirect to="/select_account" />;
  //     } else {
  //       if (props.auth.user.profile_completedness < 50){
  //         return <Redirect to="/wizard" />;
  //       } else {
  //         return <Redirect to="/dashboard" />;
  //       }
  //     }

  // if (props.auth !== undefined && !props.auth.isAuthenticated) {
  //   console.log("Redirecting");
  //   history.push("/login");
  // }

  // if (
  //   props.auth !== undefined &&
  //   props.auth.user !== undefined &&
  //   props.user.account_type !== undefined &&
  //   props.user.account_type !== ""
  // ) {
  //   console.log("Redirect Dashboard");
  //   history.push("/dashboard");
  // }

  return (
    <Box>
      <div>
        <GuestNavBar />
      </div>
      <Grid container component="main" className={classes.root}>
        {/* <CssBaseline /> */}
        {/* <Grid item xs={false} sm={6} md={7} xl={9} className={classes.image} /> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          xl={3}
          className={classes.rootGrid}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <img className={classes.decor} src={Footprint} alt="" />
            <Avatar className={classes.avatar}>
              <img src={logo} alt="ava" onClick={(e) => (window.location.href = '/')} width="30" />
            </Avatar>
            <Typography
              className={classes.title}
              component="h1"
              variant="h5"
              style={{ align: 'center' }}
            >
              Set Account Type
            </Typography>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={error_usertype}>
                {/* <FormLabel component="legend">Sign up as a:</FormLabel> */}
                <div className={classes.signWrap}>
                  <Grid item xs={12} className={classes.typeOfAccount}>
                    <FormControl
                      className={classes.formControl}
                      component="fieldset"
                      error={error_usertype}
                    >
                      <FormLabel className={classes.formLabel} component="legend">
                        Sign up as a PET:
                      </FormLabel>
                      <ToggleButtonGroup
                        className={classes.radioWrap}
                        size="small"
                        value={type.account_type}
                        exclusive
                        onChange={handleChange}
                      >
                        <ToggleButton
                          className={classes.radioButton}
                          value="service_provider"
                          aria-label="Provider"
                        >
                          <h2>Provider</h2>
                        </ToggleButton>
                        <ToggleButton
                          className={classes.radioButton}
                          value="pet_owner"
                          aria-label="Owner"
                        >
                          <h2>Owner</h2>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    {error_usertype ? (
                      <FormHelperText error>Please Choose a user type.</FormHelperText>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Divider />
                  {/*{<Grid item xs={12}>
                  <p className={classes.rulles}>By signing in or signing up I agree to  HomePetVet’s Terms&nbsp; and Privacy Policy</p>
                </Grid>*/}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handlesubmit}
                  >
                    {props.form.isLoading ? <Spinner /> : 'Continue'}
                  </Button>
                </div>
              </FormControl>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  sendUserData_account,
  loadUser
})(VerifySignupCode);
