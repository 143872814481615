/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  getresults,
  getsuggestions,
  getresults_query,
  deinit_fetch,
  clear_state
} from '../actions/results';

//import AuthButtons from "../components/auth/AuthButtons.js"
//import CustomizedInputBase from "../components/SearchBarSearchPage"
import CustomizedInputBase from '../components/SearchBarSearchPageFilter';

import { Box } from '@material-ui/core';
import ImageAvatars from '../components/LogoSearchPage.js';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import Share from '../components/Share.js';
import SingleResult from '../views/SingleResult';
import { Paper } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';
import Popper from '@material-ui/core/Popper';
import Footer from '../components/Footer';
import Spinner from '../assets/home_load.gif';
import Footprint from '../assets/footprint.svg';
import Card from '../views/atoms/Card_404';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(0),
    minHeight: '36vh'
  },
  root: {
    padding: theme.spacing(1),
    color: '#5c5c5c',
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    textAlign: 'left'
  },
  paper: {
    padding: theme.spacing(2)
  },
  root_: {
    // width: '100%',
    minHeight: '30vh',
    // paddingTop: '33px',
    background: '#D4F2F2',
    [theme.breakpoints.down('700')]: {
      paddingTop: '20px'
    }
  },

  wizardContainer: {
    [theme.breakpoints.down('450')]: {
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  },
  // root: {
  //   // width: '100%',
  //   minHeight: '36vh',
  //   // paddingTop: '33px',
  //   background: '#D4F2F2',
  //   [theme.breakpoints.down('700')]: {
  //     paddingTop: '20px'
  //   }
  // },
  page: {
    display: 'flex',
    minHeight: '80px',
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('960')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '400px',
      margin: '0 auto',
      minHeight: 'auto'
    },
    [theme.breakpoints.down('450')]: {
      maxWidth: '370px'
    }
  },
  page2: {
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    left: "0",
    bottom: "0",
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  aside: {
    width: '100%',
    maxWidth: '302px',
    [theme.breakpoints.down('960')]: {
      maxWidth: '100%',
      order: 3
    }
  },
  asideHeader: {
    background: 'white',
    height: '112px',
    fontFamily: 'Averia Sans Libre',
    fontWeight: 'normal',
    fontSize: '26px',
    lineHeight: '45px',
    color: '#23286B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '2px solid #BDBDBD',
    borderBottom: '2px solid #BDBDBD',
    [theme.breakpoints.down('960')]: {
      display: 'none'
    }
  },
  asideTitle: {
    fontWeight: '350',
    fontSize: '16px',
    color: '#23286B'
  },
  title: {
    alignItems: 'center',
    fontWeight: '350',
    fontSize: '16px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  asideMain: {
    padding: '16px 47px 16px',
    background: 'white',
    minHeight: '113px',
    borderRight: '2px solid #BDBDBD',
    [theme.breakpoints.down('960')]: {
      padding: '16px 20px 16px',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 'auto',
      order: '3'
    },
    [theme.breakpoints.down('700')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around'
    }
  },

  content: {
    width: '100%',
    // flex:1,
    [theme.breakpoints.down('960')]: {
      order: '0'
    }
  },
  headerContent: {
    width: '100%',
    background: 'white',
    height: '112px',
    fontFamily: 'Averia Sans Libre',
    fontWeight: 'normal',
    fontSize: '36px',
    lineHeight: '45px',
    color: '#4F4F4F',
    borderBottom: '2px solid #BDBDBD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('700')]: {
      height: '100%',
      padding: '0 20px'
    }
  },
  contantMain: {
    paddingTop: '17px',
    paddingBottom: '17px',
    minHeight: '80px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('700')]: {
      minHeight: '62vh',
      background: '#fff',
      paddingBottom: '0px'
    }
  },
  content_: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemDescriptions: {
    fontFamily: 'Averia Sans Libre',
    fontSize: '20px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#156981',
    textAlign: 'left',
    '& > span': {
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '16px',
      lineHeight: '20.22px'
    }
  },
  paginate: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: "center",
  },
  paginateText: {
    fontFamily: 'Dosis',
    cursor: "pointer",
    fontStyle: 'normal',
    margin: "20px",
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#000000',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('1000')]: {
      fontWeight: '600',
      fontSize: '17px'
    },
    '& a': {
      fontFamily: 'Dosis',
      color: '#327287',
      fontWeight: '600',
      textDecoration: 'none',
      fontSize: '18px',
      lineHeight: '22px',
      [theme.breakpoints.down('1000')]: {
        display: 'contents'
      }
    },
    '& span': {
      color: '#327287',
      textDecoration: 'none',
      cursore: 'pointer'
    }
  },
}));

function ResultsPage(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { next, previous } = props;

  const redirect_signup = (e) => {
    window.location.href = '/signup';
  };
  // const shareUrl = 'https://homepetvet.com';

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  // const [search, setSearch] = React.useState(props.location.state.detail);

  React.useEffect(() => {
    scroll.scrollToTop();
    // console.log(search)
    props.getsuggestions();
    if (props.location.state.id !== undefined) {
      props.getresults(props.location.state.id);
      console.log(props.location.state.id);
    }
    if (props.location.state.id === undefined) {
      const miles = localStorage.getItem('miles');
      const zip = localStorage.getItem('zipcode');
      const latit = localStorage.getItem('cords_lat');
      const long = localStorage.getItem('cords_lon');

      // console.log(props.location.state.id);
      const trimmed_input = props.location.state.detail.trim();
      // console.log(trimmed_input)
      props.getresults_query(null, trimmed_input, miles, zip, latit, long);
    }
    props.deinit_fetch();
  }, []);

  React.useEffect(() => {
    if (next || previous) {
      scroll.scrollToTop();
    }
  }, [next, previous])

  React.useEffect(() => {
    if (props.fetch === true) {
      props.clear_state();
      const query = props.query;
      // console.log(query)
      const miles = localStorage.getItem('miles');
      const zip = localStorage.getItem('zipcode');
      const latit = localStorage.getItem('cords_lat');
      const long = localStorage.getItem('cords_lon');

      props.getresults_query(null, query, miles, zip, latit, long);
      props.deinit_fetch();
    }
  }, [props.fetch]);

  const fetch_paginated_page = (url) => {
    props.clear_state();
    const query = props.query;
    // console.log(query)
    const miles = localStorage.getItem('miles');
    const zip = localStorage.getItem('zipcode');
    const latit = localStorage.getItem('cords_lat');
    const long = localStorage.getItem('cords_lon');

    props.getresults_query(url, query, miles, zip, latit, long);
    props.deinit_fetch();
  }

  const searchInput = props.location.state.detail;
  const status = props.errors.status;

  if (props.results) {
    if (props.results?.count === 0) {
      return (
        <Fragment>
          <div>{props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
          <div>{!props.auth.token ? <GuestNavBar /> : ''}</div>

          <Box>
            <Paper className="search-section" elevation={0}>
              <ImageAvatars />
              <CustomizedInputBase
                search={props.location.state.detail}
                auto_complete={props.autocompletes}
              />
              {/*<CustomizedTabs />*/}
            </Paper>
            <div className={classes.page2}>
              <div className={classes.content}>
                <div className={classes.contantMain}>
                  <Card />
                </div>
                <div className={classes.content_}>
                  <div className={classes.itemDescriptions}>
                    {/*{`Your search for `} <b>{props.location.state.detail}</b> {` did not match any pet service providers we currently have on the site.`}*/}
                    <br />
                    {'Do you work with pets or know any people that do? '}
                    <Link variant="h6" style={{ color: 'red' }} onClick={redirect_signup}>
                      <span>Join us</span>
                    </Link>{' '}
                    or
                    <Link variant="h6" style={{ color: 'red' }} onClick={handleClick}>
                      {' '}
                      <span>Invite </span>
                    </Link>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                      <Paper className={classes.paper}>
                        <Share />
                      </Paper>
                    </Popper>
                    people who work with pets
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 'auto' }}>
              <Footer />
            </div>
          </Box>
        </Fragment>
      );
    }
  }

  if (status === 500) {
    return (
      <Fragment>
        <div>{props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
        <div>{!props.auth.token ? <GuestNavBar /> : ''}</div>
        <Container className={classes.container}>
          <Paper className="search-section" elevation={0}>
            <ImageAvatars />
            <CustomizedInputBase
              search={props.location.state.detail}
              auto_complete={props.autocompletes}
            />
            {/*	<CustomizedTabs />*/}
          </Paper>
        </Container>
        <Box>
          <div className={classes.page2}>
            <div className={classes.content}>
              <div className={classes.root}>
                {`We are sorry, an Internal error occured while processing your request. Please try again in a few`}
                <br />
                <br />
                <Alert severity="info" icon={false}>
                  <AlertTitle>
                    {' '}
                    {'Do you work with pets or know any people that do? '}
                    <Link variant="h6" color="primary" onClick={redirect_signup}>
                      Join us
                    </Link>{' '}
                    or
                    <Link variant="h6" color="primary" onClick={handleClick}>
                      {' '}
                      Invite{' '}
                    </Link>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                      <Paper className={classes.paper}>
                        <Share />
                      </Paper>
                    </Popper>
                    people who work with pets
                  </AlertTitle>
                </Alert>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <Footer />
          </div>
        </Box>
      </Fragment>
    );
  }

  const LoaderComponent = () => {
    return (<div
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '60vh',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '73px',
        background: '#fff'
      }}
    >
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          style={{
            position: 'absolute',
            //bottom: '-11px',
            // right: '-28%',
            // zIndex: -1,
            marginBottom: '41px',
            marginRight: '31px'
          }}
          src={Footprint}
          alt=""
        />
        <img src={Spinner} alt="" height="100px" width="100px" />
      </div>
    </div>)
  }

  return (
    <Fragment>
      <div>{props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
      <div>{!props.auth.token ? <GuestNavBar /> : ''}</div>
      <Box>
        <Paper className="search-section" elevation={0}>
          <ImageAvatars />
          <CustomizedInputBase
            search={props.location.state.detail}
            auto_complete={props.autocompletes}
          />
        </Paper>
        <div className={classes.page2}>
          <div className={classes.content}>
            <div className={classes.container}>
              {props.results?.length !== 0 ? (
                <div className={classes.root_}>
                  <List className="search-list">
                    {props.results?.results &&
                      props.results?.results.map((result, idx) =>
                        result.fullname === null ? (
                          ''
                        ) : (
                          <SingleResult key={idx} searchInput={searchInput} result={result} />
                        )
                      )}
                    {props.results.results ? (
                      <div className={classes.paginate} >
                        {
                          previous !== null ?
                            (<p className={classes.paginateText}
                              onClick={() => fetch_paginated_page(previous)}
                            >  <ChevronLeftIcon /> Previous
                            </p>) : null
                        }
                        {
                          next !== null ? (
                            <p className={classes.paginateText}
                              onClick={() => fetch_paginated_page(next)}
                            >  Next <ChevronRightIcon />
                            </p>) : null
                        }


                      </div>) : null}
                  </List>
                </div>
              ) : LoaderComponent()}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Footer />
        </div>
      </Box>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  results: state.results.results,
  next: state.results.results.next,
  previous: state.results.results.previous,
  fetch: state.results.fetch,
  query: state.results.query,
  autocompletes: state.results.autocompletes,
  errors: state.errors
});

export default connect(mapStateToProps, {
  getresults,
  getsuggestions,
  getresults_query,
  deinit_fetch,
  clear_state
})(ResultsPage);
