import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import {
  getresults,
  getsuggestions,
  getresults_query,
  deinit_fetch,
  clear_state
} from '../actions/results';

//import AuthButtons from "../components/auth/AuthButtons.js"

//import CustomizedInputBase from "../components/SearchBarSearchPage"

import { Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import Footer from '../components/Footer';
import Spinner from '../assets/home_load.gif';
import Footprint from '../assets/footprint.svg';

// import * as Scroll from 'react-scroll';
// var scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(16)
  },
  root: {
    padding: theme.spacing(1),
    color: '#5c5c5c',
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    textAlign: 'left'
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

function Loading(props) {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  return (
    <Fragment>
      <div>{props.auth.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
      <div>{!props.auth.token ? <GuestNavBar /> : ''}</div>
      <Box>
        <Container className={classes.container} disableGutters="true">
          <div
            style={{
              display: 'flex',
              width: '100%',
              minHeight: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '73px',
              background: '#fff'
            }}
          >
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  //bottom: '-11px',
                  // right: '-28%',
                  // zIndex: -1,
                  marginBottom: '41px',
                  marginRight: '31px'
                }}
                src={Footprint}
                alt=""
              />
              <img src={Spinner} alt="" height="100px" width="100px" />
            </div>
          </div>
        </Container>
        <Footer />
      </Box>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  results: state.results.results,
  fetch: state.results.fetch,
  query: state.results.query,
  autocompletes: state.results.autocompletes,
  errors: state.errors
});

export default connect(mapStateToProps, {
  getresults,
  getsuggestions,
  getresults_query,
  deinit_fetch,
  clear_state
})(Loading);
