import axios from "axios";
import { BLOG_POST_LOADING, BLOG_POST_LOADED } from "./types";
import { tokenConfig } from "./auth";

export const get_blog_posts = () => async (dispatch, getState) => {
  dispatch({ type: BLOG_POST_LOADING });

  await axios
    .get(`${process.env.REACT_APP_API_URL}blog-posts/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: BLOG_POST_LOADED,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      dispatch({
        type: BLOG_POST_LOADED,
        payload: null,
      });
    });
};
