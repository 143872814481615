import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  get_availability_data,
  clear_aval,
  getPetData,
  clear_error,
  clear_available,
} from "../actions/form.js";
import { update_booking, create_review } from "../actions/booking.js";
import Spinner from "../assets/home_load.gif";

import ReactStars from "react-rating-stars-component";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "10px",
    width: "100%",
    minHeight: "100vh",
    paddingTop: "73px",
    background: "#D4F2F2",
  },
  page: {
    padding: "76px 0px 0px 0px",
    width: "100%",
    background: "white",
    minHeight: "400px",
    borderRadius: "10px",
    overflow: "hidden",
    [theme.breakpoints.down("680")]: {
      padding: "50px 0px 0px 0px",
      maxWidth: "400px",
      margin: "0 auto",
    },
  },
  custom: {
    margin: "0",
    padding: "18px 16px 14px",
    maxWidth: "210px",
    minHeight: "56px",
    border: "1px solid rgba(25, 25, 25, 0.32)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("680")]: {
      marginBottom: "10px",
    },
    "& > .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& > .MuiPickersToolbar": {
      toolbar: "none",
    },
  },
  toolbar: {
    backgroundColor: "red",
  },
  contant: {
    marginBottom: "48px",
    textAlign: "center",
    padding: "0 52px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("680")]: {
      padding: "0 15px",
    },
  },
  inputLabel: {
    padding: "10px 22px 10px 16px",
    display: "flex",
    alignItems: "center",
    width: "495px",
    height: "93px",
    background: "#E7E7E7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    cursor: "pointer",
  },
  inputText: {
    marginLeft: "34px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    order: "1",
  },
  petName: {
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "29px",
    lineHeight: "36px",
    color: "#156981",
  },
  petType: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#68AC82",
  },
  circle: {
    marginLeft: "auto",
    width: "32px",
    height: "32px",
    background: "#FFFFFF",
    border: "1px solid #4F4F4F",
    borderRadius: "50%",
    position: "relative",
    display: "block",
    order: "2",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "18px",
      height: "7px",
      top: "8px",
      right: "4px",
      display: "block",
    },
  },
  formgroup: {
    marginBottom: "63px",
    [theme.breakpoints.down("680")]: {
      marginBottom: "40px",
    },
  },
  grid: {
    marginBottom: "43px",
    [theme.breakpoints.down("680")]: {
      marginBottom: "20px",
    },
  },
  buttonForm: {
    marginTop: "36px",
    marginBottom: "38px",
    display: "block",
    width: "100%",
    padding: "16.5px 0",
    background: "#fff",
    borderRadius: "10px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "24px",
    // color: "#FFFFFF",
    margin: "0 auto",
    outline: "none",
    cursor: "pointer",
    border: "1px solid #BDBDBD",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#156981",
    transition: "background 0.4s",
    [theme.breakpoints.down("680")]: {
      marginTop: "20px",
      marginBottom: "40px",
    },
    "&:hover": {
      backgroundColor: "#cce5e7",
    },
  },
  submit: {
    display: "block",
    height: "39px",
    width: "141px",
    background: "#156981",
    borderRadius: "10px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "27px",
    color: "#FFFFFF",
    margin: "0 auto",
    marginTop: "36px",
    outline: "none",
    cursor: "pointer",
    border: "1px solid #BDBDBD",
    textAlign: "center",
    letterSpacing: "0.15px",
    transition: "background 0.4s",
    "&:hover": {
      backgroundColor: "#52a3bb",
    },
  },
  titleBlock: {
    marginBottom: "30px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "30px",
    color: "#000000",
    textAlign: "left",
    letterSpacing: "0.15px",
    [theme.breakpoints.down("680")]: {
      marginBottom: "20px",
      fontSize: "24px",
      lineHeight: "24px",
      padding: '5px 0',
      display: 'inline-block'
    },
  },
  textarea: {
    width: "100%",
    height: "161px",
    padding: "8px 22px 8px 14px",
    background: "rgba(51, 51, 51, 0.06)",
    borderRadius: "4px 4px 0px 0px",
    border: "none",
    borderBottom: "solid 2px #00BCD4",
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#4F4F4F",
    resize: "none",
    outline: "none",
  },
  form: {
    width: "100%",
  },
  imgWrap: {
    maxWidth: "73px",
    maxHeight: "73px",
    order: "0",
    "& > img": {
      width: "100%",
    },
    [theme.breakpoints.down("680")]: {
      maxWidth: "62px",
      maxHeight: "62px",
    },
  },
  title_: {
    maxWidth: "624px",
    alignItems: "center",
    fontWeight: "350",
    fontSize: "20px",
    // lineHeight: '58px',
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
    pageTitle: {
      maxWidth: "624px",
      marginBottom: "2px",
      // alignItems: 'center',
      fontWeight: "200",
      fontSize: "22px",
      // lineHeight: '58px',
      color: "#23286B",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    // fontSize: "18px",
    color: "#23286B",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "3px",
    border: "1px solid #cfd7de",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
    button_: {
      // alignItems: 'center',
      color: "#FF3D00",
      // float: 'left',
      justifyContent: "center",
      backgroundColor: "#fff!important",
      border: "1.5px solid #FF3D00",
      borderRadius: "50px",
    },
    formLabel_: {
      fontSize: "13px",
      color: "red",
      fontWeight: "600",
      marginBottom: theme.spacing(1),
    },
    column: {
      marginBottom: "20px",
    },
  },
  marginLeft: {
    [theme.breakpoints.down("550")]: {
      marginLeft: '0px !important'
    },
  },
  button_send: {
    maxWidth: "130px",
    maxHeight: "35px",
    minWidth: "130px",
    minHeight: "35px",
    color: "#FF3D00",
    justifyContent: "center",
    backgroundColor: "#fff!important",
    border: "1.5px solid #FF3D00",
    borderRadius: "50px",
  },
  label_style: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    // marginLeft: '20px',
    fontSize: "15px",
    textDecoration: "none",
    textTransform: "none",
    // color: 'black'
  },
}));

function DashboardViewSingleBookingReview(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [update] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [open_button, setOpenbutton] = React.useState(false);
  const [check_review, setCheck_review] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose_ = () => {
    setOpen(false);
    props.clear_error();
    setSubmitted(true);
    setTimeout(() => {
      props.clear_available();
    }, 1000);
    if (update === true) {
      window.location.href = "/dashboard";
    }
  };

  const [name] = useState(localStorage.getItem("book_provider_name"));

  // const [booking_id, setBook_id] = useState(props.booking.id);
  const [message, setMessage] = useState(null);
  // const [changed, setChanged] = React.useState(false);
  const [pet_provider] = React.useState(
    props.booking.service_provider.id
  );
  const [rated_by] = React.useState(props.auth.user.id);
  const [rate, setRate] = React.useState(null);

  function clear_input() {
    setRate(0)
    setMessage("")
  }

  const handleSubmit = () => {
    if (props.book_data.review_made) {
      setCheck_review(true)
    } else {
      if (rate !== null && message !== null && pet_provider && rated_by) {
        props.create_review(
          rate,
          message,
          props.booking.service_provider.id,
          props.auth.user.id
        );
        clear_input()
        setSubmitted(true);
        setOpenbutton(true);
      }
    }
  };

  const confirm_handle_submit = () => {
    if (rate !== null && message !== null && pet_provider && rated_by) {
      setCheck_review(false)
      props.create_review(
        rate,
        message,
        props.booking.service_provider.id,
        props.auth.user.id
      );
      clear_input()
      setSubmitted(true);
      setOpenbutton(true);
    }
  };


  React.useEffect(() => {
    if (props.messages.notify_timeout && submitted === true) {
      setOpenbutton(false);
    }
  }, [props.messages.notify_timeout, submitted]);

  const ratingChanged = (newRating) => {
    setRate(newRating);
  };

  let alert;
  if (props.messages.notify_timeout !== null) {
    alert = <div className="alerts">{props.messages.notify_timeout}</div>;
  }

  return (
    <div>
      <Button
        size="small"
        onClick={handleClickOpen("body")}
        variant="outlined"
        color="primary"
        disabled={props.button_enabled}
      >
        <span className={classes.label_style}>{props.button_label}</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"lg"}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Rate your service provider and add a review
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>

          <div className={classes.reviews}>
            {/* <span className={classes.titleBlock}>Update your booking</span>*/}
            <Grid className={classes.marginLeft} style={{ marginLeft: "10px" }} container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.title_}>Rating</span>
                <div>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
                <div className={classes.column_message}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "Dosis",
                      fontWeight: "bold",
                      color: "#23286D",
                    }}
                  >
                    Comment
                  </span>
                  <br />
                  <Input
                    disableUnderline
                    fullWidth
                    onChange={(e) => setMessage(e.target.value)}
                    multiline
                    rows={4}
                    placeholder={`Share your comment about ${name}'s services.`}
                    value={message}
                    className={classes.input}
                  />
                </div>
                <br />
                <Button
                  variant="outlined"
                  disabled={open_button}
                  style={classes.buttons_send}
                  onClick={handleSubmit}
                >
                  {"Add review"}{" "}
                  {props.book_data.loading_review === true ? (
                    <div style={{ marginLeft: "8px", marginTop: "10px" }}>
                      <img src={Spinner} alt="" height="25px" width="25px" />
                    </div>
                  ) : null}
                </Button>

                {props.messages.notify_timeout && submitted === true ? (
                  <Grid item md={12} sm={12} xs={12}>
                    <Alert
                      severity="info"
                      style={{ marginTop: "20px" }}
                      icon={false}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            props.clear_error();
                            setSubmitted(true);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div className={classes.message}>{alert}</div>
                    </Alert>
                  </Grid>
                ) : null}

                {props.book_data.review_made && check_review ? (
                  <Grid item md={12} sm={12} xs={12}>
                    <Alert
                      severity="info"
                      style={{ marginTop: "20px" }}>
                      <div className={classes.message}>{"Are you sure you want to add another review ?"}</div>
                      <span
                        onClick={confirm_handle_submit}
                        style={{
                          cursor: "pointer",
                          marginRight: "200px",
                          color: 'red',
                          fontWeight: 'bold'
                        }} >{"Yes"}</span>
                      <span
                        onClick={() => {
                          handleClose_()
                          setCheck_review(false)
                        }}
                        style={{ cursor: "pointer", fontWeight: 'bold', color: 'red' }}>
                        {"No"}</span>
                    </Alert>
                  </Grid>
                ) : null}

              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose_}
            variant="outlined"
            style={{
              color: "#FF3D00",
              justifyContent: "center",
              backgroundColor: "#fff!important",
              border: "1.5px solid #FF3D00",
              borderRadius: "50px",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pets: state.pets.pets,
  form: state.forms,
  book_data: state.booking,
  messages: state.messages,
  availability: state.forms.availability_data,
  details: state.results.petproviderdetails,
});

export default connect(mapStateToProps, {
  get_availability_data,
  clear_aval,
  getPetData,
  clear_error,
  clear_available,
  update_booking,
  create_review,
})(DashboardViewSingleBookingReview);
