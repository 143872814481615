import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Collapse from "@material-ui/core/Collapse";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import {
  create_payment_method,
  clear_error,
  get_payment_method,
  create_payment_method_first,
  clear_state_payment,
} from "../actions/form";
import { createBankAccount } from "../actions/bank-accounts";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from "../assets/home_load.gif";
import PaymentBankAccountsList from './PaymentBankAccountsList';
import NumberFormat from "react-number-format";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    width: '70%',
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("550")]: {
      margin: 0,
    },
  },
  root_wizard: {
    flexGrow: 1,
    backgroundColor: "white",
    width: '85%',
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("550")]: {
      margin: 0,
    },
  },
  margin: {
    padding: theme.spacing(2),
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall_: {
    fontSize: "13px",
    width: "500px",
    height: "30px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formLabel_: {
    fontSize: "14x",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "800",
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("550")]: {
      display: 'block',
      margin: 0,
      padding: '0 10px'
    },
  },
  formLabel__: {
    fontSize: "10x",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "200",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("550")]: {
      display: 'block',
      margin: 0,
      padding: '0 10px'
    },
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginTop: theme.spacing(2),
  },
  svg_image: {
    height: "35px",
    width: "35px",
    paddingTop: theme.spacing(2.5),
    [theme.breakpoints.down("680")]: {
      paddingTop: 0,
      paddingBottom: "20px",
    },
  },
  equalize: {
    paddingTop: theme.spacing(2.5),
  },
  btnContainer: {
    [theme.breakpoints.down("550")]: {
      display: "flex",
      justifyContent: 'center',
      padding: '20px',
    },
  },
  button_: {
    alignItems: "center",
    color: "#FF3D00",
    float: "left",
    justifyContent: "center",
    backgroundColor: "#fff!important",
    border: "1.5px solid #FF3D00",
    borderRadius: "7px",
    [theme.breakpoints.down("550")]: {
      margin: "0 !important",
    },
  },
  button2: {
    alignItems: "center",
    color: "#FF3D00",
    float: "right",
    justifyContent: "center",
    backgroundColor: "#fff!important",
    border: "1.5px solid #FF3D00",
    borderRadius: "7px",
  },
}));

function PaymentBankAccounts(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [account_number, setAccount_number] = React.useState(null);
  const [routing_number, setRouting_number] = React.useState(null);
  const [disable, setDisable] = React.useState(true);

  const [open, setOpen] = React.useState(true);

  const saving_details = () => {
    if (account_number !== null && routing_number !== null) {
      props.createBankAccount(account_number, routing_number);
    }
    props.fetch_again();
    setDisable(true);
    clearState();
    if(!props.wizard){
      setTimeout(() => {
        props.fetch_again();
        localStorage.setItem('routing_to', 'payment');
        window.location.reload()
      }, 3000);
    }
  };

  const clearState = () => {
    setAccount_number("");
    setRouting_number("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (account_number && routing_number) {
      setDisable(false);
    }
  }, [account_number, routing_number]);

  let alert;
  if (props.errors?.msg?.non_field_errors) {
    let msg = props.errors?.msg?.non_field_errors;
    alert = <div className="alerts">{msg}</div>;
  }
  if (props?.errors?.msg) {
    if(props?.errors?.msg?.data){
      if(props?.errors?.msg?.data?.routing_number){
        alert = <div className="alerts">{props?.errors?.msg?.data?.routing_number[0]}</div>;
      }else{
       alert = <div className="alerts">{props?.errors?.msg?.data[0]}</div>;
      } 
    }
  }

  if (props.messages?.notify_timeout !== null) {
    alert = <div className="alerts">{props.messages?.notify_timeout}</div>;
  }

  const [show_form, setShow_form] = React.useState(false);
  const set_show_form = () => {
    setShow_form(!show_form);
    // if (show_form === true) {
    //   scroll.scrollToTop();
    // }
  };

  return (
    <Paper elevation={0}>
      <div className={props.wizard ? classes.root_wizard : classes.root}>
        {!props.wizard ?
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Bank Accounts"
            >
              <Tab label="Bank Account" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <br />
        </>
        : null}
        {!props.wizard ?
         <>
          <FormLabel component="label" className={classes.formLabel_}>
            <span>Your earnings will be deposited into this bank account every two weeks.</span>
          </FormLabel> 
          <br />
         </>: null}
         {props.wizard ?
         <>
          <FormLabel component="label" className={classes.formLabel__}>
            <span>Add a bank account for your payment deposits.</span>
          </FormLabel> 
         </>: null}
        {" "}
        <TabPanel style={{ margin: "0px" }} value={value} index={0}>
          {/* Code to connect to Stripe goes here */}

          <PaymentBankAccountsList
            payment_methods={props.payment_methods}
            bank_accounts={props.bank_accounts}
            schedules={props.schedules}
            fetch_again={props.fetch_again}
          />


          <div>
          {show_form === true ? (
              <Grid container spacing={0} >
                <Grid item xs={12} md={12} sm={12}>
                  <Grid sm={false} container spacing={0} alignItems="flex-end">
                    <Grid item>
                      <FormLabel component="label" className={classes.formLabel}>
                        Account number
                      </FormLabel>{" "}
                      <br />
                    </Grid>
                  </Grid>
                  <NumberFormat
                    format="#############"
                    placeholder="Your account number"
                    customInput={Input}
                    value={account_number}
                    disableUnderline
                    style={{ width: "100%" }}
                    className={classes.inputSmall}
                    onChange={(e) => setAccount_number(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <Grid sm={false} container spacing={0} alignItems="flex-end">
                    <Grid item>
                      <FormLabel component="label" className={classes.formLabel}>
                        Routing number
                      </FormLabel>{" "}
                      <br />
                    </Grid>
                  </Grid>
                  <NumberFormat
                    format="#############"
                    placeholder="Your routing number"
                    customInput={Input}
                    value={routing_number}
                    disableUnderline
                    style={{ width: "100%" }}
                    className={classes.inputSmall}
                    onChange={(e) => setRouting_number(e.target.value)}
                  />
                </Grid>

                <div style={{ 
                   display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'space-between'
                }}>
                  <br />
                  <Button
                    disabled={disable}
                    variant="contained"
                    style={{
                      maxWidth: "160px",
                      maxHeight: "35px",
                      minWidth: "160px",
                      minHeight: "35px",
                      marginBottom: '10px'
                    }}
                    color="primary"
                    onClick={saving_details}
                    className={classes.button}
                    startIcon={<AddCircleRoundedIcon />}
                  >
                    {"Add"}{" "}
                    {props.booking.loading_payout === true ? (
                      <div
                        style={{
                          marginLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <img src={Spinner} alt="" height="25px" width="25px" />
                      </div>
                    ) : null}
                  </Button>
                  {!props.wizard ?
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      maxWidth: "160px",
                      maxHeight: "35px",
                      minWidth: "160px",
                      minHeight: "35px",
                      marginBottom: '10px',
                      marginLeft: `${window.innerWidth - 1000}px`
                    }}
                      onClick={set_show_form}
                      className={classes.button2}
                    >
                      {"Close"}
                    </Button>: null}

                </div>
                <br />
                {props.messages.notify_timeout ? (
                  <Grid item xs={12}>
                    <Collapse in={open}>
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                              props.clear_error();
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div className={classes.message}>{alert}</div>
                      </Alert>
                    </Collapse>
                  </Grid>
                ) : null}
                {props?.errors?.msg?.data ? (
                  <Grid item xs={12}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            props.clear_error();
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div className={classes.message}>{alert}</div>
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
              ) : (
                <div className={classes.btnContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      maxWidth: "130px",
                      maxHeight: "35px",
                      minWidth: "130px",
                      minHeight: "35px",
                    }}
                    onClick={set_show_form}
                    className={classes.button_}
                  >
                    {"Add new +"}
                  </Button>
                </div>
              )}
            </div>

        </TabPanel>
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  booking: state.booking,
  user: state.auth.user,
  messages: state.messages,
  errors: state.errors,
  all_services: state.services.admin_services,
});

export default connect(mapStateToProps, {
  create_payment_method,
  get_payment_method,
  create_payment_method_first,
  clear_state_payment,
  createBankAccount,
  clear_error,
})(PaymentBankAccounts);
