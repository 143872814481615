import * as React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  noPaymentsText: {
    color: "#a6a6a6",
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "600",
  },
}));

export default function PaymentsList() {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>
        {" "}
        Payments History{" "}
      </Typography>
      <div style={{ height: 400, width: "100%" }}>
        <Typography className={classes.noPaymentsText}>
          No payments yet
        </Typography>
      </div>
    </Paper>
  );
}
