import {
  CREATE_MESSAGES,
  CLEAR_BACKGROUNDCHECK_ERROR,
  CREATE_MESSAGES_SCHEDULE,
  CREATE_MESSAGES_STATUS
} from '../actions/types';

const initialState = {
  notify_timeout: null,
  notify_schedule: null,
  notify_status: null,
  notify_id: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_MESSAGES:
      return {
        ...state,
        notify_timeout: action.payload
      };
    case CREATE_MESSAGES_STATUS:
      return {
        ...state,
        notify_status: action.payload,
        notify_id: action.payload_id,
      };
    case CLEAR_BACKGROUNDCHECK_ERROR:
      return {
        ...state,
        notify_timeout: null,
        notify_schedule: null,
        notify_status: null,
        notify_id: null
      };
    case CREATE_MESSAGES_SCHEDULE:
      return {
        ...state,
        notify_schedule: action.payload
      };
    default:
      return state;
  }
}
