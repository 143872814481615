/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import DashboardViewBookings from "../components/DashboardViewBookings";
// import Spinner from "../assets/home_load.gif";

import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import {
  sendUserData,
  getServiceData,
  getPetData,
  sendServiceData,
  getPetTypes,
} from "../actions/form";

import {
  get_bookings,
  get_upcoming_recur_bookings,
  get_past_nonrecur_bookings,
  get_past_recur_bookings
} from "../actions/booking";

import DashboardProfileCompleteness from "../components/DashboardProfileCompleteness";
import { logout, loadUser } from "../actions/auth.js";
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  accordion: {
    margin: theme.spacing(1, 0),
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    fontSize: "25px",
    color: "black",
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();

  const fetch_again = (e) => {
    props.get_bookings();
    props.get_past_nonrecur_bookings();
  };

  React.useEffect(() => {
    props.get_bookings();
    props.get_past_nonrecur_bookings();
    scroll.scrollToTop();
  }, []);

  const get_more = () => {
    if (props.bookings_next !== null) {
      console.log("Here 1")
      console.log(props.bookings_next)
      props.get_bookings(props.bookings_next);
    }
  }

  const get_more_prev = () => {
    if (props.bookings_prev !== null) {
      props.get_bookings(props.bookings_prev);
    }
  }

  const get_more_past = () => {
    if (props.past_nonrecur_bookings_next !== null) {
      props.get_past_nonrecur_bookings(props.past_nonrecur_bookings_next);
    }
  }

  const get_past_prev = () => {
    if (props.past_bookings_prev !== null) {
      props.get_past_nonrecur_bookings(props.past_bookings_prev);
    }
  }

  return (
    <div>
      <DashboardProfileCompleteness />
      <br />
      <div>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <span className={classes.titleBlock} id="bookingH1">
            Your Bookings
          </span>
        </AccordionSummary>
      </div>
      <div>
        <div>
          <DashboardViewBookings
            booking_data={props.bookings}
            upcoming_recur_bookings={props.upcoming_recur_bookings}
            past_nonrecur_bookings={props.past_nonrecur_bookings}
            past_recur_bookings={props.past_recur_bookings}
            fetch_again={fetch_again}
            get_more={get_more}
            get_more_prev={get_more_prev}
            get_more_past={get_more_past}
            get_past_prev={get_past_prev}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  services: state.services,
  pets: state.pets.pets,
  types: state.pets.pet_types,

  bookings: state.booking.bookings,
  bookings_next: state.booking.bookings_next,
  bookings_prev: state.booking.bookings_prev,

  upcoming_recur_bookings: state.booking.upcoming_recur_bookings,
  upcoming_recur_bookings_next: state.booking.upcoming_recur_bookings_next,
  past_bookings_prev: state.booking.past_bookings_prev,

  past_nonrecur_bookings: state.booking.past_nonrecur_bookings,
  past_nonrecur_bookings_next: state.booking.past_nonrecur_bookings_next,

  past_recur_bookings: state.booking.past_recur_bookings,
  past_recur_bookings_next: state.booking.past_recur_bookings_next,

});

export default connect(mapStateToProps, {
  logout,
  loadUser,
  sendUserData,
  getServiceData,
  getPetData,
  sendServiceData,
  getPetTypes,
  get_bookings,
  get_upcoming_recur_bookings,
  get_past_nonrecur_bookings,
  get_past_recur_bookings,
})(ResponsiveDrawer);
