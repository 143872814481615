import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";
import { useState } from "react";
import Local from "../assets/local.svg";
import Exp from "../assets/cleaning.svg";
import Mark from "../assets/checked.svg";
import Avatar from "@material-ui/core/Avatar";
// import Link from "@material-ui/core/Link";
import GuestNavBar from "../components/GuestNavBar";
import PrimarySearchAppBar from "../components/AuthorizedUserHomepageNavbar";
import ProviderGalleryView from "../components/ProviderGalleryView";
import { get_availability_data, clear_aval } from "../actions/form.js";
import { fetch_reviews } from "../actions/booking.js";
// import AppointmentsBook from "../views/AppointmentsBook";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Spinner from "../assets/home_load.gif";
// import Chip from "@material-ui/core/Chip";
import CryptoJS from 'crypto-js';
import DetailsService from './DetailsService'

import Geocode from "react-geocode";
import HaversineGeolocation from "haversine-geolocation";
// Geocode.setApiKey("AIzaSyCQcx5SoD1hCaNt1-LcQREL-MGdMD6kato");
Geocode.setApiKey("AIzaSyCQcx5SoD1hCaNt1-LcQREL-MGdMD6kato");
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    paddingTop: "73px",
    paddingBottom: "20px",
    background: "#D4F2F2",
  },
  page: {
    padding: "45px 92px 44px 107px",
    width: "100%",
    background: "white",
    minHeight: "400px",
    borderRadius: "10px",
    overflow: "hidden",
    [theme.breakpoints.down("768")]: {
      maxWidth: "100%",
      margin: "0 auto",
      padding: "45px 20px 44px 20px",
    },
  },
  head: {
    marginBottom: "48px",
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("550")]: {
      flexWrap: 'wrap'
    },
  },
  avatar: {
    marginRight: "95px",
    width: "184px",
    maxWidth: "184px",
    textAlign: "center",
    [theme.breakpoints.down("768")]: {
      width: "100%",
      maxWidth: "100%",
      marginRight: "30px",
    },
    [theme.breakpoints.down("450")]: {
      marginRight: "0px",
    },
  },
  avatarImg: {
    width: "184px",
    maxWidth: "184px",
    height: "184px",
    maxHeight: "184px",
    [theme.breakpoints.down("768")]: {
      width: "140px",
      maxWidth: "140px",
      height: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("550")]: {
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      maxHeight: "100%",
    },
    "& > img": {
      width: "100%",
    },
  },
  numberStar: {
    marginLeft: "7px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#000000",
  },
  starRatings: {
    marginTop: "25px",
    marginLeft: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("768")]: {
      marginLeft: "0px",
    },
  },
  headInfo: {
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.down("550")]: {
      marginTop: "20px"
    },
  },
  headName: {
    marginTop: "20px",
    maxWidth: "fit-content",
    marginBottom: "8px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "36px",
    letterSpacing: "0.15px",
    color: "#222222",
    position: "relative",
    [theme.breakpoints.down("768")]: {
      marginTop: 0,
      marginBottom: "20px",
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  background_check: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    color: "grey",
    fontSize: "55px",
  },
  headName_: {
    marginTop: "5px",
    maxWidth: "fit-content",
    marginBottom: "5px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "36px",
    letterSpacing: "0.15px",
    color: "#222222",
    position: "relative",
    [theme.breakpoints.down("768")]: {
      marginTop: 0,
      marginBottom: "20px",
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  headName__: {
    marginTop: "10px",
    maxWidth: "fit-content",
    marginBottom: "6px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "0.15px",
    color: "#222222",
    position: "relative",
    [theme.breakpoints.down("768")]: {
      marginTop: 0,
      marginBottom: "20px",
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  headMark: {
    position: "absolute",
    right: "-26px",
    top: "0",
    [theme.breakpoints.down("768")]: {
      right: "-26px",
      left: "auto",
    },
  },
  headLocation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  headLocationImg: {
    marginRight: "20px",
  },
  headLocationTitle: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#383F45",
  },
  headLocationSubtitle: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#1b5e2c",
    marginBottom: "30px",
  },
  headLocationBtn: {
    marginTop: "14px",
    width: "149px",
    height: "41px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    background: "#156981",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "background 0.4s",
    [theme.breakpoints.down("768")]: {
      marginTop: "12px",
      fontSize: "20px",
      height: "36px",
    },
    "&:hover": {
      background: "#88CCDD",
    },
  },
  title: {
    marginBottom: "14px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "33px",
    textDecorationLine: "underline",
    color: "#222222",
    [theme.breakpoints.down("768")]: {
      marginBottom: "5px",
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  title_: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "350",
    fontSize: "32px",
    lineHeight: "58px",
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  title_no: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "350",
    fontSize: "19px",
    lineHeight: "58px",
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  title__: {
    maxWidth: "624px",
    marginBottom: "9px",
    alignItems: "center",
    fontWeight: "350",
    fontSize: "18px",
    lineHeight: "58px",
    color: "black",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
  },
  description: {
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: "10px",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#383F45",
    [theme.breakpoints.down("768")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  services: {
    width: "100%",
    [theme.breakpoints.down("1110")]: {
      width: "50%",
    },
    [theme.breakpoints.down("950")]: {
      maxWidth: "auto",
    },
    [theme.breakpoints.down("550")]: {
      width: "100%",
    },
  },
  serviceItem: {
    [theme.breakpoints.down("450")]: {
      width: '100%',
      "& > div": {
        width: '100%',
         justifyContent: "flex-start",
      }
    },
  },
  servicesWraper: {
    MaxWidth: "580px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("1150")]: {
      width: "400px",
      // marginLeft: "auto",
    },
    [theme.breakpoints.down("1100")]: {
      width: "290px",
    },
    [theme.breakpoints.down("1000")]: {
      width: "100%",
    },
    [theme.breakpoints.down("960")]: {
      width: "100%",
    },
    [theme.breakpoints.down("700")]: {
      width: "100%",
      marginLeft: "0px",
      marginTop: "8px",
      marginBottom: "8px",
    },
    [theme.breakpoints.up("700")]: {
      '& > div': {
        width: '100%'
      },
      justifyContent: "flex-start",
    },
  },
  servicesItem: {
    marginBottom: "14px",
    padding: "11px 0px 8px 0px",
    width: "calc((100% - 40px) / 2)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    background: "#F2F2F2",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    [theme.breakpoints.down("1110")]: {
      width: "calc((100% - 20px) / 2)",
    },
    [theme.breakpoints.down("950")]: {
      width: "100%",
    },
    [theme.breakpoints.down("768")]: {
      padding: "5px 0px 5px 0px",
      marginBottom: "8px",
    },
  },
  servicesTitle: {
    marginBottom: "4px",
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "40px",
    color: "#1B5E20",
    [theme.breakpoints.down("768")]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  servicesPrice: {
    marginBottom: "13px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#000000",
    [theme.breakpoints.down("768")]: {
      marginBottom: "5px",
    },
  },
  servicesBtn: {
    width: "91px",
    height: "25px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FFFFFF",
    background: "#156981",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "background 0.4s",
    [theme.breakpoints.down("768")]: {
      width: "70px",
      height: "20px",
    },
    "&:hover": {
      background: "#88CCDD",
    },
  },
  contant: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("1110")]: {
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.down("950")]: {
      maxWidth: "680px",
      margin: "0 auto",
    },
    [theme.breakpoints.down("550")]: {
      flexWrap: "wrap-reverse"
    },
  },
  contantInfo: {
    width: "100%",
    maxWidth: "564px",
    [theme.breakpoints.down("1250")]: {
      maxWidth: "50%",
    },
    [theme.breakpoints.down("950")]: {
      maxWidth: "50%",
      marginRight: "20px",
    },
    [theme.breakpoints.down("550")]: {
      maxWidth: "100%",
      marginRight: "0px",
    },
  },
  about: {
    //  marginBottom: '76px',
    maxWidth: "444px",
    [theme.breakpoints.down("950")]: {
      marginBottom: "30px",
    },
  },
  gallery: {
    marginBottom: "106px",
    maxWidth: "444px",
    [theme.breakpoints.down("950")]: {
      marginBottom: "30px",
    },
  },
  post: {
    marginBottom: "34px",
    "&:last-child": {
      marginBottom: "28px",
    },
  },
  postHead: {
    marginBottom: "14px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  postAvatar: {
    marginRight: "17px",
    maxHeight: "53px",
    maxWidth: "53px",
  },
  postAvatarImg: {
    width: "100%",
  },
  rating: {
    marginLeft: "auto",
  },
  postHeadName: {
    marginBottom: "7px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#383F45",
  },
  buttonAside: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#828282",
  },
  postText: {
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#383F45",
    [theme.breakpoints.down("768")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  postsMore: {
    display: "block",
    margin: "0 auto",
    width: "127px",
    height: "24px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FFFFFF",
    background: "#BDBDBD",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  },

  postsMore_: {
    // display: "block",
    // margin: "0 auto",
    marginLeft: '20px',
    marginTop: '20px',
    width: "127px",
    height: "32px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FFFFFF",
    background: "#FF5722",
    borderRadius: "7px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  },

  imageSelector: {
    fontSize: "8rem",
    width: theme.spacing(26),
    height: theme.spacing(26),
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("550")]: {
      margin: 'auto'
    },
  },
  reviews: {
    marginTop: "30px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  chip: {
    marginRight: "10px" ,
    width: '45%',
    [theme.breakpoints.down("950")]: {
      width: '90%',
    },
    [theme.breakpoints.down("1250")]: {
      width: '80%',
    },
    [theme.breakpoints.down("1110")]: {
      width: '80%',
    },
    
    [theme.breakpoints.down("550")]: {
      margin: 'auto',
      width: 'auto'
    },
  }
}));

function PetProviderInfo(props) {
  const classes = useStyles();

  let services;
  if (props.services) {
    services = props?.services;
  }

  const redirect_book = (e) => {
    // console.log(props.id)
    const name = props?.first_name;
    localStorage.setItem("book_provider_name", name);

    const userID = props.id.toString()
    const secretKey = '0j9$%)m46vym@vuov_@o1mby*4s6q3#q0+pb=9c3*!d0ocjdzt'
    const encryptedUserID = CryptoJS.AES.encrypt(userID, secretKey).toString();
    const encodedUserID = encodeURIComponent(encryptedUserID);

    // window.location.href = `/petprovider/${props.id}`;
    window.location.href = `/petprovider/${encodedUserID}`;
  };

  const redirect_gallery = (e) => {
    const userID = props.id.toString()
    const secretKey = '0j9$%)m46vym@vuov_@o1mby*4s6q3#q0+pb=9c3*!d0ocjdzt'
    const encryptedUserID = CryptoJS.AES.encrypt(userID, secretKey).toString();
    const encodedUserID = encodeURIComponent(encryptedUserID);
    window.location.href = `/gallery/provider/${encodedUserID}`
  };

  const _id = props?.user_id;
  React.useEffect(() => {
    props.get_availability_data(_id);

    // making sure we get current provider availability data
    const name = props.first_name;
    const id = props.user_id;
    props.fetch_reviews(id);
    props.fetch_reviews(id);
    localStorage.setItem("book_provider_name", name);
    localStorage.setItem("book_provider_id", id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  React.useEffect(() => {
    if (props.availability_data_next !== null) {
      props.get_availability_data(_id, props.availability_data_next);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.availability_data_next]);

  const [data, setData] = useState(null);
  // const fetching = () => {
  //   props.clear_aval();
  //   props.get_availability_data(_id);
  //   props.get_availability_data(_id);
  // };

  // const get_new = (date) => {
  //   console.log("");
  // };

  const [owner_lat, setOwner_lat] = useState("");
  const [owner_lng, setOwner_lng] = useState("");
  const [provider_lat, setProvider_lat] = useState("");
  const [provider_lng, setProvider_lng] = useState("");
  const [distance, setDistance] = useState(null);

  
  // calculating miles
  React.useEffect(() => {
    if (props?.auth?.user) {
      const pet_owner_address = props.auth.user.address_line_1;
      const pet_provider_address = props.address_line_1;

      // decoding adresses
      Geocode.fromAddress(pet_owner_address).then(
        (response) => {
          setOwner_lat(response?.results[0]?.geometry?.location.lat);
          setOwner_lng(response?.results[0]?.geometry?.location.lng);
        },
        (error) => {
          console.error(error);
        }
      );

      Geocode.fromAddress(pet_provider_address).then(
        (response) => {
          setProvider_lat(response?.results[0]?.geometry?.location.lat);
          setProvider_lng(response?.results[0]?.geometry?.location.lng);
        },
        (error) => {
          console.error(error);
        }
      );

      if (owner_lat && owner_lng && provider_lat && provider_lng) {
        const owner_point = {
          latitude: owner_lat,
          longitude: owner_lng,
        };
        const provider_point = {
          latitude: provider_lat,
          longitude: provider_lng,
        };

        // calculating miles
        const points = [owner_point, provider_point];
        // Distance in miles
        setDistance(
          HaversineGeolocation.getDistanceBetween(points[0], points[1], "mi")
        ); // miles
        console.log("distance btwn owner and provider:");
        console.log(distance);
      }
    } else {
    }
  }, [
    props.auth,
    owner_lat,
    owner_lng,
    provider_lat,
    provider_lng,
    distance,
    props.address_line_1,
  ]);

  // AVailability Regoin
  React.useEffect(() => {
    if (props.availability) {
      const list = [];
      function getWeeklyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 7);
        }
        return result;
      }
      function getBiWeeklyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 14);
        }
        return result;
      }

      function getMonthlyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 31);
        }
        return result;
      }

      function getYearlyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 365);
        }
        return result;
      }

      // eslint-disable-next-line array-callback-return
      props.availability.filter((availability) => {
        const end_time = availability.end_time;
        const start_time = availability.start_time;
        const start_date = availability.start_date;
        const end_date = availability.end_date;

        var dateString_start = start_date + " " + start_time;
        var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArray = reggie.exec(dateString_start);
        var dateObject_start = new Date(
          +dateArray[1],
          +dateArray[2] - 1, // Careful, month starts at 0!
          +dateArray[3],
          +dateArray[4],
          +dateArray[5],
          +dateArray[6]
        );

        var dateString_start_endtime = start_date + " " + end_time;

        var dateString_end = end_date + " " + end_time;
        var dateArray_end = reggie.exec(dateString_end);
        var dateObject_end = new Date(
          +dateArray_end[1],
          +dateArray_end[2] - 1, // Careful, month starts at 0!
          +dateArray_end[3],
          +dateArray_end[4],
          +dateArray_end[5],
          +dateArray_end[6]
        );

        const id = availability.id;
        const recur = availability.recurring;
        const object_ = {
          recurring: availability.recurring,
          interval: availability.interval,
          end: dateString_end,
        };
        let object;
        if (recur === true) {
          if (availability.interval === "weekly") {
            const count = getWeeklyDates(start_date, end_date).length;
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=WEEKLY;COUNT=${count}`,
              location: object_,
            };
            list.push(object);
          } else if (availability.interval === "biweekly") {
            const count = getBiWeeklyDates(start_date, end_date).length;
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=WEEKLY;COUNT=${count};INTERVAL=2`,
              location: object_,
            };
            list.push(object);
          } else if (availability.interval === "monthly") {
            const count = getMonthlyDates(start_date, end_date).length;
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=MONTHLY;COUNT=${count}`,
              location: object_,
            };
            list.push(object);
          } else if (availability.interval === "annually") {
            const count = getYearlyDates(start_date, end_date).length;
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=YEARLY;COUNT=${count}`,
              location: object_,
            };
            list.push(object);
          } else {
            // daily
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateObject_end,
              id: id,
              location: object_,
            };
            list.push(object);
          }
        } else {
          object = {
            title: "Not Available",
            startDate: dateObject_start,
            endDate: dateObject_end,
            id: id,
            location: object_,
          };
          list.push(object);
        }
      });
      // console.log("list: setting data")
      // console.log(list.length)
      // console.log(list)
      setData(list);
    }
  }, [props.availability, props.availability_data_next]);

  // const [current, setCurrent] = useState("");
  const [reviews_length, setLength] = useState(3);

  React.useEffect(() => {
  //   var date = new Date().getDate();
  //   var month = new Date().getMonth() + 1;
  //   var year = new Date().getFullYear();
  //   var current_date = year + "-" + month + "-" + date;
  //   setCurrent(current_date);
  }, [data]);

  React.useEffect(() => {
    if (props.form.isLoading === true) {
      props.get_availability_data(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, props.form.isLoading]);

  const set_reviews_length = () => {
    console.log(reviews_length);
    if (reviews_length === 3) {
      setLength(props?.reviews?.count);
    } else if (reviews_length > 3) {
      setLength(3);
    }
  };

  // const redirect_signin = (e) => {
  //   const bool = true;
  //   localStorage.setItem("user_from_booking", bool);
  //   window.location.href = "/login";
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [image_data, setImageData] = useState(null);
  React.useEffect(() => {
    const images_list = [];
    if (props.images) {
      if(props?.images?.length !== 0){
        const first_image = props.images[0].id;
        // eslint-disable-next-line array-callback-return
        props.images.filter((single_image) => {
          if (single_image.id === first_image) {
            const image_object = {
              id: single_image.id,
               width: 320,
               height: 212,
               src: single_image.image,
               thumbnail: single_image.image,
               isSelected: false,
            };
            images_list.push(image_object);
          } else {
            const image_object = {
              id: single_image.id,
               width: 320,
               height: 212,
               src: single_image.image,
               thumbnail: single_image.image,
               isSelected: false,
            };
            images_list.push(image_object);
          }
        });
      }else{
        setImageData(null);
      }
    }
    setImageData(images_list);
  }, [props.images]);

  const [certificate_data, setCertificateData] = useState(null);
  React.useEffect(() => {
    const certificate_list = [];
    if (props.certificates) {
      if(props?.certificates?.length !== 0){
        const first_image = props.certificates[0].id;
        // eslint-disable-next-line array-callback-return
        props.certificates.filter((single_image) => {
          if (single_image.id === first_image) {
            const image_object = {
              id: single_image.id,
               src: single_image.certificate,
               thumbnail: single_image.certificate,
               isSelected: false,
            };
            certificate_list.push(image_object);
          } else {
            const image_object = {
              id: single_image.id,
              src: single_image.certificate,
              thumbnail: single_image.certificate,
              isSelected: false,
            };
            certificate_list.push(image_object);
          }
        });
      }else{
        setCertificateData(null);
      }
    }
    setCertificateData(certificate_list);
  }, [props.certificates]);

  return (
    <div className={classes.root}>
      <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
      <div>{!props.auth.token && <GuestNavBar />}</div>
      <Container maxWidth="lg" className={classes.wizardContainer}>
        <div className={classes.page}>
          <div className={classes.head}>
            <div className={classes.avatar}>
              <div className={classes.avatarImg}>
                <Avatar
                  className={classes.imageSelector}
                  variant="rounded"
                  alt="profile picture"
                  src={props?.avatar}
                />
              </div>
              <div className={classes.starRatings}>
                <Rating
                  classes={classes.star}
                  name="customized-empty"
                  value={props?.rating}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
                <div className={classes.numberStar}>({props?.rating})</div>
              </div>
            </div>
            <div className={classes.headInfo}>
              <h2 className={classes.headName}>
                {props.first_name} {props.last_name}
                {props.background_check_status === "passed" &&
                  props.background_check_complete === true ? (
                  <div>
                    <img
                      className={classes.headMark}
                      src={Mark}
                      alt=""
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    />
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography className={classes.background_check}>
                        Background check cleared!
                      </Typography>
                    </Popover>
                  </div>
                ) : null}
              </h2>
              <h2 className={classes.headName_}>{props.tagline}</h2>
              <h2 className={classes.headName__}>{props.country}</h2>
              <div className={classes.headLocation}>
                <img
                  className={classes.headLocationImg}
                  src={Local}
                  alt="local"
                />
                <div className={classes.headLocationText}>
                  <h2 className={classes.headLocationTitle}>
                    {" "}
                    {props.state}, {props.address_line_1}
                  </h2>
                  {props.auth.isAuthenticated ? (
                    <div>
                      {distance !== null ? (
                        <span className={classes.headLocationSubtitle}>
                          {distance} miles away
                        </span>
                      ) : (
                        <span className={classes.headLocationSubtitle}>
                          Calculating miles...
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={classes.headLocation}>
                <img
                  className={classes.headLocationImg}
                  src={Exp}
                  height="25px"
                  width="25px"
                  alt="local"
                />
                <div className={classes.headLocationText}>
                  <h2 className={classes.headLocationTitle}>
                    Experience: {props.experience_years} years,{" "}
                    {props.experience_months} months
                  </h2>
                </div>
              </div>
              <button
                id="contact_btn"
                onClick={redirect_book}
                className={classes.headLocationBtn}
              >
                BOOK
              </button>
            </div>
          </div>
          <div className={classes.contant}>
            <div className={classes.services}>
              <div className={classes.title}>Services</div>
              <div className={classes.servicesWraper}>
                {props?.services
                  ? services.map((service, idx) => (
                    <div key={idx} className={classes.serviceItem} style={{ margin: "5px" }}>
                      <DetailsService 
                         label={`${service.service.name} at $${service.price} ${service.rate.name}`}
                         image={service.service.icon}
                         has_additional_rates={service?.additional_rates?.length > 0}
                         service={service}
                      />
                      {/*<Chip
                        className={classes.chip}
                        label={`${service.service.name} at $${service.price} ${service.rate.name}`}
                        icon={
                          <img
                            src={service.service.icon}
                            style={{ marginLeft: "10px" }}
                            height="20px"
                            width="20px"
                            alt=""
                          />
                        }
                      />*/}
                    </div>
                  ))
                  : null}
              </div>
              {certificate_data !== null && certificate_data?.length !== 0 ? (
                <div>
                  <div className={classes.title}>Certificates</div>
                  <ProviderGalleryView images={certificate_data} />
                </div>
              ) : null}
            </div>
            <div className={classes.contantInfo}>
              <div className={classes.about}>
                <div className={classes.title}>About</div>
                <div className={classes.description}>{props.description}</div>
                <div>
                  {image_data !== null ? (
                    <ProviderGalleryView images={image_data} />
                  ) : null}
                  {props.photos_count > 5 ? 
                      <button
                        onClick={redirect_gallery}
                        className={classes.postsMore_}
                      > 
                        View gallery
                      </button>
                   : null }
                </div>
              </div>
              <div style={{ marginBottom: "30px" }}></div>
            </div>
          </div>

          {/*{
            props?.isMobile === false ?
            <div className={classes.reviews}>
              <div className={classes.title_}>
                {props.first_name}'s Availability
              </div>
              {data && props.availability_data_next === null ? (
                <div className={classes.calendar}>
                  <AppointmentsBook
                    data={data}
                    current={current}
                    fetch={fetching}
                    get_new={get_new}
                  />
                </div>
              ) : (
                <div>
                  {props.auth.isAuthenticated ? (
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      <img src={Spinner} alt="" height="35px" width="35px" />
                    </div>
                  ) : (
                    <div className={classes.title__}>
                      Please{" "}
                      <Link
                        variant="h6"
                        color="primary"
                        onClick={redirect_signin}
                      >
                        sign in
                      </Link>{" "}
                      to view availability
                    </div>
                  )}
                </div>
              )}
            </div> : null
          }*/}
          

          <div className={classes.reviews}>
            <div className={classes.title}>Reviews</div>
            <div>
              {props.reviews_loading === true ? (
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                  }}
                >
                  <img src={Spinner} alt="" height="35px" width="35px" />
                </div>
              ) : (
                <div>
                  {props?.reviews !== null ? (
                    <div className={classes.posts}>
                      {props?.reviews?.count > 0 ? (
                        <div className={classes.post}>
                          {props.reviews.results
                            .slice(0, reviews_length)
                            .map((review, i) => (
                              <div>
                                <div className={classes.postHead}>
                                  <div className={classes.postAvatar}>
                                    <Avatar
                                      alt="avatar"
                                      src={review.rated_by.photo}
                                    />
                                  </div>
                                  <div className={classes.postHeadInfo}>
                                    <h2 className={classes.postHeadName}>
                                      {review.rated_by.first_name}
                                    </h2>

                                    <p
                                      className={classes.postHeadStatus}
                                    >{`${new Date(review.created)
                                      .toString()
                                      .substring(0, 15)}`}</p>
                                  </div>
                                  <div className={classes.rating}>
                                    <Rating
                                      classes={classes.star}
                                      name="customized"
                                      value={review.rating}
                                      precision={1}
                                      emptyIcon={
                                        <StarBorderIcon fontSize="inherit" />
                                      }
                                    />
                                  </div>
                                </div>
                                <p className={classes.postText}>
                                  {review.comment}
                                </p>
                                <br />
                              </div>
                            ))
                            .reverse()}
                          <button
                            onClick={set_reviews_length}
                            className={classes.postsMore}
                            type="button"
                          >
                            {reviews_length === 3 ? "READ MORE" : "SHOW LESS"}
                          </button>
                        </div>
                      ) : (
                        <div className={classes.title_no}>
                          {props.first_name} has no reviews yet
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  availability: state.forms.availability_data,
  availability_data_next: state.forms.availability_data_next,
  reviews: state.booking.reviews,
  reviews_loading: state.booking.reviews_loading,
});

export default connect(mapStateToProps, {
  get_availability_data,
  clear_aval,
  fetch_reviews,
})(PetProviderInfo);
