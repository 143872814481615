import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Input from "@material-ui/core/Input";
import Spinner from "../assets/home_load.gif";

import {
  getPetData,
  user_delete_pet,
  clear_state_pets,
  init_fetch,
  deinit_fetch,
  user_update_pet,
  get_availability_data,
  clear_available,
  clear_error,
} from "../actions/form.js";

import moment from "moment";

// import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import FormLabel from "@material-ui/core/FormLabel";
import PetCardDetailsPet from "./PetCardDetailsPet";
// import DashboardViewSingleBookingEdit from "./DashboardViewSingleBookingEdit";
import DashboardViewSingleBookingReview from "./DashboardViewSingleBookingReview";
import DashboardViewSingleBookingTips from "./DashboardViewSingleBookingTips";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";

import {
  get_bookings,
  confirm_booking,
  delete_booking,
  cant_cancel,
  update_message_booking,
  clear_error_bookings
} from "../actions/booking";

const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  parentDiv: {
    margin: theme.spacing(1),
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  helpText2: {
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(3),
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "3px",
    border: "1px solid #cfd7de",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  line_: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  spin: {
    height: 50,
    width: 50,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleM: {
    [theme.breakpoints.down("450")]: {
      margin: "20px 30px 30px 40px !important",
      width: '100%'
    },
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  button: {
    backgroundColor: "#b53f3fbd",
    float: "right",
    margin: theme.spacing(0, 0, 1),
  },
  submitButton: {
    backgroundColor: "#663399",
    marginLeft: theme.spacing(2),
    float: "right",
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSkeleton: {
    fontSize: "13px",
    color: "#1b1f23",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    fontSize: "20px",
    color: "black",
  },
  checkbox: {
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
  },

  root_: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    color: "#23286B",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading_: {
    fontSize: theme.typography.pxToRem(15),
    color: "blue",
  },
  secondaryHeading__: {
    fontSize: theme.typography.pxToRem(15),
    color: "green",
  },
  secondaryHeading___: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
    color: "#FF4500",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    [theme.breakpoints.down("450")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  column: {
    flexBasis: "33.33%",
  },
  column_message: {
    flexBasis: "33.33%",
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down("550")]: {
      width: "100%"
    },
  },
  column_message_: {
    flexBasis: "33.33%",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down("550")]: {
      width: "100%"
    },
  },
  column_: {
    marginLeft: "auto",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  root_time: {
    marginLeft: "40px",
    marginRight: "40px",
    [theme.breakpoints.down("450")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formLabel_: {
    fontSize: "13px",
    color: "red",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
}));

function convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(":");
    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];
    var hours;
    var period;

    if (HH === 0) {
        hours = "12";
        period = 'AM';
    } else if (HH < 12) {
        hours = HH.toString().padStart(2, "0");
        period = 'AM';
    } else if (HH === 12) {
        hours = "12";
        period = 'PM';
    } else {
        hours = (HH - 12).toString().padStart(2, "0");
        period = 'PM';
    }

    var newFormatTime = hours + ":" + min.padStart(2, "0") + ' ' + period;
    return newFormatTime;
}

function DashboardViewSingleBooking(props) {
  const classes = useStyles();

  const [, setLoad] = React.useState(false);
  const [new_message, setMessage] = React.useState(props.booking.notes);

  const update_new_message = () => {
    props.update_message_booking(new_message, props.booking.id)
  }

  const [data, setData] = useState("");

  const [default_start_] = React.useState(
    new Date(`${props.booking.start_date}T${props.booking.start_time}`)
    );
  const [default_start] = React.useState(
   convertTo12Hour(`${default_start_.getHours()}:${default_start_.getMinutes()}`)
  );

  const [default_end_] = React.useState(
    new Date(`${props.booking.start_date}T${props.booking.end_time}`)
    );
  const [default_end] = React.useState( 
    convertTo12Hour(`${default_end_.getHours()}:${default_end_.getMinutes()}`)
  );

  const cancel_status = async () => {
    if (props.booking) {
      var start = moment(
        `${props.booking.start_date}T${props.booking.start_time}`
      );

      var b = moment(new Date()); // now

      const prime_difference = start.diff(b, "hours");

      if (prime_difference >= 12) {
        // console.log("Can Cancel...")
        await props.confirm_booking(props.booking.id, "cancelled", null);
        props.get_bookings();
        props.get_bookings();
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      } else {
        // console.log("Can't Cancel...")
        props.cant_cancel(props.booking.id);
      }
    }
  };

  React.useEffect(() => {
    if (props.availability) {
      const list = [];

      // eslint-disable-next-line array-callback-return
      props.availability.filter((availability) => {
        const end_time = availability.end_time;
        const start_time = availability.start_time;
        const start_date = availability.start_date;
        const end_date = availability.end_date;

        var dateString_start = start_date + " " + start_time;
        var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArray = reggie.exec(dateString_start);
        var dateObject_start = new Date(
          +dateArray[1],
          +dateArray[2] - 1, // Careful, month starts at 0!
          +dateArray[3],
          +dateArray[4],
          +dateArray[5],
          +dateArray[6]
        );

        var dateString_end = end_date + " " + end_time;
        var dateArray_end = reggie.exec(dateString_end);
        var dateObject_end = new Date(
          +dateArray_end[1],
          +dateArray_end[2] - 1, // Careful, month starts at 0!
          +dateArray_end[3],
          +dateArray_end[4],
          +dateArray_end[5],
          +dateArray_end[6]
        );

        const id = availability.id;

        const object = {
          title: "Not Available",
          startDate: dateObject_start,
          endDate: dateObject_end,
          id: id,
          location: "Dummy",
        };
        list.push(object);
      });
      setData(list);
    } else {
      setLoad(true);
      setData(null);
    }
  }, [props.availability]);

  const [, setCurrent] = useState("");
  const [open_] = React.useState(true);

  React.useEffect(() => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var current_date = year + "-" + month + "-" + date;
    setCurrent(current_date);
  }, [data]);

  // Adding reviews logic
  const [reviewable, setReview] = useState(false);
  const [date_past, setDate_past] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [date_past_buttons, setDate_past_buttons] = React.useState(false);

  // Payments variables
  const [service, setService] = useState(null);
  const [payment_methods, setPayment_method] = useState(null);
  const [sender, setSender] = useState(null);
  const [service_provider, setService_prov] = useState(null);

  React.useEffect(() => {
    if (props.booking !== null) {
      setService(props.booking.service.id);
      setSender(props.booking.created_by.id);
      setService_prov(props.booking.service_provider.id);

      if (props.booking.status === "confirmed") {
        var end_date = moment(
          `${props.booking.end_date}T${props.booking.end_time}`
        );
        var current = moment(new Date());
        const prime_difference = end_date.diff(current, "minutes");
        if (prime_difference <= 0) {
          setReview(true);
        } else {
          setReview(false);
        }
      } else if (props.booking.status === "pending") {
        end_date = moment(
          `${props.booking.end_date}T${props.booking.end_time}`
        );
      } else {
        setDate_past(false);
      }
    }
  }, [props.booking]);

  // Getting Primary Card for payments
  React.useEffect(() => {
    if (props.payment_methods) {
      setPayment_method(props.payment_methods);
    }
  }, [props.payment_methods]);

  let alert;
  if (props.messages.notify_status !== null) {
    alert = <div className="alerts">{props.messages.notify_status}</div>;
  }

  return (
    <div className={classes.parentDiv}>
      {props.booking !== null ? (
        <div>
          <div className={classes.root_}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >

              <div style={{ 
                   width: "100%", 
                   display: 'flex', 
                   justifyContent: 'space-between',
                   flexDirection: 'row'
                }}>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                      <img
                        src={props.booking.service.service.icon}
                        height="17px"
                        width="17px"
                        style={{ marginRight: "5px" }}
                        alt=""
                      />{" "}{" "}
                      {props.booking.service.service.name}{" "}
                      {/* <span className={classes.secondaryHeading}>
                        by {props.booking.service_provider.first_name},
                        
                      </span> */}
                    </span>
                  </div>
                </div>
                <div style={{ width: '110%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {props.booking.service_provider.first_name} {" "}{props.booking.service_provider.last_name}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{`${moment(props.booking?.start_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}`}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{`${moment(props.booking?.end_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}`}
                     </span>
                </div>
                </div>
             
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{default_start}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{default_end}
                     </span>
                </div>
                </div>
              <div style={{width: '100%' }}>
                <div className={classes.column_}>
                  <Typography>
                    {props.booking.status === "pending" ? (
                      <span className={classes.secondaryHeading_}>
                        {" "}
                        {props.booking.status}
                      </span>
                    ) : null}
                    {props.booking.status === "confirmed" ? (
                      <span className={classes.secondaryHeading__}>
                        {" "}
                        {props.booking.status}
                      </span>
                    ) : null}
                    {props.booking.status === "cancelled" ? (
                      <span className={classes.secondaryHeading___}>
                        {" "}
                        {props.booking.status}
                      </span>
                    ) : null}
                    {props.booking.status === "rejected" ? (
                      <span className={classes.secondaryHeading___}>
                        {" "}
                        declined
                      </span>
                    ) : null}
                  </Typography>
                </div>
              </div>
             </div>  
            </AccordionSummary>

              <Grid item xs={12} className={classes.root_time}>
                {date_past ? (
                  <FormLabel component="label" className={classes.formLabel_}>
                    This booking has past.
                  </FormLabel>
                ) : null}
                <Grid container spacing={2}>
                  <Grid item sm={10} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Start time
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      value={default_start}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item sm={10} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      End time
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      value={default_end}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Start date
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      // defaultValue={props.booking.start_date}
                      value={moment(props.booking?.start_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      End date
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      // value={props.booking.end_date}
                      value={moment(props.booking?.end_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}
                      className={classes.line}
                      disableUnderline
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>

                <FormLabel component="label" className={classes.formLabel}>
                  Pet(s)
                </FormLabel>
                <div className={classes.column}>
                  {props.booking.pets.map((pet, i) => (
                    <PetCardDetailsPet pet={pet} />
                  ))}
                </div>
              </Grid>
              <AccordionDetails className={classes.details}>
                <div className={classes.titleM} style={{ marginLeft: "40px" }}>
                  <div className={classes.column}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontFamily: "Dosis",
                        fontWeight: "bold",
                        color: "#23286D",
                      }}
                    >
                      More Details
                    </span>
                    <br />
                    <span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Dosis",
                          fontWeight: "bold",
                          color: "#23286G",
                        }}
                      >
                        Price:{" "}
                      </span>{" "}
                      {"$"} {props.booking.service.price}
                    </span>
                    <br />

                    {props.booking.recurring === true ? (
                      <span>
                        <span
                          style={{
                            fontSize: "18px",
                            fontFamily: "Dosis",
                            fontWeight: "bold",
                            color: "#23286G",
                          }}
                        >
                          Recurring:{" "}
                        </span>{" "}
                        {"Yes"}
                        <br />
                      </span>
                    ) : null}
                    {props.booking.interval !== "none" &&
                      props.booking.interval !== null ? (
                      <span>
                        <span
                          style={{
                            fontSize: "18px",
                            fontFamily: "Dosis",
                            fontWeight: "bold",
                            color: "#23286G",
                          }}
                        >
                          Interval:{" "}
                        </span>{" "}
                        {props.booking.interval}
                        <br />
                      </span>
                    ) : null}
                    <span>
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "Dosis",
                          fontWeight: "bold",
                          color: "#23286G",
                        }}
                      >
                        Rate:{" "}
                      </span>
                      {props.booking.service.rate.name}
                    </span>
                    <br />
                  </div>
                </div>
                <div className={classes.column} />
                <Divider />
                <div className={classes.column_message_}>
                  <div className={classes.column_message}>
                    <span
                      style={{
                        fontSize: "18px",
                        fontFamily: "Dosis",
                        fontWeight: "bold",
                        color: "#23286D",
                      }}
                    >
                      Message
                    </span>
                    <br />
                    <Input
                      disableUnderline
                      fullWidth
                      multiline
                      rows={4}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Share any important notes about your pet(s)."
                      value={new_message}
                      className={classes.input}
                    />
                  </div>
                  {reviewable === false ? ( // booking has not been confirmed
                      <Button
                        size="small"
                        disabled={
                          props.booking.status === "cancelled" ||
                            props.booking.status === "rejected"
                            ? true
                            : false
                        }
                        variant="outlined"
                        onClick={update_new_message}
                        style={{ marginLeft: "20px" }}
                        color="primary"
                      >
                        {"Update message"}
                        {props.bookings.loading_update_message === true &&
                          props.bookings.update_message_id === props.booking.id ? (
                          <div style={{ marginLeft: "7px", marginTop: '8px' }}>
                            <img
                              src={Spinner}
                              alt=""
                              height="16px"
                              width="16px"
                            />
                          </div>
                        ) : null}
                      </Button>
                    ) : null}
                  </div>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                {/* code for reviews */}
                {reviewable ? (
                  <DashboardViewSingleBookingReview
                    button_enabled={false}
                    button_label="Add a review"
                    booking={props.booking}
                  />
                ) : null}
                {reviewable ? (
                  <DashboardViewSingleBookingTips
                    button_enabled={false}
                    button_label="Add a tip"
                    booking={props.booking}
                    service={service}
                    payment_method_details={payment_methods}
                    sender={sender}
                    service_provider={service_provider}
                  />
                ) : null}

                {!date_past_buttons ? (
                  <span style={{ display: "flex" }}>
                    {/*reviewable === false &&
                      props.booking.status === "pending" ? (
                      <DashboardViewSingleBookingEdit
                        data={data}
                        booking={props.booking}
                        refetch={refetch}
                      />
                    ) : null
                  <Button
                    size="small"
                    onClick={() => window.location.href = `/providers/${props.booking.service_provider?.uid}`}
                    variant="outlined"
                    color="primary"
                  >
                    <span>{" "} Re-Book {props.booking.service_provider.first_name}</span>
                  </Button>*/}
                  <Button
                    size="small"
                    onClick={() => window.location.href = `/providers/${props.booking.service_provider?.uid}`}
                    variant="outlined"
                    color="primary"
                  >
                    <span>{" "} Re-Book {props.booking.service_provider.first_name}</span>
                  </Button>
                 {/*(props.booking.status !== "cancelled" && props.booking.status !== "rejected") ?
                   <Button
                      size="small"
                      onClick={addToGoogleCalendar}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: "10px" }}
                    >
                      <span>{" "} Add to google calendar</span>
                    </Button>: null*/}

                  {reviewable === false ? ( // booking has not been confirmed
                    <Button
                      size="small"
                      disabled={
                        props.booking.status === "cancelled" ||
                          props.booking.status === "rejected"
                          ? true
                          : false
                      }
                      variant="outlined"
                      onClick={cancel_status}
                      style={{ color: "red", marginLeft: "10px" }}
                      color="primary"
                    >
                      {"Cancel"}
                      {props.bookings.cancelled_loading === true &&
                        props.bookings.cancel_id === props.booking.id ? (
                        <div style={{ marginLeft: "10px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="16px"
                            width="16px"
                          />
                        </div>
                      ) : null}
                    </Button>
                  ) : null}
                  </span>
                ) : null}
              </AccordionActions>
            </Accordion>
          </div>
          
          {props.bookings.success_update_message &&
            props.bookings.update_message_id === props.booking.id ? (
            <Collapse in={open_}>
              <Grid item xs={12}>
                <Alert
                  severity='success'
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        props.clear_error();
                        props.clear_error_bookings();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <div className={classes.message}>{"Message updated successfully."}</div>
                </Alert>
              </Grid>
            </Collapse>
          ) : null}
          {props.bookings.failed_update_message &&
            props.bookings.update_message_id === props.booking.id ? (
            <Collapse in={open_}>
              <Grid item xs={12}>
                <Alert
                  severity='success'
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        props.clear_error();
                        props.clear_error_bookings();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <div className={classes.message}>{"An error occured while updating your message, please try again later."}</div>
                </Alert>
              </Grid>
            </Collapse>
          ) : null}

          {props.messages.notify_status &&
            props.messages.notify_id === props.booking.id ? (
            <Collapse in={open_}>
              <Grid item xs={12}>
                <Alert
                  severity={
                    props.messages.notify_status ===
                      "You can only cancel a booking before 12 hours from the time it's scheduled to start."
                      ? "error"
                      : "success"
                  }
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        props.clear_error();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <div className={classes.message}>{alert}</div>
                </Alert>
              </Grid>
            </Collapse>
          ) : null}
        </div>
      ) : (
        <div>
          <Skeleton
            variant="rect"
            width="100%"
            className={classes.accordion}
            height="3em"
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  fetch: state.pets.fetch,
  types: state.pets.pet_types,
  loading: state.pets.isLoading,
  error: state.errors,
  form: state.forms,
  availability: state.forms.availability_data,
  payment_methods: state.services.payment_methods,
  progress: state.services.progress,
  pets: state.pets.pets,
  admin_pets: state.pets.pet_types,
  breeds_types: state.pets.breeds,
  breeds_cats: state.pets.breeds_cats,
  bookings: state.booking,
  messages: state.messages,
});

export default connect(mapStateToProps, {
  getPetData,
  user_delete_pet,
  clear_state_pets,
  init_fetch,
  clear_available,
  deinit_fetch,
  user_update_pet,
  get_bookings,
  get_availability_data,
  confirm_booking,
  delete_booking,
  clear_error,
  cant_cancel,
  update_message_booking,
  clear_error_bookings
})(DashboardViewSingleBooking);
