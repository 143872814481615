import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Skeleton from "@material-ui/lab/Skeleton";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Container from "@material-ui/core/Container";
import DashboardViewServiceSection from "./DashboardViewServiceSection.js";
import DashboardViewServiceSectionRates from "./DashboardViewServiceSection_Rates.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Spinner from "./Spinner";
import DialogConfirmation from "./DialogConfirmation";
import FormHelperText from '@material-ui/core/FormHelperText';

import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  getServiceData,
  get_all_services,
  get_addtional_rate_types,
  user_create_service,
  user_delete_service,
  get_all_rates,
  init_fetch,
  deinit_fetch,
  clear_error,
  user_create_additional_rate,
  getAdditional_RatesData,
} from "../actions/form.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  helpText2: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(4),
  },
  selectFormControl: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(1),
    backgroundColor: "#663399!important",
    color: "white",
  },
  submitButton: {
    backgroundColor: "#FF3D00!important",
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSkeleton: {
    fontSize: "13px",
    color: "#1b1f23",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputBio: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  wizardContainer: {
    margin: theme.spacing(1, "auto"),
    width: '70%',
  },
  form: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginTop: theme.spacing(2),
  },
  imageSelector: {
    fontSize: "8rem",
    paddingLeft: theme.spacing(0),
  },
  selector: {
    paddingLeft: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  buttonNext: {
    marginLeft: theme.spacing(1),
    backgroundColor: "#663399 !important",
    marginRight: "auto",
  },
  buttonSection: {
    margin: "auto",
    float: "right",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  addServiceSection: {
    marginTop: theme.spacing(3),
  },
  showServiceSection: {
    marginTop: theme.spacing(1),
  },
  showServiceSection_: {
    marginTop: theme.spacing(2),
  },
  characters: {
    fontSize: '11px',
    color: 'grey'
  }
}));

function ServicesSection(props) {
  const classes = useStyles();

  const [service, setService] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [show_modal, setShow_modal] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [notice, setShow_notice] = React.useState(true);
  const [service_name, setService_name] = React.useState(null);
  const [price_confirm, setPrice_confirm] = React.useState(false);
  const [rate_id, setRate_id] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [error_length, setBioLengthError] = React.useState(false);
  const [description_length, setDescrpLength] = React.useState(0);

  const [service_additional, setService_additional] = React.useState('');
  const [price_additional, setPrice_additional] = React.useState("");
  const [rate_additional, setRate_additional] = React.useState('');

  const clearState = () => {
    setService("");
    setPrice("");
    setDescription("");
    setRate_id("");
    setService_name(null);
    setShow_modal(false);
  };

   const clearState_additional = () => {
    setService_additional('');
    setPrice_additional('');
    setRate_additional('');
  };

  const getPrice = () => {
    // const net_pay = price - price * 0.15; // Changing to 10 %
    const net_pay = price - price * 0.10;
    return net_pay.toFixed(2);
  };

  const getRate = (ser_rate) => {
    var empty = null;
    if (props.rates !== null && ser_rate !== "") {
      const service_rate = props.rates.filter((rate) => rate.id === ser_rate);
      setService_name(service_rate[0].name);
      return service_rate[0].name;
    } else return empty;
  };

  React.useEffect(() => {
    if (price && service_name) {
      setShow_modal(true);
    }
  }, [price, service_name]);

  React.useEffect(() => {
    const id = props.user.id;
    props.getServiceData(id);
    props.getServiceData(id);
    props.getAdditional_RatesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // posting, getting
  React.useEffect(() => {
    props.get_all_services();
    props.get_addtional_rate_types();
    props.getAdditional_RatesData();
    props.get_all_rates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // additional_rates_next
  React.useEffect(() => {
   if(props.additional_rates_next !== null){
     props.getAdditional_RatesData(props.additional_rates_next);
   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.additional_rates_next]);

  React.useEffect(() => {
    if (props.fetch === true) {
      const usid = props.user.id;
      props.getServiceData(usid);
      props.getAdditional_RatesData();
      props.deinit_fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(service === "Overnight"){
      console.log(service)
      const night_rate = props.rates.filter(
          (rate) => rate.name === "Per Night"
      );
      console.log(night_rate)
      getRate(night_rate[0]?.id);
      setRate_id(night_rate[0]?.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, rate_id])

  const handleSubmit = (e) => {

    function call_api() {
      const ser = props.all_services.filter((serv) => serv.name === service);
      const send_id = ser[0].id;
      const user_id = props.user.id;
      props.init_fetch();
      props.user_create_service(description, price, user_id, send_id, rate_id);
      const id = props.user.id;
      props.getServiceData(id);
      props.getServiceData(id);
      clearState();
    }

    if (service !== "" && price !== "" && rate_id !== "" && error_length === false) {
      // checking rates
      const per_hour_rate = props.rates.filter(
        (rate) => rate.name === "Per Hour"
      );
      const per_night_rate = props.rates.filter(
        (rate) => rate.name === "Per Night"
      );
      const per_day_rate = props.rates.filter(
        (rate) => rate.name === "Per Day"
      );
      const per_service_rate = props.rates.filter(
        (rate) => rate.name === "Per Service"
      );

      if (
        per_hour_rate[0].id === rate_id ||
        per_night_rate[0].id === rate_id ||
        per_day_rate[0].id === rate_id
      ) {
        if (parseInt(price) > 100 && parseInt(price) < 1000000) {
          setPrice_confirm(true);
        } else if (parseInt(price) <= 100) {
          call_api();
        }
      } else if (per_service_rate[0].id === rate_id) {
        if (parseInt(price) > 500) {
          setPrice_confirm(true);
        } else if (parseInt(price) <= 500) {
          call_api();
        }
      } else {
        call_api();
      }
    }
  };

  const handleSubmit_additional = (e) => {
    // console.log(service_additional)
    // console.log(rate_additional)
    // console.log(price_additional)
    if (service_additional !== '' && price_additional !== '' && rate_additional !== '') {
      const ser = props.all_services.filter((serv) => serv.name === service_additional);
      const send_id = ser[0].id;
      const user_id = props.user.id;
      props.init_fetch();
      props.user_create_additional_rate(
         price_additional, 
         send_id, 
         user_id, 
         rate_additional
      );
      // const id = props.user.id;
      props.getAdditional_RatesData();
      props.getAdditional_RatesData();
      clearState_additional();
    }
  };

  const confirmed = () => {
    setPrice_confirm(false);
    const ser = props.all_services.filter((serv) => serv.name === service);
    const send_id = ser[0].id;
    const user_id = props.user.id;
    props.init_fetch();
    props.user_create_service(description, price, user_id, send_id, rate_id);
    const id = props.user.id;
    props.getServiceData(id);
    props.getServiceData(id);
    clearState();
  };

  const not_confirmed = () => {
    setPrice_confirm(false);
    return;
  };

  let results = null;
  // eslint-disable-next-line no-unused-vars
  let additional_rates = null;
  if (
    props.services !== undefined &&
    props.services !== null &&
    props.services.results !== undefined
  ) {
    results = props.services.results;
  }

  if (props.additional_rates !== undefined && props.additional_rates !== null) {
    additional_rates = props.additional_rates;
  }

  let alert;
  if (props.messages.notify_status !== null) {
    alert = <div className="alerts">{props.messages.notify_status}</div>;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="md" className={classes.wizardContainer}>
        {notice ? (
          <Grid item xs={12}>
            <Alert severity="info" icon={false}>
              {/*<div className={classes.message}>
                Homepetvet charges a fee of 15% for transactions on this
                platform.
              </div>*/}
              <div className={classes.message}>
                Homepetvet charges a fee of 10% for transactions on this
                platform.
              </div>
            </Alert>
          </Grid>
        ) : null}
        {results !== null && results !== undefined && results.length !== 0 ? (
          <div className={classes.showServiceSection}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Services
            </FormLabel>
            <DashboardViewServiceSection services={results} />
          </div>
        ) : null}
        {/*{additional_rates !== null && additional_rates !== undefined && additional_rates.length !== 0 ? (
          <div className={classes.showServiceSection}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Additional rates
            </FormLabel>
            <DashboardViewServiceSection_Rates additional_rates={additional_rates} />
          </div>
        ) : null}*/}
        {props.all_services !== null ? (
          <div className={classes.addServiceSection}>
            <div>
              <FormGroup className={classes.formGroup}>
                <FormLabel component="label" className={classes.formGroupLabel}>
                  Add services you offer
                </FormLabel>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Select service
                    </FormLabel>
                    <Select
                      onChange={(e) => setService(e.target.value)}
                      disableUnderline
                      fullWidth
                      autoComplete="new-password"
                      value={service}
                      displayEmpty
                      className={classes.inputSelect}
                      inputProps={{
                        "aria-label": "Select Service",
                      }}
                    >
                      {props.all_services?.map((p, i) => (
                        <MenuItem value={p.name} key={i}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Price
                    </FormLabel>
                    <Input
                      onChange={(e) => { setPrice(Math.abs(parseInt(e.target.value))) }}
                      disableUnderline
                      fullWidth
                      value={price}
                      autoComplete="new-password"
                      placeholder="25"
                      type="number"
                      min="1"
                      inputProps={{ "aria-label": "description" }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      className={classes.inputSmall}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    {props.rates !== null ? (
                      <div>
                        <FormLabel
                          component="label"
                          className={classes.formLabel}
                        >
                          Select rate
                        </FormLabel>
                        <Select
                          onChange={(e) => {
                            getRate(e.target.value);
                            setRate_id(e.target.value);
                          }}
                          disableUnderline
                          fullWidth
                          autoComplete="new-password"
                          value={rate_id}
                          displayEmpty
                          type="number"
                          min="1"
                          className={classes.inputSelect}
                          inputProps={{
                            "aria-label": "Select rate",
                          }}
                        >
                          {props.rates?.map((p, i) => (
                            <MenuItem value={p.id} key={i}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {show_modal === true &&
                  getPrice() !== 0 &&
                  service_name !== null ? (
                    <Grid item xs={12}>
                      <Alert
                        severity="info"
                        icon={false}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setShow_modal(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div className={classes.message}>
                          Your total pay is: {getPrice()} USD {service_name}
                        </div>
                      </Alert>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Description
                    </FormLabel>
                    <Input
                      // onChange={(e) => setDescription(e.target.value)}
                      onChange={(e) => {
                        if(e?.target?.value?.length <= 255){
                          setBioLengthError(false)
                          setDescription(e.target.value)
                          setDescrpLength(e.target.value.length)
                        }else{
                           setBioLengthError(true)
                        }
                      }}
                      value={description}
                      autoComplete="new-password"
                      disableUnderline
                      fullWidth
                      placeholder="Write a few words about the service"
                      inputProps={{ "aria-label": "description" }}
                      className={classes.inputSmall}
                      multiline
                      rows={3}
                    />
                    <span className={classes.characters} >{description_length}/255</span>
                    {error_length ? (
                        <FormHelperText error>Description must not exceed 255 characters.</FormHelperText>
                      ): null}
                  </Grid>
                </Grid>

              </FormGroup>
            </div>
          </div>
        ) : (
          <div>
            <FormGroup className={classes.formGroup}>
              <FormLabel component="label" className={classes.formGroupLabel}>
                Services
              </FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Select service
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="3em"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Price
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="3em"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Rate
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="3em"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Description
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="4em"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </div>
        )}
        {price_confirm ? (
          <DialogConfirmation
            price={price}
            service={service_name}
            confirmed={confirmed}
            not_confirmed={not_confirmed}
          />
        ) : null}

        {props.messages.notify_status &&  props.messages.notify_id === null ? (
          <Grid item xs={12} style={{ marginTop: '10px' }} >
            <Alert
              severity={"error"}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    props.clear_error();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div className={classes.message}>{alert}</div>
            </Alert>
          </Grid>
        ) : null}
        
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "130px",
                        maxHeight: "35px",
                        minWidth: "130px",
                        minHeight: "35px",
                      }}
                      onClick={handleSubmit}
                      className={classes.submitButton}
                    >
                      {props.progress ? <Spinner /> : "Add service"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>


        {/* Additional Rates */}

        {additional_rates !== null &&
         additional_rates !== undefined &&
         additional_rates.length !== 0 ? (
          <div className={classes.showServiceSection_}>
            <FormLabel component="label" className={classes.formGroupLabel}>
              Additional rates
            </FormLabel>
            <DashboardViewServiceSectionRates additional_rates={additional_rates} />
          </div>
         ) : (
          <div className={classes.showServiceSection_}/>
         )}

        {props.additional_rate_types !== null && props.all_services !== null ? (
          <div className={classes.addServiceSection}>
            <div>
              <FormGroup className={classes.formGroup}>
                <FormLabel component="label" className={classes.formGroupLabel}>
                  Add an additional rate
                </FormLabel>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Select service
                    </FormLabel>
                    <Select
                      onChange={(e) => setService_additional(e.target.value)}
                      disableUnderline
                      fullWidth
                      autoComplete="new-password"
                      value={service_additional}
                      displayEmpty
                      className={classes.inputSelect}
                      inputProps={{
                        'aria-label': 'Select Service'
                      }}
                    >
                      {props.all_services?.map((p, i) => (
                        <MenuItem value={p.name} key={i}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Price($)
                    </FormLabel>
                    <Input
                      // onChange={(e) => setPrice_additional(e.target.value)}
                      onChange={(e) => {
                        var value = parseInt(e.target.value);
                        if (value >= 0) {
                          setPrice_additional(e.target.value);
                        }
                      }}
                      disableUnderline
                      fullWidth
                      value={price_additional}
                      autoComplete="new-password"
                      placeholder=""
                      type="number"
                      min="1"
                      inputProps={{ 'aria-label': 'description' }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      className={classes.inputSmall}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    {props.additional_rate_types !== null ? (
                      <div>
                        <FormLabel component="label" className={classes.formLabel}>
                          Select additional rate
                        </FormLabel>
                        <Select
                          onChange={(e) => setRate_additional(e.target.value)}
                          disableUnderline
                          fullWidth
                          autoComplete="new-password"
                          value={rate_additional}
                          displayEmpty
                          type="number"
                          min="1"
                          className={classes.inputSelect}
                          inputProps={{
                            'aria-label': 'Select rate'
                          }}
                        >
                          {props.additional_rate_types?.map((p, i) => (
                            <MenuItem value={p.id} key={i}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
          </div>
        ) : (
          <div>
            <FormGroup className={classes.formGroup}>
              <FormLabel component="label" className={classes.formGroupLabel}>
                Additional Rates
              </FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Select service
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="3em"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Price
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="3em"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Rate Type
                  </FormLabel>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.inputSkeleton}
                    height="3em"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </div>
        )}

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: '180px',
                        maxHeight: '35px',
                        minWidth: '180px',
                        minHeight: '35px'
                      }}
                      onClick={handleSubmit_additional}
                      className={classes.submitButton}
                    >
                      {props.progress_additional ? <Spinner /> : 'Add additional rate'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  services: state.services.services,
  progress: state.services.progress,
  progress_additional: state.services.progress_additional,
  fetch: state.services.fetch,
  rates: state.services.rates,
  messages: state.messages,
  all_services: state.services.admin_services,
  additional_rates: state.services.additional_rates,
  additional_rates_next: state.services.additional_rates_next,
  additional_rate_types: state.services.additional_rate_types,
});

export default connect(mapStateToProps, {
  getServiceData,
  get_all_services,
  get_addtional_rate_types,
  user_create_service,
  user_delete_service,
  get_all_rates,
  init_fetch,
  clear_error,
  deinit_fetch,
  user_create_additional_rate,
  getAdditional_RatesData,
})(ServicesSection);
