import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles((theme) => ({
    formGroupLabel: {
        fontSize: "15px",
        color: "rgba(0, 0, 0, 0.7)",
        fontWeight: "400",
        marginBottom: theme.spacing(3),
    },
}))

export default function ToggleNotifications({ toggle_notifications, user }) {

    const classes = useStyles();
    const [toggleNotifications, setToggleNotifications] = React.useState(user?.sms_notifications_enabled);

    const handleChange = () => {
        setToggleNotifications(!toggleNotifications);
        toggle_notifications(!toggleNotifications)
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={<IOSSwitch checked={toggleNotifications} onChange={handleChange} name="checked" />}
                label={toggleNotifications ?
                    <FormLabel component="label" className={classes.formGroupLabel}>
                        Disable SMS Notifications
                    </FormLabel> : <FormLabel component="label" className={classes.formGroupLabel}>
                        Enable SMS Notifications
                    </FormLabel>}
            />
        </FormGroup>
    );
}
