/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import PetAdditionSection from '../components/DashboardAddPetSection0';

import {
  sendUserData,
  getPetData,
  getPetTypes,
  get_breeds,
  get_breeds_cats
} from '../actions/form';

import { logout, loadUser } from '../actions/auth.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  addPetSection: {
    marginTop: theme.spacing(8),
  }
}));

function PetsAdd(props) {
  const { user } = props;
  const user_id = user.id;
  const classes = useStyles();

  React.useEffect(() => {
    if (props.form.fetch === true) {
      props.loadUser();
      console.log('Reloading...');
    }
  }, [props.form.fetch]);

  //  React.useEffect(() => {
  //   props.get_breeds();
  //   props.get_breeds_cats();
  // }, []);

  React.useEffect(() => {
    if (props.user !== null) {
      getPets(user_id);
    }
  }, [props.user]);

  const getPets = (user_id) => {
    props.getPetData(user_id);
  };
  // const dispatch = useDispatch();
  // function sendReq(data, user_id) {
  //   const timer = setTimeout(() => {
  //     dispatch(sendUserData(data, user_id));
  //   }, 2000);

  //   return timer;
  // }


  return (
    <div className={classes.addPetSection}>
      <PetAdditionSection />
    </div>
  );
}

PetsAdd.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  pets: state.pets.pets,
  types: state.pets.pet_types
});

export default connect(mapStateToProps, {
  logout,
  loadUser,
  sendUserData,
  getPetData,
  getPetTypes,
  get_breeds,
  get_breeds_cats
})(PetsAdd);
