import React from "react";
// import Gallery from "react-grid-gallery";
import { makeStyles } from '@material-ui/core/styles';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserNavBar';
import Footer from '../components/Footer';
// import Spinner from '../assets/home_load.gif';
// import Footprint from '../assets/footprint.svg';
import { connect } from 'react-redux';
import './gallery.css'
// import axios from 'axios';
import { get_images } from "../actions/form.js";
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  image: {
    height: "100%",
    width: "100%",
    borderRadius: 20
  },
  gridList: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  image_container: {
    height: "40%",
    width: "40%",
  },
  spiner: {
  	  display: 'flex',
	  width: '100%',
	  minHeight: '100vh',
	  alignItems: 'center',
	  justifyContent: 'center',
	  paddingTop: '73px',
	  background: '#fff'
  },
  div_spiner: { 
  	alignItems: 'center', 
  	justifyContent: 'center' 
  },
  img_spin : {
      position: 'absolute',
      marginBottom: '41px',
      marginRight: '31px'
    },
  pageTitle: {
    maxWidth: '624px',
    marginBottom: '2px',
    marginLeft: '40px',
    fontWeight: '200',
    fontSize: '22px',
    lineHeight: '58px',
    color: '#23286B'
  },
  pageTitle_close: {
    maxWidth: '624px',
    marginBottom: '2px',
    marginRight: '40px',
    fontWeight: '200',
    fontSize: '22px',
    lineHeight: '58px',
    color: '#23286B',
    cursor: 'pointer'
  },
  title_div: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  pageTitle_: {
    maxWidth: '624px',
    marginBottom: '2px',
    marginLeft: '40px',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '58px',
    color: '#23286B'
  },
  link: {
    color: '#FF5722',
    cursor: 'pointer'
  }
}));


const ProviderGallery = (props) => {
  const classes = useStyles();
  
  React.useEffect(() => {
    const id = props?.match?.params?.id
    console.log(id)
    // Decode and decrypt the ID
    const secretKey = '0j9$%)m46vym@vuov_@o1mby*4s6q3#q0+pb=9c3*!d0ocjdzt'
    const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(id), secretKey);
    const decryptedUserID = decryptedBytes.toString(CryptoJS.enc.Utf8);
    props.get_images(parseInt(decryptedUserID));
    // eslint-disable-next-line
  }, [])

  const route_back = () => {
    const backid = localStorage.getItem('provider_view_details_id')
    window.location.href = `/providers/${backid}`
  }
  // React.useEffect(() => {
  //       console.log(props.form.provider_images)
  //       console.log(props.form.provider_images_loading)
  //       console.log(props.form.provider_images_failed)
  //       // eslint-disable-next-line
  // }, [props.form])

  return (
      <div >
        <div>{props.auth?.isAuthenticated ? <PrimarySearchAppBar /> : ''}</div>
        <div>{!props.auth?.token ? <GuestNavBar /> : ''}</div>
      <div style={{ marginTop: '100px' }}>

        <div className={classes.title_div}>
          <div className={classes.pageTitle}>
            Gallery
          </div>
          <div className={classes.pageTitle_close}>
            <span onClick={route_back}>Close</span>
          </div> 
        </div> 
        {
        	!props?.form?.provider_images_loading && props?.form?.provider_images === null ?
        	<div className={classes.pageTitle_}>
	          Please 
              <span 
                 onClick={() => { window.location.href = "/login" }} 
                 className={classes.link}> 
                   {" "}sign in{" "} 
              </span> 
              or 
              <span 
                 onClick={() => { window.location.href = "/signup" }} 
                 className={classes.link}> 
                   {" "}register{" "} 
              </span>
            to view the gallery
	        </div>: null
        }
        <div className="container_div_images">
	        {
	        	props?.form?.provider_images !== null ? 
	        	<div className="gallery" >
		          {props?.form?.provider_images.map((image) => (
		            <div className="pics"  key={image.id}>
		              <img style={{ width: '100%', borderRadius: '10px' }} src={image.image} alt={image.id} />
		            </div>
		          ))}
		        </div>: null
	        }
        </div>
        {/*<div className={classes.spiner}>
            <div className={classes.div_spiner}>
              <img
                className={classes.img_spin}
                src={Footprint}
                alt=""
              />
              <img src={Spinner} alt="" height="100px" width="100px" />
            </div>
        </div>*/}

        <Footer />
       </div>
      </div>
  );
}
 
const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  booking: state.booking,
  form: state.forms
});

export default connect(mapStateToProps, { get_images })(ProviderGallery);

//   const get_images = async (id) => {
//     const config = {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     };

//     await axios.get(`${process.env.REACT_APP_API_URL}users/${id}/photos`, config)
//       .then((res) => {
//         console.log(res.data);
//       })
//       .catch((err) => {
//         console.log(err.response);
//       });
//   };

