export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SUGGESTIONS = "GET_SUGGESTIONS";
export const GET_PROVIDER_DETAILS = "GET_PROVIDER_DETAILS";
export const GET_ALL_SEARCH_RESULTS = "GET_ALL_SEARCH_RESULTS";
export const GET_ERRORS = "GET_ERRORS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOADING = "LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_SUCCESS_GOOGLE = "LOGIN_SUCCESS_GOOGLE";
export const LOGIN_SUCCESS_FACEBOOK = "LOGIN_SUCCESS_FACEBOOK";
export const LOGIN_SUCCESS_APPLE = "LOGIN_SUCCESS_APPLE";
export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_FAIL = "VERIFY_FAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_INITIATE_RESET = "USER_INITIATE_RESET";
export const VERIFY_RESET_PASSWORD = "VERIFY_RESET_PASSWORD";
export const VERIFY_RESET_PASSWORD_FAIL = "VERIFY_RESET_PASSWORD_FAIL";
// export const NOTIFY_ON_TIMEOUT              =  "NOTIFY_ON_TIMEOUT";
export const CREATE_MESSAGES = "CREATE_MESSAGES";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const AWS_COGNITO_REGISTER = "AWS_COGNITO_REGISTER";
export const AWS_COGNITO_VERIFY_CODE = "AWS_COGNITO_VERIFY_CODE";
export const AWS_COGNITO_RESEND_CODE = "AWS_COGNITO_RESEND_CODE";
export const USER_REGISTER = "USER_REGISTER";
export const AWS_COGNITO_RESET = "AWS_COGNITO_RESET";
export const RESET_PASSWORD_RAILS = "RESET_PASSWORD_RAILS";
export const AWS_COGNITO_REGISTER_SOCIAL = "AWS_COGNITO_REGISTER_SOCIAL";

export const FORM_LOADING = "FORM_LOADING";
export const FORM_LOADED = "FORM_LOADED";

export const SERVICE_LOADED = "SERVICE_LOADED";
export const SERVICE_LOADING = "SERVICE_LOADING";
export const PETS_LOADED = "PETS_LOADED";
export const PETS_LOADING = "PETS_LOADING";
export const PET_DELETED = "PET_DELETED";
export const GOT_PET_TYPES = "GOT_PET_TYPES";
export const SENT_CERTIFICATE = "SENT_CERTIFICATE";
export const SENDING_CERTIFICATE = "SENDING_CERTIFICATE";
export const CERTIFICATE_LOADING = "CERTIFICATE_LOADING";
export const CERTIFICATE_LOADED = "CERTIFICATE_LOADED";

export const ALL_SERVICE_LOADED = "ALL_SERVICE_LOADED";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const SERVICE_DELETED = "SERVICE_DELETED";
export const SENDING_DATA = "SENDING_DATA";

export const SENDING_SCREENING = "SENDING_SCREENING";
export const SENT_SCREENING_SUCCESS = "SENT_SCREENING_SUCCESS";
export const SENT_SCREENING_FAIL = "SENT_SCREENING_FAIL";
export const ALL_RATES_LOADED = "ALL_RATES_LOADED";
export const FETCH = "FETCH";
export const FETCH_DONE = "FETCH_DONE";
export const CLEAR_STATE = "CLEAR_STATE";

export const CARD_LOADING = "CARD_LOADING";
export const CARD_CREATED_FAIL = "CARD_CREATED_FAIL";
export const CARD_CREATED_SUCCESS = "CARD_CREATED_SUCCESS";
export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const PAYMENT_METHOD_DELETED = "PAYMENT_METHOD_DELETED";
export const USER_CREATE_PET = "USER_CREATE_PET";
export const CLEAR_STATE_ = "CLEAR_STATE_";
export const FETCH_ = "FETCH_";
export const FETCH_DONE_ = "FETCH_DONE_";
export const CREATE = "CREATE";
export const GETTING_DONE = "GETTING_DONE";
export const CLEAR_STATE_PET = "CLEAR_STATE_PET";
export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const GET_BREEDS = "GET_BREEDS";
export const GET_BREEDS_CAT = "GET_BREEDS_CAT";
export const START_CHECK = "START_CHECK";
export const CLEAR_STATE_PAYMENT = "CLEAR_STATE_PAYMENT";
export const BACKGROUNDCHECK_PAYMENT = "BACKGROUNDCHECK_PAYMENT";
export const PAYMENT_SCREENING = "PAYMENT_SCREENING";
export const PAYMENT_SCREENING_ERROR = "PAYMENT_SCREENING_ERROR";
export const CLEAR_BACKGROUNDCHECK_ERROR = "CLEAR_BACKGROUNDCHECK_ERROR";
export const CLEAR_AVAILABILITY = "CLEAR_AVAILABILITY";
export const CREATE_AVAILABILITY_INSTANCE = "CREATE_AVAILABILITY_INSTANCE";
export const SAVE_COLLECTIVE_DATA = "SAVE_COLLECTIVE_DATA";
export const FORM_LOADING_DATA = "FORM_LOADING_DATA";
export const FORM_LOADED_DATA = "FORM_LOADED_DATA";
export const GO_TO_NEXT = "GO_TO_NEXT";
export const RESET_GO_TO_NEXT = "RESET_GO_TO_NEXT";
export const USER_EDITING = "USER_EDITING";
export const RESET_USER_EDITING = "RESET_USER_EDITING";
export const GO_TO_WIZARD_SOCIAL = "GO_TO_WIZARD_SOCIAL";
export const RESET_SAVE_SIGNAL = "RESET_SAVE_SIGNAL";
export const SET_PROFILE_ID = "SET_PROFILE_ID";
export const GET_AVAILABILITY_DATA = "GET_AVAILABILITY_DATA";
export const CREATE_BOOKING = "CREATE_BOOKING";
export const BOOKING_LOADING = "BOOKING_LOADING";
export const BOOKING_LOADED = "BOOKING_LOADED";
export const GET_BOOKING_LOADED = "GET_BOOKING_LOADED";
export const PHOTOS_LOADED = "PHOTOS_LOADED";
export const PHOTO_LOADING = "PHOTO_LOADING";
export const PHOTO_UPLOADED = "PHOTO_UPLOADED";
export const PHOTOS_DELETED = "PHOTOS_DELETED";
export const PAYOUT_LOADING = "PAYOUT_LOADING";
export const CREATE_PAYOUT = "CREATE_PAYOUT";
export const PAYOUT_LOADED = "PAYOUT_LOADED";
export const PAYOUT_DATA_LOADED = "PAYOUT_DATA_LOADED";
export const BOOKING_DATA_LOADED = "BOOKING_DATA_LOADED";
export const UPCOMING_RECURRING = "UPCOMING_RECURRING";
export const PAST_NON_RECURRING = "PAST_NON_RECURRING";
export const PAST_RECURRING = "PAST_RECURRING";
export const BOOKING_LOADING_ = "BOOKING_LOADING_";
export const GET_SCHEDULE_DATA = "GET_SCHEDULE_DATA";
export const SCHEDULE_LOADING = "SCHEDULE_LOADING";
export const SCHEDULE_CREATED = "SCHEDULE_CREATED";
export const CREATE_MESSAGES_SCHEDULE = "CREATE_MESSAGES_SCHEDULE";
export const CLEAR_AVAILABLE = "CLEAR_AVAILABLE";
export const UPDATE_BOOKING_LOADING = "UPDATE_BOOKING_LOADING";
export const UPDATE_BOOKING_FAILED = "UPDATE_BOOKING_FAILED";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const FORM_LOADING_CREATE = "FORM_LOADING_CREATE";
export const GET_ADDITIONAL_RATES_TYPES = "GET_ADDITIONAL_RATES_TYPES";
export const CREATE_ADDITIONAL = "CREATE_ADDITIONAL";
export const CREATED_ADDITIONAL = "CREATED_ADDITIONAL";
export const ADDITIONAL_RATES_LOADED = "ADDITIONAL_RATES_LOADED";

export const ENABLE_BUTTON = "ENABLE_BUTTON";
export const BOOKING_RELOAD_PAGE = "BOOKING_RELOAD_PAGE";

export const CREATE_REVIEW = "CREATE_REVIEW";
export const CREATE_REVIEW_LOADING = "CREATE_REVIEW_LOADING";

export const REVIEWS_LOADING = "REVIEWS_LOADING";
export const REVIEWS = "REVIEWS";
export const REVIEWS_FAILED = "REVIEWS_FAILED";
export const CREATE_MESSAGES_STATUS = "CREATE_MESSAGES_STATUS";

export const FAQS_LOADING = "FAQS_LOADING";
export const FAQS_LOADED = "FAQS_LOADED";

export const BLOG_POSTS = "BLOG_POSTS";
export const BLOG_POST_LOADING = "BLOG_POST_LOADING";
export const BLOG_POST_LOADED = "BLOG_POST_LOADED";

// statuses
export const CONFIRM_LOADING = "CONFIRM_LOADING";
export const REJECTED_LOADING = "REJECTED_LOADING";
export const CANCELLED_LOADING = "CANCELLED_LOADING";

export const MAKE_BOOKING_FIRST_SIGNAL = "MAKE_BOOKING_FIRST_SIGNAL";
export const MAKE_BOOKING_FIRST_SIGNAL_RESET = "MAKE_BOOKING_FIRST_SIGNAL_RESET";
export const FORM_PET_UPDATE_LOADING = "FORM_PET_UPDATE_LOADING";

export const PAYMENTS_TIPS_LOADING = "PAYMENTS_TIPS_LOADING";
export const PAYMENTS_TIPS_CREATE = "PAYMENTS_TIPS_CREATE";
export const PAYMENTS_TIPS_FAILED = "PAYMENTS_TIPS_FAILED";
export const PAYMENTS_FETCHED = "PAYMENTS_FETCHED";

export const PROVIDER_IMAGES_LOADING = "PROVIDER_IMAGES_LOADING";
export const PROVIDER_IMAGES_FETECHED = "PROVIDER_IMAGES_FETECHED";
export const PROVIDER_IMAGES_FAILED = "PROVIDER_IMAGES_FAILED";
export const DELETE_PHOTO_LOADING = "DELETE_PHOTO_LOADING";
export const USER_MISSING_INFO = "USER_MISSING_INFO";
export const USER_MISSING_INFO_TURN_OFF = "USER_MISSING_INFO_TURN_OFF";

export const ADD_TO_CALENDAR_LOAD = "ADD_TO_CALENDAR_LOAD";
export const ADDED_TO_CALENDAR = "ADDED_TO_CALENDAR";
export const CLEAR_ADD_CALENDAR_ID = "CLEAR_ADD_CALENDAR_ID";

export const UPDATE_MESSAGE_LOADING = "UPDATE_MESSAGE_LOADING";
export const UPDATE_MESSAGE_BOOKING = "UPDATE_MESSAGE_BOOKING";
export const UPDATE_MESSAGE_BOOKING_FAILED = "UPDATE_MESSAGE_BOOKING_FAILED";
export const UPDATE_MESSAGE_CLEAR = "UPDATE_MESSAGE_CLEAR";
export const SET_TO_NULL_NEXT_APPOINTMENTS = "SET_TO_NULL_NEXT_APPOINTMENTS";
export const OUTGOING_PAYMENTS_FETCHED = "OUTGOING_PAYMENTS_FETCHED";
export const ACCOUNT_CREATED_SUCCESS = "ACCOUNT_CREATED_SUCCESS";

export const MAKE_PRIMARY_LOADING = "MAKE_PRIMARY_LOADING";
export const MAKE_PRIMARY = "MAKE_PRIMARY";
export const MAKE_PRIMARY_FAILED = "MAKE_PRIMARY_FAILED";
export const ACCOUNT_DEACTIVATED = "ACCOUNT_DEACTIVATED";









