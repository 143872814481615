/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/logo_15.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import cover from '../assets/home_pet_vet_cover.jpeg';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';

import Spinner from '../components/Spinner';

import {
  register,
  verify_email,
  aws_cognito_amplify_reg,
  aws_cognito_amplify_resend,
  aws_cognito_amplify_reg_social
} from '../actions/auth.js';

import { Box, Divider } from '@material-ui/core';

var AWS = require('aws-sdk');
var CryptoJS = require('crypto-js');


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    alignItems: 'center'
  },
  image: {
    backgroundImage: `url(${cover})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh'
  },
  avatar: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'white'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: '2.5rem',
    backgroundColor: 'rgb(94, 20, 163)',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0 0.5rem'
    }
  },
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  social: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '16rem',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 'auto')
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '20rem'
    }
  },
  paper2: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  logo: {
    margin: theme.spacing(16, 0, 1),
    width: 'auto',
    maxWidth: '300px',
    height: '40px'
  },
  body2: {
    lineHeight: '1.5',
    color: '#444444'
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  input: {
    marginBottom: theme.spacing(1)
  },
  checkbox: {
    fontSize: '11px',
    '& > *': {
      fontSize: '11px'
    }
  }
}));

function SignUp(props) {
  const classes = useStyles();

  // register params
  const fullname_social = localStorage.getItem('name_social');
  const email_social = localStorage.getItem('email_social');

  const [fullname, setFullname] = useState(fullname_social);
  const [email, setEmail] = useState(email_social);
  const [account_type, setAccount_type] = useState('');
  const [checked, setChecked] = useState(false);

  const [error_name, setError_name] = useState(false);
  const [error_email, setError_email] = useState(false);
  const [error_usertype, setError_user] = useState(false);
  const [error_terms, setError_terms] = useState(false);

  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handlesubmit = (e) => {
    e.preventDefault();
    var mailFormat =
      /^([a-zA-Z0-9_\.\-!#$%&'*+/=?^`{|}~"(),:;<>[\]])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

    var arr = fullname.split('');
    if (arr.length <= 5) {
      setError_name(true);
      return false;
    } else setError_name(false);

    if (!/\s/.test(fullname)) {
      setError_name(true);
      return false;
    } else setError_name(false);

    if (!mailFormat.test(email)) {
      setError_email(true);
      return false;
    } else setError_email(false);

    if (!account_type) {
      setError_user(true);
      return false;
    } else setError_user(false);

    if (checked !== true) {
      setError_terms(true);
      return false;
    } else setError_terms(false);

    // setting default password that user can always change...
    const password_conf = 'Homepetvet2@';
    const password_one = 'Homepetvet2@';
    var encry_ = CryptoJS.AES.encrypt(password_conf, 'my-secret-key@123').toString();

    // submitting register request
    const registerUser = {
      full_name: fullname,
      email: email,
      password: password_one,
      password_confirmation: encry_,
      account_type: account_type
    };
    props.aws_cognito_amplify_reg_social(registerUser);
    //props.register(registerUser)
  };

  // submitting to rails backend...
  React.useEffect(() => {
    if (props.auth.user_register_social === true) {
      // let params = {
      //   UserPoolId: 'us-east-2_RDv65KfQ1',
      //   AttributesToGet: ['email', 'custom:full_name', 'custom:account_type', 'custom:keyword']
      // };
      // Cognito user pool infor
      AWS.config.update({
        region: 'us-east-2',
        accessKeyId: 'AKIAQFT6TNHXNBASZTWG',
        secretAccessKey: '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'
      });
      // const cognito = new AWS.CognitoIdentityServiceProvider();
      // const rawUsers = cognito
      //   .listUsers(params)
      //   .promise()
      //   .then((res) => {
      //     // console.log(res.Users)
      //     res.Users.map((faq) => {
      //       const email_aws = email;
      //       if (faq.Attributes.filter((todo) => todo.Value === email_aws).length === 1) {
      //         // console.log(faq.Attributes)
      //         const up_username = faq.Attributes[0].Value; // name
      //         const up_account = faq.Attributes[1].Value; // account type
      //         const up_passw = faq.Attributes[2].Value; // password
      //         const up_email = faq.Attributes[3].Value; // email

      //         // decrypting password
      //         var decryptedData4 = CryptoJS.AES.decrypt(up_passw, 'my-secret-key@123');
      //         var encrypt_password = decryptedData4.toString(CryptoJS.enc.Utf8);

      //         // Registering user
      //         const registerUser = {
      //           full_name: up_username,
      //           email: up_email,
      //           password: encrypt_password,
      //           password_confirmation: encrypt_password,
      //           account_type: up_account
      //         };
      //         console.log('Registering user with rails backend...');
      //         props.register(registerUser);
      //         console.log('done');
      //       }
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, [props.auth.user_register_social]);

  const handleResendCode = (e) => {
    props.aws_cognito_amplify_resend();
  };

  let alert;
  if (props.error.msg.data) {
    let msg = props.error.msg.data.non_field_errors;
    alert = (
      <div class="alerts">
        <Alert severity="error">{msg}</Alert>
      </div>
    );
  }

  switch (props.error.msg.code) {
    case 'UsernameExistsException':
      alert = (
        <div class="alerts">
          <Alert severity="error">
            An account with the given email already exists. <br />
            <Link variant="body2">
              <span onClick={handleResendCode}>Verify your email </span>
            </Link>{' '}
            or
            <Link to="/signup" variant="body2">
              <span onClick={(e) => (window.location.href = '/signup')}>
                {' '}
                register with another email.
              </span>
            </Link>
          </Alert>
        </div>
      );
      break;
    case 'LimitExceededException':
      alert = (
        <div class="alerts">
          <Alert severity="error">Attempt limit exceeded! Please try after some time.</Alert>
        </div>
      );
      break;
    case 'InvalidParameterException':
      alert = (
        <div class="alerts">
          <Alert severity="error">
            An account with the given email is already verified. <br />
            <Link to="/signup" variant="body2">
              <span onClick={(e) => (window.location.href = '/signup')}>
                Register new account again
              </span>
            </Link>
          </Alert>
        </div>
      );
      break;
    default:
      break;
  }

  if (props.auth.userConfirmed === false) {
    return <Redirect to="/verify" />;
  }

  if (props.error.msg.CodeDeliveryDetails) {
    return <Redirect to="/verify_code" />;
  }

  if (props.auth.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Box>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={6} md={7} xl={9} className={classes.image} />
        <Grid item xs={12} sm={6} md={5} xl={3} component={Paper} elevation={1} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <img onClick={(e) => (window.location.href = '/')} src={logo} alt="alt" width="30" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Social Sign up
            </Typography>
            <Typography component="h6" variant="h6" style={{ fontSize: '15px' }}>
              You are about to create a new account on Homepetvet using a login from Google (
              {email_social})
              <br />
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.message}>
                    {/*<Alert severity="error">This is an error alert — check it out!</Alert>
                  <Alert severity="warning">This is a warning alert — check it out!</Alert>
                  <Alert severity="info">This is an info alert — check it out!</Alert>*/}
                    {alert}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error_name}
                    variant="outlined"
                    className={classes.input}
                    autoComplete="fname"
                    name="fullNames"
                    size="small"
                    required
                    fullWidth
                    id="fullNames"
                    label="Full Names"
                    value={fullname}
                    autoFocus
                    onChange={(e) => setFullname(e.target.value)}
                  />
                  {error_name ? (
                    <FormHelperText error>
                      Username must be atleast more than six characters with spaces between the two
                      names.
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error_email}
                    variant="outlined"
                    className={classes.input}
                    size="small"
                    required
                    value={email}
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error_email ? (
                    <FormHelperText error>
                      Email Address is not valid, Please provide a valid Email.{' '}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" error={error_usertype}>
                    <FormLabel component="legend">Sign up as a:</FormLabel>
                    <RadioGroup
                      row
                      aria-label="account-type"
                      id="account-type-select-filled"
                      value={account_type}
                      defaultValue="Pet Provider"
                      onChange={(e) => setAccount_type(e.target.value)}
                    >
                      <FormControlLabel
                        value="Pet Provider"
                        control={<Radio color="primary" />}
                        label="Pet Provider"
                      />
                      <FormControlLabel
                        value="Pet Owner"
                        control={<Radio color="primary" />}
                        label="Pet Owner"
                      />
                    </RadioGroup>
                  </FormControl>
                  {error_usertype ? (
                    <FormHelperText error>Please Choose a user type.</FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={checked}
                          onClick={(e) => setChecked(!checked)}
                          name="acceptTerms"
                        />
                      }
                      label="By signing up, you agree to our Terms and Privacy Policy"
                      className={classes.checkbox}
                    />
                  </FormControl>
                  {error_terms ? (
                    <FormHelperText error>Please accept our terms of use.</FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handlesubmit}
              >
                {props.auth.isLoading ? <Spinner /> : 'Sign Up'}
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/login">Already have an account? Sign in</Link>
                </Grid>
              </Grid>
              <br />
            </form>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.errors
});

export default connect(mapStateToProps, {
  register,
  verify_email,
  aws_cognito_amplify_reg,
  aws_cognito_amplify_reg_social,
  aws_cognito_amplify_resend
})(SignUp);
