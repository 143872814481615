import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DashboardViewSingleBookingProvider from "./DashboardViewSingleBookingProvider";
import {
  getPetData,
  user_delete_pet,
  clear_error,
  init_fetch,
  deinit_fetch,
  get_breeds,
} from "../actions/form.js";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { styled } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Spinner from "../assets/home_load.gif";

import { get_bookings } from "../actions/booking";
// import moment from "moment";
import { Box } from "@material-ui/core";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF3D00",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 50,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(5),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FF3D00",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#FF3D00",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#FF3D00",
    },
  })
);

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  parentDiv: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("350")]: {
      margin: 0,
    },
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  helpText2: {
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(3),
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  scroll: {
    "& > div": {
      overflow: "scroll !important"
    },
  },
  spin: {
    height: 50,
    width: 50,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    fontSize: "30px",
    color: "black",
  },
  pageTitle: {
    maxWidth: "624px",
    marginBottom: "2px",
    marginTop: "5px",
    marginLeft: "15px",
    fontWeight: "200",
    fontSize: "22px",
    lineHeight: "58px",
    color: "#23286B",
  },
  button: {
    marginLeft: '10px',
    marginTop: '10px',
    alignItems: 'center',
    color: '#FF3D00',
    justifyContent: 'center',
    backgroundColor: '#fff!important',
    border: '1.5px solid #FF3D00',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#F0FFF0!important'
    }
  },
}));

function DashboardViewBookings(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [loadingMoreNext, setLoadingMoreNext] = React.useState(false);
  const [loadingMorePrev, setLoadingMorePrev] = React.useState(false);
  const [loadingPastNext, setLoadingPastNext] = React.useState(false);
  const [loadingPastPrev, setLoadingPastPrev] = React.useState(false);

  const upcoming_nonrecur_bookings = props.booking_data;
  const len = upcoming_nonrecur_bookings?.length;

  const past_nonrecur_bookings = props.past_nonrecur_bookings;
  const len3 = past_nonrecur_bookings?.length;

  const handleChange_tab = (event, newValue) => {
    setValue(newValue);
    props.clear_error();
  };

  const fetch_again = (e) => {
    props.get_bookings();
    props.get_bookings();
    props.fetch_again()
  };

  React.useEffect(() => {
    props.get_bookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (loadingMoreNext) {
      setTimeout(() => {
        setLoadingMoreNext(false)
      }, 1000)
    }
    if (loadingMorePrev) {
      setTimeout(() => {
        setLoadingMorePrev(false)
      }, 1000)
    }

    if (loadingPastNext) {
      setTimeout(() => {
        setLoadingPastNext(false)
      }, 1000)
    }

    if (loadingPastPrev) {
      setTimeout(() => {
        setLoadingPastPrev(false)
      }, 1000)
    }

  }, [loadingMoreNext, loadingMorePrev, loadingPastNext, loadingPastPrev]);


  return (
    <div className={classes.parentDiv}>
      {(len === 0 && len3 === 0) ? (
        <div className={classes.pageTitle}>No appointments yet.</div>
      ) : (
        <div>

          <Box sx={{ width: "100%" }}>
            <AntTabs
              value={value}
              onChange={handleChange_tab}
              aria-label="ant example"
            >
              <AntTab label="Upcoming Bookings" />
              <AntTab label="Past Bookings" />
            </AntTabs>
          </Box>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div style={{
              width: "100%",
              marginLeft: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row'
            }}>
              <div style={{ width: '110%' }}>
                <div className={classes.column}>
                  <span className={classes.heading}>
                    <span className={classes.secondaryHeading}>
                      Service
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.column}>
                  <span className={classes.heading}>
                    Owner
                  </span>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.column}>
                  <span className={classes.heading}>
                    Start Date
                  </span>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.column}>
                  <span className={classes.heading}>
                    End Date
                  </span>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.column}>
                  <span className={classes.heading}>
                    Start Time
                  </span>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.column}>
                  <span className={classes.heading}>
                    End Time
                  </span>
                </div>
              </div>
              <div style={{ width: '120%' }}>
                <div className={classes.column_}>
                  <span className={classes.secondaryHeading_}>
                    Status
                  </span>
                </div>
              </div>
            </div>
          </AccordionSummary>

          {value === 0 ? (
            <div>
              {upcoming_nonrecur_bookings?.length > 0 ? (
                <div>
                  {upcoming_nonrecur_bookings
                    .slice(0, len)
                    .map((booking, i) => (
                      <div>
                        <DashboardViewSingleBookingProvider
                          booking={booking}
                          key={i}
                          fetch_again={fetch_again}
                        />
                      </div>
                    ))}
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}>
                    <Button
                      size="small"
                      onClick={() => {
                        setLoadingMorePrev(true)
                        props.get_more_prev()
                      }}
                      variant="outlined"
                      disabled={props.bookings_prev === null}
                      className={classes.button}
                    >
                      <span>Prev</span>
                      {loadingMorePrev ?
                        <div style={{ marginLeft: "7px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="11px"
                            width="11px"
                          />
                        </div> : null}
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setLoadingMoreNext(true)
                        props.get_more()
                      }}
                      variant="outlined"
                      disabled={props.bookings_next === null}
                      className={classes.button}
                    >
                      <span>Next</span>
                      {loadingMoreNext ?
                        <div style={{ marginLeft: "7px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="11px"
                            width="11px"
                          />
                        </div> : null}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className={classes.pageTitle}>
                  You currently have no upcoming bookings.
                </div>
              )}
            </div>
          ) : null}

          {value === 1 ? (
            <div>
              {past_nonrecur_bookings?.length > 0 ? (
                <div>
                  {past_nonrecur_bookings
                    .slice(0, len3)
                    .map((booking, i) => (
                      <div>
                        <DashboardViewSingleBookingProvider
                          booking={booking}
                          key={i}
                          fetch_again={fetch_again}
                        />
                      </div>
                    ))}
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}>
                    <Button
                      size="small"
                      onClick={() => {
                        setLoadingPastPrev(true)
                        props.get_past_prev()
                      }}
                      variant="outlined"
                      disabled={props.past_bookings_prev === null}
                      className={classes.button}
                    >
                      <span>Prev</span>
                      {loadingPastPrev ?
                        <div style={{ marginLeft: "7px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="11px"
                            width="11px"
                          />
                        </div> : null}
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setLoadingPastNext(true)
                        props.get_more_past()
                      }}
                      variant="outlined"
                      disabled={props.past_nonrecur_bookings_next === null}
                      className={classes.button}
                    >
                      <span>Next</span>
                      {loadingPastNext ?
                        <div style={{ marginLeft: "7px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="11px"
                            width="11px"
                          />
                        </div> : null}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className={classes.pageTitle}>
                  You currently have no past bookings.
                </div>
              )}
            </div>
          ) : null}

        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  fetch: state.pets.fetch,
  types: state.pets.pet_types,
  loading: state.pets.isLoading,
  breeds_types: state.pets.breeds,
  breeds_cats: state.pets.breeds_cats,

  bookings_next: state.booking.bookings_next,
  bookings_prev: state.booking.bookings_prev,
  past_nonrecur_bookings_next: state.booking.past_nonrecur_bookings_next,
  past_bookings_prev: state.booking.past_bookings_prev,

});

export default connect(mapStateToProps, {
  getPetData,
  user_delete_pet,
  clear_error,
  init_fetch,
  deinit_fetch,
  get_breeds,
  get_bookings,
})(DashboardViewBookings);
