/* eslint-disable no-native-reassign */
import React from "react";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from '../assets/home_load.gif';

import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import discover from "payment-icons/min/flat/discover.svg";
import diners from "payment-icons/min/flat/diners.svg";
import jcb from "payment-icons/min/flat/jcb.svg";
import unionpay from "payment-icons/min/flat/unionpay.svg";
import {
  delete_payment_method,
  get_payment_method,
  set_primary,
  clear_error,
} from "../actions/form.js";

import * as Scroll from "react-scroll";

var scroll = Scroll.animateScroll;

const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);


const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  parentDiv: {
    margin: theme.spacing(0),
    width: '70%',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("550")]: {
      width: "90%",
      margin: "auto !important",
      textAlign: "center",
      paddingBottom: '10px'
    },
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("550")]: {
      display: "block",
      margin: "auto",
      fontSize: "14px",
      marginBottom: "10px",
      position: "inherit",
      transform: 'inherit'
    },
    "& > label": {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("550")]: {
        paddingLeft: '0',
        position: 'relative',
        transform: 'none',
        fontSize: '14px'
      },
    },
    "& > div": {
      [theme.breakpoints.down("550")]: {
        margin: 0,
      },
    },
    "&  input": {
      [theme.breakpoints.down("550")]: {
        textAlign: 'center'
      },
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  helpText2: {
    paddingTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  text: {
    marginLeft: theme.spacing(3),
  },
  button: {
    backgroundColor: "#FF3D00!important",
    float: "right",
    margin: theme.spacing(0, 0, 1),
  },
  submitButton: {
    backgroundColor: "#663399",
    marginLeft: theme.spacing(2),
    float: "right",
  },
  svg_image: {
    height: "45px",
    width: "45px",
    marginRight: "10px"
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "0px",
    fontSize: "25px",
    color: "black",
    [theme.breakpoints.down("550")]: {
      marginLeft: '0',
      paddingTop: '10px',
      paddingBottom: '10px',
      display: "block"
    },
  },
  cardContainer: {
    [theme.breakpoints.down("550")]: {
    },
    '& > div': {
      [theme.breakpoints.down("950")]: {
        display: "flex",
        flexWrap: "wrap",
      },
      [theme.breakpoints.down("550")]: {
        flexDirection: 'column'
      },
    },
    '& > div > div': {
      [theme.breakpoints.down("950")]: {
        width: "50%",
        marginRight: "0 !important",
        maxWidth: '100%'
      },
      [theme.breakpoints.down("950")]: {
        width: "100%",
        margin: '5px auto'
      },
    },
    '& img': {
      [theme.breakpoints.down("950")]: {
        width: "70px",
        marginRight: "auto !important",
        paddingLeft: "12px",
      },
      [theme.breakpoints.down("550")]: {
        width: "50px",
        margin: "auto !important",
        paddingLeft: "0px",
      },
    },
  },
  flex: {
    [theme.breakpoints.down("550")]: {
      display: 'flex',
      justifyContent: 'center'
    },
  },
  titleBlock_: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    // marginLeft: "20px",
    fontSize: "15px",
    color: "black",
    [theme.breakpoints.down("550")]: {
      marginLeft: '0',
      paddingTop: '20px'
    },
  },
}));

function PaymentsViewList(props) {
  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = React.useState("");
  const [error, setError] = React.useState(false);
  const [bool] = React.useState(true);
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const [load_id, setloadID] = React.useState(null);

  const payment_methods = props?.payment_methods;
  const len = payment_methods?.length;
  // sorting them
  if (len > 0) {
    console.log(payment_methods)
    payment_methods.sort((a, b) => (a.id > b.id ? 1 : -1));
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const setting_primary = (id, value) => {
    setloadID(id);
    const new_value = !value;
    let set_id;
    const check = payment_methods?.filter(
      (payment) => payment.is_primary === bool
    );
    // console.log(check);
    if (check.length >= 1) {
      set_id = check[0].id;
    }

    if (check.length === 1 && id !== set_id) {
      setError(true);
      // window.scrollTo(0, 0);
      // console.log("can not have more than two PCs")
      scroll.scrollToTop();
      setOpen(true);
    }
    if (check.length === 1 && id === set_id) {
      props.set_primary(id, new_value);
      // console.log("you have a primary card and you want to change its status")
      setError(false);
      props.fetch_again();
    }
    if (check.length === 0) {
      props.set_primary(id, new_value);
      // console.log("setting a primary card")
      setError(false);
      props.fetch_again();
    }

    // props.set_primary(id, new_value)
    // props.fetch_again();
  };

  const delete_payment_method = (id) => {
    props.delete_payment_method(id);
    props.fetch_again();
  };
  if (
    props.messages.notify_timeout !== null &&
    props.booking.payouts_data === null
  ) {
    scroll.scrollTo();
    alert = <div className="alerts">{props.messages.notify_timeout}</div>;
  }

  if (props.error.msg.length >= 1) {
    let msg = props.error.msg;
    console.log("msg")
    console.log(msg)
    alert = <div className="alerts">{msg}</div>;
  }

  // React.useEffect(() => {
  //   dispatch(returnError(null, null));
  //   dispatch(createMessage(null));
  // }, []);

  return (
    <Paper elevation={0} className={classes.paper}>
      {error ? (
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {
              "You can not have more than one primary card, please uncheck the current primary card then make any of your choice primary."
            }
          </Alert>
        </Collapse>
      ) : (
        ""
      )}
      {props.messages.notify_timeout ? ( // temporary..
        <Grid item xs={12}>
          {props.messages.notify_timeout === "Card Deleted Successfully." ? (
            <Grid item xs={12}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      props.clear_error();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <div className={classes.message}>{alert}</div>
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Alert
                severity="info"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      props.clear_error();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <div className={classes.message}>{alert}</div>
              </Alert>
            </Grid>
          )}
        </Grid>
      ) : null}
      {props.error.msg.length >= 1 ? (
        <Grid item xs={12}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.clear_error();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <div className={classes.message}>{alert}</div>
          </Alert>
        </Grid>
      ) : null}
      <div className={classes.parentDiv}>
        <span className={classes.titleBlock}>Your Payment information</span>
        {len > 0 ? (
          <div>
            {payment_methods?.slice(0, len).map((payment_method) => (
              <Accordion
                className={classes.accordion}
                key={payment_method.id}
                square
                expanded
                onChange={handleChange(`panel${payment_method.id}`)}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  className={classes.cardContainer}
                >
                  {payment_method.brand === "visa" ? (
                    <img src={visa} className={classes.svg_image} alt="Logo" />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "mastercard" ? (
                    <img
                      src={mastercard}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "discover" ? (
                    <img
                      src={discover}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "diners" ? (
                    <img
                      src={diners}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "jcb" ? (
                    <img src={jcb} className={classes.svg_image} alt="Logo" />
                  ) : (
                    ""
                  )}
                  {payment_method.brand === "unionpay" ? (
                    <img
                      src={unionpay}
                      className={classes.svg_image}
                      alt="Logo"
                    />
                  ) : (
                    ""
                  )}

                  <Grid style={{ marginRight: "5px" }} item sm={3}>
                    <TextField
                      id="last4"
                      label="Card ending with"
                      value={payment_method.last4}
                      className={classes.line}
                      InputProps={{
                        classes: { underline: classes.underline },
                      }}
                    />
                  </Grid>
                  <Grid style={{ marginRight: "5px" }} item sm={3}>
                    <TextField
                      id="exp_month"
                      label="Expiration month"
                      type="text"
                      value={payment_method.exp_month}
                      className={classes.line}
                      InputProps={{
                        classes: { underline: classes.underline },
                      }}
                    />
                  </Grid>
                  <Grid style={{ marginRight: "5px" }} item sm={3}>
                    <TextField
                      id="exp_year"
                      label="Expiration year"
                      value={payment_method.exp_year}
                      className={classes.line}
                      InputProps={{
                        classes: { underline: classes.underline },
                      }}
                    />
                  </Grid>
                  <Grid style={{ marginRight: "5px" }} item sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={payment_method.is_primary}
                          defaultValue={payment_method.is_primary}
                          onChange={(e) => {
                            if (props.form.making_primary_loading === false) {
                              setting_primary(
                                payment_method.id,
                                payment_method.is_primary
                              )
                            }
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Primary"
                    />
                  </Grid>

                  {props.form.making_primary_loading === true &&
                    load_id === payment_method.id ? (
                    <div
                      style={{
                        marginLeft: '10px',
                        marginTop: '10px'
                      }}
                    >
                      <img src={Spinner} alt="" height="20px" width="20px" />
                    </div>
                  ) : null}

                  <Grid item xs={6} sm={2} className={classes.flex}>
                    {payment_method?.is_used === false ? <Button
                      variant="contained"
                      //disabled={disabl_}
                      color="secondary"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "35px",
                        minWidth: "80px",
                        minHeight: "35px",
                      }}
                      id={`del_${len}`}
                      onClick={(e) =>
                        delete_payment_method(payment_method.id)
                      }
                      className={classes.button}>
                      {"Delete"}
                    </Button> : null}
                  </Grid>

                </AccordionSummary>
                <br />
              </Accordion>
            ))}
          </div>
        ) : (
          <div>
            <span className={classes.titleBlock_}>No cards added yet.</span>
          </div>
        )}

      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  making_primary_loading: state.forms.making_primary_loading,
  booking: state.booking,
  user: state.auth.user,
  messages: state.messages,
  results: state.services.results,
  all_services: state.services.admin_services,
});
export default connect(mapStateToProps, {
  delete_payment_method,
  get_payment_method,
  set_primary,
  clear_error,
})(PaymentsViewList);