import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

export default function SnackBarInfo() {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleClose_ = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    // localStorage.setItem("user_info_acknowlege", "OK")
  };

  const action = (
    <React.Fragment>
      <Button style={{ color: "#FF3D00", marginRight: '40px' }} size="small" onClick={handleClose_}>
        OK
      </Button>
    </React.Fragment>
  );

  const message = (
    <React.Fragment>
      <span style={{ fontSize: "17px" }}>
          Exciting news! Homepetvet mobile app is <a target="_blank" href="https://apps.apple.com/us/app/homepetvet/id6443882007" style={{ color: "#FF3D00", textDecoration: "none" }} size="small" onClick={() => window.location.href = "/signup"}>
          here</a> with even more convenience.{" "}
          <a href="/signup" style={{ color: "#FF3D00", textDecoration: "none" }} size="small" onClick={() => window.location.href = "/signup"}>
            Sign up</a> for free now to enjoy our platform's benefits. Join
          before December 2024 to keep 90% of your earnings and only pay a 10% platform fee.
          Don't miss out on this exclusive offer! Start using our platform today!
      </span>
    </React.Fragment>
  )
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={30000}
        // onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}