import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../assets/home_load.gif";
import { Alert } from "@material-ui/lab";

import { create_payment_tips } from "../actions/booking.js";

import { clear_error } from "../actions/form.js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <div>
          {/*
          <IconButton
          //   aria-label="close"
          //   onClick={onClose}
          //   style={{ 
          //     position: 'absolute',
          //     marginRight: '20px',
          //     marginTop: '20px',
          //     color: (theme) => theme.palette.grey[500],
          //   }}
          // >
          //   <CloseIcon />
          // </IconButton>
        */}
        </div>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "10px",
    width: "100%",
    minHeight: "100vh",
    paddingTop: "73px",
    background: "#D4F2F2",
  },
  custom: {
    margin: "0",
    padding: "18px 16px 14px",
    maxWidth: "210px",
    minHeight: "56px",
    border: "1px solid rgba(25, 25, 25, 0.32)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("680")]: {
      marginBottom: "10px",
    },
    "& > .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& > .MuiPickersToolbar": {
      toolbar: "none",
    },
  },
  toolbar: {
    backgroundColor: "red",
  },
  inputLabel: {
    padding: "10px 22px 10px 16px",
    display: "flex",
    alignItems: "center",
    width: "495px",
    height: "93px",
    background: "#E7E7E7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    cursor: "pointer",
  },
  formgroup: {
    marginBottom: "63px",
    [theme.breakpoints.down("680")]: {
      marginBottom: "40px",
    },
  },
  grid: {
    marginBottom: "43px",
    [theme.breakpoints.down("680")]: {
      marginBottom: "20px",
    },
  },
  textarea: {
    width: "100%",
    height: "161px",
    padding: "8px 22px 8px 14px",
    background: "rgba(51, 51, 51, 0.06)",
    borderRadius: "4px 4px 0px 0px",
    border: "none",
    borderBottom: "solid 2px #00BCD4",
    fontFamily: "Averia Sans Libre",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#4F4F4F",
    resize: "none",
    outline: "none",
  },
  form: {
    width: "100%",
  },
  imgWrap: {
    maxWidth: "73px",
    maxHeight: "73px",
    order: "0",
    "& > img": {
      width: "100%",
    },
    [theme.breakpoints.down("680")]: {
      maxWidth: "62px",
      maxHeight: "62px",
    },
  },
  title_: {
    maxWidth: "624px",
    alignItems: "center",
    fontWeight: "350",
    fontSize: "20px",
    // lineHeight: '58px',
    color: "#23286B",
    [theme.breakpoints.down("1210")]: {
      maxWidth: "506px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "327px",
      fontSize: "26px",
      lineHeight: "32.86px",
    },
    pageTitle: {
      maxWidth: "624px",
      marginBottom: "2px",
      // alignItems: 'center',
      fontWeight: "200",
      fontSize: "22px",
      // lineHeight: '58px',
      color: "#23286B",
    },
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    fontSize: "25px",
    color: "black",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "3px",
    border: "1px solid #cfd7de",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
    button_: {
      // alignItems: 'center',
      color: "#FF3D00",
      // float: 'left',
      justifyContent: "center",
      backgroundColor: "#fff!important",
      border: "1.5px solid #FF3D00",
      borderRadius: "50px",
    },
    column: {
      marginBottom: "20px",
    },
  },
  label_style: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    // marginLeft: '20px',
    fontSize: "15px",
    textDecoration: "none",
    textTransform: "none",
    // color: 'black'
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1.5px solid #949494",
    },
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  contain: {
    marginLeft: "10px",
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1.5px solid #949494",
    },
  },
  labels: {
    color: "#FF5722",
    fontWeight: "800",
    fontFamily: "Dosis",
  },
}));

function DashboardViewSingleBookingTips(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAmount(null);
    // setReason(null)
    setPayment_meth(null);
    setDescription(null);
  };

  const { sender, service_provider } = props;
  const [amount, setAmount] = React.useState(null);
  // const [reason, setReason] = React.useState(null);
  const [payment_method, setPayment_meth] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [status] = React.useState("pending");
  const [check_tip_payment, setCheck_tip] = React.useState(false);

  function clear_input(){
    setAmount("")
    setDescription("")
  }

  // console.log(props.payment_method_details)
  const make_payment = () => {
    if(amount !== null && amount > 0 && payment_method !== null){
      if(props.book_data.tips_payment_made){
        setCheck_tip(true)
      }else{
        const payment_object = JSON.stringify({
          amount,
          // reason,
          description,
          status,
          payment_method,
          sender,
          service_provider,
        });
        if (amount !== null && amount > 0 && payment_method !== null) {
          props.create_payment_tips(payment_object);
          clear_input()
        } else {
          console.log("Please add all the required information");
        }
      }
    }
 
  };

  const confirm_make_payment = () => {
      const payment_object = JSON.stringify({
        amount,
        // reason,
        description,
        status,
        payment_method,
        sender,
        service_provider,
      });
      if (amount !== null && amount > 0 && payment_method !== null) {
        props.clear_error();
        setCheck_tip(false)
        props.create_payment_tips(payment_object);
        clear_input()
      } else {
        console.log("Please add all the required information");
      }
  };

  let alert;
  if (props.messages.notify_timeout !== null) {
    alert = <div className="alerts">{props.messages.notify_timeout}</div>;
  }

  return (
    <div>
      <Button
        size="small"
        onClick={handleClickOpen}
        variant="outlined"
        color="primary"
        disabled={props.button_enabled}
      >
        <span className={classes.label_style}>{props.button_label}</span>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add a Tip
        </BootstrapDialogTitle>
        <DialogContent dividers className={classes.contain}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormLabel component="label" className={classes.formLabel}>
                Amount $
              </FormLabel>
              <Input
                id="amount"
                value={amount}
                disableUnderline
                type="number"
                fullWidth
                placeholder="Amount"
                inputProps={{ "aria-label": "amount" }}
                className={classes.inputSmall}
                onChange={(e) => {
                  // var value = parseInt(e.target.value);
                  if (e.target.value >= 0) {
                    setAmount(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel component="label" className={classes.formLabel}>
                Select a payment method
              </FormLabel>
              <br />
              {/*<FormLabel component="label" className={classes.formLabel}>
                  Card ending in 
                </FormLabel>*/}
              <Select
                onChange={(e) => setPayment_meth(e.target.value)}
                disableUnderline
                displayEmpty
                fullWidth
                // value={reason}
                className={classes.inputSelect}
                inputProps={{
                  "aria-label": "Select Reason",
                }}
              >
                {props.payment_method_details.map((payment_method) => (
                  <MenuItem value={payment_method.id}>
                    **** **** **** {payment_method.last4}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <br />
          <Grid item xs={12}>
            <FormLabel component="label" className={classes.formLabel}>
              Notes
            </FormLabel>
            <Typography gutterBottom>
              The Pet provider will receive funds after your account has been
              charged after 24 hours.
            </Typography>
            <Input
              fullWidth
              id="description"
              name="description"
              disableUnderline
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              multiline
              placeholder="Share any important information here."
              rows="5"
              className={classes.inputSmall}
            />
          </Grid>

          {props.messages.notify_timeout ? (
            <Grid item md={12} sm={12} xs={12}>
              <Alert
                severity="success"
                style={{ marginTop: "20px" }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      props.clear_error();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <div className={classes.message}>{alert}</div>
              </Alert>
            </Grid>
          ) : null}

        {props.book_data.tips_payment_made && check_tip_payment ? (
            <Grid item md={12} sm={12} xs={12}>
              <Alert
                severity="info"
                style={{ marginTop: "20px" }}>
                <div className={classes.message}>{`Are you sure you want to make another payment of $${amount}?`}</div>
                <span onClick={confirm_make_payment} style={{ cursor: "pointer", marginRight: "270px", color: 'red', fontWeight: 'bold' }} >{"Yes"}</span> 
                <span 
                  onClick={() => {
                    handleClose()
                    setCheck_tip(false)
                  }} 
                style={{ cursor: "pointer", fontWeight: 'bold', color: 'red'}}>
                {"No"}</span> 
              </Alert>
            </Grid>
          ) : null}

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={make_payment}>
            <span className={classes.labels}>Make payment</span>
            {props.tips_payments.isLoading_tips_payments === true ? (
              <div style={{ marginLeft: "5px", marginTop: "3px" }}>
                <img src={Spinner} alt="" height="18px" width="18px" />
              </div>
            ) : null}
          </Button>
          <Button variant="outlined" autoFocus onClick={handleClose}>
            <span className={classes.labels}>Close</span>
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tips_payments: state.booking,
  pets: state.pets.pets,
  form: state.forms,
  book_data: state.booking,
  messages: state.messages,
  availability: state.forms.availability_data,
  details: state.results.petproviderdetails,
});

export default connect(mapStateToProps, {
  create_payment_tips,
  clear_error,
})(DashboardViewSingleBookingTips);
