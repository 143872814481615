import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import logo from '../assets/bbb.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0 28px",
    // marginTop: "400px",
    background: "#383F45",
    minHeight: "82px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("550")]: {
      flexDirection: 'column',
      alignItems: "center",
    }
  },
  copyright: {
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "25px",
    color: "#000000",
    [theme.breakpoints.down("550")]: {
      fontSize: "14px",
      lineHeight: "17.7px",
    },
  },
  linkList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("550")]: {
      flexDirection: 'column',
      width: '100%',
      gap: '20px',
      padding: '40px 0',
    },
  },
  linkListImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("550")]: {
      // display: 'none',
      flexDirection: 'column',
      alignItems: "center",
    },
  },
  link: {
    marginRight: "19px",
    textDecoration: "none",
    fontWeight: "500",
    fontSize: "15px",
    [theme.breakpoints.down("550")]: {
      fontSize: "13.5px",
    },
    color: "#FFFFF6",
    "&:last-child": {
      marginRight: 0,
    },
  },
  Logo: {
    maxWidth: '24rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20rem'
    }
  },
  accr: {
    width: '100%',
  },
  img: {
    marginRight: "80px",
    borderRadius: 5,
    [theme.breakpoints.down("550")]: {
      marginRight: "0px",
      marginBottom: "20px"
 
    },
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.linkList}>
        <a href="/about" className={classes.link}>
          About
        </a>
        <a href="/privacy" className={classes.link}>
          Privacy policy
        </a>
        <a href="/terms" className={classes.link}>
          Terms of use
        </a>
        <a href="/cancellation" className={classes.link}>
          Cancellation Policy
        </a>
      </div>
      {/*<div className={classes.linkListImage}>
        <div className={classes.accr}>
          <img alt="HomePetVet" src={logo} className={classes.img} height="50" />
        </div>
      </div>*/}
    </div>
  );
}
