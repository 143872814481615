import axios from 'axios';
import { tokenConfig } from './auth';
import { 
    PAYOUT_LOADING,
    PAYOUT_DATA_LOADED, 
    PAYOUT_LOADED,
    CREATE_PAYOUT,
    ACCOUNT_CREATED_SUCCESS
} from './types';
import { createMessage } from './messages';
import { returnError } from './errors';

export const getBankAccounts = () => async (dispatch, getState) => {
    await axios
    .get(`${process.env.REACT_APP_API_URL}bank-accounts/`, tokenConfig(getState))
    .then((res) => {
        dispatch({
            type: PAYOUT_DATA_LOADED,
            payload: res.data
        });
    })
    .catch((e) => {
        dispatch({ type: PAYOUT_LOADED });
        console.log(e);
    });
};

export const createBankAccount = (account_number, routing_number) => (dispatch, getState) => {
    dispatch({ type: PAYOUT_LOADING });
  
    const body = JSON.stringify({
      account_number,
      routing_number
    });

    axios
    .post(`${process.env.REACT_APP_API_URL}bank-accounts/`, body, tokenConfig(getState))
    .then((res) => {
        dispatch({
            type: CREATE_PAYOUT,
            payload: res.data
        });
        dispatch({ type: ACCOUNT_CREATED_SUCCESS  });
        dispatch(createMessage('Your bank details have been saved.'));
    })
    .catch((err) => {
        dispatch({ type: PAYOUT_LOADED });

        if (err.response) {
          console.log(err.response);
          dispatch(returnError(err.response, null));
        } else {
          console.log(err);
        }
    });
};

export const deleteBankAccount = (id) => async (dispatch, getState) => {
    await axios
    .delete(`${process.env.REACT_APP_API_URL}bank-accounts/${id}/`, tokenConfig(getState))
    .then(() => {
        console.log('Deleted');
    })
    .catch((e) => {
        console.log(e);
    });
};