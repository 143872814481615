import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import logo from '../assets/logo.svg';
import logoMobile from '../assets/logo-mobile.svg';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    maxWidth: '1440px',
    '& > *': {
      margin: 'auto'
    },
    // margin: '10px',
    verticalAlign: 'middle',
    alignItems: 'center'
  },
  marginAutoItem: {
    margin: 'auto',
    alignItems: 'center'
  },
  AvatarItem: {
    [theme.breakpoints.down('800')]: {
      display: 'none'
    }
  },
  AvatarItemMobile: {
    [theme.breakpoints.up('800')]: {
      display: 'none'
    }
  }
}));

export default function ImageAvatars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="inline-flex">
        <Link href="/">
          <img alt="HomePetVet" src={logo} className={classes.AvatarItem} />
        </Link>
        <Link href="/">
          <img alt="HomePetVet" src={logoMobile} className={classes.AvatarItemMobile} />
        </Link>
      </Box>
    </div>
  );
}
