/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import FormLabel from "@material-ui/core/FormLabel";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from "@material-ui/core/Input";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Tooltip from '@material-ui/core/Tooltip';

import DialogConfirmation from "./DialogConfirmation";

import {
  getServiceData,
  user_delete_service,
  get_all_services,
  get_all_rates,
  user_update_service,
  clear_error,
  clear_state,
} from "../actions/form.js";

const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  parentDiv: {
    margin: theme.spacing(1),
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "5px",
    padding: theme.spacing(1, 1),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  helpText2: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "#b53f3fbd",
  },
  spin: {
    height: 50,
    width: 50,
    display: "flex",
    marginLeft: "10px",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  button: {
    backgroundColor: "#FF3D00!important",
    float: "right",
    margin: theme.spacing(2, 2, 1, "auto"),
  },
  submitButton: {
    backgroundColor: "#663399",
    margin: theme.spacing(2, 0, 1, "auto"),
    float: "right",
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSkeleton: {
    fontSize: "13px",
    color: "#1b1f23",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  characters: {
    fontSize: '11px',
    color: 'grey'
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  displayAdditional: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
  addIcon: {
    height: '20px',
    width: '20px'
  }
}));

function DashboardSingleAccordian(props) {
  const [expanded, setExpanded] = React.useState("");
  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [service, setService] = React.useState(props.service.service.name);
  const user_service_id = props.service.id;
  const [price, setPrice] = React.useState(props.service.price);
  const [rate_id, setRate_id] = React.useState(props.service.rate.id);
  const [description, setDescription] = React.useState(
    props.service.description
  );
  const [has_additional_rate, setAdditional] = React.useState(props?.service?.additional_rates?.length > 0);

  const [negative_error, setNegativeError] = React.useState(false);
  const [show_modal, setShow_modal] = React.useState(false);
  const [service_name, setService_name] = React.useState("");
  const [price_confirm, setPrice_confirm] = React.useState(false);
  const [error_length, setLengthError] = React.useState(false);
  const [error_number, setError] = React.useState(props.messages.notify_status);
  const [description_length, setDescrpLength] = React.useState(props.service.description !== null ? props.service.description.length : 0);


  React.useEffect(() => {
    // console.log(props.messages.notify_status)
    setInterval(() => {  
        if(props.messages.notify_status !== null){
         props.clear_error()
        }
    }, 5000)
  }, [props.messages.notify_status]);

  const handleSubmit_update = (e) => {
    function call_api() {
      const user_id = props.user.id;
      const ser = props.all_services.filter((serv) => serv.name === service);
      const send_id = ser[0].id;
      props.user_update_service(
        description,
        price,
        user_id,
        send_id,
        rate_id,
        user_service_id
      );
      props.user_update_service(
        description,
        price,
        user_id,
        send_id,
        rate_id,
        user_service_id
      );
      const usid = props.user.id;
      props.getServiceData(usid);
      props.getServiceData(usid);
      props.fetch_again();
      props.getServiceData(usid);
      props.getServiceData(usid);
    }

    if (negative_error === false && error_length === false) {
      // checking rates
      const per_hour_rate = props.rates.filter(
        (rate) => rate.name === "Per Hour"
      );
      const per_night_rate = props.rates.filter(
        (rate) => rate.name === "Per Night"
      );
      const per_day_rate = props.rates.filter(
        (rate) => rate.name === "Per Day"
      );
      const per_service_rate = props.rates.filter(
        (rate) => rate.name === "Per Service"
      );

      if (
        per_hour_rate[0].id === rate_id ||
        per_night_rate[0].id === rate_id ||
        per_day_rate[0].id === rate_id
      ) {
        if (parseInt(price) > 100 && parseInt(price) < 1000000) {
          setPrice_confirm(true);
        } else if (parseInt(price) <= 100) {
          call_api();
        }
      } else if (per_service_rate[0].id === rate_id) {
        if (parseInt(price) > 500) {
          setPrice_confirm(true);
        } else if (parseInt(price) <= 500) {
          call_api();
        }
      } else {
        call_api();
      }
    }
  };

  const confirmed = () => {
    setPrice_confirm(false);
    const user_id = props.user.id;
    const ser = props.all_services.filter((serv) => serv.name === service);
    const send_id = ser[0].id;
    props.user_update_service(
      description,
      price,
      user_id,
      send_id,
      rate_id,
      user_service_id
    );
    props.user_update_service(
      description,
      price,
      user_id,
      send_id,
      rate_id,
      user_service_id
    );
    //props.clear_state();
    const usid = props.user.id;
    props.getServiceData(usid);
    props.getServiceData(usid);
    //props.clear_state();
    props.fetch_again();
    props.getServiceData(usid);
    props.getServiceData(usid);
  };

  const not_confirmed = () => {
    setPrice_confirm(false);
    return;
  };

  React.useEffect(() => {
    if(service){
      if(service === "Overnight"){
        if(props.rates !== null){
          const night_rate = props?.rates?.filter(
            (rate) => rate.name === "Per Night"
          );
          console.log("Setting....")
          console.log(night_rate)
          getRate(night_rate[0]?.id);
          setRate_id(night_rate[0]?.id)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, rate_id])

  // posting, getting
  React.useEffect(() => {
    props.get_all_services();
    props.get_all_rates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const id = props.user.id;
    props.getServiceData(id);
    props.getServiceData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const delete_service = (id) => {
    props.fetch_again();
    props.user_delete_service(id);
    const usid = props.user.id;
    props.getServiceData(usid);
    props.getServiceData(usid);
    props.getServiceData(usid);
    props.getServiceData(usid);
  };

  const getPrice = () => {
    // const net_pay = price - price * 0.15; // Changing to 10 %
    const net_pay = price - price * 0.10;
    return net_pay.toFixed(2);
  };

  const getRate = (ser_rate) => {
    var empty = null;
    if (props.rates !== null && ser_rate !== "") {
      const service_rate = props.rates.filter((rate) => rate.id === ser_rate);
      setService_name(service_rate[0].name);
      return service_rate[0].name;
    } else return empty;
  };

  React.useEffect(() => {
    if (price && service_name) {
      setShow_modal(true);
    }
  }, [price, service_name]);

  let alert;
  if (props.messages.notify_status !== null) {
    alert = <div className="alerts">{props.messages.notify_status}</div>;
  }

  return (
    <div>
      {props.service !== null ? (
        <div>
          <Accordion
            className={classes.accordion}
            key={props.service.id}
            square
            expanded={expanded === `panel${props.service.id}`}
            onChange={handleChange(`panel${props.service.id}`)}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <div className={classes.displayAdditional}>
                <Typography>{props.service.service.name}</Typography>
                {has_additional_rate ? 
                  <Tooltip title="Has additional rates">
                     <AddBoxRoundedIcon className={classes.addIcon} fontSize="inherit" /> 
                  </Tooltip>
                : null}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {props.messages.notify_status && props.messages.notify_id === null ? (
                    <Grid item xs={12} sm={12} lg={12}>
                      <Alert
                        severity="error"
                        icon={false}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              props.clear_error();
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div className={classes.message}>
                          {alert}
                        </div>
                      </Alert>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} sm={5}>
                    <FormLabel component="label" className={classes.formLabel}> Service </FormLabel>
                    {props.all_services !== null ? (
                      <div>
                        <Select
                          onChange={(e) => setService(e.target.value)}
                          disableUnderline
                          fullWidth
                          defaultValue={props.service.service.name}
                          autoComplete="new-password"
                          displayEmpty
                          className={classes.inputSelect}
                          inputProps={{
                            "aria-label": "Update Service",
                          }}
                        >
                          {props?.all_services?.map((p, i) => (
                            <MenuItem value={p.name} key={i}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormLabel component="label" className={classes.formLabel}> Price $ </FormLabel>
                    <TextField
                      id="price"
                      onChange={(e) => {
                        var value = parseInt(e.target.value);
                        if (e.target.value >= 0) {
                          setPrice(e.target.value);
                          getRate(props.service.rate.id);
                          setNegativeError(false);
                        } else {
                          setNegativeError(true);
                          value = value * -1;
                          setPrice(value);
                        }
                      }}
                      //label="Price $"
                      type="number"
                      min={0}
                      defaultValue={props.service.price}
                      className={classes.inputSmall}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                    {negative_error === true ? (
                      <FormLabel style={{ color: "red" }}>
                        Please add a valid price
                      </FormLabel>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel component="label" className={classes.formLabel}> Rate </FormLabel>
                    {props.rates !== null ? (
                      <div>
                        <Select
                          disableUnderline
                          fullWidth
                          // onChange={(e) => setRate_id(e.target.value)}
                          onChange={(e) => {
                            getRate(e.target.value);
                            setRate_id(e.target.value);
                          }}
                          value={rate_id}
                          autoComplete="new"
                          className={classes.inputSelect}
                          inputProps={{
                            "aria-label": "Update rate",
                          }}
                        >
                          {props.rates?.map((p, i) => (
                            <MenuItem value={p.id} key={i}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    ) : null}
                  </Grid>

                  {show_modal === true &&
                  getPrice() !== 0 &&
                  service_name !== null ? (
                    <Grid item xs={12}>
                      <Alert
                        severity="info"
                        icon={false}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setShow_modal(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div className={classes.message}>
                          Your total pay is: {getPrice()} USD {service_name}
                        </div>
                      </Alert>
                    </Grid>
                  ) : null}

                  {price_confirm ? (
                    <DialogConfirmation
                      price={price}
                      service={service_name}
                      confirmed={confirmed}
                      not_confirmed={not_confirmed}
                    />
                  ) : null}
                  <Grid item xs={12}>
                    {/*<TextField
                      id="description"
                      label="Description"
                      // onChange={(e) => setDescription(e.target.value)}
                      onChange={(e) => {
                        if(e?.target?.value?.length <= 255){
                          setLengthError(false)
                          setDescription(e.target.value)
                          setDescrpLength(e.target.value.length)
                        }else{
                           setLengthError(true)
                        }
                      }}
                      defaultValue={props.service.description}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />*/}

                    <Input
                      onChange={(e) => {
                        if(e?.target?.value?.length <= 255){
                          setLengthError(false)
                          setDescription(e.target.value)
                          setDescrpLength(e.target.value.length)
                        }else{
                           setLengthError(true)
                        }
                      }}
                      defaultValue={props.service.description}
                      autoComplete="new-password"
                      disableUnderline
                      fullWidth
                      type="number"
                      placeholder="Write a few words about the service"
                      inputProps={{ "aria-label": "description" }}
                      className={classes.inputSmall}
                      multiline
                      rows={3}
                    />
                    <span className={classes.characters} >{description_length}/255</span>
                    {error_length ? (
                        <FormHelperText error>Description must not exceed 255 characters.</FormHelperText>
                      ): null}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "35px",
                        minWidth: "80px",
                        minHeight: "35px",
                      }}
                      onClick={handleSubmit_update}
                      className={classes.submitButton}
                    >
                      {"Update"}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "35px",
                        minWidth: "80px",
                        minHeight: "35px",
                      }}
                      onClick={(e) => delete_service(props.service.id)}
                      className={classes.button}
                    >
                      {"Delete"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div>
          <Skeleton
            variant="rect"
            width="100%"
            className={classes.accordion}
            height="3em"
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.errors,
  form: state.forms,
  user: state.auth.user,
  loading: state.services.isLoading,
  results: state.services.results,
  rates: state.services.rates,
  messages: state.messages,
  all_services: state.services.admin_services,
});

export default connect(mapStateToProps, {
  getServiceData,
  user_delete_service,
  get_all_services,
  get_all_rates,
  clear_error,
  user_update_service,
  clear_state,
})(DashboardSingleAccordian);
