/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import {
  verify,
  verify_email,
  register,
  aws_cognito_amplify_verify,
  aws_cognito_amplify_resend
} from '../actions/auth.js';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Footprint from '../assets/footprint.svg';
import logo from '../assets/lock.svg';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Spinner from '../components/Spinner';
import FormHelperText from '@material-ui/core/FormHelperText';

import GuestNavBar from '../components/GuestNavBar';

// var AWS = require('aws-sdk');
// var CryptoJS = require('crypto-js');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    minHeight: '100vh',
    [theme.breakpoints.down('800')]: {
      alignItems: 'flex-start'
    }
  },
  rootGrid: {
    margin: '144px 0',
    padding: '33px 0',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('800')]: {
      padding: '36px 0 60px 0',
      maxWidth: '347px',
      margin: '80px 0 0'
    }
  },
  image: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '70px',
    height: '55px'
  },
  paper: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '367px',
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(8, 'auto')
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '367px'
    },
    [theme.breakpoints.down('800')]: {
      padding: '0 25.5px',
      margin: '0'
    }
  },
  decor: {
    position: 'absolute',
    bottom: '-41px',
    right: '-28%',
    zIndex: -1,
    [theme.breakpoints.down('1260')]: {
      maxWidth: '93px',
      bottom: '-65px',
      right: '2%'
    }
  },
  avatar: {
    margin: theme.spacing(3, 1, 0),
    backgroundColor: 'inherit',
    width: '70px',
    height: '55px',
    '& img': {
      width: '56px',
      height: '55.5px'
    }
  },
  logo: {
    cursor: 'pointer',
    width: '52px',
    height: '52px'
  },
  title: {
    marginBottom: '23px',
    fontFamily: 'Dosis',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '58px',
    color: '#383F45',
    [theme.breakpoints.down('800')]: {
      fontSize: '33px',
      lineHeight: '42px'
    }
  },
  form: {
    width: "100%",
    maxWidth: "328px", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    }
  },
  buttonWrap: {
    position: 'relative'
    // pointerEvents: 'none'
  },
  iconFacebook: {
    position: 'absolute',
    top: '50%',
    left: '16.83px',
    transform: 'translateY(-50%)'
  },
  iconGoogle: {
    position: 'absolute',
    top: '51%',
    left: '16.83px',
    transform: 'translateY(-50%)'
  },
  google: {
    margin: theme.spacing(1, 0, 1),
    width: '100%',
    background: '#346CF0!important',
    opacity: '1!important',
    height: '56px!important',
    color: '#ffffff !important',
    cursor: 'pointer!important',
    transitions: 'background 0.5s ease-in',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: theme.spacing(1, 'auto')
    },
    '&:active': {
      background: 'rgb(116 141 199)!important'
    },
    '& span': {
      width: '100%',
      fontWeight: 'bold!important',
      fontSize: '19px!important',
      fontFamily: 'Dosis',
      textAlign: 'right!important',
      paddingRight: '25%!important'
    }
  },
  or: {
    margin: '5px 0',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    textAlign: 'center'
  },
  input: {
    margin: theme.spacing(3, 0, 3),
    borderRadius: '5px',
    '& input': {
      padding: '23px 52px 17px 14px'
    },
    '& label': {
      top: '8px',
      color: 'black'
    },
    '&:first-child': {
      marginTop: '10px'
    },
    '& > fieldset': {
      border: '1px solid rgba(25, 25, 25, 0.32) !impotant',
      marginTop: '100px',
      background: 'red'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '29px'
  },
  formLabel: {
    marginBottom: '18px',
    textAlign: 'center',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000'
  },
  rulles: {
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    '& a': {
      color: '#327287',
      textDecoration: 'none'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    height: '56px',
    textTransform: 'none',
    // fontSize: '13px!important',
    background: '#FF5722',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '16px',
    color: '#FFFFFF!important',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0)
    }
  },
  already: {
    marginTop: '61px',
    textAlign: 'left',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#383F45',
    justifyContent: 'center'
  },
  alreadyText: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.4px',
    color: '#000000',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      fontFamily: 'Dosis',
      color: '#327287',
      fontWeight: '600',
      textDecoration: 'none',
      fontSize: '18px',
      lineHeight: '22px'
    },
    '& span': {
      color: '#327287',
      textDecoration: 'none',
      cursore: 'pointer'
    }
  },
  radioWrap: {
    margin: '0 auto',
    marginBottom: '37px',
    border: '1px solid #B5B5B5'
  },
  radioButton: {
    minWidth: '155px',
    [theme.breakpoints.down('800')]: {
      minWidth: '142px'
    }
  },
  signWrap: {
    margin: '0 auto',
    marginTop: '15px',
    maxWidth: '328px',
    [theme.breakpoints.down('800')]: {
      marginTop: '0px'
    }
  },
  // formControl: {
  //   width: '100%'
  // },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  social: {
    margin: theme.spacing(3, 0, 2)
  },
  paper2: {
    margin: theme.spacing(8, 16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  body2: {
    lineHeight: '1.5',
    color: '#444444'
  },
  message: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  checkbox: {
    fontSize: '11px',
    '& > *': {
      fontSize: '11px'
    }
  },
  typeOfAccount: {
    marginTop: theme.spacing(1)
  },
  fogot: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '16px',
    color: '#156981',
    fontFamily: 'Dosis',
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    marginBottom: '54px'
  },
  root_: {
    flexGrow: 1,
    padding: '0 18px',
    marginTop: '10px',
    background: '#383F45',
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  copyright: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '25px',
    color: '#000000',
    [theme.breakpoints.down('550')]: {
      fontSize: '14px',
      lineHeight: '17.7px'
    }
  },
  linkList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  link: {
    marginRight: '19px',
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '25px',
    color: '#FFFFF6',
    [theme.breakpoints.down('550')]: {
      fontSize: '14px',
      lineHeight: '17.7px'
    },
    '&:last-child': {
      marginRight: 0
    }
  }
}));

function VerifySignupCode(props) {
  const classes = useStyles();

  const [sucess, setSucess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [resend_link_email, setEmailResend] = useState(null);
  const [email_error, setErrorEmail] = useState(false);
  const [resend_success, setResendsuccess] = useState(false);
  const [resend_loading, setResendLoading] = useState(false);
  const [resend_failed, setResendFailed] = useState(false);
  const [response_error, setResError] = useState(null);

  const code = props.match.params.code;
  console.log('Code is ' + code);

  let value;
  let alert;
  makeAlert();

  function makeAlert(isSuccess, detail) {
    console.log('Success is ' + isSuccess);
    let msg;
    switch (isSuccess) {
      case true:
        msg = detail;
        console.log('Detail is ' + detail);

        alert = (
          <div className="alerts">
            <Alert icon={false} severity="sucess">
              Use your verified email to sign in on the app or your desktop browser.
            </Alert>
          </div>
        );
        setSucess(true);
        console.log('making alert true');
        break;
      case false:
        msg = detail;
        alert = (
          <div className="alerts">
            <Alert severity="error">Please try again {msg}</Alert>
          </div>
        );
        setSucess(false);
        console.log('makeing alert false');
        break;
      default:
        //console.log("undefined Sucess");
        // alert = (
        //   <div className="alerts">
        //     <Alert severity="info">
        //       Please Wait While Account is being activated
        //     </Alert>
        //   </div>
        // );
        break;
    }
    value = msg;
    console.log(value);
  }

  const sendReq = async (code) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const key = {
      activation_key: code
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}auth/accounts/confirm/`, key, config)
      .then((res) => {
        // console.log(res.data);
        makeAlert(true, res.data);
      })
      .catch((e) => {
        console.log(e.data);
        setFailed(true) // 
        makeAlert(false, e.data);
      });
  };

  var mailFormat =
    /^([a-zA-Z0-9_\.\-!#$%&'*+/=?^`{|}~"(),:;<>[\]])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

  const ResendLink = async () => {
    console.log(resend_link_email)
    if (resend_link_email !== null) {

      if (!mailFormat.test(resend_link_email)) {
        setErrorEmail(true);
        return false
      } else {
        setResendLoading(true)
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };

        const body = {
          email: resend_link_email
        };
        await axios
          .post(`${process.env.REACT_APP_API_URL}auth/accounts/activation/resend/`, body, config)
          .then((res) => {
            setResendLoading(false)
            setResendsuccess(true)
            console.log(res.data);
            // makeAlert(true, res.data);
          })
          .catch((err) => {
            setResendLoading(false)
            setResendFailed(true)
            console.log(err.response.data);
            if (err.response?.data?.email) {
              setResError(err.response?.data?.email)
            }
          });
      };
    }
  }


  React.useEffect(() => {
    if (code) {
      sendReq(code);
    }
    // eslint-disable-next-line
  }, [code])

  if (sucess) {
    alert = (
      <div className="alerts">
        <Alert icon={false} severity="success">
          Email verified successfully! Use your verified email to sign in on the app or your browser.
        </Alert>
      </div>
    );
  } else if (failed && !sucess) {
    alert = (
      <div className="alerts">
        <Alert severity="info">Activation link already expired. Request new account activation link</Alert>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          className={classes.input}
          size="small"
          autoFocus
          onChange={(e) => {
            setErrorEmail(false)
            setEmailResend(e.target.value)
          }}
        />
        {email_error ? <FormHelperText error>Please provide a valid email</FormHelperText> : null}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={ResendLink}
        >
          {/* call resend API */}
          {resend_loading ? <Spinner /> : 'Resend link'}
        </Button>

        {resend_success ?
          <Alert severity="success">
            A new activation link has been sent! Check your email and click the link to activate Account. Please check your junk or spam folder if you do not see the e-mail in your specified inbox.
          </Alert>
          : null}
        {resend_failed ?
          <>
            {response_error !== null ?
              <Alert severity="info">
                {response_error}
              </Alert>
              :
              <Alert severity="info">
                We're sorry, an error occured while sending you another activation link. Please try again later.
              </Alert>
            }
          </>
          : null}


      </div>
    );
  } else {
    alert = (
      <div className="alerts">
        <Alert severity="info">Please wait while your account is activated</Alert>
      </div>
    );
  }


  if (props.auth.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <div>
        <GuestNavBar />
      </div>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          xl={3}
          component={Paper}
          elevation={6}
          className={classes.rootGrid}
          square
        >
          <div className={classes.paper}>
            <img className={classes.decor} src={Footprint} alt="" />
            <Avatar className={classes.avatar}>
              <img src={logo} onClick={(e) => (window.location.href = '/')} alt="logo" width="30" />
            </Avatar>

            <Typography component="h1" variant="h5" className={classes.title}>
              {sucess ? "Email verified" : "Verify your email"}
            </Typography>
            <Grid item xs={12} className={classes.alertSection}>
              <div className={classes.message}>{alert}</div>
            </Grid>
            {
              sucess ? null
                :
                <>
                  {
                    !failed && !sucess ?
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        // onClick={handleVerifyCode}
                        href="/login"
                      >
                        <div>
                          <Spinner />
                        </div>
                      </Button>
                      : null}
                </>
            }
            <br />
            <br />
          </div>
        </Grid>
      </Grid>

      <div className={classes.root_}>
        {/* <h3 className={classes.copyright}>Copyright HPV</h3> */}
        <div className={classes.linkList}>
          <a href="/about" className={classes.link}>
            About
          </a>
          <a href="/privacy" className={classes.link}>
            Privacy policy
          </a>
          <a href="/terms" className={classes.link}>
            Terms of use
          </a>
          <a href="/cancellation" className={classes.link}>
            Cancellation Policy
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  auth: state.auth,
  error: state.errors
});

export default connect(mapStateToProps, {
  verify,
  verify_email,
  register,
  aws_cognito_amplify_verify,
  aws_cognito_amplify_resend
})(VerifySignupCode);
