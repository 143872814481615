import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserHomepageNavbar';
import Footer from '../components/Footer';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '43px',
    paddingBottom: '59px',
    fontFamily: 'Dosis',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
    background: '#F5F5EF',
    position: 'relative',
    zIndex: '2',
    [theme.breakpoints.down('650')]: {
      paddingTop: '27px',
      paddingBottom: '40px'
    }
  },
  image: {
    margin: theme.spacing(1)
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '50%'
  },
  paper: {
    margin: theme.spacing(1)
  },
  wizardContainer: {
    margin: theme.spacing(12, 'auto', 2)
  },
  title: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '520',
    fontSize: '46px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  title_: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '520',
    fontSize: '30px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  teamSection: {
    margin: theme.spacing(3, 0, 1)
  },
  descriptions: {
    textAlign: 'center'
  },
  body: {
    // letterSpacing: '0.02em',
    // lineHeight: '2em',
    // marginBottom: theme.spacing(3),
    // fontSize: '1em',

    fontSize: '1.1em',
    lineHeight: '30px',
    //alignItems: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    fontWeight: '600',
    color: '#383F49',
    [theme.breakpoints.down('850')]: {
      fontSize: '20px',
      lineHeight: '28px'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '16px',
      lineHeight: '19.8px',
      maxWidth: '309px',
      margin: '0 auto'
    }
  },
  descriptions_: {
    fontFamily: 'Averia Sans Libre',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '500',
    color: '#737373',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('850')]: {
      fontSize: '18px',
      lineHeight: '24px'
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '14px',
      lineHeight: '17px'
    }
  }
}));

function AboutUs(props) {
  const classes = useStyles();
  // const preventDefault = (event) => event.preventDefault();
  // const { user } = props;

  // const handleSignupLinkClick = () => {
  //   window.location.href = '/signup';
  // };

  return (
    <Box>
      <div>
        <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
        <div>{!props.auth.token && <GuestNavBar />}</div>
      </div>

      <div className={classes.root}>
        <Container maxWidth="md" className={classes.wizardContainer}>
          <h2 className={classes.title}>Terms of Service</h2>
          <p variant="body2" className={classes.body} gutterBottom>
            These Terms of Service (the “Terms”) are a binding legal agreement between you and
            <span style={{ fontWeight: '800' }}>
              {' '}
              TAKESAVILLAGE.COM, LLC (“TAV” or “us” or “we”)
            </span>
            . The Terms govern your use of our software applications, resources and services for pet
            owners and Pet Providers to find each other, communicate with each other, and arrange
            for the provision of pet care services (collectively, our “Home Pet Vet Service”). The
            Terms govern all use of the Home Pet Vet Service, whether you access it from our website
            at https://www.homepetvet.com (or any localized version) the “Site”), our mobile
            applications and mobile websites, our Facebook application, our online or phone support
            offerings, or any other access point we make available to you. Our Home Pet Vet Service
            Guarantee Terms, Reservation Protection Policy and other Policies applicable to your use
            of the Home Pet Vet Service are incorporated by reference into these Terms of Service.{' '}
            <span style={{ fontWeight: '800' }}>
              {' '}
              BY AGREEING TO THESE TERMS DURING THE ACCOUNT SIGN-UP PROCESS OR ACCESSING OR USING
              THE HOME PET VET SERVICE WITHOUT AN ACCOUNT, YOU ACCEPT THESE TERMS. IF YOU DO NOT
              AGREE WITH THESE TERMS, YOU SHOULD NOT ACCEPT THEM, IN WHICH CASE YOU DO NOT HAVE THE
              RIGHT TO USE THE{' '}
            </span>{' '}
            Home Pet Vet Service. You understand and agree that we may change the Terms from time to
            time, and that any such changes will be effective when we post the modified Terms on
            https://www.homepetvet.com, unless otherwise required by applicable law. Your continued
            access and use of the Home Pet Vet Service after we post the modified Terms will
            constitute your consent to be bound by the modified Terms.
          </p>
          <br />
          <h2 className={classes.title_}>Home Pet Vet Service.</h2>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Nature of the Home Pet Vet Service.
            </span>{' '}
            The Home Pet Vet Service consists of a desktop Web application, mobile applications, and
            other related tools, support and services that pet owners (“
            <span style={{ fontWeight: '800' }}>Pet Owners</span>”) and providers of pet-related
            services (“<span style={{ fontWeight: '800' }}>Pet Providers</span>”) can use to find,
            communicate with and interact with each other.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Home Pet Vet does not provide Pet Care Services.
            </span>{' '}
            Home Pet Vet is a neutral venue for Pet Providers and Pet Owners. Home Pet Vet is not a
            Pet Provider. We make no representations or warranties about the quality of
            veterinarian, euthanasia, boarding, pet sitting, dog walking, house sitting, concierge,
            training, grooming or other services provided by Pet Providers (“
            <span style={{ fontWeight: '800' }}>Pet Care Services</span>”), or about your
            interactions and dealings with users. Pet Providers listed on Site are not under the
            direction or control of TAV.{' '}
            <span style={{ fontWeight: '800' }}>
              {' '}
              TAV will not be responsible or liable for the performance or conduct of Pet Providers
              or Pet Owners, whether online or offline.{' '}
            </span>
            You should exercise caution and use your independent judgment before engaging a Pet
            Provider, providing Pet Care Services, or otherwise interacting with users via the Home
            Pet Vet Service. Pet Owners and Pet Providers are solely responsible for making
            decisions that are in the best interests of themselves and their pets.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}> Release.</span>{' '}
            <span style={{ fontWeight: '800' }}>
              {' '}
              Our liability for any claims, injuries, loss, harm and/or damages arising from and/or
              in any way related to your interactions or dealings with other users and the acts
              and/or omissions of Pet Providers and Pet Owners, whether online or offline, is
              limited to the amounts and obligations set forth in these Terms.{' '}
            </span>
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Transactions are between Pet Owners and Pet Providers.{' '}
            </span>{' '}
            The Home Pet Vet Service may be used to find and offer Pet Care Services and to
            facilitate payment, but all transactions conducted via the Home Pet Vet Service are
            between Pet Owners and Pet Providers.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}> Bookings. </span> Pet Owners and Pet
            Providers transact with each other on the Home Pet Vet Service when they both agree to a
            “booking” that specifies the fees, time period, cancellation policy, and other terms for
            provision of Pet Care Services via the booking mechanism provided on the Home Pet Vet
            Service (a “Booking”). A Booking may be initiated by either a Pet Provider or a Pet
            Owner by selecting the type(s) of Pet Care Services to be provided and then following
            the prompts that appear on-screen. If you are a Pet Owner and you initiate a Booking,
            you agree to pay for the Pet Care Services described in the Booking when you click
            “Contact & Pay.” If you are a Pet Owner and a Pet Provider initiates a Booking, you
            agree to pay for the Pet Care Services described in the Booking when you click “Pay
            Now.” All requests are subject to acceptance by the receiving party. The receiving party
            is not obligated to accept your (or any) request and may, at their discretion, decline
            for any reason. You acknowledge that, once you complete a Booking, you agree to honor
            the price and other terms of that Booking, as acknowledged in the Booking confirmation.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              {' '}
              Pet Owners are Solely Responsible for Evaluating Pet Providers.
            </span>{' '}
            Pet Owners are solely responsible for evaluating the suitability of Pet Providers for
            the services they offer to provide.
          </p>
          <br />
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Abandoned Pets; Re-homing </span> Pet
            Owners who arrange for Pet Care Services and fail to retrieve their pet after the
            service period identified in a Booking agree that Home Pet Vet (or the Pet Provider)
            may, in its (or his or her) sole discretion, place the pet in foster care, transfer care
            to animal control or other law enforcement authorities, or find other alternate care.
            Pet Owner agrees to reimburse Home Pet Vet and/or the Pet Provider for all costs and
            expenses associated with such actions. Further, Home Pet Vet expressly reserves the
            right, in its sole discretion, to remove a Pet Owner’s pet from a Pet Provider’s care
            should Home Pet Vet deem it necessary for the safety of a pet, the Pet Provider, or any
            persons living with the Pet Provider.
          </p>
          <br />
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Emergencies. </span>
            We recommend that Pet Owners give their Pet Providers contact information where they can
            be reached in the event medical care for a pet becomes necessary.
          </p>
          <br />
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Consultation Services.</span>
            Home Pet Vet may offer Pet Owners and Pet Providers phone, chat, or email veterinary
            consultation services from a third party to provide an educational resource for
            decisions you make about your own pets or pets in your care. These consultation services
            are provided by a third party, and are not a part of the Home Pet Vet Service. If you
            use these third party consultation services, you should use them only in conjunction
            with, and not as a substitute for, professional veterinary care. You agree to resort
            solely to the applicable third party consultation service in the event of any claims
            arising from their services.
          </p>
          <br />
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Google Maps. </span>
            Use of the Home Pet Vet Service requires use of Google Maps features and content, which
            are subject to the current (1) Google Maps/Google Earth Additional Terms of Service at
            https://maps.google.com/help/terms_maps.html (including the Acceptable Use Policy at
            https://cloud.google.com/maps-platform/terms/aup/); and (2) Google Privacy Policy at
            https://www.google.com/policies/privacy/ (collectively, the "Google Terms"). By using
            the Home Pet Vet Service, you acknowledge and agree to the Google Terms as they apply to
            you (e.g., as an "End User"). Any unauthorized use of the Google Maps features and
            content may result in your suspension or termination from the Home Pet Vet Service.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ fontWeight: '800' }}>Compliance with Applicable Law. </span>By accessing
            and using the Home Pet Vet Service, you certify that you: (1) are at least 18 years of
            age or the age of majority in your jurisdiction, whichever is higher, and (2) will
            comply with all laws and regulations applicable to your activities conducted through, or
            related to, the Home Pet Vet Service. <br />
            <span style={{ fontWeight: '800', fontSize: '300' }}>•</span> For Pet Owners, this
            means, among other things, that you will ensure that your pets are vaccinated, licensed,
            identification-tagged and/or microchipped as required by local laws or regulations; that
            you have obtained and will maintain any mandatory insurance policies concerning the pets
            whose care you entrust to Pet Providers (and that such policies will benefit third
            parties, including Pet Providers, to the same extent they benefit you).
            <br />
            <span style={{ fontWeight: '800', fontSize: '300' }}>•</span>For Pet Providers, this
            includes that you are legally eligible to provide Pet Care Services in the jurisdiction
            where you provide Pet Care Services; that you have complied and will comply with all
            laws and regulations that are applicable to you; that you have obtained all business
            licenses and permits necessary to legally provide Pet Care Services; and that, when
            providing Pet Care Services, you will comply with applicable leash, pet waste disposal,
            and similar laws.
            <br />
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            You acknowledge that Home Pet Vet is entitled to rely on these commitments from you, is
            not responsible to ensure that all users have complied with applicable laws and
            regulations, and will not be liable for a user’s failure to do so.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Use of the Home Pet Vet Service; Suspension.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              {' '}
              Your Conduct on the Home Pet Vet Service.{' '}
            </span>{' '}
            When you use the Home Pet Vet Service, you agree:
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> To use the Home Pet Vet
            Service only in a lawful manner and only for its intended purposes.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to use the Home Pet
            Vet Service to arrange for the care of: (a) exotic or inherently dangerous pets such as
            venomous snakes or constrictors, primates, wolves or wolf hybrids, non-domesticated
            cats, alligators, horses or other livestock; (b) any animal whose ownership or
            third-party care is prohibited under applicable law; or (c) any animal that has a
            history of, or which has been trained for, attacks on pets or people.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to submit viruses or
            other malicious code to or through the Home Pet Vet Service.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to use the Home Pet
            Vet Service, or engage with other users of the Home Pet Vet Service, for purposes that
            violate the law.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to use the Home Pet
            Vet Service to arrange for the provision and purchase of services with another user,
            then complete transactions for those services outside of the Home Pet Vet Service.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to use the Home Pet
            Vet Service for purposes of competing with Home Pet Vet or to promote other products or
            services.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to post reviews about
            Pet Providers that aren’t based on your personal experience, that are intentionally
            inaccurate or misleading, or that violate these Terms.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to post content or
            materials that are pornographic, threatening, harassing, abusive, or defamatory, or that
            contain nudity or graphic violence, incite violence, violate intellectual property
            rights, or violate the law or the legal rights (for example, privacy rights) of others.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to post “spam” or
            other unauthorized commercial communications.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> To use the Home Pet Vet
            Service only for your own purposes, and not to impersonate any other person.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to transfer or
            authorize the use of your account for the Home Pet Vet Service by any other person, or
            to engage in fraudulent transactions.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to provide false
            information in your profile on, or registration for, the Home Pet Vet Service, or to
            create multiple or duplicate accounts.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to interfere with our
            provision of, or any other user’s use of, the Home Pet Vet Service.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Not to solicit another
            user’s username and password for the Home Pet Vet Service or any other sensitive
            personal information, including bank details.
            <br />
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Suspension and Termination. </span>
            You understand and agree that we have no obligation to provide the Home Pet Vet Service
            in any specific location or territory, nor to continue providing it once we have begun.
            We reserve the right to suspend or terminate your access to the Home Pet Vet Service for
            any reason.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ fontWeight: '800' }}>Registration; Account Security. </span>
            In order to use some aspects of the Home Pet Vet Service, you will be required to create
            a username, password, and user profile. If you elect to use the Home Pet Vet Service,
            you agree to provide accurate information about yourself and keep this information
            up-to-date. You agree not to impersonate anyone else and not to maintain more than one
            account (or, if Home Pet Vet suspends or terminates your account, not to create
            additional accounts). You are responsible for maintaining the confidentiality of your
            username and password for the Home Pet Vet Service and are responsible for all activity
            under your account. You agree to notify us promptly of any unauthorized use of your
            account.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ fontWeight: '800' }}>Privacy. </span>
            Our collection and use of your personal information on the Home Pet Vet Service is
            described in our Privacy Statement. By accessing or using the Home Pet Vet Service, you
            acknowledge that you have read and understand the
            <p
              variant="body2"
              style={{ fontStyle: 'italic' }}
              className={classes.body}
              gutterBottom
            >
              Privacy Statement.
            </p>
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Your Content.
          </p>{' '}
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Suspension and Termination. </span>
            You understand and agree that we have no obligation to provide the Home Pet Vet Service
            in any specific location or territory, nor to continue providing it once we have begun.
            We reserve the right to suspend or terminate your access to the Home Pet Vet Service for
            any reason.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Your Content. </span>
            We may require or allow you (or someone else on your behalf) to submit or upload text,
            photographs, images, videos, reviews, information and materials to your profile on the
            Home Pet Vet Service or otherwise in connection with using the Home Pet Vet Service
            and/or participating in promotional campaigns we conduct on the Site (collectively, “
            <span style={{ fontWeight: '800' }}>Your Content</span>”). <br />{' '}
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>License. </span>
            Except for the limitations on our use and disclosure of personal information described
            in our Privacy Statement, to the maximum extent and duration permitted under any
            applicable law, you grant Home Pet Vet an irrevocable, perpetual, non-exclusive, fully
            paid worldwide license to use, copy, perform, publicly display, reproduce, adapt,
            modify, transmit, broadcast, prepare derivative works of, and/or distribute Your Content
            in connection with providing and/or promoting the Home Pet Vet Service, and to
            sublicense these rights to third parties.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Release. </span>
            If your name, voice, image, persona, likeness, or performance is included in any of Your
            Content, you hereby waive, and release Home Pet Vet and its users from, any claim or
            cause of action, whether known or unknown, for defamation, copyright infringement,
            invasion of the rights of privacy, publicity, or personality, or any similar claim
            arising out of the use of Your Content in accordance with the license in License Section
            and the other provisions of these Terms.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Your Representations and Warranties about Your Content.{' '}
            </span>
            You represent and warrant that (1) you are the owner or licensor of Your Content, and
            that you have all rights, consents and permissions necessary to grant the license in the
            License Section and make the release in the Release Section with respect to Your
            Content, (2) that you have any necessary consents and releases from individuals who
            appear or whose pets appear in Your Content; and (3) Your Content does not violate the
            law or these Terms.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Right to Remove or Screen Your Content.{' '}
            </span>
            Though we are not obligated to do so, we reserve the right to monitor, screen, edit
            and/or remove Your Content on the Home Pet Vet Service.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Reviews. </span>
            The Home Pet Vet Service may provide the ability to leave public and/or private reviews
            of users or their pets. You acknowledge that even private reviews may be shared with
            third parties in accordance with applicable law and our Privacy Statement and that TAV
            has no obligation to preserve or indefinitely store any reviews. If you are a Pet
            Provider, we have no obligation to provide you with the content of any reviews about you
            submitted by other users of the Home Pet Vet Service. We will have no liability to you
            for any deletion, disclosure, loss or modification of these reviews. We reserve the
            right to screen, edit or remove these reviews from the Home Pet Vet Service at any time.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Phone, Text and Mobile Communications.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Consent to Autodialed Text Messages.{' '}
            </span>
            This Section applies only to users in the United States. You consent to Home Pet Vet
            communicating with you about the Home Pet Vet Service by SMS, text message, email and
            other electronic means, including autodialed text messages containing service
            information and/or marketing messages, even if your phone number is on the do-not-call
            list. Your carrier's normal messaging, data and other rates and fees will apply to these
            communications. You are not required to provide this consent to receive marketing
            messages as a condition of purchasing anything or using the Home Pet Vet Service, and
            you may opt-out of receiving these messages at any time as described in our Privacy
            Statement (though you may continue to receive messages while Home Pet Vet processes your
            request).
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Fees & Payment.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Currency. </span>
            All fees, deductible amounts and other payments referenced on, or charged through, the
            Home Pet Vet Service are listed and payable in US Dollars.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Fees for Pet Owners. </span>
            Pet Owners may purchase Pet Care Services from a Pet Provider by completing a Booking as
            described in these Terms you agree to pay the total fees indicated in the Booking. Where
            required by law, the amount charged will also be inclusive of applicable taxes.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Fees for Pet Providers. </span>
            Pet Providers may agree to provide Pet Care Services to a Pet Owner by agreeing to a
            Booking as described in these Terms. If you are a Pet Provider, you must confirm the
            Booking before it expires or the Pet Owner will have no obligation to complete the
            transaction. Once the Booking is completed by both parties, you agree to honor the price
            set forth in your Booking. The purchase of Pet Care Services is a transaction between
            the Pet Owner and the Pet Provider. Home Pet Vet’s role is to facilitate the
            transaction. We will (either directly or indirectly through an authorized third party)
            collect payment from the Pet Owner at the time of Booking and (except to the extent of
            any payment hold pursuant these Terms) initiate payment to the Pet Provider’s account
            forty (48) hours after completion of the service period indicated in the Booking. Pet
            Providers are charged a service fee as described in these Terms. Where required by law,
            the amount charged will also be inclusive of applicable taxes.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Service Fees. </span>
            We charge service fees for some aspects of the Home Pet Vet Service. If you are a Pet
            Provider, except where otherwise specified via the Home Pet Vet Service, our service fee
            is calculated as a percentage of the fees a Pet Owner agrees to pay to you in a Booking
            and is collected from each Booking.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Late Fees and Additional Charges.{' '}
            </span>
            If you are a Pet Owner, you acknowledge and agree that, if you fail to retrieve your pet
            at the end of the service period agreed in a Booking, you will be charged for additional
            service time (pro rata for each partial late day) at the daily rate established in the
            Booking. In addition, you agree to indemnify Home Pet Vet from, and agree that we may
            charge your credit card or other payment method for, any additional costs and expenses
            we or the Pet Provider incur as a result of your failure to retrieve your pet at the end
            of the service period agreed in a Booking.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Cancellations & Refunds. </span>
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              {' '}
              Reservation Protection.{' '}
            </span>{' '}
            As more fully described on Home Pet Vet’s Reservation Protection page, Home Pet Vet can
            help you find replacement Pet Providers when Pet Providers cancel Bookings near the
            start date of the service period identified in the Booking. The availability of the
            Reservation Protection depends on the timing of the cancellation and the type of Pet
            Care Services provided; consult the Reservation Protection page for details.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              {' '}
              Cancellations by Pet Provider.{' '}
            </span>{' '}
            If a Pet Provider cancels a Booking prior to or during the service period identified in
            the Booking, we will refund the fees paid by the Pet Owner for Pet Care Services not
            provided, as well as any service charge paid to Home Pet Vet. If you are a Pet Provider,
            you can appoint a substitute Pet Provider (as agreed by the Pet Owner and so long as the
            substitute has an active account on the Home Pet Vet Service and has agreed in writing
            to accept a Booking) by contacting Home Pet Vet to modify the Booking. If you do not
            find a substitute and repeatedly cancel accepted Bookings without justification, Home
            Pet Vet may terminate your account.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              {' '}
              Cancellations by Pet Owner.{' '}
            </span>{' '}
            If a Pet Owner cancels a Booking prior to or during the service period specified in a
            Booking, we will refund fees in accordance with the cancellation policy. For more
            information about the cancellation policy, please visit the{' '}
            <span variant="body2" style={{ fontStyle: 'italic' }}>
              Help Center{' '}
            </span>
            .<br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              Force Majeure.{' '}
            </span>{' '}
            The cancellation policies described herein may not apply in the event of certain
            emergency situations beyond the control of Pet Providers and/or Pet Owners that make it
            impossible or impractical to perform agreed Bookings, such as evacuations resulting from
            earthquake, hurricane, wildfire, flood, war, riots or other similar disaster. In such
            cases TAV may, in its reasonable discretion, issue refunds under terms of the
            cancellation policy.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              Refunds for Failure to Perform.{' '}
            </span>{' '}
            If we determine in our reasonable discretion that a Pet Provider has failed to provide
            Pet Care Services as agreed with the Pet Owner or is otherwise in breach of these Terms,
            then we may, in our reasonable discretion, cancel a Booking and/or issue a full or
            partial refund to a Pet Owner.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              General Terms for Cancellations.{' '}
            </span>{' '}
            If you wish to cancel a Booking, you should use the mechanisms available through the
            Home Pet Vet Service to do so. For purposes of the policies and terms in this Section,
            the date of cancellation is the date that a user cancels through the Home Pet Vet
            Service, regardless of any separate communications between users outside of the Home Pet
            Vet Service.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span>{' '}
            <span variant="body2" style={{ fontStyle: 'italic', fontWeight: '900' }}>
              Payment Disputes; Payment Outside of the Home Pet Vet Service.{' '}
            </span>{' '}
            TAV initiates payments to Pet Providers forty (48) hours after completion of a Booking.
            Once these amounts have been disbursed, any further payment disputes are between the Pet
            Owner and Pet Provider, and TAV has no obligation to mediate or facilitate any
            resolution. Further, TAV has no responsibility or liability with respect to any tips,
            bonuses, or other payments made outside of the Home Pet Vet Service.
            <br />
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Payment Holds. </span>
            If you are a Pet Provider, Home Pet Vet reserves the right to issue a hold on amounts
            otherwise payable to you pursuant to these Terms if there is a reasonable suspicion of
            fraudulent activity involving your account(s) or for other similarly compelling reason
            involving protection of TAV, the TAV community or third-party rights.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Authorization to Charge. </span>
            When you pay for Pet Care Services or for other services on the Home Pet Vet Service,
            you will be required to provide us with valid, up-to-date credit card or other payment
            information and to maintain that payment information (or an acceptable alternative
            payment method) on file with your account so long as you have any outstanding, confirmed
            Bookings. TAV’s role is to facilitate payments from Pet Owners to Pet Providers as
            limited payment agent for the Pet Provider. You authorize us to charge your credit card
            or other payment method for fees you incur on the Home Pet Vet Service as they become
            due and payable, and to charge any alternative payment method TAV has on record for you
            in the event your primary payment method is expired, invalid, or otherwise not able to
            be charged. You are responsible for maintaining up-to-date payment information. If we
            cannot charge you for fees when due because your payment information is no longer valid,
            or if we do not receive your payment when due, then you understand that neither TAV nor
            the Pet Provider will be responsible for any failure to provide services associated with
            those fees. Except as expressly provided in these Terms, all fees paid via the Home Pet
            Vet Service are non-refundable once paid.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Taxes. </span>
            Except for taxes on Home Pet Vet’s income and gross receipts or where Home Pet Vet is
            otherwise required to collect taxes, you acknowledge that you are solely responsible to
            pay any applicable taxes that arise as a result of your purchase, provision, or use of
            Pet Care Services via the Home Pet Vet Service.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Background Checks and Identity Verifications.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            Home Pet Vet may provide Pet Providers with access to third party consumer reporting
            agencies that perform, among other things, personal identification verification services
            (“<span style={{ fontWeight: '800' }}>Identity Verification</span>”) or criminal records
            checks, sex offender registry checks, motor vehicle records checks and/or identification
            verifications (collectively, “
            <span style={{ fontWeight: '800' }}>Background Checks</span>”). If you undergo a
            Background Check or Identity Verification via the Home Pet Vet Service, you hereby
            consent to the collection, use and disclosure of the information in the Background Check
            or Identity Verification, and you agree to provide complete and accurate information for
            your Background Check or Identity Verification. You agree that Home Pet Vet’s rights and
            obligations under the Arbitration Agreement inure to the benefit of the consumer
            reporting agency used for Background Checks or Identity Verification in respect of any
            claim that would be subject to the Arbitration Agreement if brought against us. Home Pet
            Vet reserves the right to suspend or terminate your access to the Home Pet Vet Service
            based on information in the Background Check or Identity Verification or for any other
            reason, or no reason, in our sole discretion. Pet Owners remain fully responsible to
            evaluate and investigate their Pet Providers.The money that a Pet Provider pays to a 
            third-party consumer reporting agencies to perform the Identity Verification and 
            Background Check, as set forth in this Section of the Terms, shall not be refunded 
            to the Pet Provider
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Copyright Infringement.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            If you believe in good faith that your copyrighted work has been infringed by content
            posted on the Home Pet Vet Service, please provide our designated copyright agent with a
            written notice that includes all of the following information:
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> A description of the URL
            or other location on our Site of the material you believe to be infringing;
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> Your name, mailing
            address, telephone number and email address.
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> A statement that you have
            a good faith belief that the disputed use is not authorized by the copyright owner, its
            agent, or the law;
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> A statement by you, which
            you make under penalty of perjury, that the above information in your notice is
            accurate, and that you are the copyright owner or authorized to act on the copyright
            owner’s behalf; and
            <br />
            <span style={{ fontWeight: '900', fontSize: '900' }}>•</span> An electronic or physical
            signature of the person authorized to act on behalf of the copyright owner.
            <br />
            <br />
            Our designated agent for notice of copyright infringement can be reached at:
            <br />
            A Place for Home Pet Vet, Inc.
            <br />
            Attention: Copyright Notice
            <br />
            7900 SE 28th St Suite 320 Mercer Island, WA 98040
            <br />
            copyright@HomePetVet.com
            <br />
            <span style={{ fontWeight: '900' }}>Third Party Services, Links. </span> The Home Pet
            Vet Service may contain links to third party websites or resources. You acknowledge and
            agree that we are not responsible or liable for: (i) the availability or accuracy of
            such websites or resources; or (ii) the content, products, or services on or available
            from such websites or resources. Links to such websites or resources do not imply any
            endorsement of such websites or resources, or the content, products, or services
            available from such websites or resources. You acknowledge sole responsibility for and
            assume all risk arising from your use of any such websites or resources.
            <br />
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ fontWeight: '900' }}>Indemnity. </span>
            TO THE MAXIMUM EXTENT PERMITTED UNDER ANY APPLICABLE LAW AND EXCEPT AS OTHERWISE
            PROHIBITED BY APPLICABLE LAW, YOU AGREE TO DEFEND, INDEMNIFY AND HOLD TAV HARMLESS FROM
            AND AGAINST ANY AND ALL CLAIMS, DEMANDS, CAUSES OF ACTION, LOSSES, EXPENSES, DAMAGES
            AND/OR LIABILITIES, INCLUDING REASONABLE ATTORNEYS’ FEES AND COURT COSTS, THAT ARE IN
            ANY WAY RELATED TO YOUR: (1) transactions and interactions, online or offline, with
            other users of the Home Pet Vet Service; (2) breach of these Terms; (3) disputes with
            other users of the Home Pet Vet Service; (4) your misstatements, misrepresentations, or
            violation of applicable law; (5) property damage or personal injury to third parties
            caused by your pet or pets in your care; (6) Your Content; or (7) your use of any
            Background Check or Identify Verification information in violation of any applicable
            law. YOU FURTHER AGREE THAT YOU WILL COOPERATE WITH US IN THE DEFENSE OF SUCH CLAIMS. WE
            RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO
            INDEMNIFICATION UNDER THIS SECTION, AND YOU WILL NOT SETTLE ANY SUCH CLAIM OR MATTER
            WITHOUT OUR ADVANCE WRITTEN CONSENT.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Intellectual Property.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Home Pet Vet Service. </span>
            TAV and its licensors retain all right, title and interest in and to the Home Pet Vet
            Service, the technology and software used to provide it, all electronic documentation
            and content available through the Home Pet Vet Service (other than Your Content), and
            all intellectual property and proprietary rights in the Home Pet Vet Service and such
            technology, software, documentation and content. Except for your rights to access and
            use the Home Pet Vet Service set forth in these Terms, nothing in these Terms licenses
            or conveys any of our intellectual property or proprietary rights to anyone, including
            you. You agree that we will have a perpetual right to use and incorporate into the Home
            Pet Vet Service any feedback or suggestions for improvement that you provide to us
            concerning the Home Pet Vet Service, without any obligation of compensation.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Home Pet Vet Trademarks. </span>
            TAV owns all rights in and to its trademarks, service marks, brand names and logos (the
            “<span style={{ fontWeight: '900' }}>TAV Marks</span>”). If you are a Pet Provider, TAV
            grants you, for so long as you are in good standing on the Home Pet Vet Service, a
            limited, revocable, non-exclusive, non-transferable license to use the TAV Marks solely:
            (1) in the form incorporated into goods, including customizable marketing collateral
            (promo cards, signage etc.), made available for purchase via the TAV Store and/or (2) in
            any other manner specifically authorized in writing via the Home Pet Vet Service. As a
            condition of exercising such license, you agree that (1) all goodwill associated with
            your use of the TAV Marks inures solely to the benefit of TAV, and (2) such license
            immediately terminates upon your ceasing to be a Pet Provider in good standing, whether
            at your own option or because TAV suspends or terminates your rights to use the Home Pet
            Vet Service.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Limitation of Liability.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline', fontWeight: '900' }}>
              Exclusion of Certain Types of Damages.{' '}
            </span>
            <span style={{ fontWeight: '900' }}>
              {' '}
              To the maximum extent permitted under applicable law, in no event will TAV be liable
              to you for any indirect, special, incidental, or consequential damages, or for any
              business losses, or loss of profit, revenue, contracts, data, goodwill or other
              similar losses or expenses that arise out of or relate to the use of or inability to
              use the Home Pet Vet Service, including without limitation damages related to any
              information received from the Home Pet Vet Service, removal of your profile
              information or review (or other content) from the Home Pet Vet Service, any suspension
              or termination of your access to the Home Pet Vet Service, or any failure, error,
              omission, interruption, defect, delay in operation or transmission of the Home Pet Vet
              Service, even if we are aware of the possibility of any such damages, losses or
              expenses.{' '}
            </span>
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Limit on Our Liability to You. </span>
            EXCEPT FOR THE LIMITED REIMBURSEMENTS APPROVED BY TAV UNDER THE TAV GUARANTEE AND THE
            REFUND AMOUNTS PAYABLE BY TAV THAT ARE SET FORTH IN THESE TERMS, AND EXCEPT WHERE
            PROHIBITED BY APPLICABLE LAW, IN NO EVENT WILL TAV’S AGGREGATE LIABILITY TO YOU OR ANY
            THIRD PARTY IN ANY MATTER ARISING FROM OR RELATING TO THE Home Pet Vet Service OR THESE
            TERMS EXCEED THE AMOUNTS PAID BY YOU TO TAV (SPECIFICALLY EXCLUDING AMOUNTS PAID TO Pet
            Providers) DURING THE TWELVE (12) MONTHS PRECEDING THE EVENT THAT GAVE RISE TO LIABLITY
            OR, IF YOU HAVE NOT PAID TAV FOR THE USE OF ANY SERVICES, THE AMOUNT OF $100.00 USD (OR
            EQUIVALENT IN LOCAL CURRENCY).
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              No Liability for non-Home Pet Vet Actions.
            </span>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL TAV BE LIABLE FOR
            ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, AND/OR
            CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN
            CONNECTION WITH THE Home Pet Vet Service, INCLUDING WITHOUT LIMITATION, BODILY INJURY,
            DEATH, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM RELIANCE ON
            INFORMATION OR CONTENT POSTED ON OR TRANSMITTED THROUGH THE Home Pet Vet Service, OR FOR
            ANY INTERACTIONS WITH OTHER USERS OF THE Home Pet Vet Service, WHETHER ONLINE OR
            OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS
            WHO ATTEMPT TO DEFRAUD OR HARM YOU. IF YOU HAVE A DISPUTE WITH A Pet Provider OR PET
            OWNER, YOU AGREE TO RELEASE Home Pet Vet FROM ALL CLAIMS, DEMANDS AND DAMAGES OF EVERY
            NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES,
            EXCEPT TO THE EXTENT STATED IN THE Home Pet Vet GUARANTEE. IN NO EVENT WILL Home Pet Vet
            BE LIABLE FOR DIRECT OR INDIRECT CONSEQUENCES OF A PET OWNER OR Pet Provider FAILING TO
            COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Arbitration Agreement.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ fontWeight: '900' }}>
              {' '}
              PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN
              DISPUTES AND CLAIMS WITH TAV AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM
              US.
            </span>
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Arbitration Agreement; Claims. </span>
            This Section is referred to as the "Arbitration Agreement” in these Terms. Unless you
            opt out of the Arbitration Agreement in accordance with the procedure described below,
            you and TAV (together, the “Parties”) agree that any and all disputes or claims that
            arise between you and TAV relating to the Home Pet Vet Service, interactions with others
            on the Home Pet Vet Service, and/or these Terms (including any alleged breach of these
            Terms) (collectively, “<span style={{ fontWeight: '900' }}>Claims</span> ”).
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Prohibition of Class and Representative Actions and Non-Individualized Relief.{' '}
            </span>
            <span style={{ fontWeight: '900' }}>
              {' '}
              YOU AND TAV AGREE THAT, UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT IN ACCORDANCE
              WITH THE TERMS, EACH OF US MAY BRING CLAIMS (EXCEPT TO THE EXTENT THAT THE RIGHT TO
              BRING A CLAIM IN A PURPORTED CLASS OR REPRESENTATIVE ACTION CANNOT BE WAIVED AS A
              MATTER OF LAW) AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS
              BOTH YOU AND TAV AGREE OTHERWISE (OR TO THE EXTENT THAT THE RIGHT TO BRING A CLAIM IN
              A PURPORTED CLASS OR REPRESENTATIVE ACTION CANNOT BE WAIVED AS A MATTER OF LAW), THE
              ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AND
              MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
              PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
              DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO
              THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL
              CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER HOME PET VET SERVICES USERS.{' '}
            </span>
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>
              Pre-Arbitration Dispute Resolution.{' '}
            </span>
            Before you commence arbitration, we suggest that you contact us to explain your
            complaint. Our preference will always be to resolve complaints amicably and efficiently,
            without the need for arbitration. You may contact us via email at
            <p variant="body2" style={{ fontStyle: 'italic' }}>
              legal@Homepetvet.com
            </p>
            or by mail at a place for TAV, Attn: Legal, 7900 SE 28th St Suite 320 Mercer Island, WA
            98040. If the issue is not resolved and results in arbitration, we will need to mutually
            select and agree upon an arbitrator and the rules and procedures to govern the
            arbitration.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Arbitration Procedures. </span>
            The Parties will try in good faith to mutually select and agree upon an arbitrator and
            rules and procedures to govern the arbitration. If we cannot agree, then the arbitration
            will be conducted in English by a neutral arbitrator in accordance with your choice of
            the American Arbitration Association “AAA”. The rules that apply are those in effect
            when arbitration is demanded by either of the Parties. Venue shall be Seattle,
            Washington and the law of the State of Washington will control and be used for this
            arbitration. The arbitrator must also follow the provisions of these Terms as a court
            would, including without limitation, the limitation of liability provisions in the
            Terms. The arbitrator shall submit a decision in writing, specifying the findings of
            fact and the conclusions of law on which the decision is based. Decisions by the
            arbitrator are binding and enforceable in court.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            The arbitrator will decide the substance of all claims in accordance with the laws of
            the State of Washington, including recognized principles of equity, and will honor all
            claims of privilege recognized by law. The arbitrator will have (1) the exclusive
            authority and jurisdiction to make all procedural and substantive decisions regarding a
            Claim, including the determination of whether a Claim is arbitrable, and (2) the
            authority to grant any remedy that would otherwise be available in court.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Costs of Arbitration. </span>
            Payment of all filing, administration, and arbitrator fees (collectively, the "
            <span style={{ fontWeight: '800' }}>Arbitration Fees</span>") will be shared equally by
            you and TAV. In the event the arbitrator determines the claim(s) you assert in the
            arbitration to be frivolous, you agree to reimburse TAV for all fees associated with the
            arbitration paid by TAV on your behalf that you otherwise would be obligated to pay
            under the AAA rules.
          </p>
          <br />
          <p variant="body2" className={classes.body} gutterBottom>
            <span style={{ textDecorationLine: 'underline' }}>Confidentiality. </span>
            All aspects of the arbitration proceeding, and any ruling, decision or award by the
            arbitrator, will be strictly confidential for the benefit of all parties.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Governing Law and Jurisdiction.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            These Terms, and any dispute between you and TAV, will be governed by the laws of the
            State of Washington. You and TAV agree to submit to the personal jurisdiction of the
            courts located within Seattle, Washington for the purpose of litigating all such claims
            or disputes.
          </p>
          <br />
          <p variant="h4" className={classes.title_} gutterBottom>
            Miscellaneous.
          </p>
          <p variant="body2" className={classes.body} gutterBottom>
            Nothing in these Terms will be construed as making either party the partner, joint
            venturer, agent, legal representative, employer, worker, or employee of the other.
            Neither party will have, or hold itself out to any third party as having, any authority
            to make any statements, representations or commitments of any kind, or to take any
            action, that will be binding on the other, except as provided for herein or authorized
            in writing by the party to be bound. These Terms are non-exclusive and do not prohibit
            Pet Providers from offering pet care services via other means or third parties. The
            invalidity, illegality or unenforceability of any term or provision of these Terms will
            in no way affect the validity, legality or enforceability of any other term or provision
            of these Terms. In the event a term or provision is determined to be invalid or
            unenforceable, the parties agree to replace such term or provision with a term or
            provision that is valid and enforceable and that comes closest to expressing the
            intention of the invalid or unenforceable term or provision, and these Terms will be
            enforceable as so modified. To the maximum extent possible under applicable local law,
            this Agreement will be binding on and will inure to the benefit of the legal
            representatives, successors and assigns of the parties hereto. For questions or concerns
            about the Home Pet Vet Service or these Terms, please refer to the TAV Customer Care
            Center.
          </p>
          <br />
          <p variant="body2" style={{ fontStyle: 'italic' }} className={classes.body} gutterBottom>
            Last modified – June, 14th, 2023
          </p>
        </Container>
      </div>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  availability: state.forms.availability
});

export default connect(mapStateToProps, null)(AboutUs);
